<form [formGroup]="editProfileBioForm" (ngSubmit)="onSubmitEditProfileNameForm()" class="wrapper-edit-info-page">
  <span class="title-edit-page">Bio</span>
  <span class="subtitle-edit-page">Change your account bio.</span>
  <div class="wrapper-input textarea" [ngClass]="{'error': editProfileBioForm.controls['bio'] | formControlErrorMessages: null : formTriedToSubmit}">
    <span class="label-input">Bio</span>
    <div class="custom-input">
      <textarea formControlName="bio" [maxLength]="150"></textarea>
    </div>
    <div *ngIf="editProfileBioForm.controls['bio'] | formControlErrorMessages: null: formTriedToSubmit" class="wrapper-error-message">
      <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
      <span>{{editProfileBioForm.controls['bio'] | formControlErrorMessages: null: formTriedToSubmit}}</span>
    </div>
    <span class="label-info-input" *ngIf="!(editProfileBioForm.controls['bio'] | formControlErrorMessages: null: formTriedToSubmit)">{{editProfileBioForm.controls['bio'].value ? (150 - editProfileBioForm.controls['bio'].value.length) + ' characters left.' : 'The maximum length of a bio is 150 characters.'}}</span>
  </div>
  <div class="buttons-edit-page">
    <button type="button" (click)="onCancelChanges()" class="custom-btn custom-btn--outline">
      <span>Cancel</span>
    </button>
    <button type="submit" class="custom-btn custom-btn--primary">
      <span *ngIf="!isSpinnerActive">Save changes</span>
      <tl-spinner *ngIf="isSpinnerActive"></tl-spinner>
    </button>
  </div>
</form>
