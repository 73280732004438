import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { from, Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { ListsHttpService } from 'src/app/services/http-services/lists/lists-http.service';

@Component({
  selector: 'app-recommended-lists',
  templateUrl: './recommended-lists.component.html',
})
export class RecommendedListsComponent implements OnInit {
  recommendedLists: any[] = [];
  pagination!: TlPagination;
  pageNum = 1;
  loading = false;
  private searchSubject = new Subject<string>();
  searchTerm = '';
  @ViewChild('recommendedListsContainter', { static: false }) private recommendedListsContainter!: ElementRef;
  PAGES_AND_PATHS = PAGES_AND_PATHS;

  constructor(
    private listsHttpService: ListsHttpService
  ) { }

  ngOnInit(): void {
    this.getRecommendedLists();
    this.searchSubject.pipe(
      debounceTime(300),
      switchMap((searchTerm: string) => from(this.getRecommendedLists(searchTerm)))
    ).subscribe(response => {
      this.recommendedLists = response.data;
      this.pagination = response.pagination;
    });
  }

  getRecommendedLists(searchTerm?: string) {
    this.loading = true;
    return (
      this.listsHttpService.getRecommendedLists(this.pageNum, searchTerm).then((response: any) => {
        console.log('recommendedLists', response)
        this.recommendedLists = response.data;
        this.pagination = response.pagination;
        this.loading = false;
        return response;
      })
    )
  }

  onScroll(): void {
    console.log('boop')
    const container = this.recommendedListsContainter.nativeElement;
    const scrollTop = container.scrollTop;
    const offset = 100;

    if (!this.loading && scrollTop < offset && !this.loading && ((this.pagination?.currentPage ?? 0) < (this.pagination?.lastPage ?? 0))) {
      this.pageNum++;
      this.getRecommendedLists();
    }
  }

  onSearchChange(event: any): void {
    this.searchTerm = event.target.value;
    this.pageNum = 1;
    this.searchSubject.next(this.searchTerm);
  }

  resetSearch() {
    this.searchTerm = '';
    this.searchSubject.next('');
  }

}
