<div class="wrapper-page">
  <app-my-content></app-my-content>
  <div class="wrapper-single-item-page">
    <tl-header></tl-header>
    <div class="wrapper-content-single-page">
      <div class="header-single-page-item">
        <div class="background-color-single-item"></div>
      </div>
      <div class="content-single-item-page">
        <div class="wrapper-image-name-item">
          <div class="wrapper-image-item-single-view">
            <div *ngFor="let image of item?.images | slice:0:3" class="image">
              <img [src]="image.url" alt="">
              <div *ngIf="loggedUser.id === item.lyst.user_id" (click)="deleteImage(image)" class="delete-image-item">
                <img src="../../../assets/icons/outline/icon-delete.svg" alt="">
              </div>
            </div>
            <div *ngIf="item !== undefined && item.images.length < 3 && loggedUser.id === item.lyst.user_id" class="wrapper-add-image-item">
              <label class="add-image-item">
                <img (click)="triggerFileInput($event)" src="../../../assets/icons/outline/icon-plus.svg" alt="">
                <input #fileInput type="file" accept=".jpg, .jpeg, .png, .svg" (change)="onFileChange($event)">
              </label>
            </div>
          </div>
          <span>{{item?.name}}</span>
        </div>

        <div class="wrapper-likes-and-more-btn">
          <div class="wrapper-like-rating-single-item">
            <div class="wrapper-likes">
              <button (click)="additemToFavorites()" class="custom-btn custom-btn--circle-outline">
                <img *ngIf="!item?.am_i_favorite_this_lyst_item" class="no-like"
                  src="../../../../assets/icons/outline/icon-heart.svg" alt="">
                <img *ngIf="item?.am_i_favorite_this_lyst_item" class="like"
                  src="../../../../assets/icons/fill/icon-heart.svg" alt="">
              </button>
              <span [routerLink]="[PAGES_AND_PATHS.listLikes.pagePath, item?.id, 'item']">{{item?.favorite_users_count}}
                {{item?.favorite_users_count === 1 ? 'Like' : 'Likes'}}</span>
            </div>
            <span class="vertical-line"></span>
            <app-item-rating [averageRating]="item?.average_rating"
              [reviewsCount]="item?.reviews_count"></app-item-rating>
          </div>
          <div class="wrapper-dropdown-btn" appClickedOutside (clickedOutside)="isViewMoreModalOpen = false">
            <button (click)="openDropdown($event)" class="custom-btn custom-btn--circle-outline">
              <img src="../../../../assets/icons/outline/icon-view-more.svg" alt="">
            </button>
            <div *ngIf="isViewMoreModalOpen" class="content-dropdown-btn">
              <div (click)="isShareProfileModalOpen = true" class="item-dropdown">
                <img src="../../../../assets/icons/outline/icon-share.svg" alt="">
                <span>Share</span>
              </div>
              <div (click)="reportItem()" class="item-dropdown delete">
                <img src="../../../../assets/icons/outline/icon-outline-attention.svg" alt="">
                <span>Report</span>
              </div>
              <div *ngIf="loggedUser.id === item?.lyst?.user_id" (click)="openDeleteModal()"
                class="item-dropdown delete">
                <img src="../../../../assets/icons/outline/icon-delete.svg" alt="">
                <span>Delete item</span>
              </div>
            </div>
          </div>
        </div>
        <div class="wrapper-custom-tabs">
          <div class="custom-tab" [ngClass]="{'active': tabSelected === 'info'}" (click)="selectTab('info')">
            <img src="../../../../../assets/icons/outline/icon-my-lists.svg" alt="">
            <span>Info</span>
          </div>
          <div class="custom-tab" [ngClass]="{'active': tabSelected === 'posts'}" (click)="selectTab('posts')">
            <img src="../../../../../assets/icons/outline/icon-posts.svg" alt="">
            <span>Posts</span>
          </div>
          <div class="custom-tab" [ngClass]="{'active': tabSelected === 'reviews'}" (click)="selectTab('reviews')">
            <img src="../../../../assets/icons/outline/icon-review.svg" alt="">
            <span>Reviews</span>
          </div>
        </div>
        <app-info *ngIf="tabSelected === 'info'" [item]="item"></app-info>
        <app-posts *ngIf="tabSelected === 'posts'" [item]="item" [list]="item.lyst" [id]="itemId" [isList]="false"></app-posts>
        <app-reviews *ngIf="tabSelected === 'reviews'" [reviewObj]="item" [isList]="false" (refreshSinglePage)="getSingleItem()"></app-reviews>
      </div>
    </div>
  </div>
</div>
<tl-sharing-options-modal *ngIf="isShareProfileModalOpen" [entityType]="ShareEntitiesEnums.lyst_item"
  [entityId]="itemId" [urlForShare]="itemUrl"
  (closeModal)="isShareProfileModalOpen = false; isViewMoreModalOpen = false;">
</tl-sharing-options-modal>
<tl-confirm-modal *ngIf="isDeleteModalOpen" [modalIcon]="'../../../../../assets/icons/outline/icon-delete.svg'"
  [modalTitle]="modalText.title" [confirmBtnText]="modalText.button" [modalDescription]="modalText.description"
  (cancelModal)="isDeleteModalOpen = false" (confirmModal)="deleteItem()">
</tl-confirm-modal>
<app-report-modal *ngIf="isReportModalOpen" (closeModal)="isReportModalOpen = false"></app-report-modal>