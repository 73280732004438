import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {SettingsHttpService} from "../../../../services/http-services/settings/settings-http.service";
import {
  FormControlErrorMessagesModule
} from "../../../../pipes/form-control-error-messages/form-control-error-messages.module";
import {NgClass, NgIf} from "@angular/common";
import {AuthenticationHelperService} from "../../../../services/helpers/authentication-helper.service";
import {PAGES_AND_PATHS} from "../../../../constants/pages-and-paths";

@Component({
  selector: 'tl-delete-account',
  standalone: true,
  imports: [
    FormControlErrorMessagesModule,
    NgIf,
    ReactiveFormsModule,
    NgClass
  ],
  templateUrl: './delete-account.component.html',
})
export class DeleteAccountComponent implements OnInit{
  isPasswordVisible = false;
  formTriedToSubmit = false;
  deleteAccountForm!: FormGroup;
  isSpinnerActive = false;
  constructor(private formBuilder: FormBuilder,
              private toastService: ToastrService,
              private settingsHttpService: SettingsHttpService,
              private authHelperService: AuthenticationHelperService) { }

  ngOnInit(): void {
    this.buildForm();
  }
  buildForm(): void {
    this.deleteAccountForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  onSubmitDeleteAccountForm(): void {
    this.formTriedToSubmit = true;
    if (this.deleteAccountForm.valid) {
      this.isSpinnerActive = true;
      this.settingsHttpService.deleteAccount(this.deleteAccountForm.value).then(res => {
        this.isSpinnerActive = false;
        this.authHelperService.clientLogout(PAGES_AND_PATHS.login.pagePath);
      }).catch(({error}: {error:{ errors: { [key: string]: string }}}) => {
        this.isSpinnerActive = false;
        Object.values(error.errors).forEach(error => this.toastService.error(error));
      });
    }
  }
}
