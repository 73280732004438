import { NgForOf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { ListType } from 'src/app/enums/list-enums';
import { TimeAgoModule } from 'src/app/pipes/time-ago/time-ago.module';
import { SingleListItem } from 'src/app/services/http-services/lists/lists-http-intterfaces';
import { ListsHttpService } from 'src/app/services/http-services/lists/lists-http.service';

@Component({
  selector: 'app-lists',
  standalone: true,
  imports: [RouterModule, NgForOf, TimeAgoModule],
  templateUrl: './lists.component.html',
})
export class ListsComponent implements OnInit {
  pageNum = 1;
  lists: SingleListItem[] = [];
  activeListType: ListType = ListType.all;
  PAGES_AND_PATHS = PAGES_AND_PATHS;
  ListType = ListType;

  constructor(
    private listsHttpService: ListsHttpService
  ){}

  ngOnInit(): void {
    this.getMyLists(this.pageNum, this.activeListType);
  }

  getMyLists(pagNumber: number, listType: ListType): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.listsHttpService.getMyLists(pagNumber, listType).then((res: any) => {
        try {
          this.lists = [...this.lists, ...res.data];
        } catch (e) {
        }
        resolve(true);
      }, error => {
        reject(false);
      })
    });
  }
}
