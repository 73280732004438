import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PAGES_AND_PATHS} from "../../constants/pages-and-paths";
import {AuthGuardService} from "../../guards/auth-guard.service";
import {SettingsComponent} from "./settings.component";

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.settings.pageInRouting,
    data: { data: PAGES_AND_PATHS.settings },
    component: SettingsComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./settings.module').then(m => m.SettingsModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
