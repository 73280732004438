import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ListsHelperService {
  private refreshSingleList = new BehaviorSubject<boolean>(false);
  refreshSingleListPage = this.refreshSingleList.asObservable();
  private filterSingleList = new BehaviorSubject<any>({sort: 'asc', id: 0});
  filterSingleListPage = this.filterSingleList.asObservable();

  constructor() { }

  refreshPage(refresh: boolean): void {
    this.refreshSingleList.next(refresh);
  }

  filterSingleListItems({sort, id}: {sort: string, id: number}): void {
    this.filterSingleList.next({sort, id});
  }

  checkIfWithin48Hours(createdAt: string): boolean {
    // Parse the created_at timestamp to a Date object
    const createdDate = new Date(createdAt);
  
    // Get the current date
    const currentDate = new Date();
  
    // Calculate the difference in milliseconds
    const diffInMs = currentDate.getTime() - createdDate.getTime();
  
    // Convert milliseconds to hours
    const diffInHours = diffInMs / (1000 * 60 * 60);
  
    // Check if the difference is less than 48 hours
    return diffInHours < 48;
  }
  
}
