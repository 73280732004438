import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyContentComponent } from './my-content.component';
import { TimeAgoModule } from 'src/app/pipes/time-ago/time-ago.module';
import { RouterModule } from '@angular/router';
import { ListsComponent } from './lists/lists.component';
import { ListersComponent } from './listers/listers.component';
import { PostsComponent } from '../posts/posts.component';



@NgModule({
  declarations: [
    MyContentComponent
  ],
  imports: [
    CommonModule,
    TimeAgoModule,
    RouterModule,
    ListsComponent,
    ListersComponent,
    PostsComponent
  ],
  exports: [
    MyContentComponent
  ]
})
export class MyContentModule { }
