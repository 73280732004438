<!--   Review box    -->
<div class="wrapper-box-review">
  <div class="box-review-left">
    <div class="number-rating">
      <span>{{reviewObj.average_rating}}</span>
    </div>
    <div class="wrapper-stars-box">
      <app-stars [average_rating]="reviewObj.average_rating"></app-stars>
    </div>
  </div>
  <div class="box-review-right">
    <span class="number-review">({{pagination?.totalItems}})</span>
    <span class="label-review">{{pagination?.totalItems === 1 ? 'review' : 'reviews'}}</span>
  </div>
</div>
<!--   Create review     -->
<div (click)="openCreateReviewPage()" [ngClass]="{'tl-not-allowed': !loggedUser.is_expert}" class="wrapper-btn-create-post">
  <div class="wrapper-info-author">
    <div class="author-post-image">
      <img src="./assets/images/avatar-user.png" alt="">
    </div>
    <div class="create-review-card-info">
      <span class="text-create-post">Rate and review</span>
      <div class="create-review-stars">
        <img src="./assets/icons/outline/icon-star-outline1.svg" alt="">
        <img src="./assets/icons/outline/icon-star-outline1.svg" alt="">
        <img src="./assets/icons/outline/icon-star-outline1.svg" alt="">
        <img src="./assets/icons/outline/icon-star-outline1.svg" alt="">
        <img src="./assets/icons/outline/icon-star-outline1.svg" alt="">
      </div>
    </div>
  </div>
</div>
<!-- Card review -->
<div *ngFor="let review of reviews" class="wrapper-card-post" [routerLink]="[PAGES_AND_PATHS.singleReview.pagePath, isList ? 'list' : 'item', reviewObj.id, review.id]">
  <div class="header-card-post">
    <div class="user-card-post">
      <img class="user-img" src="./assets/images/avatar-user.png" alt="">
      <div class="wrapper-user-card-info-post">
        <div class="user-name">
          <span>{{review.author.name}}</span>
          <img src="./assets/icons/fill/icon-badge.svg" alt="">
        </div>
        <span>{{review.created_at | timeAgo}}</span>
      </div>
    </div>
    <div class="wrapper-dropdown-btn" appClickedOutside
      (clickedOutside)="isDropdownOpen = false; selectedReview = null">
      <div (click)="openViewMore(review, $event)" class="view-more-post">
        <img src="../../../../assets/icons/outline/icon-view-more.svg" alt="">
      </div>
      <div *ngIf="isDropdownOpen && selectedReview.id === review.id" class="content-dropdown-btn">
        <div *ngIf="loggedUser?.id === review.user_id && selectedReview.id === review.id && listsHelperService.checkIfWithin48Hours(review.created_at)"
          class="item-dropdown" (click)="openEditReview(review)">
          <img src="../../../../assets/icons/outline/icon-edit.svg" alt="">
          <span>Edit review</span>
        </div>
        <div (click)="shareReview(review)" class="item-dropdown">
          <img src="../../../../assets/icons/outline/icon-share.svg" alt="">
          <span>Share</span>
        </div>
        <div class="item-dropdown delete">
          <img src="../../../../assets/icons/outline/icon-outline-attention.svg" alt="">
          <span>Report</span>
        </div>
        <div *ngIf="review?.user_id === loggedUser.id" (click)="openDeleteModal(review)" class="item-dropdown delete">
          <img src="../../../../assets/icons/outline/icon-delete.svg" alt="">
          <span>Delete review</span>
        </div>
      </div>
    </div>
  </div>
  <div class="content-card-post">
    <div class="wrapper-review card">
      <div class="wrapper-rating">
        <app-stars [average_rating]="review.rating"></app-stars>
      </div>
      <span>{{review.review}}</span>
    </div>
    <div class="wrapper-toolbar-post">
      <div class="wrapper-toolbar-left">
        <span>{{review.likes_count}} {{review.likes_count === 1 ? 'like' : 'likes'}} |</span>
        <span> {{review.comments_count}} {{review.comments_count === 1 ? 'comment' : 'comments'}}</span>
      </div>
      <div class="wrapper-toolbar-right">
        <div (click)="toggleLike(review);$event.stopPropagation()" class="wrapper-like" [ngClass]="{'liked': review.am_i_liked_this}">
          <img class="no-like" src="../../../../../assets/icons/outline/icon-like.svg" alt="">
          <img class="like" src="../../../../../assets/icons/fill/icon-like.svg" alt="">
          <span>Like</span>
        </div>
        <div (click)="shareReview(review);$event.stopPropagation()" class="wrapper-share">
          <img src="../../../../../assets/icons/outline/icon-share.svg" alt="">
          <span>Share</span>
        </div>
      </div>
    </div>
    <form class="wrapper-write-comment">
      <div class="avatar-comment">
        <img src="../../../assets/images/avatar-user.png" alt="">
      </div>
      <div class="wrapper-input">
        <div class="custom-input">
          <input type="text" disabled placeholder="Write a comment...">
        </div>
      </div>
      <button type="submit" class="custom-btn custom-btn--circle-primary">
        <img src="../../../../../assets/icons/outline/icon-send.svg" alt="">
      </button>
    </form>
  </div>
</div>
<tl-confirm-modal *ngIf="isDeleteModalOpen" [modalIcon]="'../../../../../assets/icons/outline/icon-delete.svg'"
  [modalTitle]="modalText.title" [confirmBtnText]="modalText.button" [modalDescription]="modalText.description"
  (cancelModal)="isDeleteModalOpen = false" (confirmModal)="takeAction()">
</tl-confirm-modal>
<tl-sharing-options-modal *ngIf="isSharePostModalOpen" [entityType]="ShareEntitiesEnums.review"
  [entityId]="selectedReview.id" [urlForShare]="reviewUrl"
  (closeModal)="isSharePostModalOpen = false"></tl-sharing-options-modal>