<div class="wrapper-page wrapper-my-list">
  <tl-header></tl-header>
  <div class="wrapper-header-my-lists">
    <div class="header-my-lists">
      <span>My lists</span>
      <button (click)="reorder()" [ngClass]="{'active': isReorder}" class="custom-btn custom-btn--gray">
        <img src="../../../../assets/icons/outline/icon-reorder.svg" alt="">
      </button>
    </div>
  </div>
  <div class="wrapper-content-my-lists">
    <div class="content-my-lists">
      <div class="filter-my-lists">
        <div class="wrapper-custom-search">
          <img class="icon-search" src="../../../assets/icons/outline/icon-search.svg" alt="">
          <input type="text" placeholder="Search..." [(ngModel)]="searchTerm" (input)="onSearchChange($event)">
          <div (click)="resetSearch()" class="wrapper-delete-search">
            <img src="../../../assets/icons/outline/icon-close.svg" alt="">
          </div>
        </div>
        <button [routerLink]="[PAGES_AND_PATHS.createList.pagePath]" class="custom-btn custom-btn--outline">
          <span>New list</span>
          <img src="../../../../assets/icons/outline/icon-plus.svg" alt="">
        </button>
      </div>
      <div class="wrapper-custom-tabs-purple">
        <!--     TODO JS - Dodajes klasu "active" u zavisnosti na koji je tab kliknuto     -->
        <!--     TODO JS - Sredio sam sve preko scss da se menjaju ikonice, da se boji, da se pojavljuje tekst, znaci samo dodajes klasu "active"     -->
        <div class="custom-tab-purple" [ngClass]="{'active': activeListType === 'all'}"
          (click)="changeListType(ListType.all)">
          <span>All</span>
        </div>
        <div class="custom-tab-purple active" [ngClass]="{'active': activeListType === 'public'}"
          (click)="changeListType(ListType.public)">
          <img class="no-active" src="../../../../assets/icons/outline/icon-global.svg" alt="">
          <img class="active" src="../../../../assets/icons/fill/icon-globe.svg" alt="">
          <span class="visibility-text">Public lists</span>
        </div>
        <div class="custom-tab-purple" [ngClass]="{'active': activeListType === 'private'}"
          (click)="changeListType(ListType.private)">
          <img class="no-active" src="../../../../assets/icons/outline/icon-lock.svg" alt="">
          <img class="active" src="../../../../assets/icons/fill/icon-lock.svg" alt="">
          <span class="visibility-text">Private lists</span>
        </div>
      </div>
      <div class="wrapper-cards-list" (scroll)="onScroll($event)" cdkDropList [cdkDropListData]="lists"
        (cdkDropListDropped)="drop($event)">
        <div *ngFor="let list of lists" cdkDrag [cdkDragDisabled]="!isReorder"
          [routerLink]="[PAGES_AND_PATHS.singleList.pagePath, list.id, 0, 'cards']" class="wrapper-card-list">
          <!-- Custom Drag Preview -->
          <div class="custom-preview" *cdkDragPreview>
            <img class="image-list" [src]="list.lyst_image_url" alt="">
            <div class="wrapper-card-list-info">
              <div class="wrapper-badges">
                <div class="badge gray">
                  <span>{{list.lyst_items_count}} {{list.lyst_items_count === 1 ? 'item' : 'items'}}</span>
                </div>
              </div>
              <span class="name-list">{{list.title}}</span>
              <span class="time-list">{{list.created_at | timeAgo}}</span>
            </div>
            <div class="wrapper-right-element">
              <img *ngIf="isReorder" src="../../../../assets/icons/outline/icon-menu.svg" alt="">
            </div>
          </div>
          <img class="image-list" [src]="list.lyst_image_url" alt="">
          <div class="wrapper-card-list-info">
            <div class="wrapper-badges">
              <div class="badge gray">
                <span>{{list.lyst_items_count}} {{list.lyst_items_count === 1 ? 'item' : 'items'}}</span>
              </div>
            </div>
            <span class="name-list">{{list.title}}</span>
            <span class="time-list">{{list.created_at | timeAgo}}</span>
          </div>
          <div class="wrapper-right-element">
            <img *ngIf="isReorder" src="../../../../assets/icons/outline/icon-menu.svg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>