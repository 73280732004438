import { NgForOf } from '@angular/common';
import { Component, Input, SimpleChanges } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { SearchHelperService } from 'src/app/services/helpers/search-helper.service';
import { SearchHttpService } from 'src/app/services/http-services/search/search-http.service';

@Component({
  selector: 'app-near-you',
  standalone: true,
  imports: [NgForOf, RouterModule],
  templateUrl: './near-you.component.html',
})
export class NearYouComponent {
  @Input() searchTerm = '';
  @Input() isLiveSearch = false;
  pageNum = 1;
  nearYouData: any[] = [];
  pagination!: TlPagination;
  latitude: number | undefined;
  longitude: number | undefined;
  PAGES_AND_PATHS = PAGES_AND_PATHS;
  
  constructor(
    private searchHttpService: SearchHttpService,
    private searchHelperService: SearchHelperService
  ) { }

  ngOnInit(): void {
    this.requestLocation();
    // this.getNearYou();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.getNearYou();
    }
  }

  getNearYou(): void {
    this.searchHttpService.formattedGetNearYouResults(this.pageNum, this.latitude, this.longitude, this.isLiveSearch, this.searchTerm).then((response: any) => {
      this.nearYouData = [...this.nearYouData, ...response.data];
      this.pagination = response.pagination;
    })
  }

  requestLocation(): void {
    console.log('boo')
    this.searchHelperService.getLatAndLong().then((response:GeolocationPosition) => {
      this.latitude = response.coords.latitude;
      this.longitude = response.coords.longitude;
      this.getNearYou();
    }).catch((error) => {
     alert(error);
      console.error('Error getting location:', error);
    });
}

}
