import { Component } from '@angular/core';

@Component({
  selector: 'app-near-you',
  templateUrl: './near-you.component.html',
})
export class NearYouComponent {
  tabSelected = 'near-me';

  selectTab(selected: string): void {
    this.tabSelected = selected;
  }
}
