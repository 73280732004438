import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SingleItemComponent } from './single-item.component';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.singleItem.pageInRouting,
    data: { data: PAGES_AND_PATHS.singleItem },
    component: SingleItemComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./single-item.module').then(m => m.SingleItemModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SingleItemRoutingModule { }
