import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PAGES_AND_PATHS} from '../../constants/pages-and-paths';
import {HomeComponent} from './home.component';
import {AuthGuardService} from '../../guards/auth-guard.service';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.home.pageInRouting,
    data: {data: PAGES_AND_PATHS.home},
    component: HomeComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./home.module').then(m => m.HomeModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
