import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {UserRoles} from "../../../../../enums/general-enums";
import {DatePipe, NgClass, NgIf} from "@angular/common";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {ProfileHttpService} from "../../../../../services/http-services/profile/profile-http.service";
import {ToastrService} from "ngx-toastr";
import {AuthenticationHelperService} from "../../../../../services/helpers/authentication-helper.service";
import {ConfirmModalComponent} from "../../../../../shared-components/confirm-modal/confirm-modal.component";
import {EditProfileEnums} from "../../../../../enums/profile-enums";

@Component({
  selector: 'tl-edit-profile-index',
  standalone: true,
  imports: [
    DatePipe,
    FormsModule,
    NgIf,
    ConfirmModalComponent,
    ReactiveFormsModule,
    NgClass
  ],
  templateUrl: './edit-profile-index.component.html',
})
export class EditProfileIndexComponent implements OnInit, OnChanges {
  @Input() profileData: any;
  @Output() changeProfile: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeActiveComponent: EventEmitter<any> = new EventEmitter<any>();
  profileImage: any;
  MAX_FILE_SIZE = 10 * 1024 * 1024;
  formTriedToSubmit = false;
  isSpinnerActive = false;
  editProfileImageForm!: FormGroup;
  isMaxSizePhotoError = false;
  isRemoveImageModalOpen = false;
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  protected readonly UserRoles = UserRoles;

  constructor(private profileHttpService: ProfileHttpService,
              private formBuilder: FormBuilder,
              private toastService: ToastrService,) {
  }
  ngOnInit() {
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.profileData && changes.profileData.currentValue) {
      this.profileImage = changes.profileData.currentValue.profile_photo_url;
    }
  }

  buildForm(): void {
    this.editProfileImageForm = this.formBuilder.group({
      profile_photo: ['', Validators.required],
    });
  }

  triggerFileInput(event: Event): void {
    event.preventDefault();
    this.fileInput.nativeElement.click();
  }

  onFileChange(event: any): void {
    const file = event.target.files[0];
    console.log(file)
    if (file?.size > this.MAX_FILE_SIZE) {
      this.isMaxSizePhotoError = true;
      this.profileImage = null;
      this.editProfileImageForm.patchValue({ profile_photo: null });
    } else {
      this.isMaxSizePhotoError = false;
      this.editProfileImageForm.patchValue({ profile_photo: file });
      this.profileImage = URL.createObjectURL(file);
    }
    this.fileInput.nativeElement.value = '';
  }

  onConfirmRemoveFile(): void {
    this.profileImage = null;
    this.editProfileImageForm.patchValue({ profile_photo: null });
    this.isRemoveImageModalOpen = false;
  }

  onSubmitEditProfileImageForm(): void {
    this.formTriedToSubmit = true;
    if (this.editProfileImageForm.valid) {
      const formData = new FormData();
      const profilePhoto = this.editProfileImageForm.get('profile_photo')?.value;
      this.isSpinnerActive = true;
      formData.append('profile_photo', profilePhoto, profilePhoto.name);
      this.profileHttpService.changeProfileImage(formData).then(res => {
        this.isSpinnerActive = false;
        this.formTriedToSubmit = false;
        this.editProfileImageForm.reset();
        this.toastService.success('Successfully change profile photo.')
      }).catch(({ error }: { error: { errors: { [key: string]: string } } }) => {
        this.isSpinnerActive = false;
        Object.values(error.errors).forEach(error => this.toastService.error(error));
      });
    }
  }

  protected readonly EditProfileEnums = EditProfileEnums;
}
