import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ChatHttpService} from "../../../../services/http-services/chat/chat-http.service";
import {ProfileHttpService} from "../../../../services/http-services/profile/profile-http.service";
import {AuthenticationHelperService} from "../../../../services/helpers/authentication-helper.service";
import {FormsModule} from "@angular/forms";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {Subject} from "rxjs";
import {NgForOf, NgIf} from "@angular/common";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'tl-sharing-options-modal',
  standalone: true,
  imports: [
    FormsModule,
    NgIf,
    NgForOf
  ],
  templateUrl: './sharing-options-modal.component.html',
})
export class SharingOptionsModalComponent {
  @Input() urlForShare: string = '';
  @Input() entityType = '';
  @Input() entityId!: number;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  searchTerm$ = new Subject<string>();
  users: any[] = [];
  linkCopied = false;
  hasSelectedUsers = false;
  urlParams= {search_keyword: ''};
  socialApps = [
    { name: 'Facebook', icon: '/assets/images/social-media/social-media-fb.png' },
    { name: 'WhatsApp', icon: '/assets/images/social-media/social-media-whats-app.png' },
    { name: 'Viber', icon: '/assets/images/social-media/social-media-viber.png' },
    { name: 'X', icon: '/assets/images/social-media/social-media-x.png' },
    { name: 'Snapchat', icon: '/assets/images/social-media/social-media-snapchat.png' },
  ];
  constructor(private authHelperService: AuthenticationHelperService,
              private profileHttpService: ProfileHttpService,
              private chatHttpService: ChatHttpService,
            private toastService: ToastrService) {
  }

  ngOnInit(): void {
    this.searchTerm$.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe((searchTerm: any) => {
      this.urlParams.search_keyword = searchTerm;
      this.getUsers();
    });
    this.getUsers();
  }

  getUsers(): void {
    this.profileHttpService.getUserForShare(this.urlParams).then(res => {
      this.users = res.data;
    })
  }

  onSearchChange(event: any): void {
    this.searchTerm$.next(event.target.value);
  }

  clearSearch(): void {
    this.searchTerm$.next('');
  }

  onSelectUserChange(): void {
    this.hasSelectedUsers = this.users.some((user: any) => user.selected)
  }

  shareInternal(): void {
    this.users.forEach(user => {
      if (user.selected) {
        this.chatHttpService.postShareEntityToConversation({type: this.entityType, entity_id: this.entityId, participants: [this.authHelperService.getUserData.id, user.id]}).then(res => {
          this.toastService.success(res.message);
        })
      }
    });
    setTimeout(() => {
      this.closeModal.emit();
    });
  }
  async shareExternal(platform: string): Promise<void> {
    let shareUrl = '';
    const encodedUrl = encodeURIComponent(this.urlForShare);
    switch (platform.toLowerCase()) {
      case 'whatsapp':
        shareUrl = `https://wa.me/?text=${encodedUrl}`;
        break;
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
        break;
      case 'viber':
        shareUrl = `viber://forward?text=${encodedUrl}`;
        break;
      case 'x':
        shareUrl = `https://twitter.com/intent/tweet?url=${encodedUrl}`;
        break;
      case 'snapchat':
        shareUrl = `https://www.snapchat.com/share?url=${encodedUrl}`;
        break;
      default:
        console.error('Unsupported platform');
        return;
    }
    window.open(shareUrl, '_blank');
    setTimeout(() => {
      this.closeModal.emit();
    });
  }

  async shareExternalWithShareBtnOptions(): Promise<void> {
    if (navigator.share) {
      navigator.share({
        title: 'Share',
        url: this.urlForShare,
      })
    } else {
      alert('Web Share API is not supported in your browser.');
    }
    setTimeout(() => {
      this.closeModal.emit();
    });
  }

  async copyLink(): Promise<void> {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(this.urlForShare);
      this.linkCopied = true;
      setTimeout(() => this.linkCopied = false, 2000);
    } else {
      alert('Clipboard API is not supported in your browser.');
    }
  }
}
