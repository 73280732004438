import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PAGES_AND_PATHS} from "../../../constants/pages-and-paths";
import {AuthGuardService} from "../../../guards/auth-guard.service";
import {PrivacyPolicyComponent} from "./privacy-policy.component";

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.privacyPolicy.pageInRouting,
    data: {data: PAGES_AND_PATHS.privacyPolicy},
    component: PrivacyPolicyComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./privacy-policy.module').then(m => m.PrivacyPolicyModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrivacyPolicyRoutingModule { }
