<div class="wrapper-select-topics-and-artist-page">
  <div class="content-select-topics-and-artist">
    <div class="back-btn" [routerLink]="[PAGES_AND_PATHS.selectTopics.pagePath]">
      <img src="../assets/icons/icon-arrow-left.svg" alt="">
    </div>
    <h1>Connect with trending listers!</h1>
    <h6>Get inspired!</h6>
    <div class="wrapper-content-select-artist" (scroll)="onScroll($event)">
      <div class="wrapper-custom-checkbox-select-artist">
        <label *ngFor="let lister of listers">
          <input type="checkbox" name="radioname" [(ngModel)]="lister.selected" (change)="onSelectListerChange(lister)">
          <div class="custom-checkbox-select-artist">
            <div class="check-artist">
              <img src="../assets/icons/outline/icon-check.svg" alt="">
            </div>
            <div class="wrapper-select-artist-info">
              <div class="wrapper-image-info-artist">
                <img src="https://media.istockphoto.com/id/1437816897/photo/business-woman-manager-or-human-resources-portrait-for-career-success-company-we-are-hiring.jpg?b=1&s=612x612&w=0&k=20&c=hEPh7-WEAqHTHdQtPrfEN9-yYCiPGKvD32VZ5lcL6SU=" alt="">
              </div>
              <span class="text-name length-character">{{lister.name}}</span>
              <span class="text-followers">23k followers</span>
            </div>
          </div>
        </label>
      </div>
    </div>
    <button [disabled]="numberOfSelectedListers < 3" (click)="onClickToContinue()" class="custom-btn custom-btn--primary xs-mt-12">
      <span>{{'GENERAL.continue' | translate}}</span>
    </button>
    <div class="wrapper-label-gray">
      <span>Please select 3 or more to continue</span>
    </div>
  </div>
</div>

