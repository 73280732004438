<div class="wrapper-modal">
  <div class="wrapper-modal-content primary">
    <div class="wrapper-modal-image">
      <img src="../../../../../../assets/icons/outline/icon-global.svg" alt="">
    </div>
    <div class="modal-title">{{modalTitle}}</div>
    <div class="modal-description">{{modalDescription}}</div>
    <div class="modal-buttons">
      <button (click)="closeModal.emit(true)" class="custom-btn custom-btn--outline">
        <span>Cancel</span>
      </button>
      <button (click)="changeVisibility.emit(true)" class="custom-btn custom-btn--primary">
        <span>{{confirmBtnText}}</span>
      </button>
    </div>
  </div>
</div>

