import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthenticationHelperService} from "../../../services/helpers/authentication-helper.service";
import {ToastrService} from "ngx-toastr";
import {ProfileHttpService} from "../../../services/http-services/profile/profile-http.service";
import {PAGES_AND_PATHS} from "../../../constants/pages-and-paths";
import {UserRoles} from "../../../enums/general-enums";
import {EditProfileEnums} from "../../../enums/profile-enums";

@Component({
  selector: 'tl-edit-profile',
  templateUrl: './edit-profile.component.html',
})
export class EditProfileComponent implements OnInit {
  profileData: any;
  activeComponent = EditProfileEnums.index;
  protected readonly EditProfileEnums = EditProfileEnums;

  constructor(private profileHttpService: ProfileHttpService,
              private authHelperService: AuthenticationHelperService) {
  }
  ngOnInit() {
    this.getAuthUserProfile();
  }

  getAuthUserProfile(): void {
    this.profileHttpService.getAuthUserProfile().then(res => {
      this.profileData = res.data;
      this.authHelperService.setUserData(this.profileData);
    });
  }

  onChangeProfileData(newProfileData: any): void {
    this.profileData = newProfileData;
    this.authHelperService.setUserData(this.profileData);
  }
}
