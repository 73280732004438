import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shouldDisplayDate',
  standalone: true
})
export class ShouldDisplayDatePipe implements PipeTransform {

  transform(messages: any[], index: number): boolean {
    if (index === 0) {
      return true;
    }

    const currentMessage = messages[index];
    const previousMessage = messages[index - 1];

    const currentDate = new Date(currentMessage.updated_at);
    const previousDate = new Date(previousMessage.updated_at);

    return currentDate.getDate() !== previousDate.getDate() ||
      currentDate.getMonth() !== previousDate.getMonth() ||
      currentDate.getFullYear() !== previousDate.getFullYear();
  }

}
