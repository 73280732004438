import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-preview-media',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './preview-media.component.html',
})
export class PreviewMediaComponent {
  @Input() selectedMedia: any;
  @Output() closeModal = new EventEmitter<boolean>();
  @Output() download = new EventEmitter<boolean>();
}
