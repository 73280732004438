import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {loadStripe, Stripe, StripeCardElement, StripeElements} from "@stripe/stripe-js";
import {environment} from "../../../../../../environments/environment";
import {PaymentHttpService} from "../../../../../services/http-services/payment/payment-http.service";

@Component({
  selector: 'tl-payment',
  standalone: true,
  imports: [],
  templateUrl: './payment.component.html',
})
export class PaymentComponent {
  @Input() planId = '';
  @ViewChild('cardElement') cardElement!: ElementRef;
  stripe: Stripe | null = null;
  elements: StripeElements | null = null;
  card: StripeCardElement | null = null;
  isPaymentProcessing = false;
  errorMessage = '';

  constructor(private paymentHttpService: PaymentHttpService) {}

  async ngOnInit() {
    this.stripe = await loadStripe(environment.STRIPE_KEY);
    if (this.stripe) {
      this.elements = this.stripe.elements();
      this.card = this.elements.create('card', {
        hidePostalCode: true
      });
      this.card.mount(this.cardElement.nativeElement);
    }
  }

  async handleSubscription() {
    if (!this.stripe || !this.card) return;
    this.isPaymentProcessing = true;
    this.errorMessage = '';
    const { paymentMethod, error } = await this.stripe.createPaymentMethod({
      type: 'card',
      card: this.card,
    });

    if (error) {
      this.errorMessage = error.message || 'An error occurred';
      this.isPaymentProcessing = false;
      return;
    }

    if (paymentMethod) {
      this.paymentHttpService.setSubscriptionPlan({plan_id: this.planId, payment_method_id: paymentMethod.id}).then(res => {
        this.isPaymentProcessing = false;
      }).catch (error => {
        this.errorMessage = error.message;
        this.isPaymentProcessing = false;
      });
    }
  }
}
