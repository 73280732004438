<div class="wrapper-page">
  <app-my-content></app-my-content>
  <div class="wrapper-home-page">
    <tl-header></tl-header>
    <div class="wrapper-search-page">
      <div class="wrapper-custom-search">
        <img class="icon-search" src="../../../assets/icons/outline/icon-search.svg" alt="">
        <input type="text" placeholder="Search..." [(ngModel)]="searchTerm" (input)="onSearchChange($event)" (keyup.enter)="onEnter(searchTerm)" (focus)="openRecent()">
        <div (click)="resetSearch()" class="wrapper-delete-search">
          <img src="../../../assets/icons/outline/icon-close.svg" alt="">
        </div>
      </div>
      <!--   Search page first view   -->
      <div *ngIf="!isRecentOpen" class="wrapper-search-first-view">
        <div class="wrapper-title-search-first-view">
          <img src="../../../assets/icons/icon-trending.svg" alt="">
          <span>Trending lists for you</span>
        </div>
        <div class="grid">
          <div *ngFor="let list of homepageData?.recommendedLysts[0]?.data" class="grid__s-4">
            <div class="wrapper-card-list-search">
              <img
                [src]="list.lyst_image_url"
                alt="">
              <div class="card-list-search-info">
                <div class="wrapper-badges">
                  <div class="badge gray">
                    <span>{{list.lyst_items_count}} {{list.lyst_items_count === 1 ? 'item' : 'items'}}</span>
                  </div>
                </div>
                <span class="name">{{list.title}}</span>
                <div class="wrapper-author">
                  <span>{{list.user.name}}</span>
                  <img *ngIf="list.user.is_expert" src="./assets/icons/fill/icon-badge.svg" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="wrapper-title-search-first-view">
          <span>Lists Themes</span>
        </div>
        <div class="grid">
          <div *ngFor="let theme of homepageData?.recommendedSubcategories" class="grid__s-4"  [routerLink]="[PAGES_AND_PATHS.singleTheme.pagePath, theme.id]">
            <div [style.background-color]="theme?.color" class="wrapper-card-theme">
              <span>{{theme.name}}</span>
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXbKMuxHD5Ho6T-lIVzT6tszCiT0qwtRlgUw&s"
                alt="">
            </div>
          </div>
        </div>
        <div [routerLink]="[PAGES_AND_PATHS.allThemes.pagePath]" class="custom-btn custom-btn--outline w-auto xs-mt-30">
          <span>Explore all list themes</span>
        </div>
      </div>
      <!--   Recently Searched   -->
      <div *ngIf="isRecentOpen" class="wrapper-recently-searched">
        <span class="title-recently-searched">Recent search</span>
        <div *ngFor="let recent of recentSearchesData" class="recently-searched-item">
          <div (click)="onEnter(recent.content)" class="tl-d-flex tl-align-center">
            <img src="./assets/icons/outline/icon-history.svg" alt="">
            <span>{{recent.content}}</span>
          </div>
          <img (click)="deleteRecent(recent)" src="./assets/icons/outline/icon-close-modal.svg" alt="">
        </div>
      </div>
    </div>
  </div>
</div>