import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Subject, from } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { ShareEntitiesEnums } from 'src/app/enums/chat-enums';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { DeleteModalText } from 'src/app/services/http-services/lists/lists-http-intterfaces';
import { ListsHttpService } from 'src/app/services/http-services/lists/lists-http.service';
import { PostsHttpService } from 'src/app/services/http-services/posts/posts-http.service';

@Component({
  selector: 'app-followed-listers',
  templateUrl: './followed-listers.component.html',
})
export class FollowedListersComponent implements OnInit {
  pageNum = 1;
  followedListers: any[] = [];
  pagination!: TlPagination;
  PAGES_AND_PATHS = PAGES_AND_PATHS;
  isShowMore = false;
  isSharePostModalOpen = false;
  protected readonly ShareEntitiesEnums = ShareEntitiesEnums;
  selectedPost: any | null = null;
  postUrl = '';
  isViewMoreModalOpen = false;
  isDeleteModalOpen = false;
  modalText: DeleteModalText = { title: '', description: '', button: '' };
  loggedUser: any;
  @ViewChild('followedListersContainer', { static: false }) private followedListersContainer!: ElementRef;
  loading = false;
  private searchSubject = new Subject<string>();
  searchTerm = '';

  constructor(
    private listsHttp: ListsHttpService,
    private postsHttpService: PostsHttpService,
  ) { }

  ngOnInit(): void {
    this.getFollowedListers();
    this.searchSubject.pipe(
      debounceTime(300),
      switchMap((searchTerm: string) => from(this.getFollowedListers(searchTerm)))
    ).subscribe(response => {
      this.followedListers = response.data;
      this.pagination = response.pagination;
    });
  }

  getFollowedListers(searchTerm?: string) {
    this.loading = true;
    return (
      this.listsHttp.getFollowedListers(this.pageNum, searchTerm).then((response: any) => {
        console.log('getFollowedListers', response)
        this.followedListers = response.data;
        this.pagination = response.pagination
        this.loading = false;
        return response;
      })
    )
  }

  togglePostLike(event: any, post: any): void {
    event.stopPropagation();
    this.postsHttpService.togglePostLike({ postId: post.id }).then(res => {
      if (res.code === 200) {
        const index = this.followedListers.findIndex((postObj) => postObj.id === post.id);
        console.log(res)
        this.followedListers[index] = res.data;
      }
    })
  }

  sharePost(post: any, event?: any) {
    event.stopPropagation()
    this.selectedPost = post;
    this.isSharePostModalOpen = true;
    this.postUrl = PAGES_AND_PATHS.singlePost.pagePath + '/' + post.id;
  }

  openDropdown(event: any, post: any): void {
    this.isViewMoreModalOpen = !this.isViewMoreModalOpen;
    this.selectedPost = post;
  }

  openDeleteModal(post: any) {
    this.selectedPost = post;
    this.isDeleteModalOpen = true;
    this.modalText.title = 'Delete post?';
    this.modalText.description = 'Are you sure you wish to delete this post? This action cannot be undone.'
    this.modalText.button = 'Delete';
  }

  onConfirmPostDelete(): void {
    this.postsHttpService.deletePost({ postId: this.selectedPost.id }).then(res => {
      if (res.code === 200) {
        this.isDeleteModalOpen = false;
        const index = this.followedListers.findIndex((postObj) => postObj.id === this.selectedPost.id);
        this.followedListers.splice(index, 1);
        this.isViewMoreModalOpen = false;
        this.isDeleteModalOpen = false;
      }
    });
  }

  resetSearch() {
    this.searchTerm = '';
    this.searchSubject.next('');
  }

  onScroll(): void {
    console.log('boop')
    const container = this.followedListersContainer.nativeElement;
    const scrollTop = container.scrollTop;
    const offset = 100;

    if (!this.loading && scrollTop < offset && !this.loading && ((this.pagination?.currentPage ?? 0) < (this.pagination?.lastPage ?? 0))) {
      this.pageNum++;
      this.getFollowedListers();
    }
  }

  onSearchChange(event: any): void {
    this.searchTerm = event.target.value;
    this.pageNum = 1;
    this.searchSubject.next(this.searchTerm);
  }

}
