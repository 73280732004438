import { NgForOf } from '@angular/common';
import { Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { SearchHttpService } from 'src/app/services/http-services/search/search-http.service';

@Component({
  selector: 'app-companies',
  standalone: true,
  imports: [NgForOf],
  templateUrl: './companies.component.html',
})
export class CompaniesComponent {
  @Input() searchTerm = '';
  @Input() isLiveSearch = false;
  companies: any[] = [];
  pagination!: TlPagination;
  PAGES_AND_PATHS = PAGES_AND_PATHS;
  @ViewChild('companiesContainer', { static: false }) private companiesContainer!: ElementRef;
  pageNum = 1;
  loading = false;

  constructor(
    private searchHttpService: SearchHttpService
  ) { }

  ngOnInit(): void {
    this.getCompanies();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.pageNum = 1;
      this.getCompanies();
    }
  }

  getCompanies(): void {
    this.loading = true;
    this.searchHttpService.formattedGetCompaniesResults(this.pageNum, this.isLiveSearch, this.searchTerm).then((response: {data: any[], pagination: TlPagination}) => {
      console.log('listers', response)
      this.companies = [...this.companies, ...response.data];
      this.pagination = response.pagination;
      this.loading = false;
    })
  }

  onScroll(): void {
    const container = this.companiesContainer.nativeElement;
    const scrollTop = container.scrollTop;
    const offset = 100;

    if (!this.loading && scrollTop < offset && !this.loading && ((this.pagination?.currentPage ?? 0) < (this.pagination?.lastPage ?? 0))) {
      this.pageNum++;
      this.getCompanies();
    }
  }
}
