<div class="wrapper-group-accounts">
  <div class="wrapper-admin-preview">
    <span class="text-admin">Admin</span>
    <div *ngFor="let admin of conversation.admin_participants" class="wrapper-info-user">
      <div class="image-preview">
        <img src="../../../../../assets/images/avatar-user.png" alt="">
      </div>
      <div class="wrapper-username">
        <span>{{admin.username}}</span>
        <img src="../../../../../assets/icons/fill/icon-badge.svg" alt="">
      </div>
      <span class="user-name">{{admin.name}}</span>
      <button *ngIf="conversation.admin_participants !== undefined && conversation.admin_participants[0].id !== loggedUser.id" (click)="followUnfollowAdmin(admin.id)" class="custom-btn custom-btn--outline"><span>{{admin.am_i_following_this_user ? 'Unfollow' : 'Follow'}}</span></button>
    </div>
  </div>
  <div class="wrapper-users-preview">
    <div *ngFor="let user of conversation.non_admin_participants" class="wrapper-info-user">
      <div class="image-preview">
        <img src="../../../../../assets/images/avatar-user.png" alt="">
      </div>
      <div class="wrapper-username">
        <span>{{user.username}}</span>
        <img src="../../../../../assets/icons/fill/icon-badge.svg" alt="">
      </div>
      <span class="user-name">{{user.name}}</span>
      <button *ngIf="conversation.admin_participants[0].id !== loggedUser.id && user.id !== loggedUser.id" (click)="followUnfollowUser(user.id)" class="custom-btn custom-btn--outline"><span>{{user.am_i_following_this_user ? 'Unfollow' : 'Follow'}}</span></button>
      <button *ngIf="conversation.admin_participants[0].id === loggedUser.id" (click)="removeUserFromGroup(user)" class="custom-btn custom-btn--outline"><span>Remove</span></button>
    </div>
  </div>
</div>
