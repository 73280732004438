import {Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild} from '@angular/core';
import {UserRoles} from "../../../../enums/general-enums";
import {PAGES_AND_PATHS} from "../../../../constants/pages-and-paths";
import {ShareEntitiesEnums} from "../../../../enums/chat-enums";
import {PostsHttpService} from "../../../../services/http-services/posts/posts-http.service";
import {Router, RouterLink} from "@angular/router";
import {NgClass, NgIf} from "@angular/common";
import {HandleShowMorePipe} from "../../../../pipes/handle-show-more/handle-show-more.pipe";
import {ConfirmModalComponent} from "../../../../shared-components/confirm-modal/confirm-modal.component";
import {TimeAgoPipe} from "../../../../pipes/time-ago/time-ago.pipe";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {
  SharingOptionsModalComponent
} from "../../../my-profile/components/sharing-options-modal/sharing-options-modal.component";
import {UiHelperService} from "../../../../services/helpers/ui-helper.service";
import {AuthenticationHelperService} from "../../../../services/helpers/authentication-helper.service";

@Component({
  selector: 'tl-post-preview',
  standalone: true,
  templateUrl: './post-preview.component.html',
  imports: [
    NgIf,
    HandleShowMorePipe,
    NgClass,
    RouterLink,
    ConfirmModalComponent,
    TimeAgoPipe,
    ReactiveFormsModule,
    SharingOptionsModalComponent
  ]
})
export class PostPreviewComponent {
  @Input() post: any;
  @Input() baseUrl = '';
  @Output() confirmPostDeleted: EventEmitter<string> = new EventEmitter<string>();
  @Output() togglePostLikeUnlike: EventEmitter<any> = new EventEmitter<any>();
  @Output() openImageInFullScreen: EventEmitter<any> = new EventEmitter<any>();
  isCallToActionModal = false;
  isSharePostModalOpen = false;
  isDeletePostModalOpen = false;
  isReportPostModalOpen = false;
  isShowMore = false;
  postUrl = '';
  commentForm!: FormGroup;
  authUserData: any;
  @ViewChild('viewMoreBtn') viewMoreBtn: ElementRef | undefined;
  protected readonly UserRoles = UserRoles;
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;
  protected readonly ShareEntitiesEnums = ShareEntitiesEnums;
  constructor(private postsHttpService: PostsHttpService,
              private authHelperService: AuthenticationHelperService,
              private toastService: ToastrService,
              private formBuilder: FormBuilder,
              private renderer: Renderer2,
              private uiHelperService: UiHelperService,
              private router: Router) { }

  ngOnInit() {
    this.buildForm();
    this.listenToClickViewMoreBtn();
    this.authUserData = this.authHelperService.getUserData;
  }

  listenToClickViewMoreBtn(): void {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.uiHelperService.checkIfClickedOutsideOfTheElement(this.viewMoreBtn, this.viewMoreBtn, e)) {
        this.isCallToActionModal = false;
      }
    });
  }

  buildForm(): void {
    this.commentForm = this.formBuilder.group({
      comment: ['', [Validators.required]],
    });
  }

  togglePostLike(event: any): void {
    event.stopPropagation();
    this.postsHttpService.togglePostLike({postId: this.post.id}).then(res => {
      if (res.code === 200) {
        this.togglePostLikeUnlike.emit(res.data);
      }
    })
  }

  onCallActionEditPost(): void {
    this.isCallToActionModal = false;
    setTimeout(() => {
      this.router.navigate([PAGES_AND_PATHS.editPost.pagePath, this.post.id]);
    })
  }
  onCallActionSharePost(): void {
    this.isCallToActionModal = false;
    this.postUrl = this.baseUrl + PAGES_AND_PATHS.singlePost.pagePath + '/' + this.post.id;
    this.isSharePostModalOpen = true;
  }

  onCallActionReportPost(): void {
    this.isCallToActionModal = false;
    this.isReportPostModalOpen = true;
  }

  onPostReportSent(): void {
    this.isReportPostModalOpen = false;
  }

  onCallActionDeletePost(): void {
    this.isCallToActionModal = false;
    this.isDeletePostModalOpen = true;
  }

  onConfirmPostDelete(): void {
    this.postsHttpService.deletePost({postId: this.post.id}).then(res => {
      if (res.code === 200) {
        this.isDeletePostModalOpen = false;
        setTimeout(() => {
          this.confirmPostDeleted.emit(this.post.id);
        })
      }
    });
  }

  onSubmitCommentForm(): void {
    if (this.commentForm.valid) {
      this.postsHttpService.storePostComment({postId: this.post.id}, this.commentForm.value).then(res => {
        if (res.code === 200) {
          this.commentForm.reset();
          this.post.comments_count++;
          this.toastService.success(res.message);
        }
      }).catch(({error}: {error:{ errors: { [key: string]: string }}}) => {
        Object.values(error.errors).forEach(error => this.toastService.error(error));
      });
    }
  }

}
