<div class="wrapper-tab-lists" (scroll)="onScroll($event)">
  <div class="wrapper-custom-search">
    <img class="icon-search" src="../../../assets/icons/outline/icon-search.svg" alt="">
    <input type="text" placeholder="Search..." [value]="urlParams.search_keyword" (input)="onSearchChange($event)">
    <div *ngIf="urlParams.search_keyword?.length" class="wrapper-delete-search" (click)="clearSearch()">
      <img src="../../../assets/icons/outline/icon-close.svg" alt="">
    </div>
  </div>
  <div class="wrapper-cards-list">
    <div class="wrapper-card-list" *ngFor="let lyst of lysts"  [routerLink]="[PAGES_AND_PATHS.singleList.pagePath, lyst.id, 0, 'cards']" >
      <img class="image-list" [src]="lyst.cover_image" alt="">
      <div class="wrapper-card-list-info">
        <div class="wrapper-badges">
          <div class="badge gray">
            <span>{{lyst.lyst_items_count}} {{lyst.lyst_items_count === 1 ? 'item' : 'items'}}</span>
          </div>
        </div>
        <span class="name-list">{{lyst.title}}</span>
        <span class="time-list" *ngIf="isAuthUserProfile">{{lyst.created_at | timeAgo}}</span>
      </div>
    </div>
  </div>
</div>
