<div *ngIf="chartOptions">
  <apx-chart
    #chartArea
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [xaxis]="chartOptions.xaxis"
    [stroke]="chartOptions.stroke"
    [dataLabels]="chartOptions.dataLabels"
    [yaxis]="chartOptions.yaxis"
    [labels]="chartOptions.labels"
    [tooltip]="chartOptions.tooltip"
    [title]="chartOptions.title ? chartOptions.title : undefined"
    [grid]="chartOptions.grid"
    [fill]="chartOptions.fill"
    [markers]="chartOptions.markers"
    [legend]="chartOptions.legend"
  ></apx-chart>
</div>
