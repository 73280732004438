import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'returnMessageEmoji',
  standalone: true
})
export class ReturnMessageEmojiPipe implements PipeTransform {

  transform(reaction: any, ...args: unknown[]): string {
    let imgSrc = '';
    if (reaction.type === 'like') {
      imgSrc = '../../../../../assets/icons/emoticons/emoticon-like.png';
    } else if (reaction.type === 'unlike') {
      imgSrc = '../../../../../assets/icons/emoticons/emoticon-dislike.png'
    } else if (reaction.type === 'heart') {
      imgSrc = '../../../../../assets/icons/emoticons/emoticon-love.png'
    } else if (reaction.type === 'happy') {
      imgSrc = '../../../../../assets/icons/emoticons/emoticon-smile.png'
    } else if (reaction.type === 'sad') {
      imgSrc = '../../../../../assets/icons/emoticons/emoticon-sad-smiley.png'
    }
    return imgSrc;
  }

}
