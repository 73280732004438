import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { ListType } from 'src/app/enums/list-enums';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { List, SingleListItem } from 'src/app/services/http-services/lists/lists-http-intterfaces';
import { ListsHttpService } from 'src/app/services/http-services/lists/lists-http.service';

@Component({
  selector: 'app-my-lists',
  templateUrl: './my-lists.component.html',
})
export class MyListsComponent {
  pageNum = 1;
  pagination!: TlPagination;
  lists: List[] = [];
  activeListType: ListType = ListType.all;
  PAGES_AND_PATHS = PAGES_AND_PATHS;
  ListType = ListType;
  isReorder = false;
  loading = false;
  searchTerm = '';
  searchSubject = new Subject<string>();

  constructor(
    private listsHttpService: ListsHttpService
  ) { }

  ngOnInit(): void {
    this.getMyLists(this.pageNum, this.activeListType);
    this.searchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((searchTerm: string) => this.getMyLists(this.pageNum, this.activeListType, searchTerm))
    ).subscribe((response: any) => {
      console.log(response)
      this.lists = response.data;
      this.pagination = response.pagination;
    });
  }

  getMyLists(pagNumber: number, listType: ListType, searchTerm?: string): Promise<any> {
    this.loading = true;
    return new Promise((resolve, reject) => {
      this.listsHttpService.getMyLists(pagNumber, listType, searchTerm).then((res: any) => {
        try {
          this.lists = [...this.lists, ...res.data];
          this.pagination = res.pagination;
          this.loading = false;
        } catch (e) {
        }
        this.pagination = res.pagination;
        resolve(res);
      }, error => {
        reject(false);
      })
    });
  }

  changeListType(listType: ListType): void {
    this.activeListType = listType;
    this.lists = [];
    this.pageNum = 1;
    console.log(listType)
    this.getMyLists(this.pageNum, this.activeListType);
  }

  reorder() {
    this.isReorder = !this.isReorder;
  }

  onScroll(event: any) {
    const threshold = 100;
    const position = event.target.scrollTop + event.target.offsetHeight;
    const height = event.target.scrollHeight;

    if (position > height - threshold && !this.loading && (this.pagination?.currentPage ?? 0) < (this.pagination?.lastPage ?? 0)) {
      this.pageNum++
      this.getMyLists(this.pageNum, this.activeListType);
    }
  }

  drop(event: CdkDragDrop<any[]>) {
    if (this.isReorder) {
      const movedItem = this.lists[event.previousIndex];
      const newIndex = event.currentIndex;
      moveItemInArray(this.lists, event.previousIndex, event.currentIndex);
      const order = {
        id: movedItem.id,
        order_value: newIndex
      };
      let reqObj: { order: { id: number; order_value: number; }[] } = {
        order: []
      };
      reqObj.order.push(order);
      this.listsHttpService.reorderLists(reqObj).then((res: any) => {
        console.log(res)
      })
    }
  }
    
  onSearchChange(event: any): void {
    this.searchTerm = event.target.value;
    this.pageNum = 1;
    this.lists = [];
    this.searchSubject.next(this.searchTerm);
  }

  resetSearch() {
    this.searchTerm = '';
    this.searchSubject.next('');
  }
}
