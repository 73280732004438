<div class="wrapper-languages-page">
  <span class="title-settings-page">Languages</span>
  <div class="wrapper-custom-search">
    <img class="icon-search" src="../assets/icons/outline/icon-search.svg" alt="">
    <input type="text" [(ngModel)]="searchQuery" (ngModelChange)="onSearchLanguage()" placeholder="Find a language">
    <div *ngIf="searchQuery" (click)="onClearSearch()" class="wrapper-delete-search">
      <img src="../assets/icons/outline/icon-close.svg" alt="">
    </div>
  </div>
  <span class="label-language-settings">Current language</span>
  <div class="wrapper-custom-radiobutton">
    <label>
      <input type="radio" name="initialLang" checked>
      <div class="custom-radiobutton">
        <div class="wrapper-language-info">
          <img [src]="initialLanguage?.flag" [alt]="'flag-' + initialLanguage.name">
          <span>{{initialLanguage?.jsonPath | translate}}</span>
        </div>
        <div class="circle-radio">
          <div class="circle-center"></div>
        </div>
      </div>
    </label>
  </div>
  <span class="label-language-settings">Other languages</span>
  <div class="wrapper-list-languages settings view-button-language">
    <div class="wrapper-custom-radiobutton">
      <label *ngFor="let lang of filteredLanguages">
        <input type="radio" name="lang" [value]="lang.name" [checked]="selectedLanguage === lang.name" (change)="onChangeLanguage($event)">
        <div class="custom-radiobutton">
          <div class="wrapper-language-info">
            <img [src]="lang.flag" [alt]="'flag-' + lang.name">
            <span>{{lang.jsonPath | translate}}</span>
          </div>
          <div class="circle-radio">
            <div class="circle-center"></div>
          </div>
        </div>
      </label>
    </div>
    <button *ngIf="selectedLanguage" (click)="onSubmitNewLanguage()" class="custom-btn custom-btn--primary">
      <span>Save changes</span>
    </button>
  </div>
</div>
