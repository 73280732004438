<div class="wrapper-modal">
  <div class="wrapper-modal-content-share">
    <div class="modal-header-share">
      <span>Share with Lister</span>
      <img (click)="closeModal.emit()" src="../../../assets/icons/outline/icon-close-modal.svg" alt="">
    </div>
    <div class="modal-content-share">
      <div class="wrapper-custom-search">
        <img class="icon-search" src="../../../assets/icons/outline/icon-search.svg" alt="">
        <input type="text" placeholder="Search..." [value]="urlParams.search_keyword" (input)="onSearchChange($event)">
        <div class="wrapper-delete-search" *ngIf="urlParams.search_keyword?.length" (click)="clearSearch()">
          <img src="../../../assets/icons/outline/icon-close.svg" alt="">
        </div>
      </div>
      <div class="wrapper-all-users-share">
        <div class="wrapper-custom-checkbox-select-artist">
          <label *ngFor="let user of users">
            <input type="checkbox" name="radioname" [(ngModel)]="user.selected" [value]="!user.selected" (ngModelChange)="onSelectUserChange()">
            <div class="custom-checkbox-select-artist">
              <div class="check-artist">
                <img src="../assets/icons/outline/icon-check.svg" alt="">
              </div>
              <div class="wrapper-select-artist-info">
                <div class="wrapper-image-info-artist">
                  <img *ngIf="!user.profile_photo_url" [src]=" true ? '../../../assets/images/avatar-user-expert.png' : '../../../assets/images/avatar-user.png'" alt="">
                  <img *ngIf="user.profile_photo_url" [src]="user.profile_photo_url" [alt]="user.name" />                </div>
                <span class="text-name length-character">{{user.name}}</span>
              </div>
            </div>
          </label>
        </div>
      </div>
      <div class="line-gray"></div>
      <div class="wrapper-share-social-media">
        <div class="title-social-media">
          <span>Or send via</span>
        </div>
        <div class="wrapper-social-media">
          <div class="wrapper-share-btn" (click)="shareExternalWithShareBtnOptions()">
            <div class="share-btn">
              <img src="../../../assets/icons/outline/icon-share-social.svg" alt="">
            </div>
            <span>Share</span>
          </div>
          <div class="social-media" *ngFor="let app of socialApps" (click)="shareExternal(app.name.toLowerCase())">
            <img [src]="app.icon" [alt]="app.name">
            <span>{{ app.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="line-gray"></div>
    <div class="modal-buttons-share">
      <button (click)="copyLink()" class="custom-btn custom-btn--outline">
        <img src="../../../assets/icons/outline/icon-copylink.svg" alt="">
        <span>{{ linkCopied ? 'Link Copied!' : 'Copy link' }}</span>
      </button>
      <button [disabled]="!hasSelectedUsers" (click)="shareInternal()" class="custom-btn custom-btn--primary"><span>Send</span></button>
    </div>
  </div>
</div>
