import { NgForOf } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-stars',
  standalone: true,
  imports: [NgForOf],
  templateUrl: './stars.component.html',
})
export class StarsComponent implements OnChanges {
  stars: any;
  @Input() average_rating: any;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.average_rating.currentValue) {
      this.stars = this.generateStars(this.average_rating);
    }
  }

  generateStars(rating: number | undefined): string[] {
    const stars: string[] = [];
    // Return empty array if rating is undefined or out of range
    if (rating === undefined || rating < 0 || rating > 5) {
      return stars;
    }
    const integerPart = Math.floor(rating);
    const decimalPart = rating % 1;
    for (let i = 1; i <= 5; i++) {
      if (i <= integerPart) {
        // Full star if the integer part is greater than or equal to the star index
        stars.push('../../../assets/icons/rating/icon-star-fill.svg');
      } else if (i - 1 === integerPart) {
        // Check the decimal part only for the current star
        if (decimalPart < 0.3) {
          stars.push('../../../assets/icons/rating/icon-star-outline.svg'); // Empty star
        } else if (decimalPart < 0.7) {
          stars.push('../../../assets/icons/rating/icon-star-half.svg'); // Half star
        } else {
          stars.push('../../../assets/icons/rating/icon-star-fill.svg'); // Full star
        }
      } else {
        // Empty star for all remaining stars
        stars.push('../../../assets/icons/rating/icon-star-outline.svg');
      }
    }
    return stars;
  }
}
