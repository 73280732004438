import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {GeneralHttpHelperService} from "../../helpers/general-http-helper.service";
import {InsightsDetailsTypeEnums} from "../../../enums/profile-enums";

const SETTINGS_API_ROUTES = {
  DELETE_ACCOUNT: 'user/profile',
  GET_INSIGHTS_OVERVIEW: 'insights/overview',
  GET_INSIGHTS_LISTS: 'insights/get_lysts_for_period',
  GET_INSIGHTS_FOLLOWER: 'insights/details/followers',
  GET_INSIGHTS_DETAILS_FOLLOWERS: 'insights/details/followers',
  GET_INSIGHTS_DETAILS_LIKES: 'insights/details/lyst_likes',
  GET_INSIGHTS_DETAILS_VIEWS: 'insights/details/lyst_views',
  GET_INSIGHTS_DETAILS_SHARES: 'insights/details/lyst_shares'
}
@Injectable({
  providedIn: 'root'
})
export class SettingsHttpService {

  constructor(private httpClient: HttpClient,
              private generalHttpHelperService: GeneralHttpHelperService) {

  }

  private deleteAccountReq(bodyParams: any): Observable<any> {
    return this.httpClient.post(SETTINGS_API_ROUTES.DELETE_ACCOUNT, bodyParams) as any;
  }
  deleteAccount(bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.deleteAccountReq(bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
  private getInsightsOverviewReq(urlParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.addUrlParams(SETTINGS_API_ROUTES.GET_INSIGHTS_OVERVIEW, urlParams);
    return this.httpClient.get(apiUrl) as any;
  }

  getInsightsOverview(urlParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getInsightsOverviewReq(urlParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private getInsightsListsReq(urlParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.addUrlParams(SETTINGS_API_ROUTES.GET_INSIGHTS_LISTS, urlParams);
    return this.httpClient.get(apiUrl) as any;
  }

  getInsightsLists(urlParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getInsightsListsReq(urlParams).subscribe((res: any) => {
        resolve({
          data: res.data.data,
          pagination: this.generalHttpHelperService.returnPagination(res.data)
        });
      }, error => {
        reject(error);
      });
    });
  }

  private getInsightsDetailsReq(urlParams: any, type: string): Observable<any> {
    let url = '';
    switch (type) {
      case InsightsDetailsTypeEnums.followers: url = SETTINGS_API_ROUTES.GET_INSIGHTS_DETAILS_FOLLOWERS;
        break;
      case InsightsDetailsTypeEnums.likes: url = SETTINGS_API_ROUTES.GET_INSIGHTS_DETAILS_LIKES;
        break;
      case InsightsDetailsTypeEnums.views: url = SETTINGS_API_ROUTES.GET_INSIGHTS_DETAILS_VIEWS;
        break;
      case InsightsDetailsTypeEnums.shares: url = SETTINGS_API_ROUTES.GET_INSIGHTS_DETAILS_SHARES;
    }
    const apiUrl = this.generalHttpHelperService.addUrlParams(url, urlParams);
    return this.httpClient.get(apiUrl) as any;
  }

  getInsightsDetails(urlParams: any, type: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getInsightsDetailsReq(urlParams, type).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
}
