import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgIf} from "@angular/common";

@Component({
  selector: 'tl-subscription-plans',
  standalone: true,
    imports: [
        NgIf
    ],
  templateUrl: './subscription-plans.component.html',
})
export class SubscriptionPlansComponent {
  @Input() planId = ''
  @Output() clickOnPlan: EventEmitter<string> = new EventEmitter<string>();
  @Output() clickToSubscribeOnPlan: EventEmitter<string> = new EventEmitter<string>();
  @Output() clickToCancelSubscription: EventEmitter<void> = new EventEmitter<void>();
}
