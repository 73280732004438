import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PAGES_AND_PATHS} from "../../../constants/pages-and-paths";
import {SuccessActionPageComponent} from "./success-action-page.component";

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.successAction.pageInRouting,
    data: {data: PAGES_AND_PATHS.successAction},
    component: SuccessActionPageComponent,
    loadChildren: () => import('./success-action-page.module').then(m => m.SuccessActionPageModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SuccessActionPageRoutingRoutingModule { }
