<div class="wrapper-list-search-accounts">
  <span class="title-search-results">Users</span>
  <div #listersContainer (scroll)="onScroll()">
    <div class="wrapper-card-lister" *ngFor="let user of listers">
      <div class="wrapper-favorite-lister" [routerLink]="[PAGES_AND_PATHS.profile.pagePath, user.id]">
        <div class="wrapper-avatar">
          <img
            [src]="user.profile_photo_url !== null ? 'user.profile_photo_url' : '../../../../assets/images/avatar-user.png'"
            alt="">
        </div>
        <div class="wrapper-user-info">
          <div class="wrapper-username">
            <span>{{user.name}}</span>
            <img src="../../../assets/icons/fill/icon-badge.svg" alt="">
          </div>
          <span>{{user?.followers_count}} {{user.followers_count === 1 ? 'follower' : 'followers'}}</span>
        </div>
      </div>
    </div>
  </div>
</div>