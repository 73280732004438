import {Component, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {ProfileHttpService} from "../../../../../services/http-services/profile/profile-http.service";
import {NgClass, NgIf} from "@angular/common";
import {SpinnerComponent} from "../../../../../shared-components/spinner/spinner.component";
import {
  FormControlErrorMessagesModule
} from "../../../../../pipes/form-control-error-messages/form-control-error-messages.module";

@Component({
  selector: 'tl-edit-profile-gender',
  standalone: true,
  templateUrl: './edit-profile-gender.component.html',
  imports: [
    ReactiveFormsModule,
    NgIf,
    SpinnerComponent,
    FormControlErrorMessagesModule,
    NgClass
  ]
})
export class EditProfileGenderComponent {
  @Input() profileData: any;
  @Output() changeProfile: EventEmitter<any> = new EventEmitter<any>();
  isSpinnerActive = false;
  formTriedToSubmit = false;
  editProfileGenderForm!: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private toastService: ToastrService,
              private profileHttpService: ProfileHttpService) {
  }

  ngOnInit(): void {
    this.buildForm();
    this.editProfileGenderForm.controls['gender'].valueChanges.subscribe(val => {
      const genderOther = this.editProfileGenderForm.get('genderOther');
      if (val === 'other') {
        genderOther?.setValidators([Validators.required]);
      } else {
        genderOther?.clearValidators();
      }
      genderOther?.updateValueAndValidity();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.profileDate && changes.profileData.currentValue) {
      if (changes.profileData.currentValue.gender === 'male' || changes.profileData.currentValue.gender === 'female') {
        this.editProfileGenderForm.patchValue(changes.profileData.currentValue);
      } else {
        this.editProfileGenderForm.controls['gender'].patchValue('other');
        this.editProfileGenderForm.controls['genderOther'].patchValue(changes.profileData.currentValue.gender);
      }
    }
  }

  buildForm(): void {
    this.editProfileGenderForm = this.formBuilder.group({
      gender: ['', [Validators.required]],
      genderOther: [''],
    });
    if (this.profileData.gender === 'male' || this.profileData.gender === 'female') {
      this.editProfileGenderForm.patchValue(this.profileData);
    } else {
      this.editProfileGenderForm.controls['gender'].patchValue('other');
      this.editProfileGenderForm.controls['genderOther'].patchValue(this.profileData.gender);
    }
  }

  onCancelChanges(): void {
    this.editProfileGenderForm.reset();
    if (this.profileData.gender === 'male' || this.profileData.gender === 'female') {
      this.editProfileGenderForm.patchValue(this.profileData);
    } else {
      this.editProfileGenderForm.controls['gender'].patchValue('other');
      this.editProfileGenderForm.controls['genderOther'].patchValue(this.profileData.gender);
    }
  }

  onSubmitEditProfileGenderForm(): void {
    this.formTriedToSubmit = false;
    if (this.editProfileGenderForm.valid) {
      this.isSpinnerActive = true;
      const bodyParams = (this.editProfileGenderForm.controls['gender'].value === 'other') ? {gender: this.editProfileGenderForm.controls['genderOther'].value} : {gender: this.editProfileGenderForm.controls['gender'].value};
      this.profileHttpService.editAuthUserProfile(bodyParams).then(res => {
        this.isSpinnerActive = false;
        this.changeProfile.emit(res.data);
        this.toastService.success(res.message);
      }).catch(({error}: {error:{ errors: { [key: string]: string }}}) => {
        this.isSpinnerActive = false;
        Object.values(error.errors).forEach(error => this.toastService.error(error));
      });
    }
  }
}
