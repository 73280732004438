import { Component } from '@angular/core';
import {PAGES_AND_PATHS} from "../../constants/pages-and-paths";

@Component({
  selector: 'tl-company-profile',
  templateUrl: './company-profile.component.html',
})
export class CompanyProfileComponent {

  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;
}
