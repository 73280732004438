import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FollowedListersComponent } from './followed-listers.component';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.followedListers.pageInRouting,
    data: { data: PAGES_AND_PATHS.followedListers },
    component: FollowedListersComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./followed-listers.module').then(m => m.FollowedListersModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FollowedListersRoutingModule { }
