import {Component, OnInit} from '@angular/core';
import {PAGES_AND_PATHS} from '../../../constants/pages-and-paths';
import {AuthenticationHttpService} from "../../../services/http-services/authentication/authentication-http.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'tl-check-your-email',
  templateUrl: './check-your-email.component.html',
})
export class CheckYourEmailComponent implements OnInit {
  bodyParams = {email: ''};
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;

  constructor(private authenticationHttpService: AuthenticationHttpService,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((res: any) => {
      if (res.email) {
        this.bodyParams.email = res.email;
      }
    });
  }

  resendResetPasswordForm(): void {
    this.authenticationHttpService.forgotPassword(this.bodyParams).then((res: any) => {
    }).catch(({error}: {error:{ errors: { [key: string]: string } }}) => {
      Object.values(error.errors).forEach(error => {
        // this.toastService.error(error);
      });
    })
  }
}
