import {Component, EventEmitter, Output} from '@angular/core';
import {PAGES_AND_PATHS} from "../../../../constants/pages-and-paths";
import {SettingsHttpService} from "../../../../services/http-services/settings/settings-http.service";
import {AuthenticationHelperService} from "../../../../services/helpers/authentication-helper.service";
import {InsightsDetailsTypeEnums} from "../../../../enums/profile-enums";

@Component({
  selector: 'tl-insights-index',
  standalone: true,
  imports: [],
  templateUrl: './insights-index.component.html',
})
export class InsightsIndexComponent {
  @Output() clickOnCard: EventEmitter<string> = new EventEmitter<string>();
  @Output() clickOnList: EventEmitter<any> = new EventEmitter<any>();
  insightsOverview: any;
  insightsLists: any[] = [];
  profileData: any;
  urlParams = {from_date: '2024-08-10', to_date: '2024-10-23'};
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS
  protected readonly InsightsDetailsTypeEnums = InsightsDetailsTypeEnums;
  constructor(private settingsHttpService: SettingsHttpService,
              private authHelperService: AuthenticationHelperService) { }
  ngOnInit() {
    this.getInsightsOverview();
    this.getInsightsLists();
    this.profileData = this.authHelperService.getUserData;
  }
  getInsightsOverview(): void {
    this.settingsHttpService.getInsightsOverview(this.urlParams).then(res => {
      this.insightsOverview = res.data;
    })
  }
  getInsightsLists(): void {
    this.settingsHttpService.getInsightsLists({...this.urlParams, page: 1}).then(res => {
      this.insightsLists = res.data;
    })
  }
}
