import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ListType } from 'src/app/enums/list-enums';
import { GeneralHttpHelperService } from '../../helpers/general-http-helper.service';
import { ListInterface } from './lists-http-intterfaces';


const LISTS_API_ROUTES = {
  POST_LIST: 'lysts',
  GET_MY_LISTS: 'lysts/type/',
  GET_SINGLE_LIST: 'lysts/',
  GET_SINGLE_LIST_ITEMS: 'items/lysts/',
  GET_SINGLE_ITEM: 'items/',
}
const LIST_TYPE = {
  [ListType.all]: '',
  [ListType.offline]: `/${ListType.offline}`,
  [ListType.private]: `/${ListType.private}`,
  [ListType.public]: `/${ListType.public}`
}
@Injectable({
  providedIn: 'root'
})
export class ListsHttpService {

  constructor(private httpClient: HttpClient, private generalHelperService: GeneralHttpHelperService) { }

  private postListReq(bodyParams: any): Observable<any> {
    return this.httpClient.post(LISTS_API_ROUTES.POST_LIST, bodyParams) as any;
  }
  postList(bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.postListReq(bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private updateListReq(bodyParams: any, listId: number): Observable<any> {
    return this.httpClient.post(`lysts/${listId}`, bodyParams) as any;
  }
  updateList(bodyParams: any, listId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.updateListReq(bodyParams, listId).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private postListColumnReq(bodyParams: any, listId: number): Observable<any> {
    return this.httpClient.post(`lysts/${listId}/new_column`, bodyParams) as any;
  }
  postListColumn(bodyParams: any, listId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.postListColumnReq(bodyParams, listId).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private deleteListColumnReq(id: number | undefined): Observable<any> {
    return this.httpClient.delete<any>(`lysts/column/${id}`);
  }
  deleteListColumn(id: number | undefined): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.deleteListColumnReq(id).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private updateListColumnReq(id: number, reqObj: { name: string }): Observable<any> {
    return this.httpClient.post<any>(`lysts/rename_column/${id}`, reqObj);
  }
  updateListColumn(id: number, reqObj: { name: string }): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.updateListColumnReq(id, reqObj).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private deleteListReq(id: number): Observable<any> {
    return this.httpClient.delete<any>(`lysts/${id}`);
  }
  deleteList(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.deleteListReq(id).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }


  private deleteListItemReq(id: number): Observable<any> {
    return this.httpClient.delete<any>(`items/${id}/delete`);
  }
  deleteListItem(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.deleteListItemReq(id).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  getMyLists(pageNumber: number, listType: ListType, searchTerm?: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getMyListsReq(pageNumber, listType, searchTerm).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.lists.data,
            pagination: this.generalHelperService.returnPagination(res.data.lists)
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    }
    )
  }
  private getMyListsReq(pageNumber: number, listType: ListType, searchTerm?: string): Observable<any> {
    let url = LISTS_API_ROUTES.GET_MY_LISTS + 'my_lists';
    let params = [];
    if (searchTerm) {
      params.push(`search_keyword=${encodeURIComponent(searchTerm)}`);
    }
    if (pageNumber) {
      params.push(`page=${pageNumber}`);
    }
    if (listType === 'private') {
      params.push(`filter=private_lists`);
    } else if (listType === 'public') {
      params.push(`filter=public_lists`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get<any[]>(url);
  }

  private reorderListsReq(body: { order: { id: number; order_value: number; }[] }): Observable<any> {
    return this.httpClient.post(`lysts/reorder`, body) as any;
  }
  reorderLists(body: { order: { id: number; order_value: number; }[] }): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.reorderListsReq(body).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private reorderListItemsReq(lyst_id: number, body: { order: { id: number; order_value: number; }[] }): Observable<any> {
    return this.httpClient.post(`lysts/${lyst_id}/lyst_items/reorder`, body) as any;
  }
  reorderListItems(lyst_id: number, body: { order: { id: number; order_value: number; }[] }): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.reorderListItemsReq(lyst_id, body).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private getSingleListReq(listId: number, searchTerm?: string, filters?: {sort: string, id: number}): Observable<any> {
    let url = `${LISTS_API_ROUTES.GET_SINGLE_LIST}${listId}`;
    let params = [];
    if (searchTerm) {
      params.push(`search_keyword=${encodeURIComponent(searchTerm)}`);
    }
    if (filters) {
      params.push(`sort=${filters.sort}`);
      if (filters.id) {
        params.push(`lyst_attribute_id=${filters.id}`);
      }
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get(url) as any;
  }
  getSingleList(listId: number, searchTerm?: string, filters?: {sort: string, id: number}): Promise<ListInterface> {
    return new Promise<any>((resolve, reject) => {
      this.getSingleListReq(listId, searchTerm, filters).subscribe({
        next: (res: any) => {
          resolve(res.data);
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private getListPostsReq(listId: number, pageNum: number): Observable<any> {
    let url = `posts/lysts/${listId}`;
    let params = [];
    if (pageNum) {
      params.push(`page=${pageNum}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get<any[]>(url) as any;
  }
  getListPosts(listId: number, pageNum: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getListPostsReq(listId, pageNum).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.data,
            pagination: this.generalHelperService.returnPagination(res.data)
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private getItemPostsReq(item_id: number, pageNum: number): Observable<any> {
    let url = `posts/lyst_items/${item_id}`;
    let params = [];
    if (pageNum) {
      params.push(`page=${pageNum}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get<any[]>(url) as any;
  }
  getItemPosts(item_id: number, pageNum: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getItemPostsReq(item_id, pageNum).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.data,
            pagination: this.generalHelperService.returnPagination(res.data)
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private getListReviewsReq(listId: number, pageNum: number): Observable<any> {
    let url = `reviews/lysts/${listId}`;
    let params = [];
    if (pageNum) {
      params.push(`page=${pageNum}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get<any[]>(url) as any;
  }
  getListReviews(listId: number, pageNum: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getListReviewsReq(listId, pageNum).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.data,
            pagination: this.generalHelperService.returnPagination(res.data)
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private getItemReviewsReq(lyst_item_id: number, pageNum: number): Observable<any> {
    let url = `reviews/lyst_items/${lyst_item_id}`;
    let params = [];
    if (pageNum) {
      params.push(`page=${pageNum}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get<any[]>(url) as any;
  }
  getItemReviews(lyst_item_id: number, pageNum: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getItemReviewsReq(lyst_item_id, pageNum).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.data,
            pagination: this.generalHelperService.returnPagination(res.data)
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private getListLikesReq(listId: number | undefined, pageNum: number): Observable<any> {
    let url = `favorites/lysts/${listId}`;
    let params = [];
    if (pageNum) {
      params.push(`page=${pageNum}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get(url) as any;
  }
  getListLikes(listId: number | undefined, pageNum: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getListLikesReq(listId, pageNum).subscribe((res: any) => {
        resolve({
          data: res.data.data,
          pagination: this.generalHelperService.returnPagination(res.data)
        });
      }, error => {
        reject(error);
      });
    });
  }

  private getSingleItemReq(itemId: number): Observable<any> {
    return this.httpClient.get(`items/${itemId}`) as any;
  }
  getSingleItem(itemId: number): Promise<ListInterface> {
    return new Promise<any>((resolve, reject) => {
      this.getSingleItemReq(itemId).subscribe({
        next: (res: any) => {
          resolve(res.data);
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private getFavoriteListersReq(pageNumber: number, searchTerm?: string): Observable<any> {
    let url = 'lysts/type/favorite_listers';
    let params = [];
    if (searchTerm) {
      params.push(`search_keyword=${encodeURIComponent(searchTerm)}`);
    }
    if (pageNumber) {
      params.push(`page=${pageNumber}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get(url) as any;
  }
  getFavoriteListers(pageNumber: number, searchTerm?: string): Promise<ListInterface> {
    return new Promise<any>((resolve, reject) => {
      this.getFavoriteListersReq(pageNumber, searchTerm).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.lists.data,
            pagination: res.data.lists
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private addListToFavoritesReq(listId: number): Observable<any> {
    return this.httpClient.post(`favorites/lysts/${listId}/toggle`, {}) as any;
  }
  addListToFavorites(userId: number): Promise<ListInterface> {
    return new Promise<any>((resolve, reject) => {
      this.addListToFavoritesReq(userId).subscribe({
        next: (res: any) => {
          resolve(res);
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  getLists() {
    return this.httpClient.get<any>('lysts');
  }

  private getFollowedListersReq(pageNumber: number, searchTerm?: string): Observable<any> {
    let url = 'lysts/type/followed_listers';
    let params = [];
    if (searchTerm) {
      params.push(`search_keyword=${encodeURIComponent(searchTerm)}`);
    }
    if (pageNumber) {
      params.push(`page=${pageNumber}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get(url) as any;
  }
  getFollowedListers(pageNumber: number, searchTerm?: string): Promise<ListInterface> {
    return new Promise<any>((resolve, reject) => {
      this.getFollowedListersReq(pageNumber, searchTerm).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.lists.data,
            pagination: res.data.lists
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private getRecommendedListsReq(pageNumber: number, searchTerm?: string): Observable<any> {
    let url = 'lysts/type/recommended_lists';
    let params = [];
    if (searchTerm) {
      params.push(`search_keyword=${encodeURIComponent(searchTerm)}`);
    }
    if (pageNumber) {
      params.push(`page=${pageNumber}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get(url) as any;
  }
  getRecommendedLists(pageNumber: number, searchTerm?: string): Promise<ListInterface> {
    return new Promise<any>((resolve, reject) => {
      this.getRecommendedListsReq(pageNumber, searchTerm).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.lists.data,
            pagination: res.data.lists
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private getRecommendedListersReq(pageNumber: number, searchTerm?: string): Observable<any> {
    let url = 'lysts/type/recommended_listers';
    let params = [];
    if (searchTerm) {
      params.push(`search_keyword=${encodeURIComponent(searchTerm)}`);
    }
    if (pageNumber) {
      params.push(`page=${pageNumber}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get(url) as any;
  }
  getRecommendedListers(pageNumber: number, searchTerm?: string): Promise<ListInterface> {
    return new Promise<any>((resolve, reject) => {
      this.getRecommendedListersReq(pageNumber, searchTerm).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.lists.data,
            pagination: res.data.lists
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private getFavoriteListsReq(pageNumber: number, searchTerm?: string): Observable<any> {
    let url = 'lysts/type/favorite_lists';
    let params = [];
    if (searchTerm) {
      params.push(`search_keyword=${encodeURIComponent(searchTerm)}`);
    }
    if (pageNumber) {
      params.push(`page=${pageNumber}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get(url) as any;
  }
  getFavoriteLists(pageNumber: number, searchTerm?: string): Promise<ListInterface> {
    return new Promise<any>((resolve, reject) => {
      this.getFavoriteListsReq(pageNumber, searchTerm).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.lists.data,
            pagination: res.data.lists
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private getFavoriteItemsReq(pageNumber: number, searchTerm?: string): Observable<any> {
    let url = 'lysts/type/favorite_items';
    let params = [];
    if (searchTerm) {
      params.push(`search_keyword=${encodeURIComponent(searchTerm)}`);
    }
    if (pageNumber) {
      params.push(`page=${pageNumber}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get(url) as any;
  }
  getFavoriteItems(pageNumber: number, searchTerm?: string): Promise<ListInterface> {
    return new Promise<any>((resolve, reject) => {
      this.getFavoriteItemsReq(pageNumber, searchTerm).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.lists.data,
            pagination: res.data.lists
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  toggleListVisibilty(listId: number) {
    return this.httpClient.post(`lysts/${listId}/toggle_visibility`, {});
  }

  print(listId: number) {
    return this.httpClient.get(`lysts/${listId}/print`, { responseType: 'blob' });
  }

  copyList(listId: number){
    return this.httpClient.post(`lysts/${listId}/copy`, {});
  }

  copyListItem(listId: number, itemId: number | undefined){
    return this.httpClient.post(`items/${itemId}/copy`, {lyst_id: listId});
  }

}
