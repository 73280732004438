import { Component } from '@angular/core';

@Component({
  selector: 'app-themes',
  standalone: true,
  imports: [],
  templateUrl: './themes.component.html',
})
export class ThemesComponent {

}
