import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, from } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { Theme } from 'src/app/services/http-services/home/home-interfaces';
import { HomeService } from 'src/app/services/http-services/home/home.service';
import { ListInterface, ListExtendedInterface } from 'src/app/services/http-services/lists/lists-http-intterfaces';

@Component({
  selector: 'app-single-theme',
  templateUrl: './single-theme.component.html',
})
export class SingleThemeComponent {
  PAGES_AND_PATHS = PAGES_AND_PATHS;
  id: number = 0;
  themes: Array<Theme> = [];
  lists: ListInterface[] | ListExtendedInterface[] | any[] = [];
  pagination!: TlPagination;
  searchTerm: string = '';
  private searchSubject = new Subject<string>();
  pageNum = 1;
  singleTheme: any;


  constructor(
    private actRoute: ActivatedRoute,
    private homeHttpService: HomeService
  ) { }

  ngOnInit() {
    this.getIdAndTheme();
    this.searchSubject.pipe(
      debounceTime(300),
      switchMap((searchTerm: string) => from(this.getSingleTheme(searchTerm)))
    ).subscribe(response => {
      this.singleTheme = response.theme;
      this.lists = response.lysts;
      this.pagination = response.pagination;
      this.themes = response.themes;
    });
  }

  getIdAndTheme() {
    this.actRoute.paramMap.subscribe(params => {
      this.id = Number(params.get('id') || '');
      this.getSingleTheme();
    });
  }

  getSingleTheme(searchTerm?: string) {
    return from(
      this.homeHttpService.formattedGetSingleTheme(this.id, this.pageNum, searchTerm).then((response: any) => {
        this.singleTheme = response.theme;
        this.lists = [...this.lists, ...response.lysts];
        this.pagination = response.pagination;
        this.themes = response.themes;
        return response;
      })
    );
  }

  onSearchChange(event: any): void {
    const keyword = event.target.value;
    this.pageNum = 1;
    this.searchSubject.next(keyword);
  }

  resetSearch() {
    this.searchTerm = '';
    this.searchSubject.next('');
  }

  loadMoreLists(): void {
    this.pageNum++;
    if ((this.pagination?.currentPage ?? 0) < (this.pagination?.lastPage ?? 0)) {
      this.pageNum++;
      this.getSingleTheme();
    }
  }

}
