import { Component, ElementRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject, from } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { ListsHttpService } from 'src/app/services/http-services/lists/lists-http.service';

@Component({
  selector: 'app-favorite-lists',
  templateUrl: './favorite-lists.component.html',
})
export class FavoriteListsComponent {
  favoriteLists: any[] = [];
  pagination!: TlPagination;
  pageNum = 1;
  loading = false;
  private searchSubject = new Subject<string>();
  searchTerm = '';
  PAGES_AND_PATHS = PAGES_AND_PATHS;
  @ViewChild('favoriteListsContainter', { static: false }) private favoriteListsContainter!: ElementRef;

  constructor(
    private listsHttpService: ListsHttpService,
    private toastService: ToastrService
  ) { }

  ngOnInit(): void {
    this.getFavoriteLists();
    this.searchSubject.pipe(
      debounceTime(300),
      switchMap((searchTerm: string) => from(this.getFavoriteLists(searchTerm)))
    ).subscribe(response => {
      this.favoriteLists = response.data;
      this.pagination = response.pagination;
    });
  }

  getFavoriteLists(searchTerm?: string) {
    this.loading = true;
    return (
      this.listsHttpService.getFavoriteLists(this.pageNum, searchTerm).then((response: any) => {
        console.log('getFavoriteLists', response)
        this.favoriteLists = response.data;
        this.pagination = response.pagination;
        this.loading = false;
        return response;
      })
    )
  }

  addListerToFavorites(list: any): void {
    this.listsHttpService.addListToFavorites(list.id).then((res: any) => {
      this.toastService.success(res.message);
      const index = this.favoriteLists.findIndex(listObj => listObj.id === list.id);
      this.favoriteLists[index].am_i_favorite_this_lyst = !this.favoriteLists[index].am_i_favorite_this_lyst;
    });
  }

  onScroll(): void {
    console.log('boop')
    const container = this.favoriteListsContainter.nativeElement;
    const scrollTop = container.scrollTop;
    const offset = 100;

    if (!this.loading && scrollTop < offset && !this.loading && ((this.pagination?.currentPage ?? 0) < (this.pagination?.lastPage ?? 0))) {
      this.pageNum++;
      this.getFavoriteLists();
    }
  }

  onSearchChange(event: any): void {
    this.searchTerm = event.target.value;
    this.pageNum = 1;
    this.searchSubject.next(this.searchTerm);
  }

  resetSearch() {
    this.searchTerm = '';
    this.searchSubject.next('');
  }

}
