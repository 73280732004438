import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AreaChartComponent} from './area-chart.component';
import {NgApexchartsModule} from 'ng-apexcharts';



@NgModule({
  declarations: [AreaChartComponent],
  imports: [
    CommonModule,
    NgApexchartsModule
  ],
  exports: [AreaChartComponent]
})
export class AreaChartModule { }
