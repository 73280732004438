import { Component } from '@angular/core';

@Component({
  selector: 'app-listers',
  standalone: true,
  imports: [],
  templateUrl: './listers.component.html'
})
export class ListersComponent {

}
