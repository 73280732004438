import {Component, OnInit} from '@angular/core';
import {TranslateHelperService} from './services/helpers/translate-helper.service';
import { ActivePageHelperService } from './services/helpers/active-page-helper.service';
import { EchoHelperService } from './services/helpers/echo-helper.service';
import { AuthenticationHelperService } from './services/helpers/authentication-helper.service';
import { register as registerSwiperElements } from 'swiper/element/bundle'
registerSwiperElements()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  constructor(
    private translateHelper: TranslateHelperService,
    public activePageHelper: ActivePageHelperService,
    private echoHelperService: EchoHelperService,
    private authenticationHelperService: AuthenticationHelperService,
  ) {
    this.translateHelper.initTranslate();
    this.initializeEchoOnAuthChange();
  }

  ngOnInit(): void {}

  private initializeEchoOnAuthChange(): void {
    this.authenticationHelperService.authStatus$.subscribe((userData: boolean) => {
      if (userData) {
        this.initialize();
      }
    });
  }

  initialize(): void {
    try {
      this.echoHelperService.initEcho();
      // console.log('Echo initialized successfully');
    } catch (error) {
      // console.error('Error initializing Echo:', error);
    }
    (window as any).Echo.connector.pusher.connection.bind('failed', () => {
      // console.log('Pusher connection failed, attempting reconnection.');
      this.echoHelperService.initEcho();
    });
  }
}
