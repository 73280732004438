<div *ngIf="selectedMedia" class="wrapper-preview-media">
    <img *ngIf="selectedMedia.type === 'image'" class="image-preview" [src]="selectedMedia?.image?.url" alt="">
    <video *ngIf="selectedMedia.type === 'video'" controls>
        <source [src]="selectedMedia.video.url" type="video/mp4">
        <source src="movie.ogg" type="video/ogg">
        Your browser does not support the video tag.
    </video>
    <div class="btns-preview-media">
        <button (click)="download.emit(true)" class="custom-btn custom-btn--circle-outline">
            <img src="../../../assets/icons/outline/icon-download-circle.svg" alt="">
        </button>
        <button (click)="closeModal.emit(true)" class="custom-btn custom-btn--circle-outline">
            <img src="../../../assets/icons/outline/icon-close-modal.svg" alt="">
        </button>
    </div>
</div>