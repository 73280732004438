<div class="wrapper-cards-lister">
  <div *ngFor="let lister of favoriteListers" class="wrapper-card-lister">
    <div class="wrapper-favorite-lister">
      <div class="wrapper-avatar">
        <img src="../../../../assets/images/avatar-user.png" alt="">
      </div>
      <div class="wrapper-user-info">
        <div class="wrapper-username">
          <span>{{lister.name}}</span>
          <img src="../../../../assets/icons/fill/icon-badge.svg" alt="">
          <!--        <img src="./assets/icons/fill/icon-fill-company.svg" alt="">-->
        </div>
        <span>{{lister.followers_count}} {{lister.followers_count === 1 ? 'follower' : 'followers'}}</span>
      </div>
    </div>
  </div>
</div>
<button [routerLink]="PAGES_AND_PATHS.favoriteListers.pagePath" class="custom-btn custom-btn--gray">
  <span>View all listers</span>
</button>
