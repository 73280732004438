import {Component} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'tl-success-action-page',
  templateUrl: './success-action-page.component.html',
})
export class SuccessActionPageComponent {
  historyState: any;
  protected readonly history = history;
  constructor() {}

  ngOnInit(): void {
    this.historyState = history.state;
  }
}
