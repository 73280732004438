import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatMessageTimestamp',
  standalone: true
})
export class FormatMessageTimestampPipe implements PipeTransform {
  transform(dateString: string): string {
    const date = new Date(dateString);
    const now = new Date();
  
    const isToday = date.toDateString() === now.toDateString();
    const isYesterday = date.toDateString() === new Date(now.setDate(now.getDate() - 1)).toDateString();
  
    if (isToday) {
      return `Today, ${date.getHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`;
    } else if (isYesterday) {
      return `Yesterday, ${date.getHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`;
    } else {
      return `${date.toLocaleString('default', { month: 'long' })} ${date.getDate()}, ${date.getHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`;
    }
  }
}
