<div  class="wrapper-page-create-list">
  <div class="header-create-list">
    <button  (click)="goBack()" class="custom-btn custom-btn--circle-outline">
      <img src="../../../assets/icons/icon-arrow-left.svg" alt="">
    </button>
  </div>
  <div class="wrapper-page-master-item">
    <div class="wrapper-custom-search">
      <img class="icon-search" src="../../../assets/icons/outline/icon-search.svg" alt="">
      <input type="text" placeholder="Search..." [(ngModel)]="searchTerm" (input)="onSearchChange($event)">
      <div (click)="resetSearch()" class="wrapper-delete-search">
        <img src="../../../assets/icons/outline/icon-close.svg" alt="">
      </div>
    </div>
    <div *ngIf="masterItems.length != 0" class="text-master-item">Recent searches</div>
    <div *ngIf="masterItems.length != 0" class="wrapper-master-item" #itemsContainer (scroll)="onScroll()">
      <div *ngFor="let item of masterItems" (click)="selectMasterItem(item)" class="wrapper-item-list-view">
        <div class="tl-d-flex tl-align-center">
          <div class="wrapper-list-item-image">
            <img src="../../../../../assets/images/avatar-user.png" alt="">
          </div>
          <div class="wrapper-list-item-info">
            <span class="name">{{item.name}}</span>
            <span class="info-item">{{item.by}}</span>
          </div>
        </div>
        <!-- <div class="rating-item-near-you">
          <img src="./assets/icons/rating/icon-star-fill.svg" alt="">
          <span>4.5</span>
        </div> -->
      </div>
    </div>
  </div>
  <div class="wrapper-add-other-item" *ngIf="masterItems.length === 0" (click)="addNewItem()">
    <span>Add new item</span>
    <div class="wrapper-icon">
      <img src="../../../../../assets/icons/outline/icon-plus.svg" alt="">
    </div>
  </div>
</div>
