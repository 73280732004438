export enum ActionDeleteGroup {
    title = 'Delete this group chat?',
    description = 'Are you sure you want to delete  this group chat?',
    button = 'Yes, delete.'
}

export enum ActionTextLeaveGrup {
    title = 'Leave this chat?',
    description = 'Are you sure you want to exit from this chat group?',
    buttonText = 'Yes, exit.'
}

export enum ActionDeleteSingleChat {
    title = "Delete chat?",
    description = "Are you sure you wish to delete this chat and all it's content? This action cannot be undone.",
    buttonText = "Delete"
}

export enum ShareEntitiesEnums {
  review = 'review',
  lysts = 'lyst',
  lyst_item = 'lyst_item',
  profile = 'profile',
  post = 'post'
}
