
  <div class="content-single-insights">
    <div class="box-insights">
      <div class="wrapper-box-top">
        <div class="wrapper-icon purple">
          <img src="../../../../assets/icons/insights/icon-insights-followers.svg" alt="">
        </div>
        <span class="label1">{{insightsDetails?.[cardEntity] | number}}</span>
        <span class="label2">{{cardTitle}}</span>
      </div>
    </div>
    <span class="title-insights">Growth</span>
    <div class="box-insights">
<!--      <div class="no-data-box">-->
<!--        <span>No data</span>-->
<!--      </div>-->
      <tl-area-chart
        [data]="{'2024-10-10': 5, '2024-10-11': 7, '2024-10-12': 12, '2024-10-15': 14}"
      ></tl-area-chart>
    </div>
    <span class="title-insights">Top locations</span>
    <div class="box-insights">
      <div class="box-insights-tabs">
        <div (click)="topLocationsActiveTab = InsightsTopLocationsTabsEnums.city" class="box-insights-tab" [ngClass]="{'active': topLocationsActiveTab === InsightsTopLocationsTabsEnums.city}">
          <span>Cities</span>
        </div>
        <div (click)="topLocationsActiveTab = InsightsTopLocationsTabsEnums.country" class="box-insights-tab" [ngClass]="{'active': topLocationsActiveTab === InsightsTopLocationsTabsEnums.country}">
          <span>Countries</span>
        </div>
      </div>
      <div *ngFor="let location of insightsDetails?.top_locations[topLocationsActiveTab] | keyvalue" class="wrapper-progressbar">
        <div class="header-progressbar">
          <span class="name">{{location.key}}</span>
          <span class="percentage">{{location.value}}%</span>
        </div>
        <div class="content-progressbar">
          <div class="overlay-progressbar" [ngStyle]="{'width': location.value + '%'}"></div>
        </div>
      </div>
    </div>
    <span class="title-insights">Age range</span>
    <div class="box-insights">
      <div class="box-insights-tabs">
        <div (click)="ageRangeActiveTab = InsightsAgeRangeTabsEnums.all" class="box-insights-tab" [ngClass]="{'active': ageRangeActiveTab === InsightsAgeRangeTabsEnums.all}">
          <span>All</span>
        </div>
        <div (click)="ageRangeActiveTab = InsightsAgeRangeTabsEnums.man" class="box-insights-tab" [ngClass]="{'active': ageRangeActiveTab === InsightsAgeRangeTabsEnums.man}">
          <span>Men</span>
        </div>
        <div (click)="ageRangeActiveTab = InsightsAgeRangeTabsEnums.women" class="box-insights-tab" [ngClass]="{'active': ageRangeActiveTab === InsightsAgeRangeTabsEnums.women}">
          <span>Women</span>
        </div>
        <div (click)="ageRangeActiveTab = InsightsAgeRangeTabsEnums.other" class="box-insights-tab" [ngClass]="{'active': ageRangeActiveTab === InsightsAgeRangeTabsEnums.other}">
          <span>Women</span>
        </div>
      </div>
      <div *ngFor="let ageRange of insightsDetails?.age_range[ageRangeActiveTab] | keyvalue" class="wrapper-progressbar">
        <div class="header-progressbar">
          <span class="name">{{ageRange.key}}</span>
          <span class="percentage">{{ageRange.value}}%</span>
        </div>
        <div class="content-progressbar">
          <div class="overlay-progressbar" [ngStyle]="{'width': ageRange.value + '%'}"></div>
        </div>
      </div>
    </div>
    <span class="title-insights">Gender</span>
    <div class="box-insights">
<!--      <div class="no-data-box">-->
<!--        <span>No data</span>-->
<!--      </div>-->
      <tl-donut-chart
        [data]="insightsDetails?.gender"
      ></tl-donut-chart>
    </div>
    <span class="title-insights">Themes</span>
    <div class="box-insights themes">
      <div *ngFor="let theme of insightsDetails?.themes | keyvalue" class="wrapper-progressbar">
        <div class="header-progressbar">
          <span class="name">{{theme.key}}</span>
          <span class="percentage">{{theme.value}}%</span>
        </div>
        <div class="content-progressbar">
          <div class="overlay-progressbar" [ngStyle]="{'width': theme.value + '%'}"></div>
        </div>
      </div>
    </div>
  </div>
