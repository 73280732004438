import {Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, ViewChild} from '@angular/core';
import {SwiperContainer} from "swiper/swiper-element";

@Component({
  selector: 'tl-onboarding-swipe',
  standalone: true,
  imports: [],
  templateUrl: './onboarding-swipe.component.html',
  styles: [
    `swiper-container {
      width: 480px;
      padding: 0px 32px;
    }
    `
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class OnboardingSwipeComponent {
  @ViewChild('swiper') swiperRef: ElementRef | undefined;
  goToSlide(slideIndex: number): void {
    this.swiperRef?.nativeElement.swiper.slideTo(slideIndex);
  }
}
