import { Component } from '@angular/core';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { ListType } from 'src/app/enums/list-enums';
import { SingleListItem } from 'src/app/services/http-services/lists/lists-http-intterfaces';
import { ListsHttpService } from 'src/app/services/http-services/lists/lists-http.service';

@Component({
  selector: 'app-my-content',
  templateUrl: './my-content.component.html',
})
export class MyContentComponent {
  tabSelected = 'lists';
  openDropdown = false;
  PAGES_AND_PATHS = PAGES_AND_PATHS;

  constructor(
  ) { }

  selectTab(tabSelected: string): void {
    this.tabSelected = tabSelected;
  }
}
