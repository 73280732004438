import { NgClass, NgForOf, NgIf } from '@angular/common';
import { Component, Input, SimpleChanges } from '@angular/core';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { SearchHttpService } from 'src/app/services/http-services/search/search-http.service';
import { ListersComponent } from '../listers/listers.component';
import { CompaniesComponent } from '../companies/companies.component';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-accounts',
  standalone: true,
  imports: [NgIf, NgClass, NgForOf, ListersComponent, CompaniesComponent, RouterModule],
  templateUrl: './accounts.component.html',
})
export class AccountsComponent {
  @Input() searchTerm = '';
  @Input() isLiveSearch = false;
  tabSelected: string = 'all';
  listers: any[] = [];
  companies: any[] = [];
  PAGES_AND_PATHS = PAGES_AND_PATHS;

  constructor(
    private searchHttpService: SearchHttpService
  ) { }

  ngOnInit(): void {
    // this.getAccounts();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.getAccounts();
    }
  }

  getAccounts(): void {
    console.log(this.searchTerm)
    this.searchHttpService.formattedGetAccountsResults(this.isLiveSearch,this.searchTerm).then((response: any) => {
      console.log('accounts', response)
      this.companies = response.data.companies;
      this.listers = response.data.users;
    })
  }

}
