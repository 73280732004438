import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { FollowersHttpService } from 'src/app/services/http-services/followers/followers-http.service';
import { Homepage } from 'src/app/services/http-services/home/home-interfaces';
import { HomeService } from 'src/app/services/http-services/home/home.service';
import { SearchHttpService } from 'src/app/services/http-services/search/search-http.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
})
export class SearchComponent implements OnInit {
  searchTerm = '';
  PAGES_AND_PATHS = PAGES_AND_PATHS;
  homepageData: any;
  isRecentOpen = false;
  recentSearchesData: any[] = [];
  private searchSubject = new Subject<string>();

  constructor(
    private router: Router,
    private homeHttpService: HomeService,
    private searchHttp: SearchHttpService
  ) { }

  ngOnInit(): void {
    this.getHomepageData();
    this.searchSubject.pipe(
      debounceTime(300),
      switchMap((searchTerm: string) => {
        return this.getRecentSearches(searchTerm);
      })
    ).subscribe((response: any) => {
      this.recentSearchesData = response.data;
    });
  }

  onSearchChange(event: any): void {
    this.searchSubject.next(this.searchTerm);
  }

  onEnter(search: any) {
    this.isRecentOpen = false;
    this.router.navigate(['search-results'], {
      queryParams: { searchTerm: search },
    });
  }

  resetSearch() {
    this.searchTerm = '';
    this.isRecentOpen = false;
  }

  getHomepageData(): void {
    this.homeHttpService.formattedGetHomepageData().then((response: { data: Homepage }) => {
      this.homepageData = response.data;
    });
  }

  openRecent(): void {
    this.isRecentOpen = true;
    this.getRecentSearches();
  }

  getRecentSearches(searchTerm?: string) {
    return (
      this.searchHttp.formattedGetRecentSearches(searchTerm).then((response: any) => {
        console.log('getRecentSearches', response)
        this.recentSearchesData = response.data;
        return response;
      })
    )
  }

  deleteRecent(recent: any): void {
    this.searchHttp.formatteddeleteRecentSearch(recent.id).then((response: any) => {
      this.getRecentSearches();
    })
  }

}
