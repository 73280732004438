import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { Conversation, SingleConversationMessage } from 'src/app/services/http-services/chat/chat-http-interfaces';
import { ChatHttpService } from 'src/app/services/http-services/chat/chat-http.service';

@Component({
  selector: 'app-group-details',
  templateUrl: './group-details.component.html',
})
export class GroupDetailsComponent implements OnInit {
  conversationId: number = 0;
  conversationInfo: Partial<Conversation> = {};
  groupName: any;
  coverImage: any = null;
  areChangesSubmitted = false;
  selectedImage: File | null = null;
  imagePreview: string | ArrayBuffer | null = null;
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  constructor(
    private actRoute: ActivatedRoute,
    private router: Router,
    private chatsHttpService: ChatHttpService,
    private toastService: ToastrService,
  ) {
  }

  ngOnInit(): void {
    this.getId();
  }

  getId() {
    this.actRoute.paramMap.subscribe(params => {
      this.conversationId = Number(params.get('id') || '');
      this.getConversationInfo(this.conversationId);
    });
  }

  getConversationInfo(conversationId: number): void {
    this.chatsHttpService.formattedGetSingleConversation(conversationId, 1).then((response: {
      conversation: Conversation,
      data: Array<SingleConversationMessage>,
      pagination: TlPagination
    }) => {
      this.conversationInfo = response.conversation;
      this.groupName = this.conversationInfo?.name;
      console.log('this.conversationInfo', this.conversationInfo)
    });
  }

  openConversation() {
    this.router.navigateByUrl(`/chat/${this.conversationId}`);
  }

  saveChanges() {
    this.areChangesSubmitted = true;
    const formData = new FormData();
    formData.append('name', this.groupName);
    if (this.selectedImage) {
      formData.append('profile_photo', this.selectedImage);
    }
    this.chatsHttpService.updateGroupChatNameAndPhoto(this.conversationId, formData).subscribe((response: any) => {
      this.toastService.success('Details successfully saved!')
      this.areChangesSubmitted = false;
      this.coverImage = null;
      this.selectedImage = null;
      this.getConversationInfo(this.conversationId);
    })
  }

  triggerFileInput(event: Event): void {
    event.preventDefault();
    this.fileInput.nativeElement.click();
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.selectedImage = file;
      const reader = new FileReader();
      reader.onload = () => {
        this.imagePreview = reader.result;
      };
      reader.readAsDataURL(file);
    }
  }
}
