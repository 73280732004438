<div class="wrapper-near-you-search-results">
    <div *ngFor="let result of nearYouData" class="wrapper-item-list-view" [routerLink]="[PAGES_AND_PATHS.singleItem.pagePath, result.id]">
        <div class="tl-d-flex tl-align-center">
            <div class="wrapper-list-item-image">
                <img [src]="result.lyst_item_image_url ? result.lyst_item_image_url : '../../../assets/images/avatar-user-expert.png'" alt="">
            </div>
            <div class="wrapper-list-item-info">
                <span class="name">{{result.name}}</span>
                <span class="info-item">{{result.by}} </span>
            </div>
        </div>
        <div class="rating-item-near-you">
            <img src="./assets/icons/rating/icon-star-fill.svg" alt="">
            <span>{{result.average_rating}}</span>
        </div>
    </div>
</div>