import { NgForOf, SlicePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ListsHelperService } from 'src/app/services/helpers/lists-helper.service';
import { Column } from 'src/app/services/http-services/lists/lists-http-intterfaces';

@Component({
  selector: 'app-filter-items',
  standalone: true,
  imports: [
    NgForOf,
    SlicePipe
  ],
  templateUrl: './filter-items.component.html',
})
export class FilterItemsComponent {
  @Input() columns: Column[] = [];
  @Output() closeModal = new EventEmitter<boolean>();
  @Input() filters = {sort: 'asc', id: 0};

  constructor(
    private listsHelperService: ListsHelperService
  ) { }

  selectColumn(column: Column): void {
    this.filters.id = column.id
  }

  toggleSort(): void {
    this.filters.sort = this.filters.sort === 'asc' ? 'desc' : 'asc';
  }

  saveChanges(): void {
    this.listsHelperService.filterSingleListItems(this.filters);
    this.closeModal.emit(true);
  }

}
