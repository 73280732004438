<div class="wrapper-auth-pages">
  <div class="wrapper-auth-left">
    <div class="wrapper-login-page">
      <div class="language-page">
        <div class="wrapper-logo-auth">
          <img src="../assets/icons/logo-horizontal-yellow.svg" alt="">
        </div>
        <div class="info-auth-language">
          <h1>Select app language</h1>
          <h6>You can change your preference later in the settings.</h6>
        </div>
        <div class="wrapper-custom-search">
          <img class="icon-search" src="../assets/icons/outline/icon-search.svg" alt="">
          <input type="text" [(ngModel)]="searchQuery" (ngModelChange)="onSearchLanguage()" placeholder="Find a language">
          <div *ngIf="searchQuery" (click)="onClearSearch()" class="wrapper-delete-search">
            <img src="../assets/icons/outline/icon-close.svg" alt="">
          </div>
        </div>
        <div class="wrapper-list-languages">
          <div class="wrapper-custom-radiobutton">
            <label *ngFor="let lang of filteredLanguages">
              <input type="radio" name="language" [value]="lang.name" [checked]="translateHelperService.defaultLang === lang.name" (change)="onChangeLanguage($event)">
              <div class="custom-radiobutton">
                <div class="wrapper-language-info">
                  <img [src]="lang.flag" [alt]="'flag-' + lang.name">
                  <span>{{lang.jsonPath | translate}}</span>
                </div>
                <div class="circle-radio">
                  <div class="circle-center"></div>
                </div>
              </div>
            </label>
          </div>
        </div>
        <button [routerLink]="[PAGES_AND_PATHS.login.pagePath]" class="custom-btn custom-btn--primary">
          <span>Continue</span>
        </button>
      </div>
    </div>
  </div>
  <tl-onboarding-swipe class="wrapper-auth-right"></tl-onboarding-swipe>
</div>
