
<div class="wrapper-page">
  <div class="wrapper-likes-page lists">
    <tl-header></tl-header>
    <div class="likes-page">
      <div class="header-likes-page">
        <span class="title">Likes</span>
        <div class="number-likes">
          <span>{{users.length}}</span>
        </div>
      </div>
      <div class="content-likes-page" #likesContainter (scroll)="onScroll()" style="overflow-y: auto; height: 100%;">
        <div *ngFor="let user of users" class="wrapper-user-liked">
          <div [routerLink]="[PAGES_AND_PATHS.profile.pagePath, user.id]" class="user-like-info">
            <img class="avatar-user" [src]="user.profile_photo_url ? user.profile_photo_url : '../../../assets/images/avatar-user.png'" alt="">
            <span>{{user.name}}</span>
            <img class="badge" src="../../../assets/icons/fill/icon-badge.svg" alt="">
          </div>
          <button (click)="followUnfollowUser(user.id)" class="custom-btn custom-btn--outline">
            <span>{{user.am_i_following_this_user ? 'Unfollow' : 'Follow'}}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
