import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchHelperService {

  constructor() { }

  getLatAndLong(): Promise<GeolocationPosition> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (response) => resolve(response),
          (error) => reject(this.handleError(error))
        );
      } else {
        reject(new Error('Geolocation is not supported by this browser.'));
      }
    });
  }

  private handleError(error: GeolocationPositionError): string {
    switch (error.code) {
      case 1:
        return 'Permission denied. Please allow location access.';
      case 2:
        return 'Position unavailable. Please check your GPS or network.';
      case 3:
        return 'Request timed out. Try again later.';
      default:
        return 'An unknown error occurred.';
    }
  }

}
