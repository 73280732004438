import { Component } from '@angular/core';
import {NgIf} from "@angular/common";
import {SubscriptionPlansComponent} from "./subscription-plans/subscription-plans.component";
import {SinglePlanComponent} from "./single-plan/single-plan.component";
import {PaymentComponent} from "./payment/payment.component";
import {CancelSubscriptionModalComponent} from "./cancel-subscription-modal/cancel-subscription-modal.component";
import {SubscriptionPlanTabsEnums} from "../../../../enums/subscription-enums";

@Component({
  selector: 'tl-subscription',
  standalone: true,
  imports: [
    NgIf,
    SubscriptionPlansComponent,
    SinglePlanComponent,
    PaymentComponent,
    CancelSubscriptionModalComponent
  ],
  templateUrl: './subscription.component.html',
})
export class SubscriptionComponent {
  activeTab = SubscriptionPlanTabsEnums.plans;
  selectedPlanId = '';
  isCancelSubscriptionModalOpen = false;
  protected readonly SubscriptionPlanTabsEnums = SubscriptionPlanTabsEnums;
}
