<div class="wrapper-page wrapper-my-list" style="color: white;">
  <tl-header></tl-header>
  <div class="wrapper-header-my-lists">
    <div class="header-my-lists">
      <span>Recommended lists</span>
    </div>
  </div>
  <div class="wrapper-content-my-lists favorite-lists">
    <div class="content-my-lists">
      <div class="filter-my-lists">
        <div class="wrapper-custom-search">
          <img class="icon-search" src="../../../assets/icons/outline/icon-search.svg" alt="">
          <input type="text" placeholder="Search..."  [(ngModel)]="searchTerm" (input)="onSearchChange($event)">
          <div (click)="resetSearch()" class="wrapper-delete-search">
            <img src="../../../assets/icons/outline/icon-close.svg" alt="">
          </div>
        </div>
      </div>
      <div #recommendedListsContainter (scroll)="onScroll()">
      <div class="wrapper-cards-list">
        <div *ngFor="let list of recommendedLists" [routerLink]="[PAGES_AND_PATHS.singleList.pagePath, list.id, 0, 'cards']"  class="wrapper-card-list">
          <img class="image-list" src="https://cdn.vox-cdn.com/thumbor/5d_RtADj8ncnVqh-afV3mU-XQv0=/0x0:1600x1067/1200x900/filters:focal(672x406:928x662)/cdn.vox-cdn.com/uploads/chorus_image/image/57698831/51951042270_78ea1e8590_h.7.jpg" alt="">
          <div class="wrapper-card-list-info">
            <div class="wrapper-badges">
              <div class="badge gray">
                <span>{{list.lyst_items_count}} {{list.lyst_items_count === 1 ? 'item' : 'items'}}</span>
              </div>
            </div>
            <span class="name-list">{{list.title}}</span>
            <div class="author-recommended-list">
              <span>jenny.wilson</span>
              <img src="./assets/icons/fill/icon-badge.svg" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
