import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {TlPagination} from "../../../../interfaces/general-interfaces";
import {PostsHttpService} from "../../../../services/http-services/posts/posts-http.service";
import {FormsModule} from "@angular/forms";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
  selector: 'tl-tag-list',
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    NgForOf
  ],
  templateUrl: './tag-list.component.html',
})
export class TagListComponent implements OnChanges {
  @Input() selectedList: any;
  @Input() selectedListItem: any;
  @Output() changeList: EventEmitter<any> = new EventEmitter<any>();
  selectedListId: any;
  urlParams = {page: 1, search: '', lystItemName: ''};
  tagListsPagination: TlPagination | undefined;
  tagLists: any[] = [];
  searchTerm$ = new Subject<string>();
  constructor(private postsHttpService: PostsHttpService) { }

  ngOnInit(): void {
    this.searchTerm$.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe((searchTerm: any) => {
      this.urlParams.search = searchTerm;
      this.tagListsPagination = undefined;
      this.getTagsLists(true);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedList) {
      this.selectedListId = changes.selectedList.currentValue?.id;
    }
    if (changes.selectedListItem) {
      this.urlParams.lystItemName = changes.selectedListItem.currentValue?.name;
      this.tagListsPagination = undefined;
      this.tagLists = [];
      this.getTagsLists();
    }
  }

  getTagsLists(isSearchChange = false): void {
    this.urlParams.page = this.tagListsPagination?.currentPage ? this.tagListsPagination.currentPage + 1 : 1;
    this.postsHttpService.getTagLists(this.urlParams).then(res => {
      this.tagLists = isSearchChange ? res.data : [...this.tagLists, ...res.data];
      this.tagListsPagination = res.pagination;
    })
  }

  onChangeTagList(event: any): void {
    this.selectedList = this.tagLists.find(tagElement => tagElement.id === event);
    this.changeList.emit(this.selectedList);
  }

  onSearchChange(event: any): void {
    this.searchTerm$.next(event.target.value);
  }

  clearSearch(): void {
    this.searchTerm$.next('');
  }

  onScroll(event: any): void {
    const threshold = 1;
    const position = event.target.scrollTop + event.target.offsetHeight;
    const height = event.target.scrollHeight;
    if (position > height - threshold && (this.tagListsPagination?.currentPage ?? 0) < (this.tagListsPagination?.lastPage ?? 0)) {
      this.getTagsLists();
    }
  }
}
