import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginRoutingModule } from './pages/auth-pages/login/login-routing.module';
import { PAGES_AND_PATHS } from './constants/pages-and-paths';
import { ForgotPasswordRoutingModule } from './pages/auth-pages/forgot-password/forgot-password-routing.module';
import { CreateCompanyAccountRoutingModule } from './pages/auth-pages/create-company-account/create-company-account-routing.module';
import { CreateAccountRoutingModule } from './pages/auth-pages/create-account/create-account-routing.module';
import { AddAccountDetailsRoutingModule } from './pages/auth-pages/add-account-details/add-account-details-routing.module';
import { VerifyAccountRoutingModule } from './pages/auth-pages/verify-account/verify-account-routing.module';
import { CheckYourEmailRoutingModule } from './pages/auth-pages/check-your-email/check-your-email-routing.module';
import { SelectLanguageRoutingModule } from './pages/shared-pages/select-language/select-language-routing.module';
import { SelectListersRoutingModule } from './pages/shared-pages/select-listers/select-listers-routing.module';
import { SelectTopicsRoutingModule } from './pages/shared-pages/select-topics/select-topics-routing.module';
import { HomeRoutingModule } from './pages/home/home-routing.module';
import { ChatRoutingModule } from './pages/chat/chat-routing.module';
import { SearchRoutingModule } from './pages/search/search-routing.module';
import { MyProfileRoutingModule } from './pages/my-profile/my-profile-routing.module';
import { ListsRoutingModule } from './pages/lists/lists-routing.module';
import { SettingsRoutingModule } from './pages/settings/settings-routing.module';
import { PrivacyPolicyRoutingModule } from './pages/shared-pages/privacy-policy/privacy-policy-routing.module';
import { TermsAndConditionsRoutingModule } from './pages/shared-pages/terms-and-conditions/terms-and-conditions-routing.module';
import { SuccessActionPageRoutingRoutingModule } from './pages/shared-pages/success-action-page/success-action-page-routing.module';
import { CompanyProfileRoutingModule } from "./pages/company-profile/company-profile-routing.module";
import { EditProfileRoutingModule } from "./pages/my-profile/edit-profile/edit-profile-routing.module";
import { TrendingItemsRoutingModule } from "./pages/company-profile/trending-items/trending-items-routing.module";
import { CreateListRoutingModule } from "./pages/lists/create-list/create-list-routing.module";
import { GroupDetailsRoutingModule } from './pages/chat/group-details/group-details-routing.module';
import { MyListsRoutingModule } from './pages/lists/my-lists/my-lists-routing.module';
import { FavoriteListsRoutingModule } from './pages/lists/favorite-lists/favorite-lists-routing.module';
import { GroupAccountsPageRoutingModule } from './pages/chat/group-accounts-page/group-accounts-page-routing.module';
import { FollowingFollowersRoutingModule } from './pages/following-followers/following-followers-routing.module';
import { FriendsExpertsRoutingModule } from './pages/friends-experts/friends-experts-routing.module';
import { SingleListRoutingModule } from './pages/lists/single-list/single-list-routing.module';
import { FavoriteItemsRoutingModule } from './pages/lists/favorite-items/favorite-items-routing.module';
import { FavoriteListersRoutingModule } from './pages/lists/favorite-listers/favorite-listers-routing.module';
import { RecommendedListsRoutingModule } from './pages/lists/recommended-lists/recommended-lists-routing.module';
import { RecommendedListersRoutingModule } from './pages/lists/recommended-listers/recommended-listers-routing.module';
import { FollowedListersRoutingModule } from './pages/lists/followed-listers/followed-listers-routing.module';
import { SingleItemRoutingModule } from './pages/single-item/single-item-routing.module';
import { NewItemRoutingModule } from './pages/lists/single-list/new-item/new-item-routing.module';
import { ListLikesRoutingModule } from './pages/shared-pages/list-likes/list-likes-routing.module';
import { SingleThemeRoutingModule } from './pages/home/single-theme/single-theme-routing.module';
import { AllThemesRoutingModule } from './pages/home/all-themes/all-themes-routing.module';
import { SingleReviewRoutingModule } from './pages/shared-pages/single-review/single-review-routing.module';
import {CreateEditPostRoutingModule} from "./pages/posts/create-edit-post/create-edit-post-routing.module";
import {SinglePostRoutingModule} from "./pages/posts/single-post/single-post-routing.module";
import {LatestPostRoutingModule} from "./pages/posts/latest-post/latest-post-routing.module";
import {PostLikesRoutingModule} from "./pages/posts/post-likes/post-likes-routing.module";
import { CreateReviewRoutingModule } from './pages/shared-pages/create-review/create-review-routing.module';
import { SearchResultsRoutingModule } from './pages/search/search-results/search-results-routing.module';
import { UpdateReviewRoutingModule } from './pages/shared-pages/update-review/update-review-routing.module';
import { NearYouRoutingModule } from './pages/near-you/near-you-routing.module';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: PAGES_AND_PATHS.selectLanguage.pagePath
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes),
        LoginRoutingModule,
        ForgotPasswordRoutingModule,
        CreateCompanyAccountRoutingModule,
        CreateAccountRoutingModule,
        AddAccountDetailsRoutingModule,
        VerifyAccountRoutingModule,
        CheckYourEmailRoutingModule,
        SelectLanguageRoutingModule,
        SelectListersRoutingModule,
        SelectTopicsRoutingModule,
        HomeRoutingModule,
        ChatRoutingModule,
        SearchRoutingModule,
        EditProfileRoutingModule,
        MyProfileRoutingModule,
        ListsRoutingModule,
        SettingsRoutingModule,
        PrivacyPolicyRoutingModule,
        TermsAndConditionsRoutingModule,
        SuccessActionPageRoutingRoutingModule,
        CompanyProfileRoutingModule,
        TrendingItemsRoutingModule,
        CreateListRoutingModule,
        GroupDetailsRoutingModule,
        MyListsRoutingModule,
        FavoriteListsRoutingModule,
        GroupAccountsPageRoutingModule,
        FollowingFollowersRoutingModule,
        FriendsExpertsRoutingModule,
        SingleListRoutingModule,
        FavoriteListersRoutingModule,
        FavoriteItemsRoutingModule,
        RecommendedListsRoutingModule,
        RecommendedListersRoutingModule,
        FollowedListersRoutingModule,
        SingleItemRoutingModule,
        NewItemRoutingModule,
        ListLikesRoutingModule,
        SingleThemeRoutingModule,
        AllThemesRoutingModule,
        SingleReviewRoutingModule,
        CreateEditPostRoutingModule,
        SinglePostRoutingModule,
        LatestPostRoutingModule,
        PostLikesRoutingModule,
        CreateReviewRoutingModule,
        SearchResultsRoutingModule,
        UpdateReviewRoutingModule,
        NearYouRoutingModule
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
