<div class="wrapper-page wrapper-my-list">
  <tl-header></tl-header>
  <div class="wrapper-header-my-lists">
    <div class="header-my-lists">
      <span>Favorite lists</span>
    </div>
  </div>
  <div class="wrapper-content-my-lists favorite-lists">
    <div class="content-my-lists">
      <div class="filter-my-lists">
        <div class="wrapper-custom-search">
          <img class="icon-search" src="../../../assets/icons/outline/icon-search.svg" alt="">
          <input type="text" placeholder="Search..." [(ngModel)]="searchTerm" (input)="onSearchChange($event)">
          <div (click)="resetSearch()" class="wrapper-delete-search">
            <img src="../../../assets/icons/outline/icon-close.svg" alt="">
          </div>
        </div>
      </div>
      <div class="wrapper-cards-list" #favoriteListsContainter (scroll)="onScroll()"
        style="overflow-y: auto; height: 100%;">
        <div *ngFor="let list of favoriteLists" class="wrapper-card-list">
          <img class="image-list"
            [src]="list.lyst_image_url ? list.lyst_image_url : 'https://cdn.vox-cdn.com/thumbor/5d_RtADj8ncnVqh-afV3mU-XQv0=/0x0:1600x1067/1200x900/filters:focal(672x406:928x662)/cdn.vox-cdn.com/uploads/chorus_image/image/57698831/51951042270_78ea1e8590_h.7.jpg'"
            alt="">
          <div class="wrapper-card-list-info" [routerLink]="[PAGES_AND_PATHS.singleList.pagePath, list.id, 0, 'cards']">
            <div class="wrapper-badges">
              <div class="badge gray">
                <span>{{list.lyst_items_count}} {{list.lyst_items_count === 1 ? 'item' : 'items'}}</span>
              </div>
            </div>
            <span class="name-list">{{list.title}}</span>
            <span class="time-list">{{list.updated_at | timeAgo}}</span>
          </div>
          <div class="wrapper-right-element">
            <div class="wrapper-send-btn">
              <button (click)="addListerToFavorites(list)" class="custom-btn"
                [ngClass]="list.am_i_favorite_this_lyst ? 'custom-btn--circle-primary' : 'custom-btn--circle-outline'">
                <img src="../../../../assets/icons/fill/icon-heart.svg" alt="">
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>