import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PAGES_AND_PATHS} from "../../../constants/pages-and-paths";
import {AuthGuardService} from "../../../guards/auth-guard.service";
import {PostLikesComponent} from "./post-likes.component";

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.postLikes.pageInRouting,
    data: { data: PAGES_AND_PATHS.postLikes },
    component: PostLikesComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./post-likes.module').then(m => m.PostLikesModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PostLikesRoutingModule { }
