import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PAGES_AND_PATHS} from "../../../constants/pages-and-paths";
import {AuthGuardService} from "../../../guards/auth-guard.service";
import {SinglePostComponent} from "./single-post.component";

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.singlePost.pageInRouting,
    data: { data: PAGES_AND_PATHS.singlePost },
    component: SinglePostComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./single-post.module').then(m => m.SinglePostModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SinglePostRoutingModule { }
