import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {EmailRegex} from '../../../constants/general-variables';
import {AuthenticationHelperService} from '../../../services/helpers/authentication-helper.service';
import {AuthenticationHttpService} from '../../../services/http-services/authentication/authentication-http.service';
import {PAGES_AND_PATHS} from '../../../constants/pages-and-paths';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'tl-create-account',
  templateUrl: './create-account.component.html',
})
export class CreateAccountComponent implements OnInit {
  isPasswordVisible = false;
  formTriedToSubmit = false;
  createAccountForm!: FormGroup;
  isSpinnerActive = false;
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;
  constructor(private formBuilder: FormBuilder,
              private toastService: ToastrService,
              private authHelperService: AuthenticationHelperService,
              private authHttpService: AuthenticationHttpService) { }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.createAccountForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(EmailRegex)]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      tos: [false, Validators.requiredTrue]
    });
  }

  onSubmitCreateAccountForm(): void {
    this.formTriedToSubmit = true;
    if (this.createAccountForm.valid) {
      this.isSpinnerActive = true;
      this.authHttpService.postRegister(this.createAccountForm.value).then(res => {
        this.isSpinnerActive = false;
        this.authHelperService.clientLogin(res.user, res.token, PAGES_AND_PATHS.verifyAccount.pagePath);
      }).catch(({error}: {error: { errors: { [key: string]: string }}}) => {
        this.isSpinnerActive = false;
        Object.values(error.errors).forEach(error => this.toastService.error(error));
      });
    }
  }
}
