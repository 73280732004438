<div class="wrapper-subscription-plans-page">
  <span class="title-settings-page">Upgrade to Expert plan?</span>
  <div class="wrapper-upgrade-to-plan">
    <div class="wrapper-header-upgrade-plan">
      <button class="custom-btn custom-btn--yellow">
        <span>Expert</span>
      </button>
      <p><span>10.99$</span> / month</p>
    </div>
    <div class="wrapper-content-upgrade-plan">
      <div class="wrapper-feature-upgrade-plan">
        <div class="circle-feature-upgrade-plan">
          <img src="../../../../../assets/icons/outline/icon-check.svg" alt="">
        </div>
        <span>No Ads</span>
      </div>
      <div class="wrapper-feature-upgrade-plan">
        <div class="circle-feature-upgrade-plan">
          <img src="../../../../../assets/icons/outline/icon-check.svg" alt="">
        </div>
        <span>Sponsored Lists</span>
      </div>
      <div class="wrapper-feature-upgrade-plan">
        <div class="circle-feature-upgrade-plan">
          <img src="../../../../../assets/icons/outline/icon-check.svg" alt="">
        </div>
        <span>Recommended Lists</span>
      </div>
      <div class="wrapper-feature-upgrade-plan">
        <div class="circle-feature-upgrade-plan">
          <img src="../../../../../assets/icons/outline/icon-check.svg" alt="">
        </div>
        <span>Up to 50 Private Lists</span>
      </div>
    </div>
  </div>
  <button class="custom-btn custom-btn--primary xs-mt-30">
    <span>Upgrade to premium</span>
  </button>
</div>
