import {Component, ElementRef, Renderer2, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TlPagination} from "../../../interfaces/general-interfaces";
import {ShareEntitiesEnums} from "../../../enums/chat-enums";
import {PAGES_AND_PATHS} from "../../../constants/pages-and-paths";
import {UserRoles} from "../../../enums/general-enums";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {PostsHttpService} from "../../../services/http-services/posts/posts-http.service";
import {AuthenticationHelperService} from "../../../services/helpers/authentication-helper.service";
import {UiHelperService} from "../../../services/helpers/ui-helper.service";

@Component({
  selector: 'tl-single-post',
  templateUrl: './single-post.component.html',
})
export class SinglePostComponent {
  postId = '';
  postData: any;
  isCallToActionModal = false;
  isShareModalOpen = false;
  isDeletePostModalOpen = false;
  isReportPostModalOpen = false;
  postUrl = '';
  commentForEdit: any = null;
  commentForDelete: any = null;
  commentForm!: FormGroup;
  comments: any[] = [];
  commentPage = 1;
  commentPagination: TlPagination | undefined;
  showImageInFullScreen: any;
  authUserData: any;
  @ViewChild('viewMoreBtn') viewMoreBtn: ElementRef | undefined;
  protected readonly ShareEntitiesEnums = ShareEntitiesEnums;
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;
  protected readonly UserRoles = UserRoles;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private toastService: ToastrService,
              private formBuilder: FormBuilder,
              private postsHttpService: PostsHttpService,
              private renderer: Renderer2,
              private uiHelperService: UiHelperService,
              private authHelperService: AuthenticationHelperService) { }

  ngOnInit() {
    const baseUrl = window.location.origin;
    this.authUserData = this.authHelperService.getUserData;
    this.activatedRoute.params.subscribe(res => {
      this.postId = res['postId'];
      this.getSinglePostData();
      this.commentPagination = undefined;
      this.comments = [];
      this.getPostComments();
      this.postUrl = baseUrl + PAGES_AND_PATHS.singlePost.pagePath + '/' + this.postId;
    });
    this.buildForm();
    this.listenToClickViewMoreBtn();
  }

  listenToClickViewMoreBtn(): void {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.uiHelperService.checkIfClickedOutsideOfTheElement(this.viewMoreBtn, this.viewMoreBtn, e)) {
        this.isCallToActionModal = false;
      }
    });
  }

  buildForm(): void {
    this.commentForm = this.formBuilder.group({
      comment: ['', [Validators.required]],
    });
  }

  getSinglePostData(): void {
    this.postsHttpService.getSinglePost({postId: this.postId}).then(res => {
      this.postData = res.data;
    })
  }

  getPostComments(): void {
    this.commentPage = this.commentPagination?.currentPage ? this.commentPagination.currentPage + 1 : 1;
    this.postsHttpService.getPostComments({postId: this.postId, page: this.commentPage}).then(res => {
      this.comments = [...this.comments, ...res.data];
      this.commentPagination = res.pagination;
    })
  }

  togglePostLike(): void {
    this.postsHttpService.togglePostLike({postId: this.postId}).then(res => {
      if (res.code === 200) {
        this.postData.am_i_liked_this = res.data.am_i_liked_this;
        this.postData.likes_count = res.data.likes_count;
      }
    })
  }

  toggleCommentLike(commentId: number): void {
    this.postsHttpService.togglePostCommentLike({commentId}).then(res => {
      if (res.code === 200) {
        console.log('res comment like ', res.data, 'commentId ', commentId);
        const commentIndex = this.comments.findIndex(comment => comment.id === commentId);
        if ( commentIndex !== -1) {
          this.comments[commentIndex].am_i_liked_this = res.data.am_i_liked_this;
          this.comments[commentIndex].likes_count = res.data.likes_count;
        }
      }
    })
  }

  onCallActionEditPost(): void {
    this.isCallToActionModal = false;
    setTimeout(() => {
      this.router.navigate([PAGES_AND_PATHS.editPost.pagePath, this.postId]);
    })
  }

  onCallActionSharePost(): void {
    this.isCallToActionModal = false;
    this.isShareModalOpen = true;
  }

  onCallActionReportPost(): void {
    this.isCallToActionModal = false;
    this.isReportPostModalOpen = true;
  }

  onCallActionDeletePost(): void {
    this.isCallToActionModal = false;
    this.isDeletePostModalOpen = true;
  }

  onConfirmPostDelete(): void {
    this.postsHttpService.deletePost({postId: this.postId}).then(res => {
      if (res.code === 200) {
        this.isDeletePostModalOpen = false;
        setTimeout(() => {
          this.router.navigate([PAGES_AND_PATHS.profile.pagePath, this.authHelperService.getUserData.id])
        })
      }
    });
  }

  onPostReportSent(): void {
    this.isReportPostModalOpen = false;
    // setTimeout(() => {
    //   this.router.navigate([PAGES_AND_PATHS.successAction.pagePath], {state: successPageDetails});
    // })
  }

  onSelectCommentForEdit(comment: any): void {
    this.commentForEdit = comment;
    this.commentForm.patchValue(comment);
  }

  onConfirmCommentDelete(): void {
    this.postsHttpService.deletePostComment({commentId: this.commentForDelete.id}).then(res => {
      if (res.code === 200) {
        this.comments = this.comments.filter(comment => comment.id !== this.commentForDelete.id);
        this.commentForDelete = null;
        this.toastService.success(res.message);
      }
    }).catch(({error}: {error:{ errors: { [key: string]: string }}}) => {
      Object.values(error.errors).forEach(error => this.toastService.error(error));
    });
  }

  onSubmitCommentForm(): void {
    if (this.commentForm.valid) {
      if (this.commentForEdit) {
        this.postsHttpService.updatePostComment({commentId: this.commentForEdit.id}, this.commentForm.value).then(res => {
          if (res.code === 200) {
            const commentIndex = this.comments.findIndex(comment => comment.id === res.data.id);
            if( commentIndex !== -1) {
              this.comments[commentIndex].comment = res.data.comment;
            }
            this.commentForm.reset();
            this.commentForEdit = null;
            this.toastService.success(res.message);
          }
        }).catch(({error}: {error:{ errors: { [key: string]: string }}}) => {
          Object.values(error.errors).forEach(error => this.toastService.error(error));
        });
      } else {
        this.postsHttpService.storePostComment({postId: this.postId}, this.commentForm.value).then(res => {
          if (res.code === 200) {
            this.comments.push({...res.data, likes_count: 0});
            this.commentForm.reset();
            this.toastService.success(res.message);
          }
        }).catch(({error}: {error:{ errors: { [key: string]: string }}}) => {
          Object.values(error.errors).forEach(error => this.toastService.error(error));
        });
      }
    }
  }

  onScrollComments(event: any): void {
    const threshold = 1;
    const position = event.target.scrollTop + event.target.offsetHeight;
    const height = event.target.scrollHeight;
    if (position > height - threshold && (this.commentPagination?.currentPage ?? 0) < (this.commentPagination?.lastPage ?? 0)) {
      this.getPostComments();
    }
  }
}
