<div *ngIf="!isLast || !addNewRow" class="wrapper-content-table-row-item">
  <div class="column-table-row-item">
    <img *ngIf="loggedUser.id === user_id;" src="../../../../../assets/icons/outline/icon-menu.svg" alt="">
  </div>
  <div class="column-table-row-item">
    <span>{{ item.position }}</span>
  </div>
  <div class="column-table-row-item">
    <div *ngIf="item.lyst_item_image_url !== null" (click)="triggerFileInput($event)" class="wrapper-photo-item">
      <img [src]="item.lyst_item_image_url" alt="">
    </div>
    <div class="wrapper-image-item-tabel">
      <label *ngIf="item.lyst_item_image_url === null" (click)="triggerFileInput($event)" class="wrapper-add-photo-item">
        <img src="../../../../../assets/icons/outline/icon-plus.svg" alt="">
      </label>
      <input #fileInput type="file" accept=".jpg, .jpeg, .png, .svg" (change)="onFileChange($event)" />
    </div>
  </div>
  <div class="column-table-row-item">
    <ng-container *ngIf="isEditing['name']; else nameView">
      <div class="column-table-row-item">
        <input #input type="text" placeholder="Type here..." [(ngModel)]="editingValues['name']" (blur)="saveEdit('name')">
      </div>
    </ng-container>
    <ng-template #nameView>
      <span (click)="editValue('name', item.name)">
        {{ item.name ? item.name : 'Type here...' }}
      </span>
    </ng-template>
  </div>
  <div *ngIf="!isMasterItem" (click)="openMasterItem()" class="column-table-row-item">
    <ng-container *ngIf="isEditing['by']; else byView">
      <div class="column-table-row-item">
        <input #input type="text" placeholder="Type here..." [(ngModel)]="editingValues['by']" (blur)="saveEdit('by')">
      </div>
    </ng-container>
    <ng-template #byView>
      <span (click)="editValue('by', item.by)">
        <span>{{item.by ? item.by : 'Type here...'}}</span>
      </span>
    </ng-template>
  </div>
  <div *ngIf="isMasterItem" class="column-table-row-item">
    <ng-container *ngIf="isEditing['by']; else byView">
      <div class="column-table-row-item">
        <input #input type="text" placeholder="Type here..." [(ngModel)]="editingValues['by']" (blur)="saveEdit('by')">
      </div>
    </ng-container>
    <ng-template #byView>
      <span (click)="editValue('by', item.by)">
        <span>{{item.by ? item.by : 'Type here...'}}</span>
      </span>
    </ng-template>
  </div>
  <div *ngFor="let column of columns | slice:4" class="column-table-row-item">
    <ng-container *ngIf="isEditing[column.id]; else notEditing">
      <div class="column-table-row-item">
        <input #input type="text" placeholder="Type here..." [(ngModel)]="editingValues[column.id]"
          (blur)="saveEdit(column.id)">
      </div>
    </ng-container>
    <ng-template #notEditing>
      <span (click)="editValue(column.id, item.lyst_item_attributes)">
        <span>{{ getAttributeValue(item.lyst_item_attributes, column.id) }}</span>
      </span>
    </ng-template>
  </div>
  <!-- below is the code for displaying after the new column was added -->
  <!-- <div class="column-table-row-item">
    <input type="text" placeholder="Type here...">
  </div> -->
  <div class="column-table-row-item">
    <div *ngIf="loggedUser.id === user_id;" class="wrapper-delete-item" appClickedOutside
    (clickedOutside)="isDeleteButtonVisible = false">
      <img (click)="isDeleteButtonVisible = !isDeleteButtonVisible"
        src="../../../../../assets/icons/outline/icon-view-more.svg" alt="">
      <div *ngIf="isDeleteButtonVisible && loggedUser.id === user_id" (click)="openDeleteItemModal(item)" class="dropdown-delete-item">
        <img src="../../../../../assets/icons/outline/icon-delete.svg" alt="">
        <span>Delete item</span>
      </div>
    </div>
    <!--   TODO JS - Copy to my list   -->
    <div *ngIf="loggedUser.id !== user_id;" (click)="openCopyModal(item, $event)"  appClickedOutside (clickedOutside)="isCopyDropdownOpen = false;" class="wrapper-delete-item copy">
      <img src="./assets/icons/outline/icon-view-more.svg" alt="">
      <div *ngIf="isCopyDropdownOpen  && selectedItem?.id === item.id" class="dropdown-delete-item">
        <img src="../../../../../../assets/icons/outline/icon-lists.svg" alt="">
        <span (click)="isCopyModalOpen = true">Copy to my list</span>
      </div>
    </div>
  </div>
</div>

<!-- new row below -->
<div *ngIf="isLast && addNewRow" class="wrapper-content-table-row-item">
  <div class="column-table-row-item">
    <img src="../../../../../assets/icons/outline/icon-menu.svg" alt="">
  </div>
  <div class="column-table-row-item">
    <span>{{item.position}}</span>
  </div>
  <div class="column-table-row-item">
    <div class="wrapper-image-item-tabel">
      <div class="wrapper-add-photo-item" (click)="triggerFileInput($event)">
        <img src="../../../../../assets/icons/outline/icon-plus.svg" alt="">
      </div>
      <input #fileInput type="file" accept=".jpg, .jpeg, .png, .svg" (change)="onFileChange($event)" />
    </div>
  </div>
  <div class="column-table-row-item">
    <input #input type="text" placeholder="Type here..." [(ngModel)]="editingValues['name']" (blur)="saveEdit('name')">
  </div>
  <div class="column-table-row-item">
    <input #input (click)="openMasterItem()" type="text" placeholder="Type here..." [(ngModel)]="editingValues['by']" (blur)="saveEdit('by')">
  </div>
  <div *ngFor="let column of columns | slice:4" class="column-table-row-item">
    <input #input type="text" placeholder="Type here..." [(ngModel)]="editingValues[column.id]"
    (blur)="saveEdit(column.id)">
  </div>
  <div class="column-table-row-item">
    <div class="wrapper-delete-item">
      <img src="../../../../../assets/icons/outline/icon-view-more.svg" alt="">
    </div>
  </div>
</div>

<tl-confirm-modal *ngIf="isDeleteModalOpen" [modalIcon]="'../../../../../assets/icons/outline/icon-delete.svg'"
  [modalTitle]="deleteModalText.title" [confirmBtnText]="deleteModalText.button"
  [modalDescription]="deleteModalText.description" (cancelModal)="isDeleteModalOpen = false"
  (confirmModal)="takeAction()">
</tl-confirm-modal>
<app-copy-item-modal *ngIf="isCopyModalOpen" [url]="url" [selectedItem]="selectedItem" (closeModal)="isCopyModalOpen = false; selectedItem = null;" (isOpenToastOpen)="isOpenToastOpen = $event" (selectedListAndItem)="selectedListAndItem = $event"></app-copy-item-modal>
<app-copy-toast-notification *ngIf="isOpenToastOpen" [selectedListAndItem]="selectedListAndItem" (closeModal)="closeCopyModal()"></app-copy-toast-notification>