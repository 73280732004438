<div class="wrapper-modal">
  <div class="wrapper-modal-content delete">
    <div class="wrapper-modal-image">
      <img [src]="modalIcon" alt="">
    </div>
    <div class="modal-title">{{modalTitle}}</div>
    <div class="modal-description">{{modalDescription}}</div>
    <div class="modal-buttons">
      <button class="custom-btn custom-btn--outline-red" (click)="cancelModal.emit()"><span>{{cancelBtnText}}</span></button>
      <button class="custom-btn custom-btn--delete" (click)="confirmModal.emit()"><span>{{confirmBtnText}}</span></button>
    </div>
  </div>
</div>
