<div class="wrapper-auth-pages">
  <div class="wrapper-auth-left add-details">
    <div class="wrapper-login-page">
      <div class="login-page">
        <div class="wrapper-logo-auth">
          <img src="../assets/icons/logo-horizontal-yellow.svg" alt="">
        </div>
        <div class="info-auth-login">
          <h1>Create company account</h1>
          <h6>Signup for a new company account by adding your information below.</h6>
        </div>
        <form [formGroup]="createCompanyAccountForm" (ngSubmit)="onSubmitCreateCompanyAccountForm()" class="wrapper-content-auth">
          <div class="wrapper-input" [ngClass]="{'error': createCompanyAccountForm.controls['full_name'] | formControlErrorMessages: null : formTriedToSubmit}">
            <span class="label-input">Full name</span>
            <div class="custom-input">
              <input type="text" formControlName="full_name" placeholder="Enter your full name">
            </div>
            <div *ngIf="createCompanyAccountForm.controls['full_name'] | formControlErrorMessages: null: formTriedToSubmit" class="wrapper-error-message">
              <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
              <span>{{createCompanyAccountForm.controls['full_name'] | formControlErrorMessages: null: formTriedToSubmit}}</span>
            </div>
          </div>
          <div class="wrapper-input">
            <span class="label-input">Company name</span>
            <div class="custom-input">
              <input type="text" formControlName="company_name" placeholder="Enter company name">
            </div>
            <div *ngIf="createCompanyAccountForm.controls['company_name'] | formControlErrorMessages: null: formTriedToSubmit" class="wrapper-error-message">
              <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
              <span>{{createCompanyAccountForm.controls['company_name'] | formControlErrorMessages: null: formTriedToSubmit}}</span>
            </div>
          </div>
          <div class="wrapper-input" [ngClass]="{'error': createCompanyAccountForm.controls['company_website'] | formControlErrorMessages: null : formTriedToSubmit}">
            <span class="label-input">Company website</span>
            <div class="custom-input">
              <input type="text" formControlName="company_website" placeholder="Enter company website">
            </div>
            <div *ngIf="createCompanyAccountForm.controls['company_website'] | formControlErrorMessages: null: formTriedToSubmit" class="wrapper-error-message">
              <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
              <span>{{createCompanyAccountForm.controls['company_website'] | formControlErrorMessages: null: formTriedToSubmit}}</span>
            </div>
          </div>
          <div class="wrapper-input" [ngClass]="{'error': createCompanyAccountForm.controls['email'] | formControlErrorMessages: null : formTriedToSubmit}">
            <span class="label-input">Email address</span>
            <div class="custom-input wrapper-email-address-autocomplete">
              <input type="text" formControlName="emailForView" placeholder="Email address">
              <div class="email-autocomplete">
                <span>{{companyMailSuffix}}</span>
              </div>
            </div>
            <div *ngIf="createCompanyAccountForm.controls['email'] | formControlErrorMessages: null: formTriedToSubmit" class="wrapper-error-message">
              <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
              <span>{{createCompanyAccountForm.controls['email'] | formControlErrorMessages: null: formTriedToSubmit}}</span>
            </div>
          </div>
          <div class="wrapper-input" [ngClass]="{'error': createCompanyAccountForm.controls['phone_number'] | formControlErrorMessages: null: formTriedToSubmit}">
            <span class="label-input">Phone number</span>
            <div class="wrapper-phone-number open" [ngClass]="{'open': isAreaCodeOpen}">
              <div #areaCodeBtn class="wrapper-flags" (click)="isAreaCodeOpen = !isAreaCodeOpen">
                <img class="icon-flag" [src]="'../../../../assets/icons/flags/' + selectedArea.code.toLowerCase() + '.png'" alt="">
                <img class="icon-arrow-flags" src="../../../assets/icons/icon-arrow-left.svg" alt="">
              </div>
              <div class="wrapper-number-country">
                <span>{{selectedArea.phone_code}}</span>
              </div>
              <input type="text" formControlName="phone_number">
              <div class="content-dropdown-phone-number" *ngIf="isAreaCodeOpen">
                <div class="item-dropdown-phone-number" *ngFor="let country of Countries" (click)="onSelectCodeArea(country)">
                  <img [src]="'../../../../assets/icons/flags/' + country.code.toLowerCase() + '.png'" alt="">
                  <span>{{country.name}} ({{country.phone_code}})</span>
                </div>
              </div>
            </div>
            <div *ngIf="createCompanyAccountForm.controls['phone_number'] | formControlErrorMessages: null : formTriedToSubmit" class="wrapper-error-message">
              <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
              <span>{{createCompanyAccountForm.controls['phone_number'] | formControlErrorMessages: null: formTriedToSubmit}}</span>
            </div>
          </div>
          <div class="wrapper-input" [ngClass]="{'error': createCompanyAccountForm.controls['username'] | formControlErrorMessages: null : formTriedToSubmit}">
            <span class="label-input">Username</span>
            <div class="custom-input">
              <input type="text" formControlName="username" placeholder="Enter your username">
            </div>
            <div *ngIf="createCompanyAccountForm.controls['username'] | formControlErrorMessages: null: formTriedToSubmit" class="wrapper-error-message">
              <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
              <span>{{createCompanyAccountForm.controls['username'] | formControlErrorMessages: null: formTriedToSubmit}}</span>
            </div>
          </div>
          <div class="wrapper-input" [ngClass]="{'error': createCompanyAccountForm.controls['password'] | formControlErrorMessages: null : formTriedToSubmit}">
            <span class="label-input">Password</span>
            <div class="custom-input">
              <input
                [type]="isPasswordVisible ? 'text' : 'password'"
                placeholder="Enter password"
                formControlName="password">
              <img *ngIf="!isPasswordVisible" (click)="isPasswordVisible = true" class="icon-input" src="../assets/icons/outline/icon-outline-eye.svg" alt="">
              <img *ngIf="isPasswordVisible" (click)="isPasswordVisible = false" class="icon-input" src="../assets/icons/outline/icon-outline-eye-with-slash.svg" alt="">
            </div>
            <div *ngIf="createCompanyAccountForm.controls['password'] | formControlErrorMessages: null: formTriedToSubmit" class="wrapper-error-message">
              <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
              <span>{{createCompanyAccountForm.controls['password'] | formControlErrorMessages: null: formTriedToSubmit}}</span>
            </div>
          </div>
          <div class="wrapper-custom-checkbox">
            <input formControlName="tos" type="checkbox" id="custom-checkbox">
            <label for="custom-checkbox">
              I agree to the <span>Terms of Service </span>and <span>Privacy Policy.</span>
            </label>
          </div>
          <button type="submit" [disabled]="createCompanyAccountForm.invalid" class="custom-btn custom-btn--primary">
            <span>Create company account</span>
            <tl-spinner *ngIf="isSpinnerActive"></tl-spinner>
          </button>
        </form>
      </div>
    </div>
  </div>
  <tl-onboarding-swipe class="wrapper-auth-right"></tl-onboarding-swipe>
</div>
