import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'setChatUsernames',
  standalone: true
})
export class SetChatUsernamesPipe implements PipeTransform {
  loggedUserId = localStorage.getItem('tl-user') as any;

  transform(chat: any): any {
    if (!chat.is_group) {
      if (chat.other_participants !== undefined) {
        const otherParticipant = chat.other_participants.find((participant: any) => participant.id !== this.loggedUserId.id);
        return otherParticipant ? otherParticipant.username : '';
      }
    } else {
      const otherParticipants = chat.other_participants
        .filter((participant: any) => participant.id !== this.loggedUserId.id)
        .map((participant: any) => participant.username)
        .join(', ');
      return otherParticipants;
    }
  }

}
