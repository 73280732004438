import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { ShareEntitiesEnums } from 'src/app/enums/chat-enums';
import { PageListType } from 'src/app/enums/list-enums';
import { AuthenticationHelperService } from 'src/app/services/helpers/authentication-helper.service';
import { ListsHelperService } from 'src/app/services/helpers/lists-helper.service';
import { Column, DeleteModalText, ListExtendedInterface, ListInterface, ListItem } from 'src/app/services/http-services/lists/lists-http-intterfaces';
import { ListsHttpService } from 'src/app/services/http-services/lists/lists-http.service';

@Component({
  selector: 'app-single-list',
  templateUrl: './single-list.component.html',
})
export class SingleListComponent implements OnInit, OnDestroy {
  listId: number = 0;
  list: ListInterface | undefined | ListExtendedInterface | any;
  tabSelected = '';
  pageType: PageListType = PageListType.mine;
  PAGES_AND_PATHS = PAGES_AND_PATHS;
  isViewMoreModalOpen = false;
  isChangeVisibilityModalOpen = false;
  columns: Column[] = [];
  items: ListItem[] = [];
  refreshPageSubscription: Subscription | undefined;
  searchTerm = '';
  searchSubject = new Subject<string>();
  isDeleteModalOpen = false;
  modalText: DeleteModalText = { title: '', description: '', button: '' };
  loggedUser: any;
  isShareProfileModalOpen = false;
  listUrl='';
  ShareEntitiesEnums = ShareEntitiesEnums;
  filterItemsSubscription: Subscription | undefined;
  filters = {sort: 'asc', id: 0};
  isCopyListModalOpen = false;

  constructor(
    private actRoute: ActivatedRoute,
    private listsHttpService: ListsHttpService,
    private listsHelperService: ListsHelperService,
    private toastService: ToastrService,
    private authenticationHelperService: AuthenticationHelperService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.listUrl = window.location.href;
    this.loggedUser = this.authenticationHelperService.getUserData;
    this.getParams();

    this.refreshPageSubscription = this.listsHelperService.refreshSingleListPage.subscribe(res => {
      if (res) {
        this.getSingleList();
      }
    });

    this.searchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((searchTerm: string) => this.getSingleList(searchTerm))
    ).subscribe((res: any) => {
      this.items = res.lyst_items;
      this.columns = res.attributes;
    });
    
    this.filterItemsSubscription = this.listsHelperService.filterSingleListPage.subscribe((res: {sort: string, id: number}) => {
      if (res) {
        this.filters = res;
        this.getSingleList();
      }
    });
  }

  getParams() {
    this.actRoute.paramMap.subscribe(params => {
      this.listId = Number(params.get('id'));
      this.tabSelected = params.get('view') as string;
      this.getSingleList();
    });
  }

  getSingleList(searchTerm?: string) {
    return (
      this.listsHttpService.getSingleList(this.listId, searchTerm, this.filters).then((res: ListInterface) => {
        if (res)
          this.list = res;
        this.items = res.lyst_items;
        this.columns = res.attributes;
        this.listsHelperService.refreshPage(false);
        return res;
      })
    )
  }

  ngOnDestroy(): void {
    this.refreshPageSubscription?.unsubscribe();
    this.filterItemsSubscription?.unsubscribe();
  }

  addListerToFavorites(): void {
    this.listsHttpService.addListToFavorites(this.list.id).then((res: any) => {
      this.getSingleList();
    });
  }

  onSearchTermChanged(searchTerm: string) {
    this.searchSubject.next(searchTerm);
  }

  openDeleteModal() {
    this.isDeleteModalOpen = true;
    this.modalText.title = 'Delete lyst?';
    this.modalText.description = 'Are you sure you wish to delete this lyst? This action cannot be undone.'
    this.modalText.button = 'Delete';
  }

  deleteList(): void {
    this.isDeleteModalOpen = false;
    this.listsHttpService.deleteList(this.list.id).then((response: any) => {
      this.toastService.success(response.message);
      this.router.navigateByUrl('lists/my_lists');
    });
  }

  openDropdown(event: any):void {
    this.isViewMoreModalOpen = !this.isViewMoreModalOpen;
  }

  takeAction(): void {
    this.isCopyListModalOpen ? this.copyList() : this.changeVisibility();
  }

  changeVisibility(): void {
    this.listsHttpService.toggleListVisibilty(this.list.id).subscribe((res: any) => {
      this.toastService.success(res.message);
      this.isChangeVisibilityModalOpen = false;
      this.getSingleList();
    })
  }

  openVisibilityModal(){
    this.isChangeVisibilityModalOpen = true;
    const listVisibility = this.list.visibility ? 'private' : 'public';
    const modalDescription = this.list.visibility ? `Are you sure you want to make your list ${listVisibility}? No one will be able to view this list.` : `Are you sure you want to make your list ${listVisibility}? Anyone will be able to view this list.`;
    this.modalText.title = `Make list ${listVisibility}`;
    this.modalText.description = modalDescription;
    this.modalText.button = `Make ${listVisibility}`;
  }

  print(): void {
    this.listsHttpService.print(this.listId).subscribe((response: Blob) => {
      if (response.type === 'application/pdf') {
        const blobUrl = URL.createObjectURL(response);
        window.open(blobUrl, '_blank');
      } else {
        console.error('The fetched file is not a PDF');
      }
    })
  }

  openCopyModal(): void {
    this.isCopyListModalOpen = true;
    this.modalText.title = `Copy this list?`;
    this.modalText.description = 'Do you wish to copy this list to your library?';
    this.modalText.button = `Yes, copy`;
  }

  copyList(): void {
    this.isCopyListModalOpen = false;
    this.listsHttpService.copyList(this.list.id).subscribe((response: any) => {
      console.log('copyList',response)
      this.toastService.success(response.message);
    })
  }
  
}