import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';

const PAYMENT_ROUTES = {
  SET_SUBSCRIPTION_PLAN: 'payments/web/store',
  CANCEL_SUBSCRIPTION: 'payments/web/resume',
  RESUME_SUBSCRIPTION: 'payments/web/cancel',

};

@Injectable({
  providedIn: 'root'
})
export class PaymentHttpService {
  constructor(private httpClient: HttpClient) {}

  private setSubscriptionPlanReq(bodyParam: any): Observable<any> {
    return this.httpClient.post(PAYMENT_ROUTES.SET_SUBSCRIPTION_PLAN, bodyParam) as any;
  }

  setSubscriptionPlan(bodyParam: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.setSubscriptionPlanReq(bodyParam).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
}
