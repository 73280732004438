import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

interface MessageReactionEvent {
  userId: number;
  reaction: string;
  // Add other relevant fields
}

@Injectable({
  providedIn: 'root'
})
export class WebsocketsHelperService {
  newMessageSubject = new Subject<any>();
  newChatSubject = new Subject<any>();
  refreshListSubject = new Subject<any>();
  newEmojiReaction = new Subject<MessageReactionEvent>();
  deleteEmojiReaction = new Subject<MessageReactionEvent>();
  messageUpdated = new Subject<any>();
  messageDeleted = new Subject<any>();
  userLeft = new Subject<any>();
  hasUserBeenRemoved = new Subject();
  groupDetailsChanges = new Subject();
  private channels: Map<number, any> = new Map();
  private activeListeners: Set<string> = new Set();

  // Method to handle common Echo instance checks
  private getEchoInstance(): any | null {
    const echoInstance = (window as any)?.Echo;
    if (!echoInstance) {
      console.error('Echo is not initialized.');
      return null; // Early exit to prevent runtime errors
    }
    // Consider storing socketId only once if necessary
    if (!localStorage.getItem('socketId')) {
      localStorage.setItem('socketId', echoInstance.socketId());
    }
    return echoInstance;
  }

  // Method to bind common Pusher connection events
  private handleConnectionEvents(echoInstance: any, conversationId?: number) {
    const pusher = echoInstance?.connector?.pusher;
    if (!pusher) {
      console.warn('Pusher is not available for connection event handling.');
      return;
    }

    pusher.connection.bind('connected', () => {
      console.log('Connected to Pusher.');
    });

    pusher.connection.bind('disconnected', () => {
      console.log('Disconnected from Pusher. Attempting to reconnect...');
      if (conversationId !== undefined) {
        this.tryReconnect(conversationId);
      }
    });

    pusher.connection.bind('reconnecting', (attempt: number) => {
      console.log(`Reconnecting to Pusher... (Attempt: ${attempt})`);
    });

    pusher.connection.bind('failed', () => {
      console.error('Failed to reconnect to Pusher.');
    });
  }

  // Unified reconnection logic
  private tryReconnect(conversationId?: number, maxAttempts: number = 5, delay: number = 2000) {
    if (conversationId === undefined) {
      console.error('Cannot attempt to reconnect without a valid conversationId.');
      return;
    }

    let attempts = 0;
    const reconnectInterval = setInterval(() => {
      const echoInstance = this.getEchoInstance();
      if (!echoInstance) {
        clearInterval(reconnectInterval);
        return;
      }

      const pusher = echoInstance.connector.pusher;
      if (pusher && pusher.connection.state === 'connected') {
        console.log('Reconnected successfully to Pusher.');
        this.removeListeners(conversationId); // Ensure all listeners are cleaned up
        this.listenToNewMessage(conversationId); // Re-subscribe
        clearInterval(reconnectInterval);
      } else {
        attempts++;
        console.log(`Reconnection attempt ${attempts}`);
        if (attempts >= maxAttempts) {
          console.log('Max reconnection attempts reached. Stopping further attempts.');
          clearInterval(reconnectInterval);
        }
      }
    }, delay);
  }

  // Extract common channel subscription logic
  private subscribeToChannel(channelName: string, event: string, subject: Subject<any>, conversationId?: number) {
    const echoInstance = this.getEchoInstance();
    if (!echoInstance) return;

    const listenerKey = `${channelName}-${event}`;
    if (this.activeListeners.has(listenerKey)) {
      // console.log(`Already listening to ${event} on ${channelName}`);
      return;
    }

    const channel = echoInstance.private(channelName);
    channel.listen(event, (e: any) => subject.next(e));
    this.activeListeners.add(listenerKey);

    if (conversationId !== undefined) {
      this.handleConnectionEvents(echoInstance, conversationId);
    }
  }

  listenIfUserHasBeenRemovedFromChat(conversationId: number): void {
    const channelName = `conversations.${conversationId}`;
    this.subscribeToChannel(channelName, '.ParticipantRemovedFromConversation', this.hasUserBeenRemoved, conversationId);
  }

  listenToNewMessage(conversationId: number): void {
    const channelName = `conversations.${conversationId}`;
    this.subscribeToChannel(channelName, '.ConversationNewMessage', this.newMessageSubject, conversationId);
  }

  listenToNewConversation(userId: number): void {
    const channelName = `App.Models.User.${userId}`;
    this.subscribeToChannel(channelName, '.NewConversation', this.newChatSubject);
  }

  listenIfSomeoneLeftConversation(conversationId: number): void {
    const channelName = `conversations.${conversationId}`;
    this.subscribeToChannel(channelName, '.ParticipantLeftTheConversation', this.userLeft, conversationId);
  }

  listenForRefreshingList(userId: number): void {
    const channelName = `App.Models.User.${userId}`;
    const echoInstance = this.getEchoInstance();
    if (!echoInstance) return;

    if (!this.channels.has(userId)) {
      console.log('Subscribing to channel:', channelName);
      const channel = echoInstance.private(channelName);
      this.channels.set(userId, channel);

      channel.listen('.RefreshConversations', (e: any) => {
        this.refreshListSubject.next(e);
      });
    } else {
      console.log('Already subscribed to channel:', channelName);
    }
  }

  listenToEmojiReaction(conversationId: number): void {
    const channelName = `conversations.${conversationId}`;
    this.subscribeToChannel(channelName, '.MessageReaction', this.newEmojiReaction, conversationId);
  }

  listenIfEmojiReactionRemoved(conversationId: number): void {
    const channelName = `conversations.${conversationId}`;
    this.subscribeToChannel(channelName, '.MessageReactionDeleted', this.deleteEmojiReaction, conversationId);
  }

  listenMessageEdit(conversationId: number): void {
    const channelName = `conversations.${conversationId}`;
    this.subscribeToChannel(channelName, '.MessageUpdated', this.messageUpdated, conversationId);
  }

  listenMessageDelete(conversationId: number): void {
    const channelName = `conversations.${conversationId}`;
    this.subscribeToChannel(channelName, '.MessageDeleted', this.messageDeleted, conversationId);
  }

  listenForGroupDetailsChanges(conversationId: number): void {
    const channelName = `conversations.${conversationId}`;
    this.subscribeToChannel(channelName, '.ConversationUpdated', this.groupDetailsChanges, conversationId);
  }

  // Cleanup all listeners for a conversation channel
  private removeListeners(conversationId: number): void {
    const echoInstance = this.getEchoInstance();
    if (!echoInstance) return;

    const channelName = `conversations.${conversationId}`;
    const channel = echoInstance.private(channelName);

    if (channel) {
      // Only stop listening to conversation-specific events
      channel.stopListening('.ConversationNewMessage');
      channel.stopListening('.MessageReaction');
      channel.stopListening('.MessageReactionDeleted');
      channel.stopListening('.ParticipantLeftTheConversation');
      channel.stopListening('.MessageDeleted');
      channel.stopListening('.MessageUpdated');
      console.log('All conversation listeners removed for channel:', channelName);
    }

    // Remove from activeListeners
    this.activeListeners.forEach(listenerKey => {
      if (listenerKey.startsWith(channelName)) {
        this.activeListeners.delete(listenerKey);
      }
    });
  }

  cleanupChannel(userId: number) {
    const channel = this.channels.get(userId);
    if (channel) {
      console.log('Cleaning up channel:', `App.Models.User.${userId}`);
      channel.stopListening('.ConversationNewMessage');
      channel.stopListening('.MessageReaction');
      channel.stopListening('.MessageReactionDeleted');
      channel.stopListening('.ParticipantLeftTheConversation');
      channel.stopListening('.MessageDeleted');
      channel.stopListening('.MessageUpdated');
      channel.stopListening('.RefreshConversations');
      channel.stopListening('.NewConversation');
      this.channels.delete(userId);
    }
  }
}
