import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GeneralHttpHelperService } from '../../helpers/general-http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(
    private httpClient: HttpClient,
    private generalHttpHelperService: GeneralHttpHelperService
  ) { }

  private getHomepageData(): Observable<any[]> {
    let url = 'homepage'
    return this.httpClient.get<any[]>(url);
  }
  formattedGetHomepageData(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getHomepageData().subscribe({
        next: (res: any) => {
          resolve(
            res
          );
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private getSingleTheme(id: number, page: number, searchTerm?: string): Observable<any[]> {
    let url = `subcategories/${id}`;
    let params = [];
    if (searchTerm) {
      params.push(`search_keyword=${encodeURIComponent(searchTerm)}`);
    }
    if (page) {
      params.push(`page=${page}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get<any[]>(url);
  }
  formattedGetSingleTheme(id: number, page: number, searchTerm?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getSingleTheme(id, page, searchTerm).subscribe({
        next: (response: any) => {
          resolve({
            themes: response.data.randomSubcategories,
            lysts: response.data.lysts?.data,
            pagination: this.generalHttpHelperService.returnPagination(response.data.lysts),
            theme: response.data
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private getAllThemes(page: number, searchTerm?: string): Observable<any[]> {
    let url = `subcategories`;
    let params = [];
    if (searchTerm) {
      params.push(`search_keyword=${encodeURIComponent(searchTerm)}`);
    }
    if (page) {
      params.push(`page=${page}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get<any[]>(url);
  }
  formattedGetAllThemes(page: number, searchTerm?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getAllThemes(page, searchTerm).subscribe({
        next: (res: any) => {
          resolve(
            {
              data: res.data.data,
              pagination: this.generalHttpHelperService.returnPagination(res.data)
            }
          );
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private getRandomUsers(page: number, searchTerm: string | undefined): Observable<any[]> {
    let url = 'user/random'
    let params = [];
    if (searchTerm) {
      params.push(`search_keyword=${encodeURIComponent(searchTerm)}`);
    }
    if (page) {
      params.push(`page=${page}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get<any[]>(url);
  }
  formattedgetRandomUsers(page: number, searchTerm: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getRandomUsers(page, searchTerm).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.data,
            pagination: this.generalHttpHelperService.returnPagination(res.data),
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }
}
