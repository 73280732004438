import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';
import { RecommendedListersComponent } from './recommended-listers.component';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.recommendedListers.pageInRouting,
    data: { data: PAGES_AND_PATHS.recommendedListers },
    component: RecommendedListersComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./recommended-listers.module').then(m => m.RecommendedListersModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RecommendedListersRoutingModule { }
