import { NgClass, NgForOf, NgIf } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject, from } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { ChatHelperService } from 'src/app/services/helpers/chat-helper.service';
import { NewConversation } from 'src/app/services/http-services/chat/chat-http-interfaces';
import { ChatHttpService } from 'src/app/services/http-services/chat/chat-http.service';

@Component({
  selector: 'app-new-conversation',
  standalone: true,
  imports: [
    NgForOf,
    FormsModule,
    NgIf,
    NgClass
  ],
  templateUrl: './new-conversation.component.html',
})
export class NewConversationComponent implements OnInit {
  users: any[] = [];
  pageNum = 1;
  pagination!: TlPagination;
  selectedUsers = new Set<number>();
  searchTerm = '';
  private searchSubject = new Subject<string>();
  @ViewChild('userListContainer', { static: false }) private userListContainer!: ElementRef;
  private loadingNewUsers = false;
  @Output() openConversation = new EventEmitter<any>();
  @Input() isGroupAccountsOpen: any = false;
  @Input() conversationId: number = 0;

  constructor(
    private chatService: ChatHttpService,
    private chatHelperService: ChatHelperService,
    private toastService: ToastrService,
  ) { }

  ngOnInit() {
    this.getUsers();
    this.searchSubject.pipe(
      debounceTime(300),
      switchMap((searchTerm: string) => from(this.getUsers(searchTerm)))
    ).subscribe(response => {
      this.users = response.data.map(user => ({
        ...user,
        isSelected: this.selectedUsers.has(user.id)
      }));
      this.pagination = response.pagination;
    });
    this.chatHelperService.refreshUsersList.subscribe(res => {
      if (res) {
        this.users = [];
        this.getUsers();
      }
    });
  }

  getUsers(searchTerm?: string) {
    if (!this.isGroupAccountsOpen) {
      this.conversationId = 0;
    }
    return from(
      this.chatService.formattedgetUsersForChat(this.pageNum, searchTerm, this.conversationId).then((response: {
        data: Array<any>,
        pagination: TlPagination
      }) => {
        this.users = [...this.users, ...response.data];
        this.pagination = response.pagination;
        return response;
      }));
  }

  async createChat() {
    const selectedEmails = Array.from(this.selectedUsers);
    const chatData: NewConversation = {
      participants: selectedEmails,
      is_group: selectedEmails.length > 1
    };

    try {
      const response = await this.chatService.createChat(chatData);
      this.openConversation.emit(response.data.id);
    } catch (error) {
      console.error('Error creating chat', error);
    }
  }

  toggleSelection(userId: number) {
    if (this.selectedUsers.has(userId)) {
      this.selectedUsers.delete(userId);
    } else {
      this.selectedUsers.add(userId);
    }
  }

  isSelected(userId: number): boolean {
    return this.selectedUsers.has(userId);
  }

  onSearchChange(event: any): void {
    const keyword = event.target.value;
    this.pageNum = 1;
    this.searchSubject.next(keyword);
  }

  onScroll(): void {
    const container = this.userListContainer.nativeElement;
    const scrollTop = container.scrollTop;
    const containerHeight = container.clientHeight;
    const scrollHeight = container.scrollHeight;
    const offset = 100;
    if (!this.loadingNewUsers && (scrollHeight - scrollTop - containerHeight < offset) && ((this.pagination?.currentPage ?? 0) < (this.pagination?.lastPage ?? 0))) {
      this.pageNum++;
      this.loadingNewUsers = true;
      this.getUsers();
    }
  }

  selectedUsersCount(): number {
    return this.selectedUsers.size;
  }

  addToGroup() {
    const selectedEmails = Array.from(this.selectedUsers);
    const chatData = {
      participants: selectedEmails
    };
    this.chatService.addParticipantsToGroupConversation(this.conversationId, chatData).subscribe((response: any) => {
      this.openConversation.emit(response.data.id);
      this.toastService.success('User successfully added to group!')
    })
  }

  resetSearch() {
    this.searchTerm = '';
    this.searchSubject.next('');
  }

}
