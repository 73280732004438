<div class="wrapper-footer-page">
    <!-- TODO JS - Dodajes klasu "active" -->
    <div [routerLink]="[PAGES_AND_PATHS.home.pagePath]" class="tab-footer"
        [ngClass]="{'active': activePageHelper.activePage?.pagePath === PAGES_AND_PATHS.home.pagePath}">
        <img src="../../../assets/icons/outline/icon-home.svg" alt="">
        <span>Home</span>
    </div>
    <div [routerLink]="[PAGES_AND_PATHS.search.pagePath]" class="tab-footer"
        [ngClass]="{'active': activePageHelper.activePage?.pagePath === PAGES_AND_PATHS.search.pagePath}">
        <img src="../../../assets/icons/outline/icon-search.svg" alt="">
        <span>Search</span>
    </div>
    <div class="tab-footer">
        <button [routerLink]="[PAGES_AND_PATHS.createList.pagePath]" class="custom-btn custom-btn&#45;&#45;circle-primary large">
            <img src="../../../assets/icons/outline/icon-plus.svg" alt="">
        </button>
    </div>
    <div [routerLink]="[PAGES_AND_PATHS.lists.pagePath]" class="tab-footer"
        [ngClass]="{'active': activePageHelper.activePage?.pagePath === PAGES_AND_PATHS.lists.pagePath}">
        <img src="../../../assets/icons/outline/icon-lists.svg" alt="">
        <span>Lists</span>
    </div>
    <div [routerLink]="[PAGES_AND_PATHS.profile.pagePath, authHelperService.getUserData?.id]" class="tab-footer"
        [ngClass]="{'active': activePageHelper.activePage?.pagePath === PAGES_AND_PATHS.profile.pagePath}">
        <div class="wrapper-tab-profile">
            <img *ngIf="!authHelperService.getUserData?.profile_photo_url" [src]="authHelperService.getUserData?.role === UserRoles.expert ? '../../../assets/images/avatar-user-expert.png' : '../../../assets/images/avatar-user.png'" alt="">
            <img *ngIf="authHelperService.getUserData?.profile_photo_url" [src]="authHelperService.getUserData?.profile_photo_url" alt="">
            <span>{{authHelperService.getUserData?.name}}</span>
        </div>
    </div>
</div>
