import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PAGES_AND_PATHS} from '../../../constants/pages-and-paths';
import {OuterPagesGuardService} from '../../../guards/outer-pages-guard.service';
import {CreateAccountComponent} from './create-account.component';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.createAccount.pageInRouting,
    data: {data: PAGES_AND_PATHS.createAccount},
    component: CreateAccountComponent,
    canActivate: [OuterPagesGuardService],
    loadChildren: () => import('./create-account.module').then(m => m.CreateAccountModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CreateAccountRoutingModule { }
