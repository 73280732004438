import { NgForOf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { ListsHttpService } from 'src/app/services/http-services/lists/lists-http.service';

@Component({
  selector: 'app-listers',
  standalone: true,
  imports: [NgForOf, RouterModule],
  templateUrl: './listers.component.html',
})
export class ListersComponent implements OnInit {
  pageNum = 1;
  favoriteListers: any[] = [];
  PAGES_AND_PATHS = PAGES_AND_PATHS;

  constructor(private listsHttp: ListsHttpService) { }

  ngOnInit(): void {
    this.getFavoriteListers()
  }

  getFavoriteListers(searchTerm?: string) {
    this.listsHttp.getFavoriteListers(this.pageNum, searchTerm).then((response: any) => {
      console.log('getFavoriteListers', response);
      this.favoriteListers = response.data;
    })
  }
}
