<div  class="wrapper-page-create-list">
  <div class="header-create-list">
    <button (click)="goBack()" class="custom-btn custom-btn--circle-outline">
      <img src="../../../assets/icons/icon-arrow-left.svg" alt="">
    </button>
  </div>
  <form [formGroup]="createListForm" (ngSubmit)="onSubmit()" class="wrapper-content-create-list">
    <div class="content-create-list">
      <h3>{{this.list === null ? 'Create new list' : 'Update list'}}</h3>
      <div class="wrapper-input">
        <span class="label-input">List title</span>
        <div class="custom-input">
          <input formControlName="title" type="text" placeholder="Enter list title">
        </div>
        <div *ngIf="(createListForm.controls['title'] | formControlErrorMessages: null: formTriedToSubmit)" class="wrapper-error-message">
          <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
          <span>{{createListForm.controls['title'] | formControlErrorMessages: null: formTriedToSubmit}}</span>
        </div>
        <div *ngIf="!(createListForm.controls['title'] | formControlErrorMessages: null: formTriedToSubmit)" class="wrapper-label-gray">
          <span>{{createListForm.controls['title'].value ? (50 - createListForm.controls['title'].value.length) + ' characters left.' : 'The maximum length of a lysts title is 50 characters.'}}</span>
        </div>
      </div>
      <div class="wrapper-upload-image">
        <span class="title-upload-image">Cover image</span>
        <div *ngIf="!coverImage" class="upload-image" [ngClass]="{'error': isMaxSizePhotoError}">
          <div class="custom-file-upload">
            <img src="../../../../assets/icons/Icon-upload-image-web.svg" alt="">
            <button type="button" (click)="triggerFileInput($event)" class="custom-btn custom-btn--white">
              <span>Add cover image</span>
            </button>
          </div>
          <input #fileInput type="file" accept=".jpg, .jpeg, .png, .svg" (change)="onFileChange($event)" />
        </div>
        <div *ngIf="isMaxSizePhotoError" class="wrapper-error-message">
          <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
          <span>Your image is too big. The maximum image size is 10mb.</span>
        </div>
        <div *ngIf="coverImage" class="upload-image">
          <div class="wrapper-preview-image-list">
            <img [src]="coverImage" alt="">
            <div class="btns-preview-image-list">
              <div>
                <label for="change-image" class="custom-btn custom-btn--white">
                    <span>Change image</span>
                </label>
                <input id="change-image" type="file" accept=".jpg, .jpeg, .png, .svg" (change)="onFileChange($event)"/>
              </div>
              <button type="button" (click)="isRemoveImageModalOpen = true" class="custom-btn custom-btn--outline">
                <span>Remove</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="(createListForm.controls['cover_image'] | formControlErrorMessages: null: formTriedToSubmit)" class="wrapper-error-message">
        <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
        <span>{{createListForm.controls['cover_image'] | formControlErrorMessages: null: formTriedToSubmit}}</span>
      </div>
      <div class="wrapper-visibility">
        <span>Visibility</span>
        <div class="wrapper-toggle-content">
          <span>Keep this list private</span>
          <div class="custom-toggle-button">
            <input formControlName="visibility" type="checkbox" name="switch" id="switch">
            <label for="switch"></label>
          </div>
        </div>
      </div>
      <button type="submit" class="custom-btn custom-btn--primary">
        <span>{{list === null ? 'Create' : 'Update'}} list</span>
      </button>
    </div>
  </form>
</div>
<tl-confirm-modal
  *ngIf="isRemoveImageModalOpen"
  [modalIcon]="'../../../../../assets/icons/outline/icon-delete.svg'"
  [modalTitle]="'Remove image?'"
  [confirmBtnText]="'Remove'"
  [modalDescription]="'Are you sure you want to remove this image for the list cover?'"
  (cancelModal)="isRemoveImageModalOpen = false"
  (confirmModal)="onConfirmRemoveFile()">
</tl-confirm-modal>