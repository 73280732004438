<div class="wrapper-single-list-page">
  <tl-header></tl-header>
  <div class="wrapper-content-single-page">
    <div class="wrapper-header-single-list">
      <div class="header-single-list">
        <div class="wrapper-info-single-list">
          <div class="wrapper-img-single-list">
            <img [src]="list?.lyst_image_url" alt="">
          </div>
          <div class="tl-d-flex tl-flex-column">
            <div class="info-single-list">
              <div class="wrapper-badges">
                <div class="badge yellow">
                  <span>{{list?.lyst_items_count}} items</span>
                </div>
                <div *ngIf="list?.visibility === 0" class="badge gray">
                  <img *ngIf="list?.visibility === 0" src="../../../../assets/icons/fill/icon-lock.svg" alt="">
                  <span>Private list</span>
                </div>
              </div>
              <app-item-rating [averageRating]="list?.average_rating"
                [reviewsCount]="list?.reviews_count"></app-item-rating>
            </div>
            <span class="title-single-list">{{list?.title}}</span>
            <div class="wrapper-info-author-list">
              <div class="wrapper-image-author-list">
                <img src="../../../../assets/images/avatar-user.png" alt="">
              </div>
              <div class="info-author-list">
                <div class="wrapper-username">
                  <span>{{list?.user.name}}</span>
                  <img src="../../../../assets/icons/fill/icon-badge.svg" alt="">
                </div>
                <div class="tl-d-flex tl-align-center">
                  <span class="date-list">Created at:{{list?.created_at | date:'d MMMM y'}},</span>
                  <span class="date-list">Updated at:{{list?.updated_at | date:'d MMMM y'}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="wrapper-likes-and-more-btn">
          <div class="wrapper-likes">
            <button (click)="addListerToFavorites()" class="custom-btn custom-btn--circle-outline">
              <img *ngIf="!list?.am_i_favorite_this_lyst" class="no-like"
                src="../../../../assets/icons/outline/icon-heart.svg" alt="">
              <img *ngIf="list?.am_i_favorite_this_lyst" class="like" src="../../../../assets/icons/fill/icon-heart.svg"
                alt="">
            </button>
            <span [routerLink]="[PAGES_AND_PATHS.listLikes.pagePath, list?.id, 'list']">{{list?.favorite_users_count}}
              {{list?.favorite_users_count === 1 ? 'Like' : 'Likes'}}</span>
            <button *ngIf="list?.user_id !== loggedUser.id" (click)="openCopyModal()" class="custom-btn custom-btn--outline-white">
              <!--      TODO JS - Dole menjas tekst da li je "Copy to My Lists" ili "Copied", takodje i ikonicu menjas       -->
              <img src="./assets/icons/outline/icon-plus.svg" alt="">
              <img *ngIf="false" src="./assets/icons/outline/icon-check.svg" alt="">
              <span>Copy to My Lists</span>
              <span *ngIf="false">Copied</span>
            </button>
          </div>
          <div class="wrapper-dropdown-btn" appClickedOutside (clickedOutside)="isViewMoreModalOpen = false">
            <button (click)="openDropdown($event)" class="custom-btn custom-btn--circle-outline">
              <img src="../../../../assets/icons/outline/icon-view-more.svg" alt="">
            </button>
            <div *ngIf="isViewMoreModalOpen" class="content-dropdown-btn">
              <div *ngIf="list?.user_id === loggedUser.id" [routerLink]="[PAGES_AND_PATHS.updateList.pagePath, listId]" class="item-dropdown">
                <img src="../../../../assets/icons/outline/icon-edit.svg" alt="">
                <span>Edit list</span>
              </div>
              <div *ngIf="list?.user_id === loggedUser.id" class="item-dropdown">
                <img
                  [src]="list?.visibility
                === 0 ? '../../../../assets/icons/outline/icon-global.svg' : '../../../../assets/icons/outline/icon-lock.svg'"
                  alt="">
                <span *ngIf="list?.user_id === loggedUser.id" (click)="openVisibilityModal()">Make {{list?.visibility
                  === 0 ? 'public' : 'private'}} </span>
              </div>
              <div *ngIf="list?.user_id === loggedUser.id" (click)="print()" class="item-dropdown">
                <img src="../../../../assets/icons/outline/icon-printer.svg" alt="">
                <span>Print list</span>
              </div>
              <div (click)="isShareProfileModalOpen = true" class="item-dropdown">
                <img src="../../../../assets/icons/outline/icon-share.svg" alt="">
                <span>Share</span>
              </div>
              <div *ngIf="list?.user_id === loggedUser.id" (click)="openDeleteModal()" class="item-dropdown delete">
                <img src="../../../../assets/icons/outline/icon-delete.svg" alt="">
                <span>Delete list</span>
              </div>
            </div>
          </div>
        </div>
        <div class="wrapper-custom-tabs">
          <div class="custom-tab" [ngClass]="{'active': tabSelected === 'cards' || tabSelected === 'table'}"
            [routerLink]="[PAGES_AND_PATHS.singleList.pagePath, listId, 0, 'cards']">
            <img src="../../../../../assets/icons/outline/icon-my-lists.svg" alt="">
            <span>Items</span>
          </div>
          <div class="custom-tab" [ngClass]="{'active': tabSelected === 'posts'}"
            [routerLink]="[PAGES_AND_PATHS.singleList.pagePath, listId, 0, 'posts']">
            <img src="../../../../../assets/icons/outline/icon-posts.svg" alt="">
            <span>Posts</span>
          </div>
          <div class="custom-tab" [ngClass]="{'active': tabSelected === 'reviews'}"
            [routerLink]="[PAGES_AND_PATHS.singleList.pagePath, listId, 0, 'reviews']">
            <img src="../../../../assets/icons/outline/icon-review.svg" alt="">
            <span>Reviews</span>
          </div>
        </div>
      </div>
    </div>
    <div class="wrapper-content-single-list">
      <div class="content-single-list">
        <app-items *ngIf="tabSelected === 'cards' || tabSelected === 'table'" [items]="items" [columns]="columns" [filters]="filters" [user_id]="list?.user_id"
          (searchTermChanged)="onSearchTermChanged($event)"></app-items>
        <app-posts *ngIf="tabSelected === 'posts'" [id]="listId" [isList]="true" [list]="list"></app-posts>
        <app-reviews *ngIf="tabSelected === 'reviews'" [reviewObj]="list" [isList]="true" (refreshSinglePage)="getSingleList()"></app-reviews>
      </div>
    </div>
  </div>
</div>
<app-change-visibility-modal *ngIf="isChangeVisibilityModalOpen || isCopyListModalOpen" (changeVisibility)="takeAction()"
  (closeModal)="isChangeVisibilityModalOpen = false; isCopyListModalOpen = false" [modalTitle]="modalText.title" [confirmBtnText]="modalText.button"
  [modalDescription]="modalText.description"></app-change-visibility-modal>
<tl-confirm-modal *ngIf="isDeleteModalOpen" [modalIcon]="'../../../../../assets/icons/outline/icon-delete.svg'"
  [modalTitle]="modalText.title" [confirmBtnText]="modalText.button" [modalDescription]="modalText.description"
  (cancelModal)="isDeleteModalOpen = false" (confirmModal)="deleteList()">
</tl-confirm-modal>
<tl-sharing-options-modal
  *ngIf="isShareProfileModalOpen"
  [entityType]="ShareEntitiesEnums.lysts"
  [entityId]="listId"
  [urlForShare]="listUrl"
  (closeModal)="isShareProfileModalOpen = false; isViewMoreModalOpen = false;">
</tl-sharing-options-modal>
