import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NewItemComponent } from './new-item.component';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.newItem.pageInRouting,
    data: { data: PAGES_AND_PATHS.newItem },
    component: NewItemComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./new-item.module').then(m => m.NewItemModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NewItemRoutingModule { }
