import { NgForOf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { ChatHttpService } from 'src/app/services/http-services/chat/chat-http.service';

@Component({
  selector: 'app-chat-info-pdfs',
  standalone: true,
  imports: [
    NgForOf
  ],
  templateUrl: './chat-info-pdfs.component.html',
})
export class ChatInfoPdfsComponent {
  @Input() conversationId: number = 0;
  @Input() chatType: string = 'single';
  pageNum = 1;
  pdfFiles: any[] = [];
  pagination: Partial<TlPagination> = {};

  constructor(
    private chatsHttpService: ChatHttpService
  ) { }

  ngOnInit() {
    this.chatType === 'single' ? this.getSinglePDFs() : this.getGroupPDFs();
  }

  getSinglePDFs() {
    this.chatsHttpService.formattedGetSingleConversationPDFs(this.conversationId, this.pageNum).then((response: any) => {
      console.log('single conversation pdfs', response);
      this.pdfFiles = [...this.pdfFiles, ...response.data];
      this.pagination = response.pagination;
    });
  }

  getGroupPDFs() {
   
  }

}
