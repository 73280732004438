import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UpdateReviewComponent } from './update-review.component';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.updateReview.pageInRouting,
    data: { data: PAGES_AND_PATHS.updateReview },
    component: UpdateReviewComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./update-review.module').then(m => m.UpdateReviewModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UpdateReviewRoutingModule { }
