import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgIf} from "@angular/common";

@Component({
  selector: 'tl-confirm-modal',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './confirm-modal.component.html',
})
export class ConfirmModalComponent {
  @Input() modalIcon = '';
  @Input() modalTitle = '';
  @Input() modalDescription = '';
  @Input() confirmBtnText = 'Confirm';
  @Input() cancelBtnText = 'Cancel';
  @Output() cancelModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmModal: EventEmitter<void> = new EventEmitter<void>();

}
