import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GeneralHttpHelperService } from '../../helpers/general-http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class FollowersHttpService {

  constructor(
    private httpClient: HttpClient,
    private generalHttpHelperService: GeneralHttpHelperService
  ) { }

  private getAllFollowers(page: number, searchTerm: string | undefined): Observable<any[]> {
    let url = 'user/followers';
    let params = [];
    if (searchTerm) {
      params.push(`search_keyword=${encodeURIComponent(searchTerm)}`);
    }
    if (page) {
      params.push(`page=${page}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get<any[]>(url);
  }
  formattedGetAllFollowers(page: number, searchTerm: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getAllFollowers(page, searchTerm).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.followers.data,
            pagination: this.generalHttpHelperService.returnPagination(res.data.followers),
            friends: res.data.friends,
            experts: res.data.experts
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private getAllFollowersUser(userId: number, page: number, searchTerm: string | undefined): Observable<any[]> {
    let url = `user/followers/${userId}`;
    let params = [];
    if (searchTerm) {
      params.push(`search_keyword=${encodeURIComponent(searchTerm)}`);
    }
    if (page) {
      params.push(`page=${page}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get<any[]>(url);
  }
  formattedGetAllFollowersUser(userId: number, page: number, searchTerm: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getAllFollowersUser(userId, page, searchTerm).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.followers.data,
            pagination: this.generalHttpHelperService.returnPagination(res.data.followers),
            friends: res.data.friends,
            experts: res.data.experts
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private getAllFollowing(page: number, searchTerm: string | undefined): Observable<any[]> {
    let url = 'user/followings'
    let params = [];
    if (searchTerm) {
      params.push(`search_keyword=${encodeURIComponent(searchTerm)}`);
    }
    if (page) {
      params.push(`page=${page}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get<any[]>(url);
  }
  formattedGetAllFollowing(page: number, searchTerm: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getAllFollowing(page, searchTerm).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.followings.data,
            pagination: this.generalHttpHelperService.returnPagination(res.data.followings),
            friends: res.data.friends,
            experts: res.data.experts
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private getAllFollowingUser(userId: number, page: number, searchTerm: string | undefined): Observable<any[]> {
    let url = `user/followings/${userId}`
    let params = [];
    if (searchTerm) {
      params.push(`search_keyword=${encodeURIComponent(searchTerm)}`);
    }
    if (page) {
      params.push(`page=${page}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get<any[]>(url);
  }
  formattedGetAllFollowingUser(userId: number, page: number, searchTerm: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getAllFollowingUser(userId, page, searchTerm).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.followings.data,
            pagination: this.generalHttpHelperService.returnPagination(res.data.followings),
            friends: res.data.friends,
            experts: res.data.experts
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  followUnfollowUser(id: number): Observable<any> { 
    return this.httpClient.post<any>(`user/followers/${id}/toggle`, {});
  }

  removeFollower(id: number): Observable<any> {
    return this.httpClient.delete<any>(`user/followers/${id}/remove_from_my_followers`);
  }

  private getFollowingFriends(page: number, searchTerm: string | undefined): Observable<any[]> {
    let url = 'user/followings/friends'
    let params = [];
    if (searchTerm) {
      params.push(`search_keyword=${encodeURIComponent(searchTerm)}`);
    }
    if (page) {
      params.push(`page=${page}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get<any[]>(url);
  }
  formattedGetFollowingFriends(page: number, searchTerm: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getFollowingFriends(page, searchTerm).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.data,
            pagination: res.data
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private getFollowingFriendsUser(userId: number, page: number, searchTerm: string | undefined): Observable<any[]> {
    let url = `user/followings/${userId}/friends`;
    let params = [];
    if (searchTerm) {
      params.push(`search_keyword=${encodeURIComponent(searchTerm)}`);
    }
    if (page) {
      params.push(`page=${page}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get<any[]>(url);
  }
  formattedGetFollowingFriendsUser(userId: number, page: number, searchTerm: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getFollowingFriendsUser(userId, page, searchTerm).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.data,
            pagination: res.data
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private getFollowersFriends(page: number, searchTerm: string | undefined): Observable<any[]> {
    let url = 'user/followers/friends'
    let params = [];
    if (searchTerm) {
      params.push(`search_keyword=${encodeURIComponent(searchTerm)}`);
    }
    if (page) {
      params.push(`page=${page}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get<any[]>(url);
  }
  formattedGetFollowersFriends(page: number, searchTerm: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getFollowersFriends(page, searchTerm).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.data,
            pagination: res.data
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private getFollowersFriendsUser(userId: number, page: number, searchTerm: string | undefined): Observable<any[]> {
    let url = `user/followers/${userId}/friends`
    let params = [];
    if (searchTerm) {
      params.push(`search_keyword=${encodeURIComponent(searchTerm)}`);
    }
    if (page) {
      params.push(`page=${page}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get<any[]>(url);
  }
  formattedGetFollowersFriendsUser(userId: number, page: number, searchTerm: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getFollowersFriendsUser(userId, page, searchTerm).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.data,
            pagination: res.data
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private getFollowingExperts(page: number, searchTerm: string | undefined): Observable<any[]> {
    let url = 'user/followings/experts'
    let params = [];
    if (searchTerm) {
      params.push(`search_keyword=${encodeURIComponent(searchTerm)}`);
    }
    if (page) {
      params.push(`page=${page}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get<any[]>(url);
  }
  formattedGetFollowingExperts(page: number, searchTerm: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getFollowingExperts(page, searchTerm).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.data,
            pagination: res.data
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private getFollowingExpertsUser(userId: number, page: number, searchTerm: string | undefined): Observable<any[]> {
    let url = `user/followings/${userId}/experts`
    let params = [];
    if (searchTerm) {
      params.push(`search_keyword=${encodeURIComponent(searchTerm)}`);
    }
    if (page) {
      params.push(`page=${page}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get<any[]>(url);
  }
  formattedGetFollowingExpertsUser(userId: number, page: number, searchTerm: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getFollowingExpertsUser(userId, page, searchTerm).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.data,
            pagination: res.data
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private getFollowersExperts(page: number, searchTerm: string | undefined): Observable<any[]> {
    let url = 'user/followers/experts'
    let params = [];
    if (searchTerm) {
      params.push(`search_keyword=${encodeURIComponent(searchTerm)}`);
    }
    if (page) {
      params.push(`page=${page}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get<any[]>(url);
  }
  formattedGetFollowersExperts(page: number, searchTerm: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getFollowersExperts(page, searchTerm).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.data,
            pagination: res.data
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }
 
  private getFollowersExpertsUser(userId: number, page: number, searchTerm: string | undefined): Observable<any[]> {
    let url = `user/followers/${userId}/experts`
    let params = [];
    if (searchTerm) {
      params.push(`search_keyword=${encodeURIComponent(searchTerm)}`);
    }
    if (page) {
      params.push(`page=${page}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get<any[]>(url);
  }
  formattedGetFollowersExpertsUser(userId: number, page: number, searchTerm: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getFollowersExpertsUser(userId, page, searchTerm).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.data,
            pagination: res.data
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  getFollowersCount(): Observable<any> {
    return this.httpClient.get<any>(`user/count_followers`);
  }

  private getRandomUsers(page: number, searchTerm: string | undefined): Observable<any[]> {
    let url = 'user/random'
    let params = [];
    if (searchTerm) {
      params.push(`search_keyword=${encodeURIComponent(searchTerm)}`);
    }
    if (page) {
      params.push(`page=${page}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get<any[]>(url);
  }
  formattedgetRandomUsers(page: number, searchTerm: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getRandomUsers(page, searchTerm).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.data,
            pagination: this.generalHttpHelperService.returnPagination(res.data),
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }
}
