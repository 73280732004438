import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PAGES_AND_PATHS} from '../../../constants/pages-and-paths';
import {OuterPagesGuardService} from '../../../guards/outer-pages-guard.service';
import {CreateCompanyAccountComponent} from './create-company-account.component';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.createCompanyAccount.pageInRouting,
    data: {data: PAGES_AND_PATHS.createCompanyAccount},
    component: CreateCompanyAccountComponent,
    canActivate: [OuterPagesGuardService],
    loadChildren: () => import('./create-company-account.module').then(m => m.CreateCompanyAccountModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CreateCompanyAccountRoutingModule { }
