export const PAGES_AND_PATHS = {
  selectLanguage: {
    pageName: 'SELECT_LANGUAGE',
    pageInRouting: 'select-language',
    pagePath: '/select-language',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: false,
    pagePathWithoutParams: ['/select-language']
  },
  login: {
    pageName: 'LOGIN',
    pageInRouting: 'login',
    pagePath: '/login',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: false,
    pagePathWithoutParams: ['/login']
  },
  forgotPassword: {
    pageName: 'FORGOT_PASSWORD',
    pageInRouting: 'forgot-password',
    pagePath: '/forgot-password',
    isChild: false,
    parent: null,
    pageHaveParams: true,
    needAuthentication: false,
    pagePathWithoutParams: ['/forgot-password']
  },
  checkYourEmail: {
    pageName: 'CHECK_YOUR_EMAIL',
    pageInRouting: 'check-your-email/:email',
    pagePath: '/check-your-email',
    isChild: false,
    parent: null,
    pageHaveParams: true,
    needAuthentication: false,
    pagePathWithoutParams: ['/check-your-email']
  },
  createAccount: {
    pageName: 'CREATE_ACCOUNT',
    pageInRouting: 'create-account',
    pagePath: '/create-account',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: false,
    pagePathWithoutParams: ['/create-account']
  },
  createCompanyAccount: {
    pageName: 'CREATE_COMPANY_ACCOUNT',
    pageInRouting: 'create-company-account',
    pagePath: '/create-company-account',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: false,
    pagePathWithoutParams: ['/create-company-account']
  },
  verifyAccount: {
    pageName: 'VERIFY_ACCOUNT',
    pageInRouting: 'verify-account',
    pagePath: '/verify-account',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/verify-account']
  },
  verifyAccountCompany: {
    pageName: 'VERIFY_ACCOUNT',
    pageInRouting: 'verify-company-account',
    pagePath: '/verify-company-account',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/verify-company-account']
  },
  chat: {
    pageName: 'CHAT',
    pageInRouting: 'chat',
    pagePath: '/chat',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/chat'],
    showFooter: true
  },
  chatSingleConversation: {
    pageName: 'CHAT',
    pageInRouting: 'chat/:id',
    pagePath: '/chat',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/chat'],
    showFooter: true
  },
  groupChatDetails: {
    pageName: 'GROUP_DETAILS',
    pageInRouting: 'group-details/:id',
    pagePath: '/group-details',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/group-details'],
    showFooter: true
  },
  completeRegistration: {
    pageName: 'complete-registration',
    pageInRouting: 'complete-registration',
    pagePath: '/complete-registration',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/complete-registration']
  },
  companySubscription: {
    pageName: 'complete-subscription',
    pageInRouting: 'complete-subscription',
    pagePath: '/complete-subscription',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/complete-subscription']
  },
  verificationSuccessful: {
    pageName: 'VERIFICATION_SUCCESSFUL',
    pageInRouting: 'verification-successful',
    pagePath: '/verification-successful',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/verification-successful']
  },
  selectTopics: {
    pageName: 'SELECT_TOPICS',
    pageInRouting: 'select-topics',
    pagePath: '/select-topics',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/select-topics']
  },
  selectListers: {
    pageName: 'SELECT_LISTERS',
    pageInRouting: 'select-listers',
    pagePath: '/select-listers',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/select-listers']
  },
  accountSuccessfullyCreated: {
    pageName: 'ACCOUNT_SUCCESSFULLY_CREATED',
    pageInRouting: 'account-successfully-created',
    pagePath: '/account-successfully-created',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/account-successfully-created']
  },
  companyAccountSuccessfullyCreated: {
    pageName: 'COMPANY_ACCOUNT_SUCCESSFULLY_CREATED',
    pageInRouting: 'company-account-successfully-created',
    pagePath: '/company-account-successfully-created',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/company-account-successfully-created']
  },
  successAction: {
    pageName: 'success-action',
    pageInRouting: 'success-action',
    pagePath: '/success-action',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/success-action']
  },
  home: {
    pageName: 'HOME',
    pageInRouting: 'home',
    pagePath: '/home',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/home'],
    showFooter: true
  },
  nearYou: {
    pageName: 'NEAR_YOU',
    pageInRouting: 'near-you',
    pagePath: '/near-you',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/near-you'],
    showFooter: true
  },
  singleTheme: {
    pageName: 'SINGLE_THEME',
    pageInRouting: 'single-theme/:id',
    pagePath: '/single-theme',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/single-theme'],
    showFooter: true
  },
  allThemes: {
    pageName: 'ALL_THEMES',
    pageInRouting: 'all-themes',
    pagePath: '/all-themes',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/all-themes'],
    showFooter: true
  },
  search: {
    pageName: 'SEARCH',
    pageInRouting: 'search',
    pagePath: '/search',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/search'],
    showFooter: true
  },
  searchResults: {
    pageName: 'SEARCH_RESULTS',
    pageInRouting: 'search-results',
    pagePath: '/search-results',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/search-results'],
    showFooter: true
  },
  lists: {
    pageName: 'LISTS',
    pageInRouting: 'lists',
    pagePath: '/lists',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/lists'],
    showFooter: true
  },
  createList: {
    pageName: 'CREATE_LIST',
    pageInRouting: 'list/create',
    pagePath: '/list/create',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['list/create'],
    showFooter: true
  },
  myLists: {
    pageName: 'MY_LISTS',
    pageInRouting: 'lists/my_lists',
    pagePath: '/lists/my_lists',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/lists/my_lists'],
    showFooter: true
  },
  singleList: {
    pageName: 'SINGLE_LIST',
    pageInRouting: 'single-list/:id/:isMasterItem/:view',
    pagePath: '/single-list',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/single-list'],
    showFooter: true
  },
  updateList: {
    pageName: 'UPDATE_LIST',
    pageInRouting: 'update-list/:id',
    pagePath: '/update-list',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/update-list'],
    showFooter: true
  },
  newItem: {
    pageName: 'NEW_ITEM',
    pageInRouting: 'new-item/:itemID/:listID',
    pagePath: '/new-item',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/new-item'],
    showFooter: true
  },
  singleReview: {
    pageName: 'SINGLE_REVIEW',
    pageInRouting: 'single-review/:type/:typeId/:id',
    pagePath: '/single-review',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/single-review'],
    showFooter: true
  },
  listLikes: {
    pageName: 'LIST_LIKES',
    pageInRouting: 'list-of-likes/:id/:type',
    pagePath: '/list-of-likes',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/list-of-likes'],
    showFooter: true
  },
  favoriteLists: {
    pageName: 'FAVORITE_LISTS',
    pageInRouting: 'lists/favorite_lists',
    pagePath: '/lists/favorite_lists',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/lists/favorite_lists'],
    showFooter: true
  },
  recommendedLists: {
    pageName: 'RECOMMENDED_LISTS',
    pageInRouting: 'lists/recommended_lists',
    pagePath: '/lists/recommended_lists',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/lists/recommended_lists'],
    showFooter: true
  },
  recommendedListers: {
    pageName: 'RECOMMENDED_LISTERS',
    pageInRouting: 'lists/recommended_listers',
    pagePath: '/lists/recommended_listers',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/lists/recommended_listers'],
    showFooter: true
  },
  followedListers: {
    pageName: 'FOLLOWED_LISTERS',
    pageInRouting: 'lists/followed_listers',
    pagePath: '/lists/followed_listers',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/lists/followed_listers'],
    showFooter: true
  },
  singleItem: {
    pageName: 'SINGLE_ITEM',
    pageInRouting: 'single-item/:id',
    pagePath: '/single-item',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/single-item'],
    showFooter: true
  },
  profile: {
    pageName: 'PROFILE',
    pageInRouting: 'profile/:userId',
    pagePath: '/profile',
    showSideMenu: false,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/profile'],
    showFooter: true
  },
  companyProfile: {
    pageName: 'COMPANY_PROFILE',
    pageInRouting: 'company-profile/:id',
    pagePath: '/company-profile',
    showSideMenu: false,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/company-profile'],
    showFooter: true
  },
  trendingItems: {
    pageName: 'TRENDING_ITEMS',
    pageInRouting: 'trending-items',
    pagePath: '/trending-items',
    showSideMenu: false,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/trending-items'],
    showFooter: true
  },
  editProfile: {
    pageName: 'EDIT_PROFILE',
    pageInRouting: 'profile/edit',
    pagePath: '/profile/edit',
    showSideMenu: false,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/profile/edit'],
    showFooter: true
  },
  createPost: {
    pageName: 'CREATE_POST',
    pageInRouting: 'create-post',
    pagePath: '/create-post',
    showSideMenu: false,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/create-post'],
    showFooter: true
  },
  editPost: {
    pageName: 'EDIT_POST',
    pageInRouting: 'edit-post/:postId',
    pagePath: '/edit-post',
    showSideMenu: false,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/edit-post'],
    showFooter: true
  },
  latestPost: {
    pageName: 'LATEST_POST',
    pageInRouting: 'latest-post',
    pagePath: '/latest-post',
    showSideMenu: false,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/latest-post'],
    showFooter: true
  },
  singlePost: {
    pageName: 'SINGLE_POST',
    pageInRouting: 'single-post/:postId',
    pagePath: '/single-post',
    showSideMenu: false,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/single-post'],
    showFooter: true
  },
  postLikes: {
    pageName: 'POST_LIKES',
    pageInRouting: 'post-likes/:postId',
    pagePath: '/post-likes',
    showSideMenu: false,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/post-likes'],
    showFooter: true
  },
  settings: {
    pageName: 'SETTINGS',
    pageInRouting: 'settings',
    pagePath: '/settings',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/settings'],
    showFooter: true
  },
  privacyPolicy: {
    pageName: 'PRIVACY_POLICY',
    pageInRouting: 'privacy-policy',
    pagePath: '/privacy-policy',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/privacy-policy']
  },
  termsAndConditions: {
    pageName: 'TERMS_AND_CONDITONS',
    pageInRouting: 'terms-and-conditions',
    pagePath: '/terms-and-conditions',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/terms-and-conditions']
  },
  groupAccountsAddUser: {
    pageName: 'GROUP_ACCOUNTS_ADD_USER',
    pageInRouting: 'add-users-to-group/:id',
    pagePath: '/add-users-to-group',
    showSideMenu: false,
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/add-users-to-group'],
    showFooter: true
  },
  followingFollowers: {
    pageName: 'FOLLOWING_FOLLOWERS',
    pageInRouting: 'following-followers/:userId/:type',
    pagePath: '/following-followers',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/following-followers'],
    showFooter: true
  },
  friendsExperts: {
    pageName: 'FRIENDS_EXPERTS',
    pageInRouting: 'friends-experts/:userId/:followingFollowers/:friendsExperts',
    pagePath: '/friends-experts',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/friends-experts'],
    showFooter: true
  },
  favoriteItems: {
    pageName: 'FAVORITE_ITEMS',
    pageInRouting: 'favorite-items',
    pagePath: '/favorite-items',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/favorite-items'],
    showFooter: true
  },
  favoriteListers: {
    pageName: 'FAVORITE_LISTERS',
    pageInRouting: 'favorite-listers',
    pagePath: '/favorite-listers',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/favorite-listers'],
    showFooter: true
  },
  createReview:{
    pageName: 'CRETE_REVIEW',
    pageInRouting: 'create-review/:type',
    pagePath: '/create-review',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/create-review'],
    showFooter: true
  },
  updateReview:{
    pageName: 'UPDATE_REVIEW',
    pageInRouting: 'update-review/:type',
    pagePath: '/update-review',
    isChild: false,
    parent: null,
    pageHaveParams: false,
    needAuthentication: true,
    pagePathWithoutParams: ['/update-review'],
    showFooter: true
  },
};

export const DEFAULT_AUTHENTICATED_PAGE = {
  page: PAGES_AND_PATHS.home
};
