import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Countries, EmailRegex} from "../../../constants/general-variables";
import {AuthenticationHttpService} from "../../../services/http-services/authentication/authentication-http.service";
import {AuthenticationHelperService} from "../../../services/helpers/authentication-helper.service";
import {PAGES_AND_PATHS} from "../../../constants/pages-and-paths";
import {ToastrService} from "ngx-toastr";
import {UiHelperService} from "../../../services/helpers/ui-helper.service";

@Component({
  selector: 'tl-create-company-account',
  templateUrl: './create-company-account.component.html',
})
export class CreateCompanyAccountComponent implements OnInit {
  isPasswordVisible = false;
  formTriedToSubmit = false;
  createCompanyAccountForm!: FormGroup;
  isSpinnerActive = false;
  companyMailSuffix = '@';
  isAreaCodeOpen =  false;
  selectedArea = Countries[0];
  @ViewChild('areaCodeBtn') areaCodeBtn: ElementRef | undefined;
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;
  protected readonly Countries = Countries;
  constructor(private formBuilder: FormBuilder,
              private toastService: ToastrService,
              private renderer: Renderer2,
              private uiHelperService: UiHelperService,
              private authHelperService: AuthenticationHelperService,
              private authHttpService: AuthenticationHttpService) { }

  ngOnInit(): void {
    this.buildForm();
    this.createCompanyAccountForm.controls.company_website.valueChanges.subscribe(val => {
      this.companyMailSuffix = '@' + val.split('.').slice(-2).join('.');
      this.createCompanyAccountForm.controls.email.patchValue(val + this.companyMailSuffix);
    });
    this.createCompanyAccountForm.controls.emailForView.valueChanges.subscribe(val => {
      this.createCompanyAccountForm.controls.email.markAsTouched();
      this.createCompanyAccountForm.controls.email.patchValue(val + this.companyMailSuffix);
    });
    this.listenToClickAreaCode();
  }

  listenToClickAreaCode(): void {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.uiHelperService.checkIfClickedOutsideOfTheElement(this.areaCodeBtn, this.areaCodeBtn, e)) {
        this.isAreaCodeOpen = false;
      }
    });
  }

  buildForm(): void {
    this.createCompanyAccountForm = this.formBuilder.group({
      full_name: ['', [Validators.required]],
      company_name: ['', [Validators.required]],
      company_website: ['', [Validators.required]],
      emailForView: [''],
      email: ['', [Validators.required, Validators.pattern(EmailRegex)]],
      phone_number: ['', [Validators.required]],
      username: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      tos: [false, Validators.requiredTrue]
    });
  }

  onSubmitCreateCompanyAccountForm(): void {
    this.formTriedToSubmit = true;
    if (this.createCompanyAccountForm.valid) {
      this.isSpinnerActive = true;
      this.authHttpService.postCompanyRegister({...this.createCompanyAccountForm.value, phone_number: this.selectedArea.phone_code + ' ' + this.createCompanyAccountForm.controls['phone_number'].value}).then(res => {
        this.isSpinnerActive = false;
        this.authHelperService.clientLogin(res.user, res.token, PAGES_AND_PATHS.verifyAccountCompany.pagePath);
      }).catch(({error}: {error: { errors: { [key: string]: string }}}) => {
        this.isSpinnerActive = false;
        Object.values(error.errors).forEach(error => this.toastService.error(error));
      });
    }
  }

  onSelectCodeArea(country: any): void {
    this.selectedArea = country;
  }
}
