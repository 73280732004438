<form [formGroup]="editProfileGenderForm" (ngSubmit)="onSubmitEditProfileGenderForm()" class="wrapper-edit-info-page">
  <span class="title-edit-page">Gender</span>
  <span class="subtitle-edit-page">Change your account gender.</span>
  <div class="wrapper-input">
    <span class="label-input">Gender</span>
    <div class="wrapper-custom-radiobutton-gender">
      <label>
        <input type="radio" formControlName="gender" [value]="'male'">
        <div class="custom-radiobutton">
          <div class="wrapper-language-info">
            <span>Male</span>
          </div>
          <div class="circle-radio">
            <div class="circle-center"></div>
          </div>
        </div>
      </label>
      <label>
        <input type="radio" formControlName="gender" [value]="'female'">
        <div class="custom-radiobutton">
          <div class="wrapper-language-info">
            <span>Female</span>
          </div>
          <div class="circle-radio">
            <div class="circle-center"></div>
          </div>
        </div>
      </label>
      <label>
        <input type="radio" formControlName="gender" [value]="'other'">
        <div class="custom-radiobutton">
          <div class="wrapper-language-info">
            <span>Other</span>
          </div>
          <div class="circle-radio">
            <div class="circle-center"></div>
          </div>
        </div>
      </label>
    </div>
    <div *ngIf="editProfileGenderForm.controls['gender'].value === 'other'" class="wrapper-input" [ngClass]="{'error': editProfileGenderForm.controls['genderOther'] | formControlErrorMessages: null : formTriedToSubmit}">
      <div class="custom-input">
        <input type="text" formControlName="genderOther">
      </div>
      <div *ngIf="editProfileGenderForm.controls['genderOther'] | formControlErrorMessages: null: formTriedToSubmit" class="wrapper-error-message">
        <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
        <span>{{editProfileGenderForm.controls['genderOther'] | formControlErrorMessages: null: formTriedToSubmit}}</span>
      </div>
    </div>
  </div>
  <div class="buttons-edit-page">
    <button type="button" (click)="onCancelChanges()" class="custom-btn custom-btn--outline">
      <span>Cancel</span>
    </button>
    <button type="submit" class="custom-btn custom-btn--primary">
      <span *ngIf="!isSpinnerActive">Save changes</span>
      <tl-spinner *ngIf="isSpinnerActive"></tl-spinner>
    </button>
  </div>
</form>
