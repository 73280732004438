import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReturnMessageEmojiPipe } from './return-message-emoji.pipe';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ReturnMessageEmojiPipe
  ],
  exports: [
    ReturnMessageEmojiPipe
  ]
})
export class ReturnMessageEmojiModule { }
