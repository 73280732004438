<tl-subscription-plans
  *ngIf="activeTab === SubscriptionPlanTabsEnums.plans"
  [planId]="selectedPlanId"
  (clickOnPlan)="selectedPlanId = $event; activeTab = SubscriptionPlanTabsEnums.singlePlan"
  (clickToSubscribeOnPlan)="selectedPlanId = $event; activeTab = SubscriptionPlanTabsEnums.payment"
  (clickToCancelSubscription)="isCancelSubscriptionModalOpen = true">
</tl-subscription-plans>
<tl-single-plan
  *ngIf="activeTab === SubscriptionPlanTabsEnums.singlePlan"
  [planId]="selectedPlanId"
  (clickToSubscribeOnPlan)="activeTab = SubscriptionPlanTabsEnums.payment">
</tl-single-plan>
<tl-payment
  *ngIf="activeTab === SubscriptionPlanTabsEnums.payment"
  [planId]="selectedPlanId"
></tl-payment>
<tl-cancel-subscription-modal
  *ngIf="isCancelSubscriptionModalOpen"

></tl-cancel-subscription-modal>

