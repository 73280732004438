import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { RecommendedList } from 'src/app/services/http-services/home/home-interfaces';

@Component({
  selector: 'app-list',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './list.component.html',
})
export class ListComponent {
  @Input() list!: RecommendedList;
  PAGES_AND_PATHS = PAGES_AND_PATHS;
}
