export enum ListType {
    all = 'all',
    offline = 'offline',
    private = 'private',
    public = 'public'
  }
  export enum PageListType {
    mine = 'my_lists',
    offline = 'offline_lists',
    favorite = 'favorite_lists'
  }
  