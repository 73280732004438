import { Component } from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {AuthenticationHelperService} from "../../../../services/helpers/authentication-helper.service";
import {AuthenticationHttpService} from "../../../../services/http-services/authentication/authentication-http.service";
import {
  FormControlErrorMessagesModule
} from "../../../../pipes/form-control-error-messages/form-control-error-messages.module";
import {NgClass, NgIf} from "@angular/common";
import {SpinnerComponent} from "../../../../shared-components/spinner/spinner.component";
import {ProfileHttpService} from "../../../../services/http-services/profile/profile-http.service";

@Component({
  selector: 'tl-change-username',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormControlErrorMessagesModule,
    NgIf,
    NgClass,
    SpinnerComponent
  ],
  templateUrl: './change-username.component.html',
})
export class ChangeUsernameComponent {
  changeUsernameForm!: FormGroup;
  isSpinnerActive = false;
  formTriedToSubmit = false;
  constructor(private formBuilder: FormBuilder,
              private toastService: ToastrService,
              private authHelperService: AuthenticationHelperService,
              private profileHttpService: ProfileHttpService) { }

  ngOnInit(): void {
    this.buildForm();
  }
  buildForm(): void {
    this.changeUsernameForm = this.formBuilder.group({
      username: ['', [Validators.required]],
    });
    this.changeUsernameForm.patchValue(this.authHelperService.getUserData);
  }

  onSubmitChangeUsernameForm(): void {
    this.formTriedToSubmit = true;
    if (this.changeUsernameForm.valid) {
      this.isSpinnerActive = true;
      this.profileHttpService.editProfileUsername(this.changeUsernameForm.value).then(res => {
        this.authHelperService.setUserData({...this.authHelperService.getUserData, username: res.username})
        this.isSpinnerActive = false;
        this.toastService.success(res.message);
      }).catch(error => {
        this.isSpinnerActive = false;
        if (error.error.code === 403) {
          this.toastService.error(error.error.message);
        } else {
          Object.values(error.error.data).forEach((value: any) => this.toastService.error(value[0]));
        }
      });
    }
  }
}
