import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HandleShowMorePipe } from './handle-show-more.pipe';



@NgModule({
  declarations: [

  ],
  imports: [
    CommonModule,
    HandleShowMorePipe
  ],
  exports: [
    HandleShowMorePipe
  ]
})
export class HandleShowMoreModule { }
