import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PAGES_AND_PATHS} from "../../../constants/pages-and-paths";
import {AuthGuardService} from "../../../guards/auth-guard.service";
import {CreateEditPostComponent} from "./create-edit-post.component";

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.createPost.pageInRouting,
    data: { data: PAGES_AND_PATHS.createPost },
    component: CreateEditPostComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./create-edit-post.module').then(m => m.CreateEditPostModule)
  },
  {
    path: PAGES_AND_PATHS.editPost.pageInRouting,
    data: { data: PAGES_AND_PATHS.editPost },
    component: CreateEditPostComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./create-edit-post.module').then(m => m.CreateEditPostModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CreateEditPostRoutingModule { }
