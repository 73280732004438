import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {  ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { AuthenticationHelperService } from 'src/app/services/helpers/authentication-helper.service';
import { ItemsHttpService } from 'src/app/services/http-services/items/items-http.service';

@Component({
  selector: 'app-update-review',
  templateUrl: './update-review.component.html',
})
export class UpdateReviewComponent {
  reviewType = '';
  review: any;
  reviewForm!: FormGroup;
  filledStar: string = "../../../../assets/icons/rating/icon-star-fill.svg";
  outlinedStar: string = "../../../../assets/icons/outline/icon-star-outline1.svg";
  stars: number[] = [1, 2, 3, 4, 5];
  loggedUser: any;
  PAGES_AND_PATHS = PAGES_AND_PATHS;

  constructor(
    private location: Location,
    private itemsHttpService: ItemsHttpService,
    private formBuilder: FormBuilder,
    private authService: AuthenticationHelperService,
    private toastService: ToastrService,
    private router: Router,
    private actRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.review = history.state.obj;
    this.getReviewType();
    console.log(this.review)
    this.loggedUser = this.authService.getUserData;
    this.buildForm();
  }

  getReviewType(): void {
    this.actRoute.paramMap.subscribe((params: any) => {
      this.reviewType = params.get('type') || '';
      console.log(this.reviewType)
    })
  }

  buildForm(): void {
    this.reviewForm = this.formBuilder.group({
      rating: [this.review ? this.review.rating : '', [Validators.required]],
      review: [this.review ? this.review.review : '', [Validators.required, Validators.maxLength(200)]]
    });
  }

  rate(star: number) {
    this.reviewForm.controls['rating'].setValue(star);
  }

  goBack(): void {
    this.location.back();
  }
  
  submitReview(): void {
    console.log(this.reviewForm.value)
    this.reviewType === 'list' ? this.updateListReview() : this.updateItemReview();
  }

  updateListReview(): void {
    this.itemsHttpService.updateListReview(this.review.id, this.reviewForm.value).then((response: any) => {
      this.toastService.success(response.message);
      this.router.navigate([PAGES_AND_PATHS.singleList.pagePath, this.review.id, 0, 'cards']);
    })
  }

  updateItemReview(): void {
    this.itemsHttpService.updateItemReview(this.review.id, this.reviewForm.value).then((response: any) => {
      this.toastService.success(response.message);
      this.router.navigate([PAGES_AND_PATHS.singleItem.pagePath, this.review.id]);
    })
  }
}
