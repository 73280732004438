<div class="wrapper-auth-pages">
  <div class="wrapper-auth-left add-details">
    <div class="wrapper-login-page">
      <div class="login-page">
        <div class="wrapper-logo-auth">
          <img src="../assets/icons/logo-horizontal-yellow.svg" alt="">
        </div>
        <div class="info-auth-login">
          <h1>Add details</h1>
          <h6>Signup for a new account by adding your information below.</h6>
        </div>
        <form [formGroup]="completeRegistrationForm" (ngSubmit)="onSubmitCompleteRegistrationForm()" class="wrapper-content-auth">
          <!--     Name     -->
          <div class="wrapper-input" [ngClass]="{'error': completeRegistrationForm.controls['name'] | formControlErrorMessages: null : formTriedToSubmit}">
            <span class="label-input">Name</span>
            <div class="custom-input">
              <input type="text" formControlName="name" placeholder="Enter your name">
            </div>
            <div *ngIf="completeRegistrationForm.controls['name'] | formControlErrorMessages: null: formTriedToSubmit" class="wrapper-error-message">
              <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
              <span>{{completeRegistrationForm.controls['name'] | formControlErrorMessages: null: formTriedToSubmit}}</span>
            </div>
          </div>
          <!--     Gender     -->
          <div class="wrapper-input">
            <span class="label-input">Gender</span>
            <div class="wrapper-custom-radiobutton-gender">
              <label>
                <input type="radio" formControlName="gender" [value]="'male'">
                <div class="custom-radiobutton">
                  <div class="wrapper-language-info">
                    <span>Male</span>
                  </div>
                  <div class="circle-radio">
                    <div class="circle-center"></div>
                  </div>
                </div>
              </label>
              <label>
                <input type="radio" formControlName="gender" [value]="'female'">
                <div class="custom-radiobutton">
                  <div class="wrapper-language-info">
                    <span>Female</span>
                  </div>
                  <div class="circle-radio">
                    <div class="circle-center"></div>
                  </div>
                </div>
              </label>
              <label>
                <input type="radio" formControlName="gender" [value]="'other'">
                <div class="custom-radiobutton">
                  <div class="wrapper-language-info">
                    <span>Other</span>
                  </div>
                  <div class="circle-radio">
                    <div class="circle-center"></div>
                  </div>
                </div>
              </label>
            </div>
          </div>
          <div *ngIf="completeRegistrationForm.controls['gender'].value === 'other'" class="wrapper-input" [ngClass]="{'error': completeRegistrationForm.controls['genderOther'] | formControlErrorMessages: null : formTriedToSubmit}">
            <span class="label-input">Other</span>
            <div class="custom-input">
              <input type="text" formControlName="genderOther" placeholder="Enter your ethnicity">
            </div>
            <div *ngIf="completeRegistrationForm.controls['genderOther'] | formControlErrorMessages: null: formTriedToSubmit" class="wrapper-error-message">
              <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
              <span>{{completeRegistrationForm.controls['genderOther'] | formControlErrorMessages: null: formTriedToSubmit}}</span>
            </div>
          </div>
          <div class="wrapper-input" [ngClass]="{'error': completeRegistrationForm.controls['ethnicity'] | formControlErrorMessages: null : formTriedToSubmit}">
            <span class="label-input">Ethnicity</span>
            <div class="custom-input">
              <input type="text" formControlName="ethnicity" placeholder="Enter your ethnicity">
            </div>
            <div *ngIf="completeRegistrationForm.controls['ethnicity'] | formControlErrorMessages: null: formTriedToSubmit" class="wrapper-error-message">
              <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
              <span>{{completeRegistrationForm.controls['ethnicity'] | formControlErrorMessages: null: formTriedToSubmit}}</span>
            </div>
          </div>
          <div class="wrapper-input" [ngClass]="{'error': completeRegistrationForm.controls['date_of_birth'] | formControlErrorMessages: null : formTriedToSubmit}">
            <span class="label-input">Date of birth</span>
            <tl-date-picker
              [datepickerLabel]="'From'"
              (dateSelected)="onSelectDateOfBirth($event)"
            ></tl-date-picker>
            <div *ngIf="completeRegistrationForm.controls['date_of_birth'] | formControlErrorMessages: null: formTriedToSubmit" class="wrapper-error-message">
              <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
              <span>{{completeRegistrationForm.controls['date_of_birth'] | formControlErrorMessages: null: formTriedToSubmit}}</span>
            </div>
          </div>
          <!--     Country     -->
          <div class="wrapper-input" [ngClass]="{'error': completeRegistrationForm.controls['country'] | formControlErrorMessages: null: formTriedToSubmit}">
            <span class="label-input">Country</span>
            <div *ngIf="!selectedCountry" class="custom-input">
              <input #countrySearch  type="text" (focus)="showCountryDropdown = true" (blur)="onBlurSearchbar()" [(ngModel)]="searchQuery" (ngModelChange)="onSearchCountries()" [ngModelOptions]="{standalone: true}" placeholder="Enter country">
            </div>
            <div class="wrapper-selected-country" *ngIf="selectedCountry" (click)="removeSelectedCountry()">
              <img [src]="'../../../assets/icons/flags/' + selectedCountry.code.toLowerCase() + '.png'" alt="">
              <span>{{selectedCountry.name}}</span>
            </div>
            <div
              *ngIf="completeRegistrationForm.controls['country'] | formControlErrorMessages: null: formTriedToSubmit"
              class="wrapper-error-message">
              <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
              <span>{{completeRegistrationForm.controls['country'] | formControlErrorMessages: null: formTriedToSubmit}}</span>
            </div>
            <div  *ngIf="showCountryDropdown" class="wrapper-list-country">
              <div class="item-country" *ngFor="let country of filteredCountries" (click)="onSelectCountry(country)">
                <img [src]="'../../../assets/icons/flags/' + country.code.toLowerCase() + '.png'" alt="">
                <span>{{country.name}}</span>
              </div>
            </div>
          </div>
          <!--     City     -->
          <div class="wrapper-input" [ngClass]="{'error': completeRegistrationForm.controls['city'] | formControlErrorMessages: null : formTriedToSubmit}">
            <span class="label-input">City</span>
            <div class="custom-input">
              <input type="text" formControlName="city" placeholder="Enter city">
            </div>
            <div *ngIf="completeRegistrationForm.controls['city'] | formControlErrorMessages: null: formTriedToSubmit" class="wrapper-error-message">
              <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
              <span>{{completeRegistrationForm.controls['city'] | formControlErrorMessages: null: formTriedToSubmit}}</span>
            </div>
          </div>
          <!--     Phone number     -->
          <div class="wrapper-input" [ngClass]="{'error': completeRegistrationForm.controls['phone_number'] | formControlErrorMessages: null: formTriedToSubmit}">
            <span class="label-input">Phone number</span>
            <div class="wrapper-phone-number open" [ngClass]="{'open': isAreaCodeOpen}">
              <div #areaCodeBtn class="wrapper-flags" (click)="isAreaCodeOpen = !isAreaCodeOpen">
                <img class="icon-flag" [src]="'../../../../assets/icons/flags/' + selectedArea.code.toLowerCase() + '.png'" alt="">
                <img class="icon-arrow-flags" src="../../../assets/icons/icon-arrow-left.svg" alt="">
              </div>
              <div class="wrapper-number-country">
                <span>{{selectedArea.phone_code}}</span>
              </div>
              <input type="text" formControlName="phone_number">
              <div class="content-dropdown-phone-number" *ngIf="isAreaCodeOpen">
                <div class="item-dropdown-phone-number" *ngFor="let country of Countries" (click)="onSelectCodeArea(country)">
                  <img [src]="'../../../../assets/icons/flags/' + country.code.toLowerCase() + '.png'" alt="">
                  <span>{{country.name}} ({{country.phone_code}})</span>
                </div>
              </div>
            </div>
            <div *ngIf="completeRegistrationForm.controls['phone_number'] | formControlErrorMessages: null : formTriedToSubmit" class="wrapper-error-message">
              <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
              <span>{{completeRegistrationForm.controls['phone_number'] | formControlErrorMessages: null: formTriedToSubmit}}</span>
            </div>
          </div>

          <button type="submit" [disabled]="completeRegistrationForm.invalid" class="custom-btn custom-btn--primary">
            <span>Create account</span>
            <tl-spinner *ngIf="isSpinnerActive"></tl-spinner>
          </button>
        </form>
      </div>
    </div>
  </div>
  <tl-onboarding-swipe class="wrapper-auth-right"></tl-onboarding-swipe>
</div>
