<div class="wrapper-auth-pages">
  <div class="wrapper-auth-left">
    <div class="wrapper-login-page">
      <div class="login-page">
        <div class="wrapper-logo-auth">
          <img src="../assets/icons/logo-horizontal-yellow.svg" alt="">
        </div>
        <div class="info-auth-login">
          <h1>Create your account</h1>
          <h6>Signup for a new account by adding your information below.</h6>
        </div>
        <form [formGroup]="createAccountForm" (ngSubmit)="onSubmitCreateAccountForm()" class="wrapper-content-auth">
          <div class="wrapper-input" [ngClass]="{'error': createAccountForm.controls['username'] | formControlErrorMessages: null : formTriedToSubmit}">
            <span class="label-input">Username</span>
            <div class="custom-input">
              <input type="text" formControlName="username" placeholder="Enter your username">
            </div>
            <div *ngIf="createAccountForm.controls['username'] | formControlErrorMessages: null: formTriedToSubmit" class="wrapper-error-message">
              <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
              <span>{{createAccountForm.controls['username'] | formControlErrorMessages: null: formTriedToSubmit}}</span>
            </div>
          </div>
          <div class="wrapper-input" [ngClass]="{'error': createAccountForm.controls['email'] | formControlErrorMessages: 'Please enter a valid email address.' : formTriedToSubmit}">
            <span class="label-input">Email address</span>
            <div class="custom-input">
              <input type="text" formControlName="email" placeholder="Enter you email address">
            </div>
            <div *ngIf="createAccountForm.controls['email'] | formControlErrorMessages: 'Please enter a valid email address.': formTriedToSubmit" class="wrapper-error-message">
              <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
              <span>{{createAccountForm.controls['email'] | formControlErrorMessages: 'Please enter a valid email address.': formTriedToSubmit}}</span>
            </div>
          </div>
          <div class="wrapper-input" [ngClass]="{'error': createAccountForm.controls['password'] | formControlErrorMessages: null : formTriedToSubmit}">
            <span class="label-input">Password</span>
            <div class="custom-input">
              <input
                [type]="isPasswordVisible ? 'text' : 'password'"
                placeholder="Enter password"
                formControlName="password">
              <img *ngIf="!isPasswordVisible" (click)="isPasswordVisible = true" class="icon-input" src="../assets/icons/outline/icon-outline-eye.svg" alt="">
              <img *ngIf="isPasswordVisible" (click)="isPasswordVisible = false" class="icon-input" src="../assets/icons/outline/icon-outline-eye-with-slash.svg" alt="">
            </div>
            <div *ngIf="createAccountForm.controls['password'] | formControlErrorMessages: null: formTriedToSubmit" class="wrapper-error-message">
              <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
              <span>{{createAccountForm.controls['password'] | formControlErrorMessages: null: formTriedToSubmit}}</span>
            </div>
          </div>
          <div class="wrapper-custom-checkbox">
            <input formControlName="tos" type="checkbox" id="custom-checkbox">
            <label for="custom-checkbox">
              I agree to the <span>Terms of Service </span>and <span>Privacy Policy.</span>
            </label>
          </div>
          <button type="submit" [disabled]="createAccountForm.invalid" class="custom-btn custom-btn--primary">
            <span>Create account</span>
            <tl-spinner *ngIf="isSpinnerActive"></tl-spinner>
          </button>
          <span class="line-text">Or signup with</span>
          <div class="wrapper-social-login">
            <div class="item-social-login">
              <img src="../assets/icons/icon-google.svg" alt="">
              <span>Google</span>
            </div>
            <div class="item-social-login">
              <img src="../assets/icons/icon-facebook.svg" alt="">
              <span>Facebook</span>
            </div>
            <div class="item-social-login">
              <img src="../assets/icons/icon-apple.svg" alt="">
              <span>Apple</span>
            </div>
          </div>
          <div class="wrapper-label-link">
            <span>Already have an account?</span>
            <a href="javascript:void(0)" [routerLink]="[PAGES_AND_PATHS.login.pagePath]">Login</a>
          </div>
        </form>
      </div>
      <div class="wrapper-sign-up-company-fixed">
        <div class="wrapper-btn-sign-up-company">
          <img src="../assets/icons/fill/icon-fill-company.svg" alt="">
          <span> For companies? </span>
          <a href="javascript:void(0)" [routerLink]="[PAGES_AND_PATHS.createCompanyAccount.pagePath]">Sign up</a>
        </div>
      </div>
    </div>
  </div>
  <tl-onboarding-swipe class="wrapper-auth-right"></tl-onboarding-swipe>
</div>
