import { Component } from '@angular/core';

@Component({
  selector: 'app-no-chats',
  standalone: true,
  imports: [],
  templateUrl: './no-chats.component.html',
})
export class NoChatsComponent {

}
