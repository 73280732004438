import { Pipe, PipeTransform } from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Pipe({
  name: 'formControlErrorMessages',
  pure: false
})
export class FormControlErrorMessagesPipe implements PipeTransform {

  transform(formControl: AbstractControl, customPatternMsg: string | null, submittedForm: boolean | undefined, ...args: unknown[]): string {
    let isRequired: any;
    if (formControl.validator) {
      isRequired = formControl.validator({} as AbstractControl);
    }
    if ((formControl.dirty || formControl.touched || submittedForm) && formControl.invalid && !formControl.value && isRequired) {
      return 'This field is required';
    } else if ((formControl.dirty || formControl.touched || submittedForm) && formControl.invalid && formControl.errors?.minlength) {
      return 'Minimum of ' + formControl.errors?.minlength.requiredLength + ' characters';
    } else if ((formControl.dirty || formControl.touched || submittedForm) && formControl.invalid && formControl.errors?.maxlength) {
      return 'Maximum of ' + formControl.errors?.maxlength.requiredLength + ' characters';
    } else if ((formControl.dirty || formControl.touched || submittedForm) && formControl.invalid && formControl.errors?.confirmedValidator) {
      return 'Passwords do not match';
    } else if ((formControl.dirty || formControl.touched || submittedForm) && formControl.invalid && customPatternMsg && formControl.errors?.pattern) {
      return customPatternMsg;
    } else {
      return '';
    }
  }
}
