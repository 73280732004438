import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {TlPagination} from "../../../../interfaces/general-interfaces";
import {AuthenticationHelperService} from "../../../../services/helpers/authentication-helper.service";
import {ProfileHttpService} from "../../../../services/http-services/profile/profile-http.service";
import {PostPreviewComponent} from "../../../posts/components/post-preview/post-preview.component";
import {PAGES_AND_PATHS} from "../../../../constants/pages-and-paths";
import {RouterLink} from "@angular/router";
import {NgForOf, NgIf} from "@angular/common";
import {UserRoles} from "../../../../enums/general-enums";

@Component({
  selector: 'tl-posts-tab',
  standalone: true,
  imports: [
    PostPreviewComponent,
    RouterLink,
    NgForOf,
    NgIf
  ],
  templateUrl: './posts-tab.component.html',
})
export class PostsTabComponent implements OnInit, OnChanges {
  @Input() userId!: number;
  @Input() profileData: any;
  @Input() isAuthUser = false;
  @Input() baseUrl = '';
  authUserData: any;
  posts: any[] = [];
  postsPagination: TlPagination | undefined;
  urlParams = {page: 1};
  showImageInFullScreen: any;
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;
  protected readonly UserRoles = UserRoles;

  constructor(private authHelperService: AuthenticationHelperService,
              private profileHttpService: ProfileHttpService) {
  }
  ngOnInit() {
    this.authUserData = this.authHelperService.getUserData;
    this.isAuthUser = this.userId == this.authUserData.id;
    this.postsPagination = undefined;
    this.posts = [];
    this.isAuthUser ? this.getAuthUserPosts() : this.getUserPosts();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userId && changes.userId.currentValue) {
      this.isAuthUser = changes.userId.currentValue == this.authUserData?.id;
      this.postsPagination = undefined;
      this.posts = [];
      this.isAuthUser ? this.getAuthUserPosts() : this.getUserPosts();
    }
  }

  getAuthUserPosts(): void {
    this.urlParams.page = this.postsPagination?.currentPage ? this.postsPagination.currentPage + 1 : 1;
    this.profileHttpService.getAuthUserPosts(this.urlParams).then(res => {
      this.posts = [...this.posts, ...res.data];
      this.postsPagination = res.pagination;
    })
  }

  getUserPosts(): void {
    this.urlParams.page = this.postsPagination?.currentPage ? this.postsPagination.currentPage + 1 : 1;
    this.profileHttpService.getUserPosts({...this.urlParams, userId: this.userId}).then(res => {
      this.posts = [...this.posts, ...res.data];
      this.postsPagination = res.pagination;
    })
  }

  onTogglePostLike(post: any): void {
    const postIndex = this.posts.findIndex(postElement => postElement.id === post.id);
    if (postIndex !== -1) {
      this.posts[postIndex].am_i_liked_this = post.am_i_liked_this;
      this.posts[postIndex].likes_count = post.likes_count;
    }
  }

  onConfirmPostDeleted(postId: string): void {
    this.posts = this.posts.filter(post => post.id !== postId);
  }
}
