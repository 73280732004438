<div class="wrapper-page wrapper-my-list">
  <tl-header></tl-header>
  <div class="wrapper-header-my-lists">
    <div class="header-my-lists">
      <span>Recommended listers</span>
    </div>
  </div>
  <div class="wrapper-content-my-lists favorite-lists">
    <div class="content-my-lists">
      <div class="filter-my-lists">
        <div class="wrapper-custom-search">
          <img class="icon-search" src="../../../assets/icons/outline/icon-search.svg" alt="">
          <input type="text" placeholder="Search..."  [(ngModel)]="searchTerm" (input)="onSearchChange($event)">
          <div (click)="resetSearch()" class="wrapper-delete-search">
            <img src="../../../assets/icons/outline/icon-close.svg" alt="">
          </div>
        </div>
      </div>
      <div *ngFor="let lister of recommendedListers" [routerLink]="[PAGES_AND_PATHS.profile.pagePath, lister.id]" class="wrapper-card-lister">
        <div class="wrapper-favorite-lister">
          <div class="wrapper-avatar">
            <img src="../../../../assets/images/avatar-user.png" alt="">
          </div>
          <div class="wrapper-user-info">
            <div class="wrapper-username">
              <span>{{lister.name}}</span>
              <img src="../../../assets/icons/fill/icon-badge.svg" alt="">
            </div>
            <span>{{lister.followers_count}} {{lister.followers_count === 1 ? 'follower' : 'followers'}}</span>
          </div>
        </div>
        <div class="wrapper-send-btn">
          <button class="custom-btn custom-btn--outline">
            <span>193 lists</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>