import { DatePipe, NgClass, NgFor, NgIf, SlicePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NewConversationComponent } from '../new-conversation/new-conversation.component';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { ChatHttpService } from 'src/app/services/http-services/chat/chat-http.service';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { from, Subject, Subscription } from 'rxjs';
import { SetChatUsernamesModule } from 'src/app/pipes/set-chat-usernames/set-chat-usernames.module';
import { ChatHelperService } from 'src/app/services/helpers/chat-helper.service';
import { Location } from '@angular/common';
import { AuthenticationHelperService } from 'src/app/services/helpers/authentication-helper.service';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { FormsModule } from '@angular/forms';
import { WebsocketsHelperService } from 'src/app/services/helpers/websockets-helper.service';
import { CheckChatGroupLengthModule } from 'src/app/pipes/check-chat-group-length/check-chat-group-length.module';

@Component({
  selector: 'app-chat-list',
  standalone: true,
  imports: [
    NgIf,
    NewConversationComponent,
    NgFor,
    NgClass,
    DatePipe,
    SetChatUsernamesModule,
    RouterModule,
    FormsModule,
    CheckChatGroupLengthModule,
    SlicePipe
  ],
  templateUrl: './chat-list.component.html',
})
export class ChatListComponent implements OnInit, OnChanges, OnDestroy {
  @Output() isNewChatActive = new EventEmitter<boolean>();
  @Output() loadedConversations = new EventEmitter<number>();
  @Input() isGroupAccountsOpen: any = false;
  @Output() closeGroupAccounts = new EventEmitter<boolean>();
  conversations: Array<any> = [];
  isUserListOpen = false;
  title = 'Chat';
  searchTerm: string = '';
  isLoading: boolean = false;
  isModalOpen: boolean = false;
  pagination!: TlPagination;
  private searchSubject = new Subject<string>();
  pageNum = 1;
  isEventLoaded = false;
  PAGES_AND_PATHS = PAGES_AND_PATHS;
  selectedConversationId: number = 0;
  loggedUser: any;
  newChatSubscription: Subscription | undefined;
  refreshSubscription: Subscription | undefined;
  destroy$ = new Subject<void>();
  @ViewChild('conversationsContainer', { static: false }) private conversationsContainer!: ElementRef;
  private loadingOlderConversations = false;
  isNewMessageSent = false;
  hasUserBeenRemovedSubscription: Subscription | undefined;
  refreshListSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private chatHttpService: ChatHttpService,
    private actRoute: ActivatedRoute,
    private websocketsHelperService: WebsocketsHelperService,
    private location: Location,
    private authenticationHelperService: AuthenticationHelperService,
    private chatHelperService: ChatHelperService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isGroupAccountsOpen.currentValue) {
      this.isUserListOpen = true;
    }
  }

  ngOnInit(): void {
    this.clearSubscription();
    this.loggedUser = this.authenticationHelperService.getUserData;
    this.conversations = [];
    this.getConversations();
    this.listenForRefresh();
    this.listenForNewConversation();
    this.checkForIdChanges();
    this.listenHasUserBeenRemoved();
    this.searchSubject.pipe(
      debounceTime(300),
      switchMap((searchTerm: string) => from(this.getConversations(searchTerm)))
    ).subscribe(response => {
      this.conversations = response.data;
      this.pagination = response.pagination;
    });
    this.refreshListSubscription = this.chatHelperService.refreshConversationsList.subscribe(res => {
      if (res) {
        console.log('booooooooooooooooo')
        this.isNewMessageSent = true;
        this.getConversations();
      }
    });
    this.actRoute.paramMap.subscribe(params => {
      const conversationId = Number(params.get('id') || '');
      if (conversationId !== 0 && conversationId !== null) {
        this.chatHelperService.selectConversation(conversationId);
      }
    });
  }

  listenHasUserBeenRemoved() {
    const previousScrollHeight = this.conversationsContainer?.nativeElement.scrollHeight || 0;
    this.websocketsHelperService.listenIfUserHasBeenRemovedFromChat(this.selectedConversationId);
    this.hasUserBeenRemovedSubscription = this.websocketsHelperService.hasUserBeenRemoved.subscribe((res: any) => {
      console.log('USER REMOVED', res)
      this.getConversations();
      if (res.user.id === this.loggedUser.id) {
        this.chatHelperService.selectConversation(0);
        this.location.replaceState(`/chat`);
      } else {
        setTimeout(() => {
          const newScrollHeight = this.conversationsContainer.nativeElement.scrollHeight;
          this.conversationsContainer.nativeElement.scrollTop = newScrollHeight - previousScrollHeight;
        }, 100);
      }
    });
  }

  checkForIdChanges() {
    this.chatHelperService.selectedConversationId$.subscribe(conversationId => {
      this.selectedConversationId = conversationId;
    });
  }

  listenForNewConversation() {
    this.websocketsHelperService.listenToNewConversation(this.loggedUser.id);
    this.newChatSubscription = this.websocketsHelperService.newChatSubject.subscribe((response: any) => {
      console.log('newChatSubject', response);
      // this.chats.unshift(response.conversation);
      // this.isEventLoaded = true;
      // this.getConversations();
    });
  }

  listenForRefresh() {
    this.websocketsHelperService.listenForRefreshingList(this.loggedUser.id);
    this.refreshSubscription = this.websocketsHelperService.refreshListSubject.pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      console.log('refresh', response);
      this.isEventLoaded = true;
      this.getConversations();
    });
  }

  resetSearch() {
    this.searchTerm = '';
    this.searchSubject.next('');
  }

  openConversation(conversationId: number): void {
    this.resetSearch();
    this.location.replaceState(`/chat/${conversationId}`);
    this.chatHelperService.selectConversation(conversationId);
  }

  openUserList() {
    this.isNewChatActive.emit(true);
    this.isUserListOpen = true;
    this.title = 'New Chat';
  }

  back() {
    if (this.isUserListOpen) {
      this.isNewChatActive.emit(false);
      this.isUserListOpen = false;
      this.title = 'Chat';
      this.isGroupAccountsOpen = false;
      this.chatHelperService.closeGroupAccounts(true);
    } else {
      this.router.navigate([PAGES_AND_PATHS.home.pagePath]);
    }
  }

  getConversations(searchTerm?: string) {
    return from(
      this.chatHttpService.formattedGetConversations(this.pageNum, searchTerm).then((response: {
        data: Array<any>,
        pagination: TlPagination
      }) => {
        if (this.isEventLoaded || this.isNewMessageSent) {
          this.conversations = [];
        }
        this.conversations = [...this.conversations, ...response.data];
        console.log(this.conversations)
        this.pagination = response.pagination;
        this.isEventLoaded = false;
        this.loadedConversations.emit(this.pagination.totalItems);
        return response;
      })
    );
  }

  clearSubscription() {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
      this.refreshSubscription = undefined; // Clear the subscription reference
    }
  }

  onSearchChange(event: any): void {
    this.searchTerm = event.target.value;
    this.pageNum = 1;
    this.searchSubject.next(this.searchTerm);
  }


  onScroll(): void {
    const container = this.conversationsContainer.nativeElement;
    const scrollTop = container.scrollTop;
    const containerHeight = container.clientHeight;
    const scrollHeight = container.scrollHeight;
    const offset = 100; // Trigger fetching new users when near the bottom

    // Check if we are near the bottom
    if (!this.loadingOlderConversations && (scrollHeight - scrollTop - containerHeight < offset) && ((this.pagination?.currentPage ?? 0) < (this.pagination?.lastPage ?? 0))) {
      this.pageNum++;
      this.loadingOlderConversations = true;
      console.log('boooo')
      this.getConversations();
    }
  }

  ngOnDestroy(): void {
    this.refreshListSubscription?.unsubscribe();
    this.refreshSubscription?.unsubscribe();
    this.newChatSubscription?.unsubscribe()
  }

}
