import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DeleteChatModalComponent } from '../delete-chat-modal/delete-chat-modal.component';
import { DatePipe, Location, NgClass, NgForOf, NgIf, SlicePipe } from '@angular/common';
import { ChatInfoComponent } from '../chat-info/chat-info.component';
import { ChatHelperService } from 'src/app/services/helpers/chat-helper.service';
import { ChatHttpService } from 'src/app/services/http-services/chat/chat-http.service';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { Conversation, SingleConversationMessage } from 'src/app/services/http-services/chat/chat-http-interfaces';
import { FormatMessageTimestampModule } from 'src/app/pipes/format-message-timestamp/format-message-timestamp.module';
import { ShouldDisplayDateModule } from 'src/app/pipes/should-display-date/should-display-date.module';
import { FormsModule } from '@angular/forms';
import { VideoBoxComponent } from '../video-box/video-box.component';
import { from, Subject, Subscription } from 'rxjs';
import { SetChatUsernamesModule } from 'src/app/pipes/set-chat-usernames/set-chat-usernames.module';
import { NoChatsComponent } from '../no-chats/no-chats.component';
import { WebsocketsHelperService } from 'src/app/services/helpers/websockets-helper.service';
import { debounceTime, switchMap } from 'rxjs/operators';
import { HighlightSearchTermModule } from 'src/app/pipes/highlight-search-term/highlight-search-term.module';
import { IsSameMinutePipe } from 'src/app/pipes/is-same-minute/is-same-minute.pipe';
import { AuthenticationHelperService } from 'src/app/services/helpers/authentication-helper.service';
import { ClickedOutsideModule } from 'src/app/directives/clicked-outside/clicked-outside.module';
import { PreviewMediaComponent } from '../preview-media/preview-media.component';
import { DisplayEditAndDeleteMessageModule } from 'src/app/pipes/display-edit-and-delete-message/display-edit-and-delete-message.module';
import { CheckChatGroupLengthModule } from 'src/app/pipes/check-chat-group-length/check-chat-group-length.module';
import { ReturnMessageEmojiModule } from 'src/app/pipes/return-message-emoji/return-message-emoji.module';
import { GetUserDataModule } from 'src/app/pipes/get-user-data/get-user-data.module';
import { GroupAccountsComponent } from '../group-accounts/group-accounts.component';
import { ActionDeleteSingleChat, ActionTextLeaveGrup } from 'src/app/enums/chat-enums';
import { TimeAgoModule } from 'src/app/pipes/time-ago/time-ago.module';
import { RouterModule } from '@angular/router';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { StarsComponent } from 'src/app/shared-components/stars/stars.component';

@Component({
  selector: 'app-chat-content',
  standalone: true,
  imports: [
    NgIf,
    DeleteChatModalComponent,
    ChatInfoComponent,
    NgForOf,
    FormatMessageTimestampModule,
    NgClass,
    ShouldDisplayDateModule,
    FormsModule,
    VideoBoxComponent,
    SetChatUsernamesModule,
    NoChatsComponent,
    HighlightSearchTermModule,
    DatePipe,
    IsSameMinutePipe,
    ClickedOutsideModule,
    PreviewMediaComponent,
    DisplayEditAndDeleteMessageModule,
    CheckChatGroupLengthModule,
    SlicePipe,
    ReturnMessageEmojiModule,
    GetUserDataModule,
    GroupAccountsComponent,
    TimeAgoModule,
    RouterModule,
    StarsComponent
  ],
  templateUrl: './chat-content.component.html',
})
export class ChatContentComponent implements OnInit, OnDestroy {
  @Input() isNewChatActive = false;
  @Input() loadedConversations: number = 0;
  isDeleteModalOpen = false;
  isChatInfoOpen = false;
  chatInfoButtonText = 'Chat info';
  conversationId!: number;
  loadingOlderMessages = false;
  messages: Array<SingleConversationMessage> = [];
  conversation!: Conversation;
  pagination: Partial<TlPagination> = {};
  pageNum = 1;
  message: string = '';
  isInputFocused = false;
  isMediaUploading = false;
  participantsLeftChatSubscription: Subscription | undefined;
  newMessageSubscription: Subscription | undefined;
  @ViewChild('chatContainer', { static: false }) private chatContainer!: ElementRef;
  selectedMessage: SingleConversationMessage | null = null;
  isEmojiClicked = false;
  emojiReactionSubscription: Subscription | undefined;
  isSearchOpen = false;
  searchTerm: string = '';
  private searchSubject = new Subject<string>();
  editingMessage: any = null;
  loggedUser: any;
  selectedMedia: any | null = null;
  messageUpdateSubscription: Subscription | undefined;
  messageDeleteSubscription: Subscription | undefined;
  @Output() isGroupAccountsOpen = new EventEmitter<boolean>();
  deleteChatText: any;
  closeGroupSubscription: Subscription | undefined;
  emojiReactionDeletedSubscription: Subscription | undefined;
  groupAccountDetailsChanged: Subscription | undefined;
  PAGES_AND_PATHS = PAGES_AND_PATHS;

  constructor(
    private chatHelperService: ChatHelperService,
    private chatHttpService: ChatHttpService,
    private websocketsHelperService: WebsocketsHelperService,
    private authenticationHelperService: AuthenticationHelperService,
    private location: Location
  ) {
  }

  ngOnInit(): void {
    this.loggedUser = this.authenticationHelperService.getUserData;
    this.chatHelperService.selectedConversationId$.subscribe(conversationId => {
      this.conversationId = conversationId;
      if (this.conversationId !== null && this.conversationId !== 0) {
        this.getSingleConversation();
        this.listenToNewMessage();
        this.listenToNewEmojiReaction();
        this.listenIfEmojiReactionDeleted();
        this.listenToMessageUpdate();
        this.listenToMessageDelete();
        this.listenIfParticipantLeftChat();
        this.listenForGroupDetailsChanges();
      }
    });
    this.searchSubject.pipe(
      debounceTime(300),
      switchMap((searchTerm: string) => from(this.getSingleConversation(searchTerm)))
    ).subscribe(response => {
      this.messages = response.data;
      this.pagination = response.pagination;
    });
    this.closeGroupSubscription = this.chatHelperService.closeGroupInfo.subscribe((res) => {
      this.isChatInfoOpen = false;
      this.chatInfoButtonText = 'Chat info';
      this.isGroupAccountsOpen.emit(false);
    })
  }

  listenForGroupDetailsChanges() {
    this.websocketsHelperService.listenForGroupDetailsChanges(this.conversationId);
    this.groupAccountDetailsChanged = this.websocketsHelperService.groupDetailsChanges.subscribe((res: any) => {
      console.log('details changed!', res)
      this.conversation.name = res.conversation.name;
      this.conversation.profile_photo = res.conversation.profile_photo;
    });
  }

  listenToNewMessage() {
    if (this.newMessageSubscription) {
      this.newMessageSubscription.unsubscribe();
    }
    this.websocketsHelperService.listenToNewMessage(this.conversationId);
    this.newMessageSubscription = this.websocketsHelperService.newMessageSubject.subscribe((res: any) => {
      // this.messages.push(res.message);
      console.log('listenToNewMessage', res)
      
      this.getSingleConversation();
      setTimeout(() => {
        this.scrollToBottom();
      }, 100);
    });
  }

  listenToNewEmojiReaction() {
    if (this.emojiReactionSubscription) {
      this.emojiReactionSubscription.unsubscribe();
    }
    
    this.websocketsHelperService.listenToEmojiReaction(this.conversationId);
    this.emojiReactionSubscription = this.websocketsHelperService.newEmojiReaction.subscribe((res: any) => {
      console.log('new moji reaction', res)
      if (!this.conversation.is_group) {
        console.log('listenToNewEmojiReaction', res)
        const message = this.messages.find((msg) => msg.id === res.messageReaction.message_id);
        console.log('message', message)
        if (message) {
          if (message.message_reactions.length) {
            message.message_reactions[0] = res.messageReaction;
          } else {
            message.message_reactions.push(res.messageReaction);
            message.message_reactions_count++;
          }
        }
      } else {
        const message = this.messages.find((msg) => msg.id === res.messageReaction.message_id);
        if (message) {
          const existingReactionIndex = message.message_reactions.findIndex(
            (reaction) => reaction.user_id === res.messageReaction.user_id
          );
          if (existingReactionIndex === -1) {
            message.message_reactions.push(res.messageReaction);
            message.message_reactions_count++;
          } else {
            message.message_reactions[existingReactionIndex] = res.messageReaction;
          }
        }
      }
    });
  }

  listenIfEmojiReactionDeleted() {
    if (this.emojiReactionDeletedSubscription) {
      this.emojiReactionDeletedSubscription.unsubscribe();
    }
    this.websocketsHelperService.listenIfEmojiReactionRemoved(this.conversationId);
    this.emojiReactionDeletedSubscription = this.websocketsHelperService.deleteEmojiReaction.subscribe((res: any) => {
      console.log(' moji reaction deleted', res)
      if (!this.conversation.is_group) {
        const message = this.messages.find((msg) => msg.id === res.message.id);
        if (message) {
          message.message_reactions = [];
          message.message_reactions_count = 0;
        } else {
          console.warn(`Message with ID not found.`);
        }
      } else {
        console.log('reaction removed', res);
        const messageId = res.message.id;
        const userId = res.messageReactionId;
        const message = this.messages.find((msg) => msg.id === messageId);
        if (message) {
          const existingReactionIndex = message.message_reactions.findIndex(
            (reaction) => reaction.id === userId
          );
          if (existingReactionIndex !== -1) {
            message.message_reactions.splice(existingReactionIndex, 1);
            message.message_reactions_count = message.message_reactions.length;
          }
        } else {
          console.warn(`Message with ID ${messageId} not found.`);
        }
      }
    });
  }

  listenToMessageUpdate() {
    if (this.messageUpdateSubscription) {
      this.messageUpdateSubscription.unsubscribe();
    }
    this.websocketsHelperService.listenMessageEdit(this.conversationId);
    this.messageUpdateSubscription = this.websocketsHelperService.messageUpdated.subscribe((res: any) => {
       if (this.loggedUser.id !== res.message.user_id) {
        let messageIndex = this.messages.findIndex((msg) => msg.id === res.message.id);
        this.messages = this.chatHelperService.handleMessageUpdate(messageIndex, this.messages, res.message);
        }
    });
  }

  listenToMessageDelete() {
    if (this.messageDeleteSubscription) {
      this.messageDeleteSubscription.unsubscribe();
    }
    this.websocketsHelperService.listenMessageDelete(this.conversationId);
    this.messageDeleteSubscription = this.websocketsHelperService.messageDeleted.subscribe((res: any) => {
      console.log(res)
      const index = this.messages.findIndex(m => m.id === res.message.id);
      if (index > -1 && index < this.messages.length) {
        this.messages.splice(index, 1);
      }
      if (res.conversation.latest_message === null) {
        this.location.replaceState(`/chat`);
        this.conversationId = 0;
        this.chatHelperService.refreshListOfConversations(true);
      }
    });
  }

  listenIfParticipantLeftChat() {
    this.websocketsHelperService.listenIfSomeoneLeftConversation(this.conversationId);
    this.participantsLeftChatSubscription = this.websocketsHelperService.userLeft.subscribe((res: any) => {
      const userId = res.user.id;
      if (this.conversation.other_participants) {
        this.chatHelperService.removeParticipant(this.conversation.other_participants, userId);
      }
      if (this.conversation.non_admin_participants) {
        this.chatHelperService.removeParticipant(this.conversation.non_admin_participants, userId);
      }
    });
  }

  openCloseChatInfo() {
    this.isChatInfoOpen = !this.isChatInfoOpen;
    this.chatInfoButtonText = this.isChatInfoOpen ? 'Exit chat info' : 'Chat info';
  }

  openDeleteModal() {
    this.isDeleteModalOpen = true;
    this.deleteChatText = ActionDeleteSingleChat;
  }

  getSingleConversation(searchTerm?: string) {
    this.isChatInfoOpen = false;
    this.chatInfoButtonText = 'Chat info';
    if (searchTerm === undefined) {
      this.isSearchOpen = false;
      this.searchTerm = '';
    }
    this.loadingOlderMessages = true;
    const previousScrollHeight = this.chatContainer?.nativeElement.scrollHeight || 0;

    return from(
      this.chatHttpService.formattedGetSingleConversation(this.conversationId, this.pageNum, searchTerm).then((response: {
        conversation: any,
        data: Array<SingleConversationMessage>,
        pagination: TlPagination
      }) => {
        console.log('single conversation res', response)
        this.pagination = response.pagination;
        this.conversation = response.conversation;
        if (this.pageNum === 1) {
          this.messages = response.data.reverse();
          setTimeout(() => {
            this.scrollToBottom();
          }, 600);
        } else {
          this.messages = [...response.data.reverse(), ...this.messages];

          setTimeout(() => {
            const newScrollHeight = this.chatContainer.nativeElement.scrollHeight;
            this.chatContainer.nativeElement.scrollTop = newScrollHeight - previousScrollHeight;
          }, 500);
        }
        this.loadingOlderMessages = false;
        return response;
      }));
  }

  onInputFocus(isFocused: boolean) {
    this.isInputFocused = isFocused;
  }

  sendMessage() {
    const updatedChat = {
      type: 'text',
      body: this.message
    };
    if (this.editingMessage) {
      this.editMessage(updatedChat);
    } else {
      this.updateChat(updatedChat);
    }
  }

  sendFile(event: any) {
    this.isMediaUploading = true;
    const file: File = event.target.files[0];
    const fileType = file.type;
    let formData = new FormData();
    formData = this.chatHelperService.handleMediaFormData(formData, fileType, file);
    if (file) {
      this.updateChat(formData);
    }
  }

  async updateChat(formData: any) {
    this.chatHelperService.refreshListOfConversations(false);
    const socketId = localStorage.getItem('socketId');
    try {
      this.chatHttpService.updateConversation(this.conversationId, formData, socketId).subscribe(updatedChat => {
        this.messages.push(updatedChat.data);
        this.chatHelperService.refreshListOfConversations(true);
        this.message = '';
        this.isMediaUploading = false;
        setTimeout(() => {
          this.scrollToBottom();
        }, 400);
      })
    } catch (error) {
      console.log(error)
    }
  }

  scrollToBottom(): void {
    if (this.chatContainer && this.chatContainer.nativeElement) {
      this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
    } else {
      console.error('Chat container element is not available.');
    }
  }

  onScroll(): void {
    const container = this.chatContainer.nativeElement;
    const scrollTop = container.scrollTop;
    const offset = 100;

    if (!this.loadingOlderMessages && scrollTop < offset && !this.loadingOlderMessages && ((this.pagination?.currentPage ?? 0) < (this.pagination?.lastPage ?? 0))) {
      this.pageNum++;
      this.getSingleConversation();
    }
  }

  openEmojies(message: SingleConversationMessage) {
    this.selectedMessage = this.selectedMessage === message ? null : message;
  }

  emojiReaction(emoji: string, messageId: number) {
    const socketId = localStorage.getItem('socketId');
    const reactionObj = {
      type: emoji
    }
    console.log('socketId', socketId)
    this.chatHttpService.reactOnMessage(messageId, reactionObj, socketId).subscribe((response: any) => {
      console.log(' moji reaction set', response)
      this.isEmojiClicked = true;
      this.selectedMessage = null;
      let message = this.messages.find((msg) => msg.id === response.data.id);
      // if (message) {
      //   message = this.chatHelperService.handleSetEmojiReaction(message, this.conversation.is_group, response);
      // }
    });
  }

  onSearchChange(event: any): void {
    this.searchTerm = event.target.value;
    this.pageNum = 1;
    this.searchSubject.next(this.searchTerm);
  }

  onEditMessage(msg: any) {
    this.message = msg.body;
    this.editingMessage = msg;
    this.selectedMessage = null;
  }

  deleteMessage(messageId: number) {
    const socketId = localStorage.getItem('socketId');
    this.chatHttpService.deleteMessage(messageId, socketId).subscribe(response => {
      const index = this.messages.findIndex(m => m.id === messageId);
      if (index > -1 && index < this.messages.length) {
        this.messages.splice(index, 1);
      }
      if (response.data.latest_message === null) {
        this.location.replaceState(`/chat`);
        this.conversationId = 0;
        this.chatHelperService.refreshListOfConversations(true);
      }
    });
  }

  async editMessage(chatData: any) {
    const socketId = localStorage.getItem('socketId');
    console.log('socketId',socketId)
    try {
      this.chatHttpService.updateMessage(this.editingMessage.id, chatData, socketId).subscribe(response => {
        console.log('res[ponse u] update message', response);
        const index = this.messages.findIndex(m => m.id === response.data.id);

        if (index !== -1) {
          this.messages[index].body = response.data.body;
        }
        this.editingMessage = null;
        this.message = '';
      })
    } catch (error) {
      console.log(error);
      this.editingMessage = null;
    }
  }

  openMedia(media: any) {
    this.selectedMedia = media;
  }

  download(selectedMedia: any) {
    let mediaId: any;
    mediaId = this.chatHelperService.handleMediaId(selectedMedia, mediaId);

    this.chatHttpService.downloadMedia(this.conversationId, mediaId).subscribe((blob: any) => {
      const timestamp = new Date().getTime();
      let fileName = `downloaded_media_${timestamp}`;
      fileName = this.chatHelperService.handleDownloadMediaName(fileName, blob, timestamp);
      this.chatHelperService.downloadFile(blob, fileName);
    });
  }

  ngOnDestroy(): void {
    this.clearSubscription();
  }

  deleteConversation() {
    this.location.replaceState(`/chat`);
    this.conversationId = 0;
  }

  openLeaveChatModal() {
    this.isDeleteModalOpen = true;
    this.deleteChatText = ActionTextLeaveGrup;
  }

  clearSubscription() {
    this.participantsLeftChatSubscription?.unsubscribe();
    this.messageDeleteSubscription?.unsubscribe();
    this.messageUpdateSubscription?.unsubscribe();
    this.newMessageSubscription?.unsubscribe();
    this.emojiReactionSubscription?.unsubscribe();
    this.emojiReactionDeletedSubscription?.unsubscribe();
    this.groupAccountDetailsChanged?.unsubscribe();
  }

}