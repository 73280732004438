import { NgForOf, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { AuthenticationHelperService } from 'src/app/services/helpers/authentication-helper.service';
import { ChatHelperService } from 'src/app/services/helpers/chat-helper.service';
import { WebsocketsHelperService } from 'src/app/services/helpers/websockets-helper.service';
import { Conversation } from 'src/app/services/http-services/chat/chat-http-interfaces';
import { ChatHttpService } from 'src/app/services/http-services/chat/chat-http.service';
import { FollowersHttpService } from 'src/app/services/http-services/followers/followers-http.service';

@Component({
  selector: 'app-group-accounts',
  standalone: true,
  imports: [
    NgForOf,
    NgIf
  ],
  templateUrl: './group-accounts.component.html',
})
export class GroupAccountsComponent implements OnInit, OnDestroy {
  @Input() conversation!: Conversation;
  @Output() userRemoved = new EventEmitter<boolean>();
  loggedUser: any;
  participantsLeftChatSubscription: Subscription | undefined;

  constructor(
    private authenticationHelperService: AuthenticationHelperService,
    private chatHelperService: ChatHelperService,
    private websocketsHelperService: WebsocketsHelperService,
    private chatHttpService: ChatHttpService,
    private toastService: ToastrService,
    private followersHttpService: FollowersHttpService
  ) { }

  ngOnInit(): void {
    this.clearSubscription();
    this.loggedUser = this.authenticationHelperService.getUserData;
    this.listenIfParticipantLeftChat();
  }

  listenIfParticipantLeftChat() {
    this.websocketsHelperService.listenIfSomeoneLeftConversation(this.conversation.id);
    this.participantsLeftChatSubscription = this.websocketsHelperService.userLeft.subscribe((res: any) => {
      const userId = res.user.id;
      if (this.conversation.other_participants) {
        this.chatHelperService.removeParticipant(this.conversation.other_participants, userId);
      }
      if (this.conversation.non_admin_participants) {
        this.chatHelperService.removeParticipant(this.conversation.non_admin_participants, userId);
      }
    });
  }

  clearSubscription() {
    this.participantsLeftChatSubscription?.unsubscribe();
  }

  removeUserFromGroup(user: any) {
    const socketId = localStorage.getItem('socketId');
    this.chatHttpService.removeParticipantFromGroup(this.conversation.id, user.id, socketId).subscribe((response: any) => {
      this.toastService.success('User successfully removed!');
      this.userRemoved.emit(true);
      // this.chatHelperService.refreshUsersGroupAccounts(true);
      this.chatHelperService.removeParticipant(this.conversation.other_participants, user.id);
      this.chatHelperService.removeParticipant(this.conversation.non_admin_participants, user.id);
    })
  }

  ngOnDestroy(): void {
    this.clearSubscription();
  }

  followUnfollowUser(id: number) {
    this.followersHttpService.followUnfollowUser(id).subscribe((response: any) => {
      let userIndex = this.conversation.non_admin_participants?.findIndex((user: any) => user.id === id);
      if (this.conversation.non_admin_participants && userIndex) {
        this.conversation.non_admin_participants[userIndex].am_i_following_this_user = response.data.am_i_following_this_user;
        this.toastService.success(this.conversation.non_admin_participants[userIndex].am_i_following_this_user ? 'User has been followed' : 'User has been unfollowed!');
      }
    })
  }

  followUnfollowAdmin(id: number) {
    console.log('boop')
    this.followersHttpService.followUnfollowUser(id).subscribe((response: any) => {
      console.log(response)
   
      if (this.conversation.admin_participants) {
        let userIndexAdmin = this.conversation.admin_participants?.findIndex((user: any) => user.id === response.data.id);
        this.conversation.admin_participants[userIndexAdmin].am_i_following_this_user = response.data.am_i_following_this_user;
        this.toastService.success(this.conversation.admin_participants[userIndexAdmin].am_i_following_this_user ? 'User has been followed' : 'User has been unfollowed!');
      }
    })
  }
}
