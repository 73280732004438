import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PAGES_AND_PATHS} from '../../constants/pages-and-paths';
import {AuthGuardService} from '../../guards/auth-guard.service';
import {CompanyProfileComponent} from './company-profile.component';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.companyProfile.pageInRouting,
    data: { data: PAGES_AND_PATHS.companyProfile },
    component: CompanyProfileComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./company-profile.module').then(m => m.CompanyProfileModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompanyProfileRoutingModule { }
