import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { ItemsHttpService } from 'src/app/services/http-services/items/items-http.service';
import { MasterItem } from 'src/app/services/http-services/items/items-interfaces';

@Component({
  selector: 'app-new-item',
  templateUrl: './new-item.component.html',
})
export class NewItemComponent implements OnInit {
  pageNum = 1;
  masterItems: MasterItem[] = [];
  searchTerm = '';
  searchSubject = new Subject<string>();
  pagination!: TlPagination;
  itemId = 0;
  listId = 0;
  @ViewChild('itemsContainer', { static: false }) private itemsContainer!: ElementRef;
  loadingOlderMessages = false;

  constructor(
    private itemsHttpService: ItemsHttpService,
    private actRoute: ActivatedRoute,
    private router: Router,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.actRoute.paramMap.subscribe(params => {
     this.itemId = Number(params.get('itemID') || '');
     this.listId = Number(params.get('listID') || '');
    });
    this.getMasterItems();
    this.searchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((searchTerm: string) => this.getMasterItems(searchTerm))
    ).subscribe((response: any) => {
      this.masterItems = response.data;
    });
  }

  getMasterItems(searchTerm?: string) {
    this.loadingOlderMessages = true;
    return (
      this.itemsHttpService.getMasterItems(this.pageNum, this.itemId, searchTerm).then((res: { data: MasterItem[], pagination: TlPagination }) => {
        console.log('getMasterItems', res)
        this.masterItems = res.data;
        this.pagination = res.pagination;
        this.loadingOlderMessages = false;
        return res;
      })
    )
  }

  onSearchChange(event: any): void {
    this.searchTerm = event.target.value;
    this.pageNum = 1;
    this.searchSubject.next(this.searchTerm);
  }

  resetSearch() {
    this.searchTerm = '';
    this.searchSubject.next('');
  }

  selectMasterItem(item: any) {
    const reqObj = {
      master_item_id: item.id
    }
    this.itemsHttpService.updateItem(this.itemId, reqObj).then((response: any) => {
      this.router.navigate(['single-list', this.listId, 0, 'table'])
    })
  }

  addNewItem(): void {
    this.router.navigate(['single-list', this.listId, 1, 'table'])
  }

  onScroll(): void {
    const container = this.itemsContainer.nativeElement;
    const scrollTop = container.scrollTop;
    const offset = 100;

    if (!this.loadingOlderMessages && scrollTop < offset && !this.loadingOlderMessages && ((this.pagination?.currentPage ?? 0) < (this.pagination?.lastPage ?? 0))) {
      this.pageNum++;
      this.getMasterItems();
    }
  }

  goBack(): void{
    this.location.back();
  }

}
