<div class="pie-chart-wrapper" *ngIf="chartOptions">
  <apx-chart
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [labels]="chartOptions.labels"
    [dataLabels]="chartOptions.dataLabels"
    [colors]="chartOptions.colors"
    [states]="chartOptions.states"
    [stroke]="chartOptions.stroke"
  ></apx-chart>
</div>
<!--[legend]="chartOptions.legend"-->
<!--[tooltip]="chartOptions.tooltip"-->
<!--[plotOptions]="chartOptions.plotOptions"-->
<!---->
<!--[states]="chartOptions.states"-->
