<div class="wrapper-subscription-plans-page">
  <span class="title-settings-page">Subscription plans page</span>
  <div class="wrapper-subscription-plan">
    <span class="label-subscription-plan">Current plan</span>
    <!--  TODO JS - Free plan  -->
    <!--  TODO JS - Dodajes klasu "selected" u zavisnosti koji je plan kuplje  -->
    <!--  TODO JS - Kod dugmica gde se kupuje plan menjaces tekst na tom dugmetu, dal treba da kupi iil da se unsubskrajbuje (jsa sma kroz scss i to sredio da dugme bude kao u dizjanu kad je dodata klasa selected)  -->
    <div class="wrapper-plan-card free-plan">
      <span class="title-plan-card">Lister</span>
      <p>Free</p>
      <div class="wrapper-feature-plan">
        <div class="feature-plan">
          <div class="wrapper-feature-circle">
            <div class="feature-circle"></div>
          </div>
          <span>Ads</span>
        </div>
      </div>
      <button class="custom-btn custom-btn--yellow">
        <span>Stay free forever</span>
      </button>
      <div class="wrapper-circle-corner">
        <div class="circle-corner"></div>
      </div>
    </div>
    <span class="label-subscription-plan">Avaiable plans</span>
    <!--  TODO JS - A-Lister plan  -->
    <div class="wrapper-plan-card pay-plan">
      <span class="title-plan-card">A-Lister</span>
      <p><span>1.99$ </span>/ month</p>
      <div class="wrapper-feature-plan">
        <div class="feature-plan">
          <div class="wrapper-feature-circle">
            <div class="feature-circle"></div>
            <img src="../../../../../assets/icons/outline/icon-check.svg" alt="">
          </div>
          <span>No Ads</span>
        </div>
      </div>
      <button class="custom-btn custom-btn--primary">
        <span>Upgrade to A-Lister plan</span>
      </button>
      <div class="wrapper-circle-corner">
        <div class="circle-corner"></div>
      </div>
    </div>
    <!--  TODO JS - Expert plan  -->
    <div (click)="clickOnPlan.emit('expert')" class="wrapper-plan-card pay-plan">
      <span class="title-plan-card">Expert</span>
      <p><span>10.99$</span> / month</p>
      <div class="wrapper-feature-plan">
        <div class="feature-plan">
          <div class="wrapper-feature-circle">
            <div class="feature-circle"></div>
            <img src="../../../../../assets/icons/outline/icon-check.svg" alt="">
          </div>
          <span>No Ads</span>
        </div>
        <div class="feature-plan">
          <div class="wrapper-feature-circle">
            <div class="feature-circle"></div>
            <img src="../../../../../assets/icons/outline/icon-check.svg" alt="">
          </div>
          <span>Sponsored Lists</span>
        </div>
        <div class="feature-plan">
          <div class="wrapper-feature-circle">
            <div class="feature-circle"></div>
            <img src="../../../../../assets/icons/outline/icon-check.svg" alt="">
          </div>
          <span>Recommended Lists</span>
        </div>
      </div>
      <button (click)="clickToSubscribeOnPlan.emit('tlexpert')" class="custom-btn custom-btn--primary">
        <span>Upgrade to Expert plan</span>
      </button>
      <!--   TODO JS - Ovo vidi kad trebas da pojavis ima u dizajnu   -->
<!--      <span  class="expert-text">You will have all premium features until your subscription ends on August 7, 2024.</span>-->
      <div class="wrapper-circle-corner">
        <div class="circle-corner"></div>
      </div>
    </div>
  </div>
</div>
