import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GeneralHttpHelperService} from '../../helpers/general-http-helper.service';
import {ForgotPasswordApiData, ForgotPasswordReqObj, LoginApiData, LoginReqObj} from './autentication-http-interfaces';

const AUTH_API_ROUTES = {
  LOGIN: 'login',
  FORGOT_PASSWORD: 'password-reset',
  POST_REGISTER: 'register',
  POST_COMPANY_REGISTER: 'company/register',
  POST_VERIFY_ACCOUNT: 'verify-account',
  POST_RESEND_VERIFICATION_CODE: 'resend-code',
  GET_TOPICS: 'subcategories',
  POST_TOGGLE_TOPIC: 'subcategories/user/interested_in/:topicId',
  GET_USER_TOPICS: 'subcategories/user/interested_in',
  GET_LISTERS: 'suggested-listers',
  POST_TOGGLE_LISTER: 'interested-in-listers/:listerId',
  GET_USER_LISTERS: 'interested-in/listers',
  GET_LANGUAGES: 'languages'
};
@Injectable({
  providedIn: 'root'
})
export class AuthenticationHttpService {

  constructor(private httpClient: HttpClient,
              private generalHttpHelperService: GeneralHttpHelperService) {

  }

  private getLanguagesReq(): Observable<any> {
    return this.httpClient.get(AUTH_API_ROUTES.GET_LANGUAGES) as any;
  }

  getLanguages(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getLanguagesReq().subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
  private loginReq(bodyParams: LoginReqObj): Observable<LoginApiData> {
    return this.httpClient.post(AUTH_API_ROUTES.LOGIN, bodyParams) as any;
  }

  login(bodyParams: LoginReqObj): Promise<LoginApiData> {
    return new Promise<any>((resolve, reject) => {
      this.loginReq(bodyParams).subscribe((res: LoginApiData) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }


  private forgotPasswordReq(bodyParams: ForgotPasswordReqObj): Observable<ForgotPasswordApiData> {
    return this.httpClient.post(AUTH_API_ROUTES.FORGOT_PASSWORD, bodyParams) as any;
  }

  forgotPassword(bodyParams: ForgotPasswordReqObj): Promise<ForgotPasswordApiData> {
    return new Promise<ForgotPasswordApiData>((resolve, reject) => {
      this.forgotPasswordReq(bodyParams).subscribe((res: ForgotPasswordApiData) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private postRegisterReq(bodyReq: any): Observable<any> {
    return this.httpClient.post(AUTH_API_ROUTES.POST_REGISTER, bodyReq);
  }

  postRegister(bodyReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.postRegisterReq(bodyReq).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private postCompanyRegisterReq(bodyReq: any): Observable<any> {
    return this.httpClient.post(AUTH_API_ROUTES.POST_COMPANY_REGISTER, bodyReq);
  }

  postCompanyRegister(bodyReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.postCompanyRegisterReq(bodyReq).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private postVerifyAccountReq(bodyReq: any): Observable<any> {
    return this.httpClient.post(AUTH_API_ROUTES.POST_VERIFY_ACCOUNT, bodyReq);
  }

  postVerifyAccount(bodyReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.postVerifyAccountReq(bodyReq).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private postResendVerificationCodeReq(): Observable<any> {
    return this.httpClient.post(AUTH_API_ROUTES.POST_RESEND_VERIFICATION_CODE, {}) as any;
  }

  postResendVerificationCode(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.postResendVerificationCodeReq().subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private getTopicsReq(urlParams: any): Observable<any> {
    const url = this.generalHttpHelperService.addUrlParams(AUTH_API_ROUTES.GET_TOPICS, urlParams);
    return this.httpClient.get(url);
  }

  getTopics(urlParams: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getTopicsReq(urlParams).subscribe((res: any) => {
        resolve({
          data: res.data.data,
          pagination: this.generalHttpHelperService.returnPagination(res.data)
        });
      }, error => {
        reject(error);
      });
    });
  }

  private getUserTopicsReq(): Observable<any> {
    return this.httpClient.get(AUTH_API_ROUTES.GET_USER_TOPICS);
  }

  getUserTopics(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getUserTopicsReq().subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private postToggleUserTopicReq(urlParams: any): Observable<any> {
    const url = this.generalHttpHelperService.replaceUrlParamsWithValues(AUTH_API_ROUTES.POST_TOGGLE_TOPIC, urlParams);
    return this.httpClient.post(url, {});
  }

  postToggleUserTopic(urlParams: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.postToggleUserTopicReq(urlParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private getSuggestedListersReq(urlParams: any): Observable<any> {
    const url = this.generalHttpHelperService.addUrlParams(AUTH_API_ROUTES.GET_LISTERS, urlParams);
    return this.httpClient.get(url);
  }

  getSuggestedListers(urlParams: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getSuggestedListersReq(urlParams).subscribe((res: any) => {
        resolve({
          data: res.data,
          pagination: this.generalHttpHelperService.returnPagination(res)
        });
      }, error => {
        reject(error);
      });
    });
  }

  private getUserListersReq(): Observable<any> {
    return this.httpClient.get(AUTH_API_ROUTES.GET_USER_LISTERS);
  }

  getUserListers(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getUserListersReq().subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private postToggleUserListerReq(urlParams: any): Observable<any> {
    const url = this.generalHttpHelperService.replaceUrlParamsWithValues(AUTH_API_ROUTES.POST_TOGGLE_LISTER, urlParams);
    return this.httpClient.post(url, {});
  }

  postToggleUserLister(urlParams: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.postToggleUserListerReq(urlParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
}
