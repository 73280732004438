<div class="wrapper-card-list-search" [routerLink]="[PAGES_AND_PATHS.singleList.pagePath, list.id, 0, 'cards']">
  <img [src]="list.lyst_image_url"
    alt="">
  <div class="card-list-search-info">
    <div class="wrapper-badges">
      <div class="badge gray">
        <span>{{list.lyst_items_count}} {{list.lyst_items_count === 1 ? 'item' : 'items'}}</span>
      </div>
    </div>
    <span class="name">{{list.title}}</span>
    <div class="wrapper-author">
      <span>{{list.user.name}}</span>
      <img src="" alt="">
    </div>
  </div>
</div>