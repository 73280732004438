import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, from } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
})
export class SearchResultsComponent implements OnInit {
  tabSelected = 'lists';
  searchTerm: string = '';
  private searchSubject = new Subject<string>();
  isLiveSearch = false;
  debouncedSearchTerm: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.searchTerm = params['searchTerm'] || '';
      this.debouncedSearchTerm = this.searchTerm;
      if (this.debouncedSearchTerm !== '') {
        this.isLiveSearch = true;
      }
    });
    this.searchSubject
      .pipe(
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe((term) => {
        this.debouncedSearchTerm = term;
      });
  }

  onSearchChange(event: any) {
    this.searchSubject.next(event.target.value);
    this.isLiveSearch = false;
  }

  clearSearchTerm() {
    this.searchTerm = '';
    this.debouncedSearchTerm = '';
    this.isLiveSearch = false;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { searchTerm: null },
      queryParamsHandling: 'merge',
    });
  }

}
