import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-change-visibility-modal',
  standalone: true,
  imports: [],
  templateUrl: './change-visibility-modal.component.html',
})
export class ChangeVisibilityModalComponent {
  @Input() modalTitle = '';
  @Input() modalDescription = '';
  @Input() confirmBtnText = '';
  @Input() cancelBtnText = 'Cancel';
  @Output() closeModal = new EventEmitter<boolean>();
  @Output() changeVisibility = new EventEmitter<boolean>();
}
