export enum SettingsTabs {
  insights = 'insights',
  changeUsername = 'change-username',
  changeEmail = 'change-email',
  changePhone = 'change-phone',
  deleteAccount = 'delete-account',
  languages = 'languages',
  subscriptionPlan = 'subscription-plan',
  changePassword = 'change-password',

}
