import { Component } from '@angular/core';

@Component({
  selector: 'app-near-me',
  standalone: true,
  imports: [],
  templateUrl: './near-me.component.html',
})
export class NearMeComponent {

}
