import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickedOutsideDirective } from './clicked-outside.directive';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ClickedOutsideDirective
  ],
  exports: [
    ClickedOutsideDirective
  ]
})
export class ClickedOutsideModule { }
