<div class="wrapper-modal-filter">
  <div class="wrapper-content-modal-filter">
    <div class="header-modal-filter">
      <span>Filters</span>
      <div class="custom-btn custom-btn--circle-outline" (click)="closeModal.emit(true)">
        <img src="../../../../../../assets/icons/outline/icon-close-modal.svg" alt="">
      </div>
    </div>
    <div class="content-modal-filter">
      <!--   Sort:   -->
      <span class="label-filter">Sort</span>
      <div (click)="toggleSort()" class="custom-btn custom-btn--outline-white">
        <span>{{ this.filters.sort === 'asc' ? 'a-z' : 'z-a' }}</span>
      </div>
      <!--   Column   -->
      <span class="label-filter">Column</span>
      <div class="wrapper-content-select-artist">
        <div class="wrapper-custom-checkbox-select-topics">
          <label *ngFor="let column of columns | slice:2" (click)="selectColumn(column)">
            <input type="radio" name="radioname">
            <div class="custom-checkbox-select-topics">
              <div class="check-topic">
                <img src="../assets/icons/outline/icon-check.svg" alt="">
              </div>
              <div class="wrapper-select-topics-info">
                <span>{{column.name}}</span>
              </div>
            </div>
          </label>
        </div>
      </div>
      <div class="buttons-filter-modal">
        <div class="custom-btn custom-btn--outline">
          <span>Cancel</span>
        </div>
        <div (click)="saveChanges()" class="custom-btn custom-btn--primary">
          <span>Save</span>
        </div>
      </div>
    </div>
  </div>
</div>
