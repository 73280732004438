<div class="wrapper-forgot-password-page">
  <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmitForgotPasswordForm()" novalidate class="content-forgot-password">
    <div class="back-btn" [routerLink]="PAGES_AND_PATHS.login.pagePath">
      <img src="../assets/icons/icon-arrow-left.svg" alt="">
    </div>
    <div class="wrapper-circle-gray-icon">
      <img src="../assets/icons/outline/icon-security.svg" alt="">
    </div>
    <div class="wrapper-header-forgot-password">
      <h1>Forgot password</h1>
      <h6>Enter your account email and we will send a temporary password for your account.</h6>
    </div>
    <div class="wrapper-input" [ngClass]="{'error': forgotPasswordForm.controls['email'] | formControlErrorMessages: 'Please enter a valid email address.' : formTriedToSubmit}">
      <span class="label-input">Email address</span>
      <div class="custom-input">
        <input formControlName="email" type="text" placeholder="Enter you email address">
      </div>
      <div *ngIf="forgotPasswordForm.controls['email'] | formControlErrorMessages: 'Please enter a valid email address.': formTriedToSubmit" class="wrapper-error-message">
        <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
        <span>{{forgotPasswordForm.controls['email'] | formControlErrorMessages: 'Please enter a valid email address.': formTriedToSubmit}}</span>
      </div>
    </div>
    <button type="submit" class="custom-btn custom-btn--primary xs-mt-12">
      <span>Reset your password</span>
      <tl-spinner *ngIf="isSpinnerActive"></tl-spinner>
    </button>
  </form>
</div>

