import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'tl-single-plan',
  standalone: true,
    imports: [
    ],
  templateUrl: './single-plan.component.html',
})
export class SinglePlanComponent {
  @Input() planId = ''
  @Output() clickToSubscribeOnPlan: EventEmitter<string> = new EventEmitter<string>();
}
