import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {TimeAgoPipe} from "../../../../pipes/time-ago/time-ago.pipe";
import {NgForOf, NgIf} from "@angular/common";
import {TlPagination} from "../../../../interfaces/general-interfaces";
import {ProfileHttpService} from "../../../../services/http-services/profile/profile-http.service";
import {Subject} from "rxjs";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'tl-lists-tab',
  standalone: true,
  imports: [
    TimeAgoPipe,
    NgIf,
    NgForOf,
    RouterModule
  ],
  templateUrl: './lists-tab.component.html',
})
export class ListsTabComponent implements OnInit, OnChanges {
  @Input() userId!: number;
  @Input() isAuthUserProfile = true;
  searchTerm$ = new Subject<string>();
  lysts: any[] = [];
  lystsPagination: TlPagination | undefined;
  urlParams = {page: 1, search_keyword: ''};
  PAGES_AND_PATHS = PAGES_AND_PATHS;

  constructor(private profileHttpService: ProfileHttpService) {
  }

  ngOnInit() {
    this.searchTerm$.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe((searchTerm: any) => {
      this.urlParams.search_keyword = searchTerm;
      this.lystsPagination = undefined;
      this.isAuthUserProfile ? this.getAuthUserLysts(true) : this.getUserLysts(true);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    (changes.isAuthUserProfile && changes.isAuthUserProfile.currentValue) ? this.getAuthUserLysts() : this.getUserLysts();
  }

  getUserLysts(isSearchChange = false): void {
    this.urlParams.page = this.lystsPagination?.currentPage ? this.lystsPagination.currentPage + 1 : 1;
    this.profileHttpService.getUserLysts({...this.urlParams, userId: this.userId}).then(res => {
      this.lysts = isSearchChange ? res.data : [...this.lysts, ...res.data];
      this.lystsPagination = res.pagination;
    })
  }

  getAuthUserLysts(isSearchChange = false): void {
    this.urlParams.page = this.lystsPagination?.currentPage ? this.lystsPagination.currentPage + 1 : 1;
    this.profileHttpService.getAuthUserLysts(this.urlParams).then(res => {
      this.lysts = isSearchChange ? res.data : [...this.lysts, ...res.data];
      this.lystsPagination = res.pagination;
    })
  }

  onSearchChange(event: any): void {
    this.searchTerm$.next(event.target.value);
  }

  clearSearch(): void {
    this.searchTerm$.next('');
  }

  onScroll(event: any): void {
    const threshold = 100;
    const position = event.target.scrollTop + event.target.offsetHeight;
    const height = event.target.scrollHeight;

    if (position > height - threshold && (this.lystsPagination?.currentPage ?? 0) < (this.lystsPagination?.lastPage ?? 0)) {
      this.isAuthUserProfile ? this.getAuthUserLysts() : this.getUserLysts();
    }
  }
}
