<div class="wrapper-chat-settings">
  <div class="chat-settings-info">
    <div class="my-info-chat">
      <div class="wrapper-user-avatar-group-chat settings-avatar">
        <div *ngIf="conversation.is_group && conversation.profile_photo === null" class="images-grid-chat"
          [ngClass]="conversation.other_participants?.length | checkChatGroupLength">
          <div class="image">
            <img [src]="'../../../../../assets/images/avatar-user.png'"
              alt="">
          </div>
          <div *ngFor="let person of conversation.other_participants | slice:0:3" class="image">
            <img [src]="'../../../../../assets/images/avatar-user.png'"
              alt="">
          </div>
          <div class="number-more-people">
            <span>{{conversation.other_participants?.length}}+</span>
          </div>
        </div>
        <div *ngIf="conversation.is_group && conversation.profile_photo !== null" class="images-grid-chat"
          [ngClass]="conversation.other_participants?.length | checkChatGroupLength">
          <div class="image">
            <img [src]="conversation.profile_photo.url" alt="">
          </div>
        </div>
        <div *ngIf="!conversation.is_group" class="images-grid-chat">
          <div class="image">
            <img src="../../../../../assets/images/avatar-user.png" alt="">
          </div>
        </div>
      </div>
      <span>{{conversation.is_group ? conversation.name : conversation.other_participants[0].username}}</span>
      <img src="../../../../../assets/icons/fill/icon-badge.svg" alt="">
    </div>
    <div class="btns-chat-settings">
      <div *ngIf="!conversation.is_group" class="gray-btn-img">
        <span>View profile</span>
        <img src="../../../../../assets/icons/outline/icon-user.svg" alt="">
      </div>
      <div *ngIf="conversation.is_group"  class="gray-btn-img" [routerLink]="[PAGES_AND_PATHS.groupAccountsAddUser.pagePath, conversation.id]">
        <span>View accounts</span>
        <img src="../../../../../assets/icons/outline/icon-user.svg" alt="">
      </div>
      <div *ngIf="conversation.is_group" [routerLink]="[PAGES_AND_PATHS.groupChatDetails.pagePath, conversation.id]"
        class="gray-btn-img">
        <span>Edit group details</span>
        <img src="../../../../../assets/icons/outline/icon-edit.svg" alt="">
      </div>
      <div (click)="isSearchOpen.emit(true)" class="gray-btn-img">
        <span>Search in conversation</span>
        <img src="../../../../../assets/icons/outline/icon-search.svg" alt="">
      </div>
    </div>
  </div>
  <div class="wrapper-custom-tabs">
    <div class="custom-tab" (click)="selectTab('media')" [ngClass]="{'active': tabSelected === 'media'}">
      <img src="../../../../../assets/icons/outline/icon-posts.svg" alt="">
      <span>Media</span>
    </div>
    <div class="custom-tab" (click)="selectTab('pdfs')" [ngClass]="{'active': tabSelected === 'pdfs'}">
      <img src="../../../../../assets/icons/outline/icon-my-lists.svg" alt="">
      <span>PDF's</span>
    </div>
  </div>
  <div *ngIf="tabSelected === 'media'">
    <app-chat-info-media [conversationId]="conversation.id"></app-chat-info-media>
  </div>
  <div *ngIf="tabSelected === 'pdfs'">
    <app-chat-info-pdfs [conversationId]="conversation.id"></app-chat-info-pdfs>
  </div>
</div>
