import { NgClass, NgForOf, NgIf, SlicePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { FilterItemsComponent } from '../filter-items/filter-items.component';
import { AddNewColumnComponent } from '../add-new-column/add-new-column.component';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { Column, DeleteModalText, ListItem } from 'src/app/services/http-services/lists/lists-http-intterfaces';
import { ListsHttpService } from 'src/app/services/http-services/lists/lists-http.service';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ConfirmModalComponent } from 'src/app/shared-components/confirm-modal/confirm-modal.component';
import { ItemRowComponent } from '../item-row/item-row.component';
import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { ItemsHttpService } from 'src/app/services/http-services/items/items-http.service';
import { ListsHelperService } from 'src/app/services/helpers/lists-helper.service';
import { AuthenticationHelperService } from 'src/app/services/helpers/authentication-helper.service';
import { ClickedOutsideModule } from 'src/app/directives/clicked-outside/clicked-outside.module';
import { CopyItemModalComponent } from '../copy-item-modal/copy-item-modal.component';
import { CopyToastNotificationComponent } from 'src/app/shared-components/copy-toast-notification/copy-toast-notification.component';

@Component({
  selector: 'app-items',
  standalone: true,
  imports: [
    NgForOf,
    RouterModule,
    NgClass,
    NgIf,
    FilterItemsComponent,
    AddNewColumnComponent,
    RouterModule,
    FormsModule,
    ConfirmModalComponent,
    ItemRowComponent,
    DragDropModule,
    SlicePipe,
    ClickedOutsideModule,
    CopyItemModalComponent,
    CopyToastNotificationComponent
  ],
  templateUrl: './items.component.html',
})
export class ItemsComponent implements OnInit, OnChanges {
  listId: number = 0;
  PAGES_AND_PATHS = PAGES_AND_PATHS;
  itemsView = '';
  isFilterModalOpen = false;
  isAddNewColumnOpen = false;
  pageNum = 1;
  @Input() items: ListItem[] = [];
  @Input() user_id = 0;
  pagination!: TlPagination;
  searchTerm = '';
  @Input() columns: Column[] = [];
  isDeleteModalOpen = false;
  deleteModalText: DeleteModalText = { title: '', description: '', button: '' };
  selectedColumn: Column | null = null;
  addNewRow = false;
  @Output() searchTermChanged = new EventEmitter<string>();
  @Input() filters = { sort: 'asc', id: 0 };
  loggedUser: any;
  isCopyModalOpen = false;
  isCopyDropdownOpen = false;
  selectedItem: ListItem | null = null;
  url = '';
  isOpenToastOpen = false;
  selectedListAndItem: any;
  historyState: any;

  constructor(
    private listsHttpService: ListsHttpService,
    private actRoute: ActivatedRoute,
    private toastService: ToastrService,
    private itemsHttpService: ItemsHttpService,
    private listsHelperService: ListsHelperService,
    private authenticationHelperService: AuthenticationHelperService,
    private router: Router
  ) { }

  ngOnInit(): void {
    // this.historyState = history.state;
    // console.log('HISTORY STAT U ITEMS', this.historyState)
    // if (this.historyState.list || this.historyState.item) {
    //   this.selectedListAndItem = { list: this.historyState.list, item: this.historyState.item };
    //   this.isOpenToastOpen = true;
    //   window.history.replaceState({}, '', this.router.url);
    // }
    this.loggedUser = this.authenticationHelperService.getUserData;
    this.actRoute.paramMap.subscribe(params => {
      this.listId = Number(params.get('id') || '');
      this.itemsView = params.get('view') as string;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  addNewItem(): void {
    this.itemsView = 'table';
    this.itemsHttpService.createNewItem({ lyst_id: this.listId }).then((response: any) => {
      this.addNewRow = true;
      this.listsHelperService.refreshPage(true);
    })
  }

  onSearchChange(event: any): void {
    this.searchTerm = event.target.value;
    this.pageNum = 1;
    this.searchTermChanged.emit(this.searchTerm)
  }

  resetSearch() {
    this.searchTerm = '';
    this.searchTermChanged.emit(this.searchTerm)
  }

  handleColumnName(reqObj: { name: string }) {
    if (this.selectedColumn && this.isAddNewColumnOpen) {
      this.updateColumn(this.selectedColumn.id, reqObj);
    } else {
      this.addNewColumn(reqObj)
    }
  }

  addNewColumn(reqObj: { name: string }): void {
    this.listsHttpService.postListColumn(reqObj, this.listId).then((response: any) => {
      this.toastService.success(response.message);
      this.isAddNewColumnOpen = false;
      this.listsHelperService.refreshPage(true);
    });
  }

  deleteColumn(id: number | undefined): void {
    this.isDeleteModalOpen = false;
    this.listsHttpService.deleteListColumn(id).then((response: any) => {
      this.toastService.success(response.message);
      this.listsHelperService.refreshPage(true);
    });
  }

  updateColumn(id: number, reqObj: { name: string }): void {
    this.isAddNewColumnOpen = false;
    this.listsHttpService.updateListColumn(id, reqObj).then((response: any) => {
      this.toastService.success(response.message);
      this.listsHelperService.refreshPage(true);
    });
  }

  openDeleteColumnModal(column: Column) {
    this.isDeleteModalOpen = true;
    this.selectedColumn = column;
    this.deleteModalText.title = 'Delete column?';
    this.deleteModalText.description = 'Are you sure you wish to delete this column? This action cannot be undone.'
    this.deleteModalText.button = 'Delete';
  }

  openUpdateColumnModal(column: Column) {
    if (this.loggedUser.id !== this.user_id) {
      return;
    }
    this.selectedColumn = column;
    this.isAddNewColumnOpen = true;
  }

  takeAction(): void {
    this.deleteColumn(this.selectedColumn?.id);
  }

  drop(event: CdkDragDrop<any[]>) {
    const movedItem = this.items[event.previousIndex];
    const newIndex = event.currentIndex;
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    const order = {
      id: movedItem.id,
      order_value: newIndex
    };
    let reqObj: { order: { id: number; order_value: number; }[] } = {
      order: []
    };
    reqObj.order.push(order);
    this.listsHttpService.reorderListItems(this.listId, reqObj).then((res: any) => {
      console.log(res)
    })
  }

  trackByIndex(index: number, item: any): number {
    return index;
  }

  openCopyModal(selectedItem: ListItem | null, event: any): void {
    this.isCopyDropdownOpen = true;
    event.stopPropagation();
    this.url = `/single-list/${selectedItem?.lyst_id}/0/cards`
    this.selectedItem = selectedItem;
  }

  closeCopyModal(): void {
    this.isOpenToastOpen = false;
  }
}
