import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {
  InsightsAgeRangeTabsEnums,
  InsightsDetailsTypeEnums,
  InsightsTopLocationsTabsEnums
} from "../../../../enums/profile-enums";
import {ActivatedRoute} from "@angular/router";
import {SettingsHttpService} from "../../../../services/http-services/settings/settings-http.service";
import {PAGES_AND_PATHS} from "../../../../constants/pages-and-paths";
import {DecimalPipe, KeyValuePipe, NgClass, NgForOf, NgStyle} from "@angular/common";
import {AreaChartModule} from "../../../../shared-components/area-chart/area-chart.module";
import {DonutChartModule} from "../../../../shared-components/donut-chart/donut-chart.module";

@Component({
  selector: 'tl-insights-details',
  standalone: true,
  imports: [
    DecimalPipe,
    AreaChartModule,
    NgClass,
    KeyValuePipe,
    NgForOf,
    NgStyle,
    DonutChartModule
  ],
  templateUrl: './insights-details.component.html',
})
export class InsightsDetailsComponent implements OnChanges {
  @Input() detailsType = '';
  urlParams = {from_date: '2024-08-10', to_date: '2024-10-20'};
  headerTitle = '';
  cardTitle = '';
  cardEntity = '';
  insightsDetails: any;
  topLocationsActiveTab = InsightsTopLocationsTabsEnums.city;
  ageRangeActiveTab = InsightsAgeRangeTabsEnums.all;
  protected readonly InsightsTopLocationsTabsEnums = InsightsTopLocationsTabsEnums;
  protected readonly InsightsAgeRangeTabsEnums = InsightsAgeRangeTabsEnums;
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;

  constructor(private activatedRoute: ActivatedRoute,
              private settingsHttpService: SettingsHttpService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    switch (this.detailsType) {
      case InsightsDetailsTypeEnums.followers: {
        this.headerTitle = 'Total followers';
        this.cardTitle = 'Followers';
        this.cardEntity = 'total_followers_count';
      }
        break;
      case InsightsDetailsTypeEnums.likes: {
        this.headerTitle = 'Total list likes';
        this.cardTitle = 'List likes';
        this.cardEntity = 'total_lyst_likes';
      }
        break;
      case InsightsDetailsTypeEnums.views: {
        this.headerTitle = 'Total list views';
        this.cardTitle = 'List views';
        this.cardEntity = 'total_lyst_views';
      }
        break;
      case InsightsDetailsTypeEnums.shares: {
        this.headerTitle = 'Total list shares';
        this.cardTitle = 'List shares';
        this.cardEntity = 'total_lyst_shares';
      }
        break;
    }
    this.getInsightsDetails();
  }

  getInsightsDetails(): void {
    this.settingsHttpService.getInsightsDetails(this.urlParams, this.detailsType).then(res => {
      if (res.code === 200) {
        this.insightsDetails = res.data;
      }
    });
  }
}
