import {Component, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {ProfileHttpService} from "../../../../../services/http-services/profile/profile-http.service";
import {
  FormControlErrorMessagesModule
} from "../../../../../pipes/form-control-error-messages/form-control-error-messages.module";
import {NgClass, NgIf} from "@angular/common";
import {SpinnerComponent} from "../../../../../shared-components/spinner/spinner.component";

@Component({
  selector: 'tl-edit-profile-city',
  standalone: true,
  templateUrl: './edit-profile-city.component.html',
  imports: [
    FormControlErrorMessagesModule,
    NgIf,
    ReactiveFormsModule,
    NgClass,
    SpinnerComponent
  ]
})
export class EditProfileCityComponent {
  @Input() profileData: any;
  @Output() changeProfile: EventEmitter<any> = new EventEmitter<any>();
  isSpinnerActive = false;
  formTriedToSubmit = false;
  editProfileCityForm!: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private toastService: ToastrService,
              private profileHttpService: ProfileHttpService) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.profileDate && changes.profileData.currentValue) {
      this.editProfileCityForm.patchValue(changes.profileData.currentValue);
    }
  }

  buildForm(): void {
    this.editProfileCityForm = this.formBuilder.group({
      city: ['', [Validators.required]],
    });
    this.editProfileCityForm.patchValue(this.profileData);
  }

  onCancelChanges(): void {
    this.editProfileCityForm.reset();
    this.editProfileCityForm.patchValue(this.profileData);
  }

  onSubmitEditProfileCityForm(): void {
    this.formTriedToSubmit = false;
    if (this.editProfileCityForm.valid) {
      this.isSpinnerActive = true;
      this.profileHttpService.editAuthUserProfile(this.editProfileCityForm.value).then(res => {
        this.isSpinnerActive = false;
        this.changeProfile.emit(res.data);
        this.toastService.success(res.message);
      }).catch(({error}: {error:{ errors: { [key: string]: string }}}) => {
        this.isSpinnerActive = false;
        Object.values(error.errors).forEach(error => this.toastService.error(error));
      });
    }
  }
}
