import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SingleThemeComponent } from './single-theme.component';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.singleTheme.pageInRouting,
    data: {data: PAGES_AND_PATHS.singleTheme},
    component: SingleThemeComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./single-theme.module').then(m => m.SingleThemeModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SingleThemeRoutingModule { }
