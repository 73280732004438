<form [formGroup]="editProfileCountryForm" (ngSubmit)="onSubmitEditProfileCountryForm()" class="wrapper-edit-info-page">
  <span class="title-edit-page">Country</span>
  <span class="subtitle-edit-page">Change your account country.</span>
  <div class="wrapper-input" [ngClass]="{'error': editProfileCountryForm.controls['country'] | formControlErrorMessages: null: formTriedToSubmit}">
    <span class="label-input">Country</span>
    <div *ngIf="!selectedCountry" class="custom-input">
      <input #countrySearch  type="text" (focus)="showCountryDropdown = true" (blur)="onBlurSearchbar()" [(ngModel)]="searchQuery" (ngModelChange)="onSearchCountries()" [ngModelOptions]="{standalone: true}" placeholder="Enter country">
    </div>
    <div class="wrapper-selected-country" *ngIf="selectedCountry" (click)="removeSelectedCountry()">
      <img [src]="'../../../assets/icons/flags/' + selectedCountry.code.toLowerCase() + '.png'" alt="">
      <span>{{selectedCountry.name}}</span>
    </div>
    <div
      *ngIf="editProfileCountryForm.controls['country'] | formControlErrorMessages: null: formTriedToSubmit"
      class="wrapper-error-message">
      <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
      <span>{{editProfileCountryForm.controls['country'] | formControlErrorMessages: null: formTriedToSubmit}}</span>
    </div>
    <div  *ngIf="showCountryDropdown" class="wrapper-list-country">
      <div class="item-country" *ngFor="let country of filteredCountries" (click)="onSelectCountry(country)">
        <img [src]="'../../../assets/icons/flags/' + country.code.toLowerCase() + '.png'" alt="">
        <span>{{country.name}}</span>
      </div>
    </div>
  </div>
  <div class="buttons-edit-page">
    <button type="button" (click)="onCancelChanges()" class="custom-btn custom-btn--outline">
      <span>Cancel</span>
    </button>
    <button type="submit" class="custom-btn custom-btn--primary">
      <span *ngIf="!isSpinnerActive">Save changes</span>
      <tl-spinner *ngIf="isSpinnerActive"></tl-spinner>
    </button>
  </div>
</form>
