import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Subject, from } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { ListsHttpService } from 'src/app/services/http-services/lists/lists-http.service';

@Component({
  selector: 'app-recommended-listers',
  templateUrl: './recommended-listers.component.html',
})
export class RecommendedListersComponent implements OnInit {
  recommendedListers: any[] = [];
  pagination!: TlPagination;
  pageNum = 1;
  loading = false;
  private searchSubject = new Subject<string>();
  searchTerm = '';
  PAGES_AND_PATHS = PAGES_AND_PATHS;
  @ViewChild('favoriteItemsContainter', { static: false }) private favoriteItemsContainter!: ElementRef;

  constructor(
    private listsHttpService: ListsHttpService
  ) { }

  ngOnInit(): void {
    this.getRecommendedListers();
    this.searchSubject.pipe(
      debounceTime(300),
      switchMap((searchTerm: string) => from(this.getRecommendedListers(searchTerm)))
    ).subscribe(response => {
      this.recommendedListers = response.data;
      this.pagination = response.pagination;
    });
  }

  getRecommendedListers(searchTerm?: string) {
    this.loading = true;
    return (
      this.listsHttpService.getRecommendedListers(this.pageNum, searchTerm).then((response: any) => {
        console.log('getRecommendedListers', response)
        this.recommendedListers = response.data;
        this.pagination = response.pagination;
        this.loading = false;
        return response;
      })
    )
  }

  onScroll(): void {
    console.log('boop')
    const container = this.favoriteItemsContainter.nativeElement;
    const scrollTop = container.scrollTop;
    const offset = 100;

    if (!this.loading && scrollTop < offset && !this.loading && ((this.pagination?.currentPage ?? 0) < (this.pagination?.lastPage ?? 0))) {
      this.pageNum++;
      this.getRecommendedListers();
    }
  }

  onSearchChange(event: any): void {
    this.searchTerm = event.target.value;
    this.pageNum = 1;
    this.searchSubject.next(this.searchTerm);
  }

  resetSearch() {
    this.searchTerm = '';
    this.searchSubject.next('');
  }
}
