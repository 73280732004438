import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { MyListsComponent } from './my-lists.component';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.myLists.pageInRouting,
    data: { data: PAGES_AND_PATHS.myLists },
    component: MyListsComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./my-lists.module').then(m => m.MyListsModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MyListsRoutingModule { }
