import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkChatGroupLength',
  standalone: true
})
export class CheckChatGroupLengthPipe implements PipeTransform {

  transform(groupSize: number | undefined): any {
    if (groupSize !== undefined && (groupSize === 2 || groupSize === 3 || groupSize === 4)) {
      return `people-${groupSize}`;
    } else if (groupSize !== undefined && (groupSize > 4)) {
      return 'people-more';
    }
  }

}
