<div class="wrapper-confirmation-page">
  <div class="content-confirmation-page">
    <div class="wrapper-circle-gray-icon">
      <img [src]="historyState?.iconPath" alt="">
    </div>
    <div class="wrapper-header-confirmation-page">
      <h1>{{historyState?.title}}</h1>
      <h6>{{historyState?.description}}</h6>
    </div>
    <button [routerLink]="[historyState?.redirectToUrl]" class="custom-btn custom-btn--primary xs-mt-12">
      <span>{{historyState?.btnText}}</span>
    </button>
  </div>
</div>
