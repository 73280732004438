<div class="wrapper-add-new-conversation">
  <span class="text-add-new-conversation-group" *ngIf="isGroupAccountsOpen">Add user to this group chat</span>
  <div class="wrapper-custom-search">
    <img class="icon-search" src="../../../assets/icons/outline/icon-search.svg" alt="">
    <input type="text" [(ngModel)]="searchTerm" (input)="onSearchChange($event)" placeholder="Search..." />
    <div (click)="resetSearch()" class="wrapper-delete-search">
      <img src="../../../assets/icons/outline/icon-close.svg" alt="">
    </div>
  </div>
  <div class="wrapper-all-people" [ngClass]="{'group': isGroupAccountsOpen}">
    <div class="wrapper-custom-checkbox" #userListContainer (scroll)="onScroll()" style="overflow-y: auto;">
      <label *ngFor="let user of users">
        <input type="checkbox" [checked]="isSelected(user.id)" (change)="toggleSelection(user.id)" />
        <div class="user-item-list">
          <div class="user-item-list-left">
            <div class="wrapper-avatar-item">
              <img
                src="../../../../../assets/images/avatar-user.png"
                alt="">
            </div>
            <div class="wrapper-name-message">
              <div class="wrapper-name">
                <span class="length-character">{{ user.name }}</span>
                <!--                    <img src="../../../assets/icons/fill/icon-badge.svg" alt="">-->
              </div>
              <span class="message length-character">{{ user.username }}</span>
            </div>
          </div>
          <div class="user-item-list-right">
            <div class="checkbox-rectangle">
              <div class="checkbox-center">
                <img src="../../../../../assets/icons/outline/icon-check.svg" alt="">
              </div>
            </div>
          </div>
        </div>
      </label>
    </div>
  </div>
  <div class="wrapper-button-add-chat">
    <button class="custom-btn custom-btn--primary" [disabled]="selectedUsersCount() === 0" (click)="!isGroupAccountsOpen ? createChat() : addToGroup()">
      <span *ngIf="selectedUsersCount() > 1; else singleChat">Create Group Chat</span>
      <ng-template #singleChat>
        <span>{{!isGroupAccountsOpen ? 'Create Chat' : '+ Add users'}}</span>
      </ng-template>
    </button>
  </div>
</div>
