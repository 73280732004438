import { NgForOf, NgIf } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { SafeUrlPipe } from 'src/app/pipes/safe-url/safe-url.pipe';
import { ChatHttpService } from 'src/app/services/http-services/chat/chat-http.service';
import { PreviewMediaComponent } from '../preview-media/preview-media.component';
import { ChatHelperService } from 'src/app/services/helpers/chat-helper.service';

@Component({
  selector: 'app-chat-info-media',
  standalone: true,
  imports: [
    NgForOf,
    SafeUrlPipe,
    PreviewMediaComponent,
    NgIf
  ],
  templateUrl: './chat-info-media.component.html',
})
export class ChatInfoMediaComponent implements OnChanges {
  @Input() conversationId: number = 0;
  @Input() chatType: string = 'single';
  pageNum = 1;
  mediaFiles: any[] = [];
  pagination: Partial<TlPagination> = {};
  selectedMedia: any;

  constructor(
    private chatsHttpService: ChatHttpService,
    private chatHelperService: ChatHelperService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['conversationId'].currentValue) {
      this.getMedia();
    }
  }

  getMedia() {
    this.chatsHttpService.formattedGetSingleConversationMedia(this.conversationId, this.pageNum).then((response: any) => {
      console.log('single conversation media', response);
      this.mediaFiles = [...this.mediaFiles, ...response.data];
      this.pagination = response.pagination;
    });
  }

  openMedia(media: any) {
    this.selectedMedia = media;
  }

  download(selectedMedia: any) {
    let mediaId;
    if (selectedMedia.type === 'image') {
      mediaId = selectedMedia.image.id;
    } else if (selectedMedia.type === 'video') {
      mediaId = selectedMedia.video.id;
    } else {
      mediaId = selectedMedia.attachment.id;
    }
    this.chatsHttpService.downloadMedia(this.conversationId, mediaId).subscribe((blob: any) => {
      const timestamp = new Date().getTime();
      let fileName = `downloaded_media_${timestamp}`;
      if (blob.type === 'image/jpeg') {
        fileName = `image_${timestamp}.jpg`;
      } else if (blob.type === 'video/mp4') {
        fileName = `video_${timestamp}.mp4`;
      } else if (blob.type === 'application/pdf') {
        fileName = `document_${timestamp}.pdf`;
      }
      this.chatHelperService.downloadFile(blob, fileName);
    });
  }

}
