import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isSameMinutePipe',
  standalone: true
})
export class IsSameMinutePipe implements PipeTransform {

  transform(currentTimestamp: string, previousTimestamp: string): boolean {
    if (!previousTimestamp) return true; // Show timestamp for the first message

    const date1 = new Date(currentTimestamp);
    const date2 = new Date(previousTimestamp);

    if (isNaN(date1.getTime()) || isNaN(date2.getTime())) {
      return false;
    }

    // Compare year, month, day, hour, and minute
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate() &&
      date1.getHours() === date2.getHours() &&
      date1.getMinutes() === date2.getMinutes()
    );
  }

}
