<div class="wrapper-page">
  <app-my-content></app-my-content>
  <div class="wrapper-home-page">
    <tl-header></tl-header>
    <div class="wrapper-following-followers">
      <div class="wrapper-custom-tabs">
        <div class="custom-tab" [ngClass]="{'active': tabSelected === 'friends'}" (click)="selectTab('friends')">
          <span>{{title === 'following' ? profileData?.followings_friends_count : profileData?.followers_friends_count}} {{profileData?.followers_friends_count > 1 || profileData?.followings_friends_count > 1 ? 'Friends' : 'Friend'}}</span>
        </div>
        <div class="custom-tab" [ngClass]="{'active': tabSelected === 'experts'}" (click)="selectTab('experts')">
          <span>{{title === 'following' ? profileData?.followings_experts_count : profileData?.followers_experts_count}} {{profileData?.followers_experts_count > 1 || profileData?.followings_experts_count > 1 ? 'Experts' : 'Expert'}}</span>
        </div>
      </div>
      <div class="wrapper-custom-search">
        <img class="icon-search" src="../../../assets/icons/outline/icon-search.svg" alt="">
        <input type="text" placeholder="Search..." [(ngModel)]="searchTerm" (input)="onSearchChange($event)">
        <div class="wrapper-delete-search">
          <img src="../../../assets/icons/outline/icon-close.svg" alt="">
        </div>
      </div>
      <div class="wrapper-list-users-ff">
        <!-- ispod ide klasa expert -->
        <div *ngFor="let user of users" class="wrapper-user-item" [ngClass]="{'expert': user.is_expert}">
          <div [routerLink]="[PAGES_AND_PATHS.profile.pagePath, user.id]" class="wrapper-info-user-ff">
            <div class="avatar-user">
              <img [src]="user.avatar ? user.avatar : '../../../../assets/images/avatar-user.png'" alt="">
            </div>
            <span>{{user.name}}</span>
            <img *ngIf="user.is_expert" src="../../../../assets/icons/fill/icon-badge.svg" alt="">
          </div>
          <button *ngIf="title === 'followers' && profileData?.id === loggedUser.id" (click)="openRemoveModal(user)" class="custom-btn custom-btn--outline">
            <span>Remove</span>
          </button>
          <button *ngIf="title === 'followers' && profileData?.id !== loggedUser.id" (click)="followUnfollow(user.id)"  class="custom-btn" [ngClass]="user.am_i_following_this_user ? 'custom-btn--outline' : 'custom-btn--primary'">
            <span>{{user.am_i_following_this_user ? 'Following' : 'Follow'}}</span>
          </button>
          <button *ngIf="title === 'following'" (click)="followUnfollow(user.id)" class="custom-btn" [ngClass]="user.am_i_following_this_user ? 'custom-btn--outline' : 'custom-btn--primary'">
            <span>{{user.am_i_following_this_user ? 'Unfollow' : 'Follow'}}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<tl-confirm-modal *ngIf="isRemoveUserModalOpen" [modalIcon]="'../../../../../assets/icons/outline/icon-delete.svg'"
  [modalTitle]="removeModalText.title" [confirmBtnText]="removeModalText.button"
  [modalDescription]="removeModalText.description" (cancelModal)="isRemoveUserModalOpen = false"
  (confirmModal)="takeAction()">
</tl-confirm-modal>