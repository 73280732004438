import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {PAGES_AND_PATHS} from "../../constants/pages-and-paths";
import { Location } from '@angular/common';
import {AuthenticationHelperService} from "../../services/helpers/authentication-helper.service";
import {UiHelperService} from "../../services/helpers/ui-helper.service";

@Component({
  selector: 'tl-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  @Input() isEditProfile = false;
  @Output() clickOnBackArrow: EventEmitter<void> = new EventEmitter<void>();
  isDropdownOpened = false;
  @ViewChild('dropdownBtn') dropdownBtn: ElementRef | undefined;
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;

  constructor(private location: Location,
              private renderer: Renderer2,
              private uiHelperService: UiHelperService,
              private authHelperService: AuthenticationHelperService) {
  }

  ngOnInit(): void {
    this.listenToClickDropdown();
  }

  listenToClickDropdown(): void {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.uiHelperService.checkIfClickedOutsideOfTheElement(this.dropdownBtn, this.dropdownBtn, e)) {
        setTimeout(() => {
          this.isDropdownOpened = false;
        },300);
      }
    });
  }

  goBack(): void {
    this.isEditProfile ? this.clickOnBackArrow.emit() : this.location.back();
  }

  logout(): void {
    this.authHelperService.clientLogout(PAGES_AND_PATHS.login.pagePath);
  }
}
