<div class="wrapper-page">
  <app-my-content></app-my-content>
  <div class="wrapper-lists-page">
    <tl-header></tl-header>
    <div class="wrapper-content-lists-page">
      <span class="title-lists-page">Lists</span>
      <div class="wrapper-lists-content">
        <div [routerLink]="PAGES_AND_PATHS.myLists.pagePath" class="wrapper-list-item">
          <div class="image-name-item">
            <img src="../../../assets/icons/outline/icon-my-lists.svg" alt="">
            <span>My lists</span>
          </div>
          <img class="icon-right" src="../../../assets/icons/icon-arrow-left.svg" alt="">
        </div>
        <div [routerLink]="PAGES_AND_PATHS.favoriteLists.pagePath" class="wrapper-list-item">
          <div class="image-name-item">
            <img src="../../../assets/icons/outline/icon-my-lists.svg" alt="">
            <span>Favorite lists</span>
          </div>
          <img class="icon-right" src="../../../assets/icons/icon-arrow-left.svg" alt="">
        </div>
        <div [routerLink]="PAGES_AND_PATHS.favoriteItems.pagePath" class="wrapper-list-item">
          <div class="image-name-item">
            <img src="../../../assets/icons/outline/icon-my-lists.svg" alt="">
            <span>Favorite items</span>
          </div>
          <img class="icon-right" src="../../../assets/icons/icon-arrow-left.svg" alt="">
        </div>
        <div [routerLink]="PAGES_AND_PATHS.favoriteListers.pagePath" class="wrapper-list-item">
          <div class="image-name-item">
            <img src="../../../assets/icons/outline/icon-my-lists.svg" alt="">
            <span>Favorite listers</span>
          </div>
          <img class="icon-right" src="../../../assets/icons/icon-arrow-left.svg" alt="">
        </div>
        <div [routerLink]="PAGES_AND_PATHS.followedListers.pagePath" class="wrapper-list-item">
          <div class="image-name-item">
            <img src="../../../assets/icons/outline/icon-my-lists.svg" alt="">
            <span>Followed listers</span>
          </div>
          <img class="icon-right" src="../../../assets/icons/icon-arrow-left.svg" alt="">
        </div>
        <div [routerLink]="PAGES_AND_PATHS.recommendedLists.pagePath" class="wrapper-list-item">
          <div class="image-name-item">
            <img src="../../../assets/icons/outline/icon-my-lists.svg" alt="">
            <span>Recommended lists</span>
          </div>
          <img class="icon-right" src="../../../assets/icons/icon-arrow-left.svg" alt="">
        </div>
        <div [routerLink]="PAGES_AND_PATHS.recommendedListers.pagePath" class="wrapper-list-item">
          <div class="image-name-item">
            <img src="../../../assets/icons/outline/icon-my-lists.svg" alt="">
            <span>Recommended listers</span>
          </div>
          <img class="icon-right" src="../../../assets/icons/icon-arrow-left.svg" alt="">
        </div>
      </div>
    </div>
  </div>
</div>