import { Component } from '@angular/core';
import {Following} from "../../../services/http-services/followers/followers-interface";
import {TlPagination} from "../../../interfaces/general-interfaces";
import {UserRoles} from "../../../enums/general-enums";
import {FollowersHttpService} from "../../../services/http-services/followers/followers-http.service";
import {ActivatedRoute} from "@angular/router";
import {PostsHttpService} from "../../../services/http-services/posts/posts-http.service";
import {AuthenticationHelperService} from "../../../services/helpers/authentication-helper.service";
import {PAGES_AND_PATHS} from "../../../constants/pages-and-paths";

@Component({
  selector: 'tl-post-likes',
  templateUrl: './post-likes.component.html'
})
export class PostLikesComponent {
  likesData: any[] = [];
  likesPagination: TlPagination | undefined;
  urlParams = {page: 1, search_keyword: '', postId: null};
  protected readonly UserRoles = UserRoles;
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;
  constructor(private followersHttpService: FollowersHttpService,
              private activatedRoute: ActivatedRoute,
              private postsHttpService: PostsHttpService,
              public authHelperService: AuthenticationHelperService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(res => {
      this.urlParams.postId = res['postId'];
      this.likesPagination = undefined;
      this.likesData = [];
      this.getPostLikeData();
    })
  }

  getPostLikeData(isSearchChange = false): void {
    this.urlParams.page = this.likesPagination?.currentPage ? this.likesPagination.currentPage + 1 : 1;
    this.postsHttpService.getPostLikesData(this.urlParams).then(res => {
      this.likesData = isSearchChange ? res.data : [...this.likesData, ...res.data];
      this.likesPagination = res.pagination;
    })
  }

  followUnfollow(user: Following) {
    this.followersHttpService.followUnfollowUser(user.id).subscribe((response: any) => {
      const userSelected = this.likesData.findIndex(like => like.user_id === response.data.id);
      this.likesData[userSelected].liker.am_i_following_this_user = response.data.am_i_following_this_user;
    })
  }

  onSearchChange(event: CustomEvent): void {
    this.likesPagination = undefined;
    this.urlParams.search_keyword = event.detail.value;
    this.getPostLikeData(true);
  }

  onScroll(event: any): void {
    const threshold = 1;
    const position = event.target.scrollTop + event.target.offsetHeight;
    const height = event.target.scrollHeight;
    if (position > height - threshold && (this.likesPagination?.currentPage ?? 0) < (this.likesPagination?.lastPage ?? 0)) {
      this.getPostLikeData();
    }
  }
}
