import { NgClass, NgForOf, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { TimeAgoModule } from 'src/app/pipes/time-ago/time-ago.module';
import { ListsHelperService } from 'src/app/services/helpers/lists-helper.service';
import { DeleteModalText } from 'src/app/services/http-services/lists/lists-http-intterfaces';
import { ListsHttpService } from 'src/app/services/http-services/lists/lists-http.service';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { AuthenticationHelperService } from 'src/app/services/helpers/authentication-helper.service';
import { ItemsHttpService } from 'src/app/services/http-services/items/items-http.service';
import { ToastrService } from 'ngx-toastr';
import { StarsComponent } from '../stars/stars.component';
import { ShareEntitiesEnums } from 'src/app/enums/chat-enums';
import { SharingOptionsModalComponent } from 'src/app/pages/my-profile/components/sharing-options-modal/sharing-options-modal.component';

@Component({
  selector: 'app-reviews',
  standalone: true,
  imports: [
    NgForOf,
    RouterModule,
    NgIf,
    TimeAgoModule,
    ConfirmModalComponent,
    StarsComponent,
    SharingOptionsModalComponent,
    NgClass
  ],
  templateUrl: './reviews.component.html',
})
export class ReviewsComponent implements OnInit, OnChanges {
  @Input() reviewObj: any;
  @Input() isList = false;
  @Output() refreshSinglePage = new EventEmitter<boolean>();
  pageNum = 1;
  reviews: any[] = [];
  pagination!: TlPagination;
  PAGES_AND_PATHS = PAGES_AND_PATHS;
  isDropdownOpen = false;
  selectedReview: any;
  isDeleteModalOpen = false;
  modalText: DeleteModalText = { title: '', description: '', button: '' };
  loggedUser: any;
  isSharePostModalOpen = false;
  ShareEntitiesEnums = ShareEntitiesEnums;
  reviewUrl = ''

  constructor(
    private listsHttpService: ListsHttpService,
    private router: Router,
    public listsHelperService: ListsHelperService,
    private authenticationHelperService: AuthenticationHelperService,
    private itemsHttpService: ItemsHttpService,
    private toastService: ToastrService
  ) { }

  ngOnInit(): void {
    this.loggedUser = this.authenticationHelperService.getUserData;
    // if (this.reviewObj) {
    //   this.isList ? this.getListReviews() : this.getItemReviews();
    // }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.reviewObj.currentValue) {
      this.isList ? this.getListReviews() : this.getItemReviews();
    }
  }

  getListReviews() {
    console.log(this.reviewObj)
    this.listsHttpService.getListReviews(this.reviewObj.id, this.pageNum).then((res: { data: any[], pagination: TlPagination }) => {
      console.log('getListReviews', res)
      this.reviews = res.data;
      this.pagination = res.pagination;
    });
  }

  getItemReviews() {
    this.listsHttpService.getItemReviews(this.reviewObj.id, this.pageNum).then((res: { data: any[], pagination: TlPagination }) => {
      console.log('getPostReviews', res)
      this.reviews = res.data;
      this.pagination = res.pagination;
    });
  }

  openCreateReviewPage(): void {
    if (this.loggedUser.isExpert) {
      const reviewDetails = {
        obj: this.reviewObj
      }
      this.router.navigate([PAGES_AND_PATHS.createReview.pagePath, this.isList ? 'list' : 'item'], { state: reviewDetails })
    }
  }

  openViewMore(review: any, event: any): void {
    event.stopPropagation();
    this.isDropdownOpen = !this.isDropdownOpen;
    this.selectedReview = review;
  }

  openEditReview(review: any) {
    const reviewDetails = {
      obj: review
    }
    this.router.navigate([PAGES_AND_PATHS.updateReview.pagePath, this.isList ? 'list' : 'item'], { state: reviewDetails })
  }

  openDeleteModal(review: any) {
    this.selectedReview = review;
    this.isDeleteModalOpen = true;
    this.modalText.title = 'Delete review?';
    this.modalText.description = 'Are you sure you wish to delete this review? This action cannot be undone.'
    this.modalText.button = 'Delete';
  }

  takeAction(): void {
    this.isList ? this.deleteListReview() : this.deleteItemReview();
  }

  deleteItemReview(): void {
    this.isDeleteModalOpen = false;
    this.itemsHttpService.deleteItemReview(this.selectedReview.id).then((response: any) => {
      this.toastService.success(response.message);
      const index = this.reviews.findIndex(obj => obj.id === this.selectedReview.id);
      this.reviews.splice(index, 1);
      this.refreshSinglePage.emit(true);
    })
  }

  deleteListReview(): void {
    this.isDeleteModalOpen = false;
    this.itemsHttpService.deleteListReview(this.selectedReview.id).then((response: any) => {
      this.toastService.success(response.message);
      const index = this.reviews.findIndex(obj => obj.id === this.selectedReview.id);
      this.reviews.splice(index, 1);
      this.refreshSinglePage.emit(true);
    })
  }

  shareReview(review: any): void {
    this.selectedReview = review;
    this.isSharePostModalOpen = true;
  }

  toggleLike(review: any): void {
    this.itemsHttpService.addReviewToFavorites(review.id).then((response: any) => {
      const index = this.reviews.findIndex(objRev => objRev.id === review.id);
      this.reviews[index] = response.data;
    })
  }

}
