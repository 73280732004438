import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GeneralHttpHelperService } from '../../helpers/general-http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class ItemsHttpService {

  constructor(
    private httpClient: HttpClient,
    private generalHelperService: GeneralHttpHelperService
  ) { }

  private getMasterItemsReq(pageNumber: number, itemId: number, searchTerm?: string): Observable<any> {
    let url = 'list_of_items';
    let params = [];
    if (searchTerm) {
      params.push(`search_keyword=${encodeURIComponent(searchTerm)}`);
    }
    if (pageNumber) {
      params.push(`page=${pageNumber}`);
    }
    if (itemId) {
      params.push(`item_id=${itemId}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get(url) as any;
  }
  getMasterItems(pageNumber: number, itemId: number, searchTerm?: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getMasterItemsReq(pageNumber, itemId, searchTerm).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.data,
            pagination: this.generalHelperService.returnPagination(res.data)
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private createNewItemReq(reqBody: { lyst_id: number }): Observable<any> {
    return this.httpClient.post(`items`, reqBody);
  }
  createNewItem(reqBody: { lyst_id: number }): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.createNewItemReq(reqBody).subscribe({
        next: (res: any) => {
          resolve(res);
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private updateItemReq(itemId: number, reqBody: any): Observable<any> {
    return this.httpClient.patch(`items/${itemId}/update`, reqBody);
  }
  updateItem(itemId: number, reqBody: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.updateItemReq(itemId, reqBody).subscribe({
        next: (res: any) => {
          resolve(res);
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private addImageToItemReq(listIdd: number, reqBody: any): Observable<any> {
    return this.httpClient.post(`items/${listIdd}/images`, reqBody);
  }
  addImageToItem(listIdd: number, reqBody: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.addImageToItemReq(listIdd, reqBody).subscribe({
        next: (res: any) => {
          resolve(res);
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private deleteImageFromItemReq(listIdd: number, imageId: number): Observable<any> {
    return this.httpClient.delete(`items/${listIdd}/images/${imageId}/delete`);
  }
  deleteImageFromItem(listIdd: number, reqBody: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.deleteImageFromItemReq(listIdd, reqBody).subscribe({
        next: (res: any) => {
          resolve(res);
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private getFavoritesLikesReq(itemID: number, pageNum: number): Observable<any> {
    let url = `favorites/lyst_items/${itemID}`;
    let params = [];
    if (pageNum) {
      params.push(`page=${pageNum}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get(url);
  }
  getFavoritesLikes(userId: number, pageNum: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getFavoritesLikesReq(userId, pageNum).subscribe({
        next: (res: any) => {
          resolve(res);
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private addItemToFavoritesReq(itemID: number): Observable<any> {
    return this.httpClient.post(`favorites/lyst_items/${itemID}/toggle`, {}) as any;
  }
  addItemToFavorites(userId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.addItemToFavoritesReq(userId).subscribe({
        next: (res: any) => {
          resolve(res);
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  deleteItem(itemID: number) {
    return this.httpClient.delete(`items/${itemID}/delete`);
  }

  reportItem(itemID: number, body: any) {
    return this.httpClient.post(`reports/lyst_items/${itemID}`, body);
  }

  private createListReviewReq(listId: number, reqObj: any): Observable<any> {
    return this.httpClient.post(`reviews/lysts/${listId}`, reqObj) as any;
  }
  createListReview(listId: number, reqObj: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.createListReviewReq(listId, reqObj).subscribe({
        next: (res: any) => {
          resolve(res);
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private updateListReviewReq(listId: number, reqObj: any): Observable<any> {
    return this.httpClient.put(`reviews/lysts/${listId}`, reqObj) as any;
  }
  updateListReview(listId: number, reqObj: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.updateListReviewReq(listId, reqObj).subscribe({
        next: (res: any) => {
          resolve(res);
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private deleteListReviewReq(reviewId: number): Observable<any> {
    return this.httpClient.delete(`reviews/lysts/${reviewId}`) as any;
  }
  deleteListReview(reviewId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.deleteListReviewReq(reviewId).subscribe({
        next: (res: any) => {
          resolve(res);
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }


  private createItemReviewReq(itemId: number, reqObj: any): Observable<any> {
    return this.httpClient.post(`reviews/lyst_items/${itemId}`, reqObj) as any;
  }
  createItemReview(itemId: number, reqObj: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.createItemReviewReq(itemId, reqObj).subscribe({
        next: (res: any) => {
          resolve(res);
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private updateItemReviewReq(itemId: number, reqObj: any): Observable<any> {
    return this.httpClient.put(`reviews/lyst_items/${itemId}`, reqObj) as any;
  }
  updateItemReview(itemId: number, reqObj: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.updateItemReviewReq(itemId, reqObj).subscribe({
        next: (res: any) => {
          resolve(res);
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private deleteItemReviewReq(reviewId: number): Observable<any> {
    return this.httpClient.delete(`reviews/lyst_items/${reviewId}`) as any;
  }
  deleteItemReview(reviewId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.deleteItemReviewReq(reviewId).subscribe({
        next: (res: any) => {
          resolve(res);
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private addReviewToFavoritesReq(review_id: number): Observable<any> {
    return this.httpClient.post(`likes/reviews/${review_id}/toggle`, {}) as any;
  }
  addReviewToFavorites(review_id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.addReviewToFavoritesReq(review_id).subscribe({
        next: (res: any) => {
          resolve(res);
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private addCommentToFavoritesReq(comment_id: number): Observable<any> {
    return this.httpClient.post(`likes/comments/${comment_id}/toggle`, {}) as any;
  }
  addCommentToFavorites(comment_id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.addCommentToFavoritesReq(comment_id).subscribe({
        next: (res: any) => {
          resolve(res);
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private getSingleListReviewReq(review_id: number, listId: number): Observable<any> {
    return this.httpClient.get(`reviews/lysts/${listId}/reviews/${review_id}`, {}) as any;
  }
  getSingleListReview(review_id: number, listId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getSingleListReviewReq(review_id, listId).subscribe({
        next: (res: any) => {
          resolve(res);
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private getSingleItemReviewReq(review_id: number, itemId: number): Observable<any> {
    return this.httpClient.get(`reviews/lyst_items/${itemId}/reviews/${review_id}`, {}) as any;
  }
  getSingleItemReview(review_id: number, itemId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getSingleItemReviewReq(review_id, itemId).subscribe({
        next: (res: any) => {
          resolve(res);
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private storeReviewCommentReq(review_id: number, reqBody: {comment: string}): Observable<any> {
    return this.httpClient.post(`comments/reviews/${review_id}`, reqBody) as any;
  }
  storeReviewComment(review_id: number, reqBody: {comment: string}): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.storeReviewCommentReq(review_id, reqBody).subscribe({
        next: (res: any) => {
          resolve(res);
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private getItemReviewLikesReq(review_id: number, pageNum: number): Observable<any> {
    let url = `likes/reviews/${review_id}`;
    let params = [];
    if (pageNum) {
      params.push(`page=${pageNum}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get(url);
  }
  getItemReviewLikes(review_id: number, pageNum: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getItemReviewLikesReq(review_id, pageNum).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.data,
            pagination: this.generalHelperService.returnPagination(res.data)
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }


}
