import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-lister',
  standalone: true,
  imports: [],
  templateUrl: './lister.component.html',
})
export class ListerComponent {
  @Input() lister: any;

}
