<div class="wrapper-page">
  <app-my-content></app-my-content>
  <div class="wrapper-likes-page">
    <tl-header></tl-header>
    <div class="likes-page">
      <div class="header-likes-page">
        <span class="title">Likes</span>
        <div class="number-likes">
          <span>{{likesData?.length}}</span>
        </div>
      </div>
      <div class="content-likes-page" (scroll)="onScroll($event)">
        <div *ngFor="let like of likesData" class="wrapper-user-liked">
          <div class="user-like-info">
            <img class="avatar-user"
                 *ngIf="!like.liker?.profile_photo_url"
                 [routerLink]="[PAGES_AND_PATHS.profile.pagePath, like.liker?.id]"
                 [src]="like.liker.is_expert ? './assets/images/avatar-user-expert.png' : './assets/images/avatar-user.png'" alt="">
            <img class="avatar-user"
                 *ngIf="like.liker?.profile_photo_url"
                 [routerLink]="[PAGES_AND_PATHS.profile.pagePath, like.liker?.id]"
                 [src]="like.liker?.profile_photo_url" alt="">

            <span>{{like.liker.name}}</span>
            <img *ngIf="like.liker.is_expert" class="badge" src="../../../assets/icons/fill/icon-badge.svg" alt="">
          </div>
          <button *ngIf="like.user_id !== authHelperService.getUserData.id" (click)="followUnfollow(like)" class="custom-btn custom-btn--outline" [ngClass]="like.liker.am_i_following_this_user ? 'tl-btn--outline' : 'tl-btn--primary'">
            <span>{{like.liker.am_i_following_this_user ? 'Following' : 'Follow'}}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
