<swiper-container #swiper>
  <swiper-slide class="wrapper-onboarding">
    <div class="wrapper-image-onboarding">
      <img src="../assets/images/onboarding-image1.png" alt="">
      <div class="wrapper-steps-btn">
        <div class="step-btn active"></div>
        <div class="step-btn" (click)="goToSlide(1)"></div>
        <div class="step-btn" (click)="goToSlide(2)"></div>
      </div>
      <div class="wrapper-onboarding-text">
        <h1>Create your own lysts with your favorite items</h1>
        <h6>Seamlessly add entries to your lysts and items with our Mobile App, and remember: "Lyst it, Live it."</h6>
      </div>
    </div>
  </swiper-slide>
  <swiper-slide class="wrapper-onboarding">
    <div class="wrapper-image-onboarding">
      <img src="../assets/images/onboarding-image2.png" alt="">
      <div class="wrapper-steps-btn">
        <div class="step-btn" (click)="goToSlide(0)"></div>
        <div class="step-btn active"></div>
        <div class="step-btn" (click)="goToSlide(2)"></div>
      </div>
      <div class="wrapper-onboarding-text">
        <h1>Follow your favorite lysters</h1>
        <h6>Follow TheLysts Experts or discover and connect with your friends—you never know what exciting lysts they might have!</h6>
      </div>
    </div>
  </swiper-slide>
  <swiper-slide class="wrapper-onboarding">
    <div class="wrapper-image-onboarding">
      <img src="../assets/images/onboarding-image3.png" alt="">
      <div class="wrapper-steps-btn">
        <div class="step-btn" (click)="goToSlide(0)"></div>
        <div class="step-btn" (click)="goToSlide(1)"></div>
        <div class="step-btn active"></div>
      </div>
      <div class="wrapper-onboarding-text">
        <h1>Discover many more interesting lysts</h1>
        <h6>TheLysts universe is expansive and constantly evolving—explore your favorite topics and lysts all in one place.</h6>
      </div>
    </div>
  </swiper-slide>
</swiper-container>
