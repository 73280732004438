import { NgForOf, NgIf, SlicePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SingleItem } from 'src/app/services/http-services/items/items-interfaces';

@Component({
  selector: 'app-info',
  standalone: true,
  imports: [NgForOf, SlicePipe, NgIf],
  templateUrl: './info.component.html',
})
export class InfoComponent {
  @Input() item!: SingleItem;

  getAttributeValue(attributes: any[], lyst_attribute_id: number | undefined): string {
    const attribute = attributes.find(attr => attr.lyst_attribute_id === lyst_attribute_id);
    if (attribute?.type === 'url' || attribute?.type === 'phone' || attribute?.type === 'email') {
      if (attribute?.type === 'phone') {
        return `<a href="tel:${attribute.value}">${attribute.value}</a>`
      } else if (attribute?.type === 'email') {
        return `<a href="mailto:${attribute.value}">${attribute.value}</a>`
      } else {
        return `<a href="${attribute.value}">${attribute.value}</a>`
      }
    } else {
      return attribute && attribute.value ? attribute.value : ''
    }
  }

}
