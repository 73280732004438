<div class="wrapper-posts-tab">
  <!-- TODO JS - Klikom na ovaj div vodis ga na kreiranje posta -->
  <div *ngIf="!myPosts" (click)="routeToCreatePost()" class="wrapper-card-create-post">
    <div class="wrapper-left-card-create-post">
      <img src="../../../../../assets/images/avatar-user.png" alt="">
      <span>What’s on your mind...</span>
    </div>
    <img src="../../../../../assets/icons/outline/icon-tag.svg" alt="">
  </div>
  <!-- TODO JS - Jedna slika -->
  <!-- [routerLink]="[PAGES_AND_PATHS.singlePost.pagePath, post.id]" -->
  <div *ngFor="let post of posts" class="wrapper-card-post">
    <div class="header-card-post">
      <div class="user-card-post">
        <img *ngIf="!post?.user?.profile_photo_url"
          [src]="post?.user?.is_expert ? '../../../assets/images/avatar-user-expert.png' : '../../../assets/images/avatar-user.png'"
          alt="">
        <div class="wrapper-user-card-info-post">
          <div [routerLink]="[PAGES_AND_PATHS.profile.pagePath, post?.user_id]" class="user-name">
            <span>{{post?.user?.name}}</span>
            <img *ngIf="post?.user?.is_expert" src="../../../assets/icons/fill/icon-badge.svg" alt="">
          </div>
          <span>{{post.created_at | timeAgo}}</span>
        </div>
      </div>
      <div class="wrapper-dropdown-btn" appClickedOutside (clickedOutside)="isViewMoreModalOpen= false;">
        <div (click)="openDropdown($event, post)" class="view-more-post">
          <img src="../../../../assets/icons/outline/icon-view-more.svg" alt="">
        </div>
        <div *ngIf="isViewMoreModalOpen && selectedPost.id === post.id" class="content-dropdown-btn">
          <div [routerLink]="[PAGES_AND_PATHS.singlePost.pagePath, post.id]" class="item-dropdown">
            <img src="../../../../assets/icons/outline/icon-edit.svg" alt="">
            <span>Edit post</span>
          </div>
          <div (click)="sharePost(post)" class="item-dropdown">
            <img src="../../../../assets/icons/outline/icon-share.svg" alt="">
            <span>Share</span>
          </div>
          <div class="item-dropdown delete">
            <img src="../../../../assets/icons/outline/icon-outline-attention.svg" alt="">
            <span>Report</span>
          </div>
          <div (click)="openDeleteModal(post)" class="item-dropdown delete">
            <img src="../../../../assets/icons/outline/icon-delete.svg" alt="">
            <span>Delete post</span>
          </div>
        </div>
      </div>
    </div>
    <div [routerLink]="[PAGES_AND_PATHS.singlePost.pagePath, post.id]" class="content-card-post">
      <p class="description">
        {{post.content | handleShowMore: 100: isShowMore}}
        <span (click)="$event.stopPropagation(); isShowMore = !isShowMore"
          *ngIf="post.content.length > 100">{{isShowMore ? 'less' : 'more'}}</span>
      </p>
      <div *ngIf="post.lyst !== null || post.lyst_item !== null" class="wrapper-image-tagged">
        <img *ngIf="post.lyst?.lyst_image_url" [src]="post.lyst?.lyst_image_url" [alt]="post.lyst?.title + '-image'">
        <img *ngIf="post.lyst?.lyst_item_image_url" [src]="post.lyst_item?.lyst_item_image_url"
          [alt]="post.lyst_item?.name  + '-image'">
      </div>
      <div *ngIf="post.lyst !== null || post.lyst_item !== null" class="wrapper-tagged-list-item">
        <div class="wrapper-tags">
          <div *ngIf="post.lyst !== null" class="wrapper-tag list">
            <img src="../../../../../assets/icons/outline/icon-tag.svg" alt="">
            <span>{{post.lyst?.title}}</span>
          </div>
          <div *ngIf="post.lyst_item !== null" class="wrapper-tag item">
            <img src="../../../../../assets/icons/outline/icon-tag.svg" alt="">
            <span>{{post.lyst_item?.name}}</span>
          </div>
        </div>
      </div>
      <div class="wrapper-toolbar-post">
        <div class="wrapper-toolbar-left">
          <span [routerLink]="[PAGES_AND_PATHS.postLikes.pagePath, post.id]">{{post.likes_count}} {{post.likes_count ===
            1 ? 'like' : 'likes'}}</span>
          <span [routerLink]="[PAGES_AND_PATHS.singlePost.pagePath, post.id]"> {{post.comments_count}}
            {{post.comments_count === 1 ? 'comment' : 'comments'}}</span>
        </div>
        <div class="wrapper-toolbar-right">
          <div class="wrapper-like" [ngClass]="{'liked': post.am_i_liked_this}" (click)="togglePostLike($event, post)">
            <img class="no-like" src="../../../../../assets/icons/outline/icon-like.svg" alt="">
            <img class="like" src="../../../../../assets/icons/fill/icon-like.svg" alt="">
            <span>Like</span>
          </div>
          <div class="wrapper-share" (click)="sharePost(post);$event.stopPropagation()">
            <img src="../../../../../assets/icons/outline/icon-share.svg" alt="">
            <span>Share</span>
          </div>
        </div>
      </div>
      <div [routerLink]="[PAGES_AND_PATHS.singlePost.pagePath, post.id]" class="wrapper-write-comment">
        <div class="avatar-comment">
          <img src="../../../../../assets/images/avatar-user.png" alt="">
        </div>
        <div class="wrapper-input">
          <div class="custom-input">
            <input disabled type="text" placeholder="Write a comment...">
          </div>
        </div>
        <button class="custom-btn custom-btn--circle-primary">
          <img src="../../../../../assets/icons/outline/icon-send.svg" alt="">
        </button>
      </div>
    </div>
  </div>
</div>
<button *ngIf="myPosts" [routerLink]="[PAGES_AND_PATHS.profile.pagePath, loggedUser.id]"
  class="custom-btn custom-btn--gray">
  <span>View my posts</span>
</button>
<tl-sharing-options-modal *ngIf="isSharePostModalOpen" [entityType]="ShareEntitiesEnums.post"
  [entityId]="selectedPost.id" [urlForShare]="postUrl"
  (closeModal)="isSharePostModalOpen = false"></tl-sharing-options-modal>
<tl-confirm-modal *ngIf="isDeleteModalOpen" [modalTitle]="'Delete post?'"
  [modalDescription]="'Are you sure you want to delete this post?'" (cancelModal)="isDeleteModalOpen = false"
  (confirmModal)="onConfirmPostDelete()"></tl-confirm-modal>