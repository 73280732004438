import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightFoundSearchTerm',
  standalone: true
})
export class HighlightSearchTermPipe implements PipeTransform {

  transform(value: string, search: string): string {
    if (!search || !value) {
      return value;
    }

    const searchPattern = search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    const regex = new RegExp(searchPattern, 'gi');

    return value.replace(regex, (match) => `<b>${match}</b>`);
  }

}
