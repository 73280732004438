import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PAGES_AND_PATHS} from '../../../constants/pages-and-paths';
import {AuthGuardService} from '../../../guards/auth-guard.service';
import {CreateListComponent} from './create-list.component';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.createList.pageInRouting,
    data: { data: PAGES_AND_PATHS.createList },
    component: CreateListComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./create-list.module').then(m => m.CreateListModule)
  },
  {
    path: PAGES_AND_PATHS.updateList.pageInRouting,
    data: { data: PAGES_AND_PATHS.updateList },
    component: CreateListComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./create-list.module').then(m => m.CreateListModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CreateListRoutingModule { }
