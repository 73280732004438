import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';

@Component({
  selector: 'app-copy-toast-notification',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './copy-toast-notification.component.html',
})
export class CopyToastNotificationComponent {
  @Input() selectedListAndItem: any;
  @Output() closeModal = new EventEmitter<boolean>();
  PAGES_AND_PATHS = PAGES_AND_PATHS;

}
