import { UntypedFormGroup } from '@angular/forms';

export function confirmMatchControlsValidator(controlName: string, matchingControlName: string): any{
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors?.['confirmedValidator']) return;

    (control.value !== matchingControl.value)
      ? matchingControl.setErrors({ confirmedValidator: true })
      : matchingControl.setErrors(null);
  };
}
