import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { FollowersHttpService } from 'src/app/services/http-services/followers/followers-http.service';
import { ItemsHttpService } from 'src/app/services/http-services/items/items-http.service';
import { ListsHttpService } from 'src/app/services/http-services/lists/lists-http.service';

@Component({
  selector: 'app-list-likes',
  templateUrl: './list-likes.component.html',
})
export class ListLikesComponent implements OnInit {
  id = 0;
  users: any[] = [];
  PAGES_AND_PATHS = PAGES_AND_PATHS;
  type = '';
  pagination!: TlPagination;
  pageNum = 1;
  @ViewChild('likesContainter', { static: false }) private likesContainter!: ElementRef;
  loading = false;

  constructor(
    private listsHttpService: ListsHttpService,
    private actRoute: ActivatedRoute,
    private followersHttpService: FollowersHttpService,
    private itemsHttpService: ItemsHttpService
  ) { }

  ngOnInit(): void {
    this.actRoute.paramMap.subscribe(params => {
      this.id = Number(params.get('id') || '');
      this.type = params.get('type') as string;
      if (this.type === 'list') {
        this.getListLikes(this.id);
      } else if (this.type === 'item') {
        this.getItemLikes(this.id);
      } else if (this.type === 'itemReview') {
        this.getReviewLikes(this.id);
      } else if (this.type === 'listReview') {
        this.getReviewLikes(this.id);
      }
    });
  }

  getReviewLikes(id: number): void {
    this.loading = true;
    this.itemsHttpService.getItemReviewLikes(id, this.pageNum).then((res: {
      data: any[],
      pagination: TlPagination
    }) => {
      this.users = [...this.users, ...res.data];
      this.pagination = res.pagination;
      this.loading = false;
    })
  }

  getListLikes(id: number) {
    this.loading = true;
    this.listsHttpService.getListLikes(id, this.pageNum).then((res: {
      data: any[],
      pagination: TlPagination
    }) => {
      this.users = [...this.users, ...res.data];
      this.pagination = res.pagination;
      this.loading = false;
    });
  }

  getItemLikes(id: number) {
    this.loading = true;
    this.itemsHttpService.getFavoritesLikes(id, this.pageNum).then((res: {
      data: any[],
      pagination: TlPagination
    }) => {
      this.users = [...this.users, ...res.data];
      this.pagination = res.pagination;
      this.loading = false;
    })
  }

  followUnfollowUser(id: number) {
    this.followersHttpService.followUnfollowUser(id).subscribe((response: any) => {
      let userIndex = this.users?.findIndex((user: any) => user.id === id);
      this.users[userIndex] = response.data;
    })
  }

  onScroll(): void {
    console.log('boop')
    const container = this.likesContainter.nativeElement;
    const scrollTop = container.scrollTop;
    const offset = 100;

    if (!this.loading && scrollTop < offset && !this.loading && ((this.pagination?.currentPage ?? 0) < (this.pagination?.lastPage ?? 0))) {
      this.pageNum++;
      if (this.type === 'list') {
        this.getListLikes(this.id);
      } else if (this.type === 'item') {
        this.getItemLikes(this.id);
      } else if (this.type === 'itemReview') {
        this.getReviewLikes(this.id);
      } else if (this.type === 'listReview') {
        this.getReviewLikes(this.id);
      }
    }
  }


}
