import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {
  ApexAnnotations,
  ApexChart,
  ApexDataLabels, ApexFill, ApexGrid,
  ApexLegend, ApexMarkers,
  ApexNonAxisChartSeries,
  ApexPlotOptions, ApexResponsive, ApexStates, ApexStroke, ApexTooltip, ApexXAxis, ApexYAxis,
  ChartComponent
} from "ng-apexcharts";


export type ChartOptions = {
  series?: ApexNonAxisChartSeries | any;
  chart?: ApexChart | any;
  dataLabels?: ApexDataLabels | any;
  plotOptions?: ApexPlotOptions | any;
  xaxis?: ApexXAxis | any;
  yaxis?: ApexYAxis | any;
  fill?: ApexFill | any;
  tooltip?: ApexTooltip | any;
  stroke?: ApexStroke | any;
  legend?: ApexLegend | any;
  grid?: ApexGrid | any;
  colors?: string[] | any;
  states?: ApexStates | any;
  responsive?: ApexResponsive | any;
  markers?: ApexMarkers | any;
  annotations?: ApexAnnotations | any;
  labels?: string[] | any;
  theme?: any;
  title?: any;
};

@Component({
  selector: 'tl-area-chart',
  templateUrl: './area-chart.component.html',
})

export class AreaChartComponent implements OnChanges {
  @Input() data: any;
  chartData: any = {series: [], labels: []}
  @ViewChild('chartArea') chartArea!: ChartComponent;
  chartOptions: ChartOptions | undefined;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && changes['data'].currentValue) {
      this.chartData.series = [
        {
          name: 'Followers',
          data: Object.values(changes['data'].currentValue)
        }
      ];
      this.chartData.labels = Object.keys(changes['data'].currentValue);
      this.initChart();
    }
  }

  initChart(): void {
    this.chartOptions = {
      series: this.chartData.series,
      chart: {
        type: 'area',
        width: '100%',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      grid: {
        show: true,
        strokeDashArray: 3
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        colors: ['#974C8B'],
        curve: 'smooth',
        width: 2,
      },
      markers: {
        colors: ['#974C8B'],
      },
      tooltip: {
        marker: {
          fillColors: ['#974C8B']
        }
      },
      legend: {
        show: false,
        position: 'top',
        horizontalAlign: 'right',
        markers: {
          fillColors: ['#974C8B'],
        }
      },
      fill: {
        colors: ['#974C8B'],
        type: 'gradient',
        gradient: {
          shadeIntensity: 0.9,
          opacityFrom: 0.85,
          opacityTo: 0,
          stops: [0, 100]
        }
      },
      labels: this.chartData.labels,
      xaxis: {
        type: 'datetime',
        tickAmount: 3,
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        min: new Date(this.chartData.labels[0]).getTime(),
        max: new Date(this.chartData.labels[this.chartData.labels.length - 1]).getTime(),
      },
      yaxis: {
        min: 0,
        labels: {
          formatter(val: number): string {
            return val.toFixed(2);
          }
        }
      }
    };
  }
}
