import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, from } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { AuthenticationHelperService } from 'src/app/services/helpers/authentication-helper.service';
import { FollowersHttpService } from 'src/app/services/http-services/followers/followers-http.service';
import { Following } from 'src/app/services/http-services/followers/followers-interface';
import { ProfileHttpService } from 'src/app/services/http-services/profile/profile-http.service';

@Component({
  selector: 'app-friends-experts',
  templateUrl: './friends-experts.component.html',
})
export class FriendsExpertsComponent implements OnInit {
  tabSelected = '';
  title = '';
  pagination!: TlPagination;
  users: Array<Following> = [];
  private searchSubject = new Subject<string>();
  searchTerm: string = '';
  pageNum = 1;
  isRemoveUserModalOpen = false;
  selectedUser: Following | null = null;
  removeModalText = {
    title: '',
    description: '',
    button: ''
  };
  userId: number = 0;
  profileData: any;
  loggedUser: any;
  PAGES_AND_PATHS = PAGES_AND_PATHS;

  constructor(
    private actRoute: ActivatedRoute,
    private router: Router,
    private followersHttpService: FollowersHttpService,
    private profileHttpService: ProfileHttpService,
    private authenticationHelperService: AuthenticationHelperService,
  ) { }

  ngOnInit() {
    this.loggedUser = this.authenticationHelperService.getUserData;
    this.getParams();
    this.searchSubject.pipe(
      debounceTime(300),
      switchMap((searchTerm: string) => {
        if (this.title === 'followers') {
          return this.tabSelected === 'experts' ? this.getFollowersExperts(searchTerm) : this.getFollowersFriends(searchTerm);
        } else {
          return this.tabSelected === 'experts' ? this.getFollowingExperts(searchTerm) : this.getFollowingFriends(searchTerm);
        }
      })
    ).subscribe(response => {
      this.users = response.data;
      this.pagination = response.pagination;
    });
  }

  getParams() {
    this.actRoute.paramMap.subscribe(params => {
      this.title = params.get('followingFollowers') || '';
      this.tabSelected = params.get('friendsExperts') || '';
      this.userId = Number(params.get('userId'));
      this.getUserProfile();
      if (this.title === 'followers') {
        this.tabSelected === 'experts' ? this.getFollowersExperts() : this.getFollowersFriends();
      } else {
        this.tabSelected === 'experts' ? this.getFollowingExperts() : this.getFollowingFriends();
      }
    });
  }

  selectTab(tabSelected: string) {
    this.tabSelected = tabSelected;
    this.users = [];
    this.searchTerm = '';
    this.router.navigate([PAGES_AND_PATHS.friendsExperts.pagePath, this.userId, this.title, this.tabSelected])
  }

  getFollowersFriends(searchTerm?: string) {
    if (this.userId !== 0) {
      return (
        this.followersHttpService.formattedGetFollowersFriendsUser(this.userId, this.pageNum, searchTerm).then((response: any) => {
          console.log('getFollowersFriends', response)
          this.users = [...this.users, ...response.data];
          this.pagination = response.pagination;
          return response
        })
      )
    } else {
      return (
        this.followersHttpService.formattedGetFollowersFriends(this.pageNum, searchTerm).then((response: any) => {
          console.log('getFollowersFriends', response)
          this.users = [...this.users, ...response.data];
          this.pagination = response.pagination;
          return response
        })
      )
    }
  }
  getFollowingFriends(searchTerm?: string) {
    if (this.userId !== 0) {
      return (
        this.followersHttpService.formattedGetFollowingFriendsUser(this.userId, this.pageNum, searchTerm).then((response: any) => {
          console.log('getFollowingFriends', response)
          this.users = [...this.users, ...response.data];
          this.pagination = response.pagination;
          return response
        })
      )
    }else {
      return (
        this.followersHttpService.formattedGetFollowingFriends(this.pageNum, searchTerm).then((response: any) => {
          console.log('getFollowingFriends', response)
          this.users = [...this.users, ...response.data];
          this.pagination = response.pagination;
          return response
        })
      )
    }
  }

  getFollowersExperts(searchTerm?: string) {
    if (this.userId !== 0) {
      return from(
        this.followersHttpService.formattedGetFollowersExpertsUser(this.userId, this.pageNum, searchTerm).then((response: any) => {
          console.log('getFollowersExperts', response)
          this.users = [...this.users, ...response.data];
          this.pagination = response.pagination;
          return response
        })
      )
    } else {
      return from(
        this.followersHttpService.formattedGetFollowersExperts(this.pageNum, searchTerm).then((response: any) => {
          console.log('getFollowersExperts', response)
          this.users = [...this.users, ...response.data];
          this.pagination = response.pagination;
          return response
        })
      )
    }
    
  }
  getFollowingExperts(searchTerm?: string) {
    if (this.userId !== 0) {
      return from(
        this.followersHttpService.formattedGetFollowingExpertsUser(this.userId, this.pageNum, searchTerm).then((response: any) => {
          console.log('getFollowingExperts', response)
          this.users = [...this.users, ...response.data];
          this.pagination = response.pagination;
          return response
        })
      )
    } else {
      return from(
        this.followersHttpService.formattedGetFollowingExperts(this.pageNum, searchTerm).then((response: any) => {
          console.log('getFollowingExperts', response)
          this.users = [...this.users, ...response.data];
          this.pagination = response.pagination;
          return response
        })
      )
    }
    
  }

  getUserProfile(): void {
    if (this.userId !== 0) {
      this.profileHttpService.getUserProfile({ userId: this.userId }).then(res => {
        this.profileData = res.data;
        console.log(this.profileData)
      })
    } else {
      this.profileHttpService.getAuthUserProfile().then(res => {
        this.profileData = res.data;
      })
    }
  }

  openRemoveModal(user: Following) {
    this.isRemoveUserModalOpen = true;
    this.selectedUser = user;
    this.removeModalText.title = `Remove ${this.selectedUser.name}?`;
    this.removeModalText.description = `The Lysts won’t notify ${this.selectedUser.name} that they have been removed from your followers.`
    this.removeModalText.button = 'Remove';
  }

  takeAction() {
    this.isRemoveUserModalOpen = false;
    if (this.selectedUser !== null) {
      this.removeFollower(this.selectedUser.id)
    }
  }

  removeFollower(userId: number): void {
    this.followersHttpService.removeFollower(userId).subscribe((response: any) => {
      console.log('removeFollower', response)
      const index = this.users?.findIndex(user => user.id === userId);
      if (index > -1 && index < this.users?.length) {
        this.users?.splice(index, 1);
      }
    })
  }

  followUnfollow(userId: number) {
    this.followersHttpService.followUnfollowUser(userId).subscribe((response: any) => {
      const userSelected = this.users.findIndex(user => user.id === response.data.id);
      this.users[userSelected].am_i_following_this_user = !this.users[userSelected].am_i_following_this_user;
    })
  }

  onSearchChange(event: any): void {
    this.searchTerm = event.target.value;
    this.pageNum = 1;
    this.searchSubject.next(this.searchTerm);
  }

  resetSearch() {
    this.searchTerm = '';
    this.searchSubject.next('');
  }

}
