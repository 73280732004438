import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {DatePipe} from "@angular/common";

@Component({
  selector: 'tl-date-picker',
  templateUrl: './date-picker.component.html',
  providers: [DatePipe]
})
export class DatePickerComponent implements OnChanges {
  @Input() datepickerLabel = '';
  @Input() defaultDate?: string;
  @Output() dateSelected: EventEmitter<Date> = new EventEmitter<Date>();
  currentDate: Date = new Date();
  selectedDate: any;

  protected readonly Date = Date;

  constructor(private datePipe: DatePipe) {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.defaultDate && changes.defaultDate.currentValue) {
      this.selectedDate = this.datePipe.transform(changes['defaultDate'].currentValue, 'yyyy-MM-dd')
    }
  }

  onDateChange(date: MatDatepickerInputEvent<any, any>): void {
    this.dateSelected.emit(date.value);
  }
}
