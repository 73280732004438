import { NgIf } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-video-box',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './video-box.component.html',
})
export class VideoBoxComponent {
  @Input() video: any;
  @ViewChild('videoElement', { static: false }) videoElement!: ElementRef;
  @Output() onDownloadClick = new EventEmitter<boolean>();
  videoTitle: string = 'Your Video Title';
  isPlaying: boolean = false;

  togglePlayPause() {
    const video: HTMLVideoElement = this.videoElement.nativeElement;
    if (video.paused) {
      video.play();
      this.isPlaying = true;
    } else {
      video.pause();
      this.isPlaying = false;
    }
  }
}
