<div class="wrapper-added-to-your-list">
    <div class="header-added-to-your-list">
        <img src="https://media-cdn.tripadvisor.com/media/photo-s/0b/74/9e/d2/restourant-amphora-a.jpg" alt="">
        <span>Added to {{selectedListAndItem.list.title}}</span>
    </div>
    <button (click)="closeModal.emit(true)" class="custom-btn custom-btn--white xs-mt-12">
        <span>Stay here</span>
    </button>
    <button [routerLink]="[PAGES_AND_PATHS.singleItem.pagePath, selectedListAndItem.item.id]" class="custom-btn custom-btn--primary xs-mt-12">
        <span>View item</span>
    </button>
</div>