import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { ListsHttpService } from 'src/app/services/http-services/lists/lists-http.service';
import { ProfileHttpService } from 'src/app/services/http-services/profile/profile-http.service';

@Component({
  selector: 'app-favorite-listers',
  templateUrl: './favorite-listers.component.html',
})
export class FavoriteListersComponent implements OnInit {
  pageNum = 1;
  searchTerm = '';
  favoriteListers: any[] = [];
  searchSubject = new Subject<string>();
  pagination!: TlPagination;
  PAGES_AND_PATHS = PAGES_AND_PATHS;

  constructor(
    private listsHttp: ListsHttpService,
    private toastService: ToastrService,
    private profileHttpService: ProfileHttpService
  ) { }

  ngOnInit(): void {
    this.getFavoriteListers();
    this.searchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((searchTerm: string) => this.getFavoriteListers(searchTerm))
    ).subscribe((response: any) => {
      this.favoriteListers = response.data;
    });
  }

  getFavoriteListers(searchTerm?: string) {
    return (
      this.listsHttp.getFavoriteListers(this.pageNum, searchTerm).then((response: any) => {
        console.log('getFavoriteListers', response);
        this.favoriteListers = response.data;
        this.pagination = response.pageNum;
        return response;
      })
    )
  }

  onSearchChange(event: any): void {
    this.searchTerm = event.target.value;
    this.pageNum = 1;
    this.searchSubject.next(this.searchTerm);
  }

  resetSearch() {
    this.searchTerm = '';
    this.searchSubject.next('');
  }

  toggleFavoriteProfile(user: any): void {
    this.profileHttpService.toggleProfileFavorite({userId: user.id}).then(res => {
      this.toastService.success(res.message);
      const index = this.favoriteListers.findIndex(listerObj => listerObj.id === user.id);
      this.favoriteListers[index].am_i_favorite_this_user = !this.favoriteListers[index].am_i_favorite_this_user;
    });
  }

}
