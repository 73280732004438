<div class="wrapper-list-search-accounts">
    <span class="title-search-results">Companies</span>
    <div #companiesContainer (scroll)="onScroll()">
        <div *ngFor="let company of companies" class="wrapper-card-lister">
            <div class="wrapper-favorite-lister">
                <div class="wrapper-avatar">
                    <img [src]="company?.profile_photo_url ? company?.profile_photo_url : './assets/images/avatar-user-expert.png'"
                        alt="">
                </div>
                <div class="wrapper-user-info">
                    <div class="wrapper-username">
                        <span>{{company.name}}</span>
                        <img src="./assets/icons/fill/icon-fill-company.svg" alt="">
                    </div>
                    <span>{{company?.followers_count}} {{company.followers_count === 1 ? 'follower' :
                        'followers'}}</span>
                </div>
            </div>
        </div>
    </div>
</div>