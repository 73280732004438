<div class="wrapper-modal copy-to-my-list">
  <div class="wrapper-modal-content">
    <div class="wrapper-header-modal-copy-to-my-list">
      <span>Add item to list</span>
      <div (click)="closeModal.emit(true)"  class="custom-btn custom-btn--circle-outline">
        <img  src="./assets/icons/outline/icon-close-modal.svg" alt="">
      </div>
    </div>
    <button class="custom-btn custom-btn--outline" (click)="createNewList()">
      <img  src="./assets/icons/outline/icon-plus.svg" alt="">
      <span>Create new list</span>
    </button>
    <div class="wrapper-content-modal-copy-to-my-list">
      <div class="wrapper-tab-lists">
        <div class="wrapper-custom-radiobutton-list copy-item">
          <!--    TODO JS - kad su disabled dodaje klasu "tl-not-allowed"      -->
          <label *ngFor="let list of lists" (click)="select(list)">
            <input type="radio" name="tag-list">
            <div class="custom-radiobutton">
              <div class="wrapper-language-info">
                <div class="wrapper-list-preview">
                  <div class="wrapper-image-list">
                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQzk92qOx7c5k5fybjVbUkwg6BGW_ptjgID9A&s" alt="">
                  </div>
                  <div class="wrapper-info-list">
                    <span class="label-item">22 items</span>
                    <div class="info-list-tag">
                      <div class="title-list">
                        <span>{{list.title}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="circle-radio">
                <div class="circle-center"></div>
              </div>
            </div>
          </label>
        </div>
      </div>
    </div>
    <button class="custom-btn custom-btn--primary" (click)="addItemToList()">
      <span>Add item to list</span>
    </button>
  </div>
</div>
