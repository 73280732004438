<div class="wrapper-video-preview">
    <video #videoElement width="100%" (click)="togglePlayPause()" (ended)="isPlaying = false" [poster]="video?.thumbnail?.url">
      <source [src]="video?.video.url" type="video/mp4" />
    </video>
    <div *ngIf="!isPlaying" class="play-btn" (click)="togglePlayPause()">
      <img src="../../../../../assets/icons/fill/icon-play.svg" alt="">
    </div>
    <div *ngIf="video?.thumbnail?.url === undefined" class="download-btn" (click)="togglePlayPause()">
      <img src="../../../../../assets/icons/icon-download-btn.svg" alt="">
    </div>
  </div>
