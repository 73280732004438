import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {GeneralHttpHelperService} from "../../helpers/general-http-helper.service";

const POSTS_API_ROUTES = {
  GET_LATEST_POSTS: 'posts/following',
  GET_SINGLE_POST: 'posts/:postId',
  POST_POSTS: 'posts',
  PUT_POSTS: 'posts/:postId',
  DELETE_POSTS: 'posts/:postId',
  GET_POST_COMMENTS: 'comments/posts/:postId',
  CREATE_POST_COMMENT: 'comments/posts/:postId',
  UPDATE_POST_COMMENT: 'comments/posts/:commentId',
  DELETE_POST_COMMENT: 'comments/posts/:commentId',
  GET_POST_LIKES: 'likes/posts/:postId',
  TOGGLE_POST_LIKE: 'likes/posts/:postId/toggle',
  TOGGLE_POST_COMMENT_LIKE: 'likes/comments/:commentId/toggle',
  GET_TAG_LISTS: 'posts/posts_tags/lysts',
  GET_TAG_LIST_ITEMS: 'posts/posts_tags/lystItems',
  SEND_POST_REPORT: 'reports/posts/:postId'
}
@Injectable({
  providedIn: 'root'
})
export class PostsHttpService {

  constructor(private httpClient: HttpClient,
              private generalHttpHelperService: GeneralHttpHelperService) {
  }


  private getLatestPostsReq(urlParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.addUrlParams(POSTS_API_ROUTES.GET_LATEST_POSTS, urlParams);
    return this.httpClient.get(apiUrl) as any;
  }

  getLatestPosts(urlParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getLatestPostsReq(urlParams).subscribe((res: any) => {
        resolve({
          data: res.data.data,
          pagination: this.generalHttpHelperService.returnPagination(res.data)
        });
      }, error => {
        reject(error);
      });
    });
  }

  private getSinglePostReq(urlParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(POSTS_API_ROUTES.GET_SINGLE_POST, urlParams);
    return this.httpClient.get(apiUrl) as any;
  }

  getSinglePost(urlParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getSinglePostReq(urlParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private storePostReq(bodyParams: any): Observable<any> {
    return this.httpClient.post(POSTS_API_ROUTES.POST_POSTS, bodyParams) as any;
  }

  storePost(bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.storePostReq(bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private updatePostReq(urlParams: any, bodyParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(POSTS_API_ROUTES.PUT_POSTS, urlParams);
    return this.httpClient.put(apiUrl, bodyParams) as any;
  }

  updatePost(urlParams: any, bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.updatePostReq(urlParams, bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private deletePostReq(urlParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(POSTS_API_ROUTES.DELETE_POSTS, urlParams);
    return this.httpClient.delete(apiUrl) as any;
  }

  deletePost(urlParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.deletePostReq(urlParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private sendPostReportReq(urlParams: any, bodyParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(POSTS_API_ROUTES.SEND_POST_REPORT, urlParams);
    return this.httpClient.post(apiUrl, bodyParams) as any;
  }

  sendPostReport(urlParams: any, bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.sendPostReportReq(urlParams, bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private getPostCommentsReq(urlParams: any): Observable<any> {
    const {postId, page} = urlParams;
    let apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(POSTS_API_ROUTES.GET_POST_COMMENTS, {postId});
    apiUrl = this.generalHttpHelperService.addUrlParams(apiUrl, {page});
    return this.httpClient.get(apiUrl) as any;
  }

  getPostComments(urlParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getPostCommentsReq(urlParams).subscribe((res: any) => {
        resolve({
          data: res.data.data,
          pagination: this.generalHttpHelperService.returnPagination(res.data)
        });
      }, error => {
        reject(error);
      });
    });
  }

  private storePostCommentReq(urlParams: any, bodyParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(POSTS_API_ROUTES.CREATE_POST_COMMENT, urlParams);
    return this.httpClient.post(apiUrl, bodyParams) as any;
  }

  storePostComment(urlParams: any, bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.storePostCommentReq(urlParams, bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private updatePostCommentReq(urlParams: any, bodyParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(POSTS_API_ROUTES.UPDATE_POST_COMMENT, urlParams);
    return this.httpClient.put(apiUrl, bodyParams) as any;
  }

  updatePostComment(urlParams: any, bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.updatePostCommentReq(urlParams, bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private deletePostCommentReq(urlParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(POSTS_API_ROUTES.DELETE_POST_COMMENT, urlParams);
    return this.httpClient.delete(apiUrl) as any;
  }

  deletePostComment(urlParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.deletePostCommentReq(urlParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private getPostLikesDataReq(urlParams: any): Observable<any> {
    const {postId, ...restUrlParams} = urlParams;
    let apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(POSTS_API_ROUTES.GET_POST_LIKES, {postId});
    apiUrl = this.generalHttpHelperService.addUrlParams(apiUrl, restUrlParams);
    return this.httpClient.get(apiUrl) as any;
  }

  getPostLikesData(urlParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getPostLikesDataReq(urlParams).subscribe((res: any) => {
        resolve({
          data: res.data.data,
          pagination: this.generalHttpHelperService.returnPagination(res.data)
        });
      }, error => {
        reject(error);
      });
    });
  }

  private togglePostLikeReq(urlParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(POSTS_API_ROUTES.TOGGLE_POST_LIKE, urlParams);
    return this.httpClient.post(apiUrl, {}) as any;
  }

  togglePostLike(urlParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.togglePostLikeReq(urlParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private togglePostCommentLikeReq(urlParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(POSTS_API_ROUTES.TOGGLE_POST_COMMENT_LIKE, urlParams);
    return this.httpClient.post(apiUrl, {}) as any;
  }

  togglePostCommentLike(urlParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.togglePostCommentLikeReq(urlParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }


  private getTagListsReq(urlParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.addUrlParams(POSTS_API_ROUTES.GET_TAG_LISTS, urlParams);
    return this.httpClient.get(apiUrl) as any;
  }

  getTagLists(urlParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getTagListsReq(urlParams).subscribe((res: any) => {
        resolve({
          data: res.data.data,
          pagination: this.generalHttpHelperService.returnPagination(res.data)
        });
      }, error => {
        reject(error);
      });
    });
  }

  private getTagListItemsReq(urlParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.addUrlParams(POSTS_API_ROUTES.GET_TAG_LIST_ITEMS, urlParams);
    return this.httpClient.get(apiUrl) as any;
  }

  getTagListItems(urlParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getTagListItemsReq(urlParams).subscribe((res: any) => {
        resolve({
          data: res.data.data,
          pagination: this.generalHttpHelperService.returnPagination(res.data)
        });
      }, error => {
        reject(error);
      });
    });
  }
}
