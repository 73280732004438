<div class="wrapper-page">
  <app-my-content></app-my-content>
  <div class="wrapper-single-post-page">
    <tl-header></tl-header>
    <div class="single-post-page" (scroll)="onScrollComments($event)">
      <div class="wrapper-card-post">
        <div class="header-card-post">
          <div class="user-card-post">
            <img class="user-img"
                 *ngIf="!postData?.user?.profile_photo_url"
                 [routerLink]="[PAGES_AND_PATHS.profile.pagePath, postData?.user_id]"
                 [src]="postData?.user?.is_expert ? '../../../assets/images/avatar-user-expert.png' : '../../../assets/images/avatar-user.png'"
                 alt="">
            <img class="user-img"
                 *ngIf="postData?.user?.profile_photo_url"
                 [routerLink]="[PAGES_AND_PATHS.profile.pagePath, postData?.user_id]"
                 [src]="postData?.user?.profile_photo_url" alt="">
            <div class="wrapper-user-card-info-post">
              <div class="user-name">
                <span>{{postData?.user?.name}}</span>
                <img *ngIf="postData?.user.is_expert" src="../../../assets/icons/fill/icon-badge.svg" alt="">
              </div>
              <span>{{postData?.created_at | timeAgo}}</span>
            </div>
          </div>
          <div class="wrapper-dropdown-btn">
            <div class="view-more-post" #viewMoreBtn (click)="isCallToActionModal = !isCallToActionModal">
              <img src="../../../../assets/icons/outline/icon-view-more.svg" alt="">
            </div>
            <div *ngIf="isCallToActionModal" class="content-dropdown-btn">
              <div *ngIf="postData.user_id === authUserData.id" class="item-dropdown" (click)="onCallActionEditPost()">
                <img src="../../../../assets/icons/outline/icon-edit.svg" alt="">
                <span>Edit post</span>
              </div>
              <div class="item-dropdown" (click)="onCallActionSharePost()">
                <img src="../../../../assets/icons/outline/icon-share.svg" alt="">
                <span>Share</span>
              </div>
              <div class="item-dropdown delete" (click)="onCallActionReportPost()">
                <img src="../../../../assets/icons/outline/icon-outline-attention.svg" alt="">
                <span>Report</span>
              </div>
              <div *ngIf="postData.user_id === authUserData.id" class="item-dropdown delete" (click)="onCallActionDeletePost()">
                <img src="../../../../assets/icons/outline/icon-delete.svg" alt="">
                <span>Delete post</span>
              </div>
            </div>
          </div>
        </div>
        <div class="content-card-post">
          <p class="description">{{postData?.content}}</p>
          <div *ngIf="postData?.lyst !== null || postData?.lyst_item !== null" class="wrapper-image-tagged">
            <img *ngIf="postData.lyst?.lyst_image_url"
                 (click)="showImageInFullScreen = postData.lyst?.lyst_image_url"
                 [src]="postData.lyst?.lyst_image_url" [alt]="postData.lyst?.title + '-image'">
            <img *ngIf="postData.lyst?.lyst_item_image_url"
                 (click)="showImageInFullScreen = postData.lyst?.lyst_item_image_url"
                 [src]="postData.lyst_item?.lyst_item_image_url" [alt]="postData.lyst_item?.name  + '-image'">
          </div>
          <div *ngIf="postData?.lyst !== null || postData?.lyst_item !== null" class="wrapper-tagged-list-item">
            <div class="wrapper-tags">
              <div *ngIf="postData.lyst !== null" class="wrapper-tag list">
                <img src="../../../../../assets/icons/outline/icon-tag.svg" alt="">
                <span>{{postData.lyst?.title}}</span>
              </div>
              <div *ngIf="postData.lyst_item !== null" class="wrapper-tag item">
                <img src="../../../../../assets/icons/outline/icon-tag.svg" alt="">
                <span>{{postData.lyst_item?.name}}</span>
              </div>
            </div>
          </div>
          <div class="wrapper-toolbar-post">
            <div class="wrapper-toolbar-left">
              <span *ngIf="postData?.am_i_liked_this" [routerLink]="[PAGES_AND_PATHS.postLikes.pagePath, postData?.id]">You <span *ngIf="postData?.likes_count > 1">and {{postData?.likes_count - 1}} others</span> |</span>
              <span *ngIf="!postData?.am_i_liked_this" [routerLink]="[PAGES_AND_PATHS.postLikes.pagePath, postData?.id]">{{postData?.likes_count}} {{postData?.likes_count === 1 ? 'like' : 'likes'}} |</span>

              <span> {{postData?.comments_count}} {{postData?.comments_count === 1 ? 'comment' : 'comments'}}</span>
            </div>
            <div class="wrapper-toolbar-right">
              <div class="wrapper-like" [ngClass]="{'liked': postData?.am_i_liked_this}" (click)="togglePostLike()">
                <img class="no-like" src="../../../../../assets/icons/outline/icon-like.svg" alt="">
                <img class="like" src="../../../../../assets/icons/fill/icon-like.svg" alt="">
                <span>Like</span>
              </div>
              <div class="wrapper-share" (click)="isShareModalOpen = true">
                <img src="../../../../../assets/icons/outline/icon-share.svg" alt="">
                <span>Share</span>
              </div>
            </div>
          </div>
          <form [formGroup]="commentForm" (ngSubmit)="onSubmitCommentForm()" class="wrapper-write-comment">
            <div class="avatar-comment">
              <img
                *ngIf="!authUserData?.profile_photo_url"
                [routerLink]="[PAGES_AND_PATHS.profile.pagePath, authUserData.id]"
                [src]="authUserData?.roles[0].name === UserRoles.expert ? '../../../assets/images/avatar-user-expert.png' : '../../../assets/images/avatar-user.png'"
                alt="">
              <img
                *ngIf="authUserData?.profile_photo_url"
                [routerLink]="[PAGES_AND_PATHS.profile.pagePath, authUserData.id]"
                [src]="authUserData?.profile_photo_url" alt="">
            </div>
            <div class="wrapper-input">
              <div class="custom-input">
                <input type="text" formControlName="comment" placeholder="Write a comment...">
              </div>
            </div>
            <button type="submit" [disabled]="commentForm.invalid" class="custom-btn custom-btn--circle-primary">
              <img src="../../../../../assets/icons/outline/icon-send.svg" alt="">
            </button>
          </form>
        </div>
      </div>
      <div class="wrapper-comments">
        <div *ngFor="let comment of comments" class="wrapper-comment">
          <div class="wrapper-comment-left">
            <div class="avatar-comment">
              <img
                *ngIf="!comment.author?.profile_photo_url"
                [routerLink]="[PAGES_AND_PATHS.profile.pagePath, comment.author?.id]"
                [src]="comment.author?.roles[0].name === UserRoles.expert ? './assets/images/avatar-user-expert.png' : './assets/images/avatar-user.png'"
                   alt="">
              <img
                *ngIf="comment.author?.profile_photo_url"
                [routerLink]="[PAGES_AND_PATHS.profile.pagePath, comment.author?.id]"
                [src]="comment.author?.profile_photo_url" alt="">
            </div>
            <div class="info-comment">
              <div class="user-name-comment">
                <span class="name">{{comment.author.name}}</span>
                <img *ngIf="comment.author.is_expert" src="../../../assets/icons/fill/icon-badge.svg" alt="">
                <span class="time">{{comment.created_at | timeAgo}}</span>
              </div>
              <span class="comment">{{comment.comment}}</span>
            </div>
          </div>
          <div class="wrapper-comment-right">
            <div class="wrapper-like" [ngClass]="{'liked': comment.am_i_liked_this}"
                 (click)="toggleCommentLike(comment.id)">
              <img class="no-like" src="../../../assets/icons/outline/icon-heart.svg" alt="">
              <img class="like" src="../../../assets/icons/fill/icon-heart.svg" alt="">
              <span>{{comment.likes_count}}</span>
            </div>
            <img *ngIf="comment.user_id === authUserData.id" (click)="onSelectCommentForEdit(comment)"
                 src="../../../assets/icons/outline/icon-edit.svg" alt="">
            <img *ngIf="comment.user_id === authUserData.id" (click)="commentForDelete = comment"
                 src="../../../assets/icons/outline/icon-delete.svg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<tl-confirm-modal
  *ngIf="isDeletePostModalOpen"
  [modalTitle]="'Delete post?'"
  [modalDescription]="'Are you sure you want to delete this post?'"
  (cancelModal)="isDeletePostModalOpen = false"
  (confirmModal)="onConfirmPostDelete()"
></tl-confirm-modal>

<tl-confirm-modal
  *ngIf="commentForDelete !== null"
  [modalTitle]="'Delete comment?'"
  [modalDescription]="'Are you sure you want to delete this comment?'"
  (cancelModal)="commentForDelete = null"
  (confirmModal)="onConfirmCommentDelete()"
></tl-confirm-modal>

<!--<tl-post-report-modal-->
<!--  [isModalOpen]="isReportPostModalOpen"-->
<!--  [postId]="post?.id"-->
<!--  (closeModal)="isReportPostModalOpen = false"-->
<!--  (reportSent)="onPostReportSent()"-->
<!--&gt;</tl-post-report-modal>-->

<tl-sharing-options-modal
  *ngIf="isShareModalOpen"
  [entityType]="ShareEntitiesEnums.post"
  [entityId]="postData.id"
  [urlForShare]="postUrl"
  (closeModal)="isShareModalOpen = false"
></tl-sharing-options-modal>
