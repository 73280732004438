<div class="wrapper-modal">
  <div class="wrapper-modal-content delete">
    <div class="wrapper-modal-image">
      <img src="../../../../../assets/icons/outline/icon-delete.svg" alt="">
    </div>
    <div class="modal-title">{{deleteChatText.title}}</div>
    <div class="modal-description">{{deleteChatText.description}}</div>
    <div class="modal-buttons">
      <button class="custom-btn custom-btn--outline-red" (click)="closeDeleteChatModal.emit()">
        <span>Cancel</span>
      </button>
      <button class="custom-btn custom-btn--delete" (click)="takeAction()">
        <span>{{deleteChatText.buttonText}}</span>
      </button>
    </div>
  </div>
</div>
