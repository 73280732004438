import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PAGES_AND_PATHS} from '../../../constants/pages-and-paths';
import {TrendingItemsComponent} from './trending-items.component';
import {AuthGuardService} from '../../../guards/auth-guard.service';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.trendingItems.pageInRouting,
    data: { data: PAGES_AND_PATHS.trendingItems },
    component: TrendingItemsComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./trending-items.module').then(m => m.TrendingItemsModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TrendingItemsRoutingModule { }
