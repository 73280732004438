<div class="wrapper-auth-pages">
  <div class="wrapper-auth-left">
    <form [formGroup]="codeForm" (ngSubmit)="onSubmit()" class="wrapper-verify-account">
      <div class="verify-account-page">
        <div class="wrapper-logo-auth">
          <img src="../assets/icons/logo-horizontal-yellow.svg" alt="">
        </div>
        <div class="info-auth-verify-account">
          <h1>Verify account</h1>
          <h6>Lastly, please check your email and enter the verification code we’ve sent.</h6>
        </div>
        <div class="wrapper-verify-code" [ngClass]="{'error': isCodeError}">
          <input
            #codeInput
            *ngFor="let digit of codeArray; let i = index"
            [formControlName]="'code' + i"
            (input)="onInput($event, i)"
            (keydown)="onKeyDown($event, i)"
            (paste)="onPaste($event)"
            [autofocus]="i === 0"
            inputmode="numeric"
            maxlength="1"
            type="text">
        </div>
        <div *ngIf="isCodeError" class="error-message">
          <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
          <span>Wrong code. Please enter a valid code.</span>
        </div>
      </div>
      <div class="footer-verify-account">
        <button type="submit" class="custom-btn custom-btn--primary">
          <span>Verify account</span>
          <tl-spinner *ngIf="isSpinnerActive"></tl-spinner>
        </button>
        <div class="wrapper-label-link">
          <span>Didn’t get the code?</span>
          <a href="javascript:void(0)" (click)="resendVerificationCode()">Resend <span
            *ngIf="numberOfSecondsToEnableResendCodeBtn">({{numberOfSecondsToEnableResendCodeBtn + ' sec'}})</span></a>
        </div>
      </div>
    </form>
  </div>
  <tl-onboarding-swipe class="wrapper-auth-right"></tl-onboarding-swipe>
</div>

