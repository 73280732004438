<div class="wrapper-modal">
  <div class="wrapper-modal-content add-new">
    <div class="modal-title">{{selectedColumn === null ? 'Add a new column' : 'Update column name'}}</div>
    <div class="wrapper-input">
      <span class="label-input">Column name</span>
      <div class="custom-input">
        <input type="text" [(ngModel)]="columnName" name="columnName" placeholder="Enter column name..."
          maxlength="20" />
      </div>
      <div class="label-info-input">The maximum length of a column name is 20 characters.</div>
    </div>
    <div class="modal-buttons">
      <button class="custom-btn custom-btn--outline" (click)="closeModal.emit(true)">
        <span>Cancel</span>
      </button>
      <button class="custom-btn custom-btn--primary" (click)="addColumn()">
        <span>{{selectedColumn === null ? 'Add a new column' : 'Update column name'}}</span>
      </button>
    </div>
  </div>
</div>