import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PAGES_AND_PATHS} from '../../../constants/pages-and-paths';
import {AuthGuardService} from '../../../guards/auth-guard.service';
import {SelectListersComponent} from './select-listers.component';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.selectListers.pageInRouting,
    data: {data: PAGES_AND_PATHS.selectListers},
    component: SelectListersComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./select-listers.module').then(m => m.SelectListersModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SelectListersRoutingModule { }
