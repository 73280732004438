import { Location } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject, from } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { ChatHelperService } from 'src/app/services/helpers/chat-helper.service';
import { Conversation, SingleConversationMessage } from 'src/app/services/http-services/chat/chat-http-interfaces';
import { ChatHttpService } from 'src/app/services/http-services/chat/chat-http.service';

@Component({
  selector: 'app-group-accounts-page',
  templateUrl: './group-accounts-page.component.html',
})
export class GroupAccountsPageComponent {
  users: any[] = [];
  pageNum = 1;
  pagination!: TlPagination;
  selectedUsers = new Set<number>();
  searchTerm = '';
  private searchSubject = new Subject<string>();
  @ViewChild('userListContainer', { static: false }) private userListContainer!: ElementRef;
  private loadingNewUsers = false;
  @Output() openConversation = new EventEmitter<any>();
  @Input() isGroupAccountsOpen: any = false;
  conversationId: number = 0;
  conversation!: Conversation;
  isUserRemoved = false;

  constructor(
    private chatService: ChatHttpService,
    private chatHelperService: ChatHelperService,
    private toastService: ToastrService,
    private actRoute: ActivatedRoute,
    private chatHttpService: ChatHttpService,
    private router: Router,
    private location: Location
  ) { }

  ngOnInit() {
    this.getId();
    this.searchSubject.pipe(
      debounceTime(300),
      switchMap((searchTerm: string) => from(this.getUsers(searchTerm)))
    ).subscribe(response => {
      this.users = response.data.map(user => ({
        ...user,
        isSelected: this.selectedUsers.has(user.id)
      }));
      this.pagination = response.pagination;
    });
  }

  getId() {
    this.actRoute.paramMap.subscribe(params => {
      this.conversationId = Number(params.get('id') || '');
      this.getUsers();
      this.getSingleConversation();
    });
  }

  getUsers(searchTerm?: string) {
    return from(
      this.chatService.formattedgetUsersForChat(this.pageNum, searchTerm, this.conversationId).then((response: {
        data: Array<any>,
        pagination: TlPagination
      }) => {
        if (this.isUserRemoved) {
          this.users = [];
        }
        this.users = [...this.users, ...response.data];
        this.pagination = response.pagination;
        return response;
      }));
  }

  toggleSelection(userId: number) {
    if (this.selectedUsers.has(userId)) {
      this.selectedUsers.delete(userId);
    } else {
      this.selectedUsers.add(userId);
    }
  }

  isSelected(userId: number): boolean {
    return this.selectedUsers.has(userId);
  }

  onSearchChange(event: any): void {
    const keyword = event.target.value;
    this.pageNum = 1;
    this.searchSubject.next(keyword);
  }

  onScroll(): void {
    const container = this.userListContainer.nativeElement;
    const scrollTop = container.scrollTop;
    const containerHeight = container.clientHeight;
    const scrollHeight = container.scrollHeight;
    const offset = 100;
    if (!this.loadingNewUsers && (scrollHeight - scrollTop - containerHeight < offset) && ((this.pagination?.currentPage ?? 0) < (this.pagination?.lastPage ?? 0))) {
      this.pageNum++;
      this.loadingNewUsers = true;
      this.getUsers();
    }
  }

  selectedUsersCount(): number {
    return this.selectedUsers.size;
  }

  addToGroup() {
    const selectedEmails = Array.from(this.selectedUsers);
    const chatData = {
      participants: selectedEmails
    };
    this.chatService.addParticipantsToGroupConversation(this.conversationId, chatData).subscribe((response: any) => {
      this.router.navigate([PAGES_AND_PATHS.chat.pagePath, this.conversationId]);
      this.toastService.success('User successfully added to group!')
    })
  }

  resetSearch() {
    this.searchTerm = '';
    this.searchSubject.next('');
  }

  getSingleConversation(searchTerm?: string) {
    this.chatHttpService.formattedGetSingleConversation(this.conversationId, this.pageNum, searchTerm).then((response: {
      conversation: Conversation,
      data: Array<SingleConversationMessage>,
      pagination: TlPagination
    }) => {
      this.conversation = response.conversation;
    });
  }

  back(): void {
    this.location.back();
  }

  userRemovedEvent(isUserRemoved: boolean): void {
    this.isUserRemoved = isUserRemoved;
    this.getUsers();
  }

}
