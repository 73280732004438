<div class="wrapper-select-topics-and-artist-page">
  <div class="content-select-topics-and-artist">
<!--    <div class="back-btn">-->
<!--      <img src="../assets/icons/icon-arrow-left.svg" alt="">-->
<!--    </div>-->
    <h1>What topics are you interested in?</h1>
    <h6>Select topics you prefer to have better lists recommendations.</h6>
    <div class="wrapper-content-select-artist" (scroll)="onScroll($event)">
      <div class="wrapper-custom-checkbox-select-topics">
        <label *ngFor="let topic of topics">
          <input type="checkbox" name="radioname" [(ngModel)]="topic.selected" (change)="onSelectTopicChange(topic)">
          <div class="custom-checkbox-select-topics">
            <div class="check-topic">
              <img src="../assets/icons/outline/icon-check.svg" alt="">
            </div>
            <div class="wrapper-select-topics-info">
              <span>{{topic.name}}</span>
            </div>
          </div>
        </label>
      </div>
    </div>
    <button [routerLink]="PAGES_AND_PATHS.selectListers.pagePath" [disabled]="numberOfSelectedTopics < 3" class="custom-btn custom-btn--primary xs-mt-12">
      <span>Continue</span>
    </button>
    <div class="wrapper-label-gray">
      <span>Please select 3 or more to continue</span>
    </div>
  </div>
</div>
