import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisplayEditAndDeleteMessagePipe } from './display-edit-and-delete-message.pipe';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    DisplayEditAndDeleteMessagePipe
  ],
  exports: [
    DisplayEditAndDeleteMessagePipe
  ]
})
export class DisplayEditAndDeleteMessageModule { }
