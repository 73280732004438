<form [formGroup]="changeEmailForm" (ngSubmit)="onSubmitChangeEmailForm()" class="wrapper-account-page">
  <span class="title-settings-page">Change email address</span>
  <span class="subtitle-settings-page">Changing email address can be performed once every -- days.</span>
  <span class="subtitle-settings-page">The update will take effect immediately.</span>
  <div class="wrapper-input" [ngClass]="{'error': changeEmailForm.controls['email'] | formControlErrorMessages: 'Please enter a valid email address.': formTriedToSubmit}">
    <span class="label-input">New email address</span>
    <div class="custom-input">
      <input type="email" formControlName="email" placeholder="Enter new email address">
    </div>
    <div *ngIf="changeEmailForm.controls['email'] | formControlErrorMessages: 'Please enter a valid email address.': formTriedToSubmit" class="wrapper-error-message">
      <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
      <span>{{changeEmailForm.controls['email'] | formControlErrorMessages: 'Please enter a valid email address.': formTriedToSubmit}}</span>
    </div>
  </div>
  <div class="wrapper-submit-settings">
    <button type="submit" class="custom-btn custom-btn--primary">
      <span>Save changes</span>
      <tl-spinner *ngIf="isSpinnerActive"></tl-spinner>
    </button>
  </div>
</form>
