import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AllThemesComponent } from './all-themes.component';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.allThemes.pageInRouting,
    data: {data: PAGES_AND_PATHS.allThemes},
    component: AllThemesComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./all-themes.module').then(m => m.AllThemesModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AllThemesRoutingModule { }
