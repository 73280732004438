<div class="wrapper-tabs-items">
  <div class="header-tab-items">
    <div class="wrapper-custom-search">
      <img class="icon-search" src="../../../assets/icons/outline/icon-search.svg" alt="">
      <input type="text" placeholder="Search..." [(ngModel)]="searchTerm" (input)="onSearchChange($event)">
      <div (click)="resetSearch()" class="wrapper-delete-search">
        <img src="../../../assets/icons/outline/icon-close.svg" alt="">
      </div>
    </div>
    <button (click)="isFilterModalOpen = !isFilterModalOpen" class="custom-btn custom-btn--circle-outline">
      <img src="../../../../assets/icons/outline/icon-filter.svg" alt="">
    </button>
    <div class="switch-grid">
      <div class="btn-switch-grid" [routerLink]="[PAGES_AND_PATHS.singleList.pagePath, listId, 0, 'cards']" [ngClass]="{'active': itemsView === 'cards'}">
        <img src="../../../../../assets/icons/outline/icon-menu.svg" alt="">
      </div>
      <div class="btn-switch-grid" [routerLink]="[PAGES_AND_PATHS.singleList.pagePath, listId, 0, 'table']" [ngClass]="{'active': itemsView === 'table'}">
        <img src="../../../../../assets/icons/outline/icon-posts.svg" alt="">
      </div>
    </div>
    <button *ngIf="loggedUser.id === user_id" (click)="addNewItem()" class="custom-btn custom-btn--outline">
      <span>New item</span>
      <img src="../../../../../assets/icons/outline/icon-plus.svg" alt="">
    </button>
  </div>
  <div *ngIf="itemsView === 'cards'" class="wrapper-list-items">
    <div *ngFor="let item of items" [routerLink]="[PAGES_AND_PATHS.singleItem.pagePath, item.id]"
      class="wrapper-item-list-view">
      <div class="tl-d-flex tl-align-center">
        <span class="number">{{item.position}}</span>
        <div class="wrapper-list-item-image">
          <img [src]="item.lyst_item_image_url" alt="">
        </div>
        <div class="wrapper-list-item-info">
          <span class="name">{{item.name}}</span>
          <span class="info-item">{{item.by}}</span>
        </div>
      </div>
      <div *ngIf="loggedUser.id !== user_id;" (click)="openCopyModal(item, $event)"  appClickedOutside (clickedOutside)="isCopyDropdownOpen = false;" class="wrapper-delete-item copy">
        <img src="./assets/icons/outline/icon-view-more.svg" alt="">
        <div *ngIf="isCopyDropdownOpen  && selectedItem?.id === item.id" class="dropdown-delete-item">
          <img src="../../../../../../assets/icons/outline/icon-lists.svg" alt="">
          <span (click)="isCopyModalOpen = true">Copy to my list</span>
        </div>
      </div>
    </div>
    <div *ngIf="!items.length" class="no-item-list">
      <img src="../../../../../../assets/icons/illustration/no-item-illustration.svg" alt="">
      <span>Oops.. There are no list items</span>
    </div>
  </div>
  <div *ngIf="itemsView === 'table'" class="wrapper-add-items">
    <div class="wrapper-table-items">
      <div class="wrapper-header-table-item">
        <div class="wrapper-header-table-row-item">
          <div class="column-table-row-item">
            <span></span>
          </div>
          <div class="column-table-row-item">
            <span>#</span>
          </div>
          <div class="column-table-row-item">
            <span (click)="openUpdateColumnModal(columns[1])">{{columns[1]?.name}}</span>
          </div>
          <div class="column-table-row-item">
            <span (click)="openUpdateColumnModal(columns[2])">{{columns[2]?.name}}</span>
          </div>
          <div class="column-table-row-item">
            <span (click)="openUpdateColumnModal(columns[3])">{{columns[3]?.name}}</span>
          </div>
          <div *ngFor="let column of columns | slice:4" class="column-table-row-item">
            <span (click)="openUpdateColumnModal(column)">{{column.name}}</span>
            <div class="delete-column" (click)="openDeleteColumnModal(column)">
              <img src="../../../../../../assets/icons/outline/icon-delete.svg" alt="">
            </div>
          </div>
          <div class="column-table-row-item">
            <div *ngIf="loggedUser.id === user_id;" (click)="isAddNewColumnOpen = true" class="wrapper-add-new-row-item">
              <div class="wrapper-plus">
                <img src="../../../../../assets/icons/outline/icon-plus.svg" alt="">
              </div>
              <span>New</span>
            </div>
          </div>
          <div class="column-table-row-item">
            <span></span>
          </div>
        </div>
      </div>
      <div class="wrapper-content-table-item" cdkDropList [cdkDropListData]="items"
      (cdkDropListDropped)="drop($event)">
        <app-item-row *ngFor="let item of items; let isLast = last" [item]="item" [user_id]="user_id" [addNewRow]="addNewRow" [columns]="columns" [isLast]="isLast" cdkDrag></app-item-row>
        <div class="custom-preview" *cdkDragPreview>
          <app-item-row></app-item-row>
        </div>
        <div *ngIf="loggedUser.id === user_id;" (click)="addNewItem()" class="wrapper-add-new-row-item">
          <div class="wrapper-plus">
            <img src="../../../../../assets/icons/outline/icon-plus.svg" alt="">
          </div>
          <span>New</span>
        </div>
      </div>
    </div>
  </div>
</div>

<app-filter-items *ngIf="isFilterModalOpen" (closeModal)="isFilterModalOpen = false;" [columns]="columns" [filters]="filters"></app-filter-items>
<app-add-new-column *ngIf="isAddNewColumnOpen"
(reqObj)="handleColumnName($event)"
(closeModal)="isAddNewColumnOpen = false"
[selectedColumn]="selectedColumn"
></app-add-new-column>
<tl-confirm-modal *ngIf="isDeleteModalOpen" [modalIcon]="'../../../../../assets/icons/outline/icon-delete.svg'"
  [modalTitle]="deleteModalText.title" [confirmBtnText]="deleteModalText.button"
  [modalDescription]="deleteModalText.description" (cancelModal)="isDeleteModalOpen = false"
  (confirmModal)="takeAction()">
</tl-confirm-modal>
<app-copy-item-modal *ngIf="isCopyModalOpen" [url]="url" [selectedItem]="selectedItem" (closeModal)="isCopyModalOpen = false; selectedItem = null;" (isOpenToastOpen)="isOpenToastOpen = $event" (selectedListAndItem)="selectedListAndItem = $event"></app-copy-item-modal>
<app-copy-toast-notification *ngIf="isOpenToastOpen" [selectedListAndItem]="selectedListAndItem" (closeModal)="closeCopyModal()"></app-copy-toast-notification>