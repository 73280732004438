import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PAGES_AND_PATHS} from '../../../constants/pages-and-paths';
import {AuthGuardService} from '../../../guards/auth-guard.service';
import {EditProfileComponent} from './edit-profile.component';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.editProfile.pageInRouting,
    data: { data: PAGES_AND_PATHS.editProfile },
    component: EditProfileComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./edit-profile.module').then(m => m.EditProfileModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes),],
  exports: [RouterModule]
})
export class EditProfileRoutingModule { }
