<div class="wrapper-card-post" [routerLink]="[PAGES_AND_PATHS.singlePost.pagePath, post.id]">
  <div class="header-card-post">
    <div class="user-card-post">
      <img
        class="user-img"
        (click)="$event.stopPropagation()"
        [routerLink]="[PAGES_AND_PATHS.profile.pagePath, post?.user_id]"
        *ngIf="!post?.user?.profile_photo_url" [src]="post?.user?.is_expert ? '../../../assets/images/avatar-user-expert.png' : '../../../assets/images/avatar-user.png'" alt="">
      <img
        class="user-img"
        (click)="$event.stopPropagation()"
        [routerLink]="[PAGES_AND_PATHS.profile.pagePath, post?.user_id]"
        *ngIf="post?.user?.profile_photo_url" [src]="post?.user?.profile_photo_url" alt="">
      <div class="wrapper-user-card-info-post">
        <div class="user-name">
          <span>{{post?.user?.name}}</span>
          <img *ngIf="post?.user?.is_expert" src="../../../assets/icons/fill/icon-badge.svg" alt="">
        </div>
        <span>{{post.created_at | timeAgo}}</span>
      </div>
    </div>
    <div class="wrapper-dropdown-btn">
      <div class="view-more-post" #viewMoreBtn (click)="$event.stopPropagation(); isCallToActionModal = !isCallToActionModal">
        <img src="../../../../assets/icons/outline/icon-view-more.svg" alt="">
      </div>
      <div *ngIf="isCallToActionModal" class="content-dropdown-btn">
        <div *ngIf="post.user_id === authUserData.id" class="item-dropdown" (click)="onCallActionEditPost()">
          <img src="../../../../assets/icons/outline/icon-edit.svg" alt="">
          <span>Edit post</span>
        </div>
        <div class="item-dropdown" (click)="onCallActionSharePost()">
          <img src="../../../../assets/icons/outline/icon-share.svg" alt="">
          <span>Share</span>
        </div>
        <div class="item-dropdown delete" (click)="onCallActionReportPost()">
          <img src="../../../../assets/icons/outline/icon-outline-attention.svg" alt="">
          <span>Report</span>
        </div>
        <div *ngIf="post.user_id === authUserData.id" class="item-dropdown delete" (click)="onCallActionReportPost()">
          <img src="../../../../assets/icons/outline/icon-delete.svg" alt="">
          <span>Delete post</span>
        </div>
      </div>
    </div>
  </div>
  <div class="content-card-post">
    <p class="description">{{post.content | handleShowMore: 100: isShowMore}} <span
      (click)="$event.stopPropagation(); isShowMore = !isShowMore"
      *ngIf="post.content.length > 100">{{isShowMore ? 'less' : 'more'}}</span></p>
    <div *ngIf="post.lyst !== null || post.lyst_item !== null" class="wrapper-image-tagged">
      <img *ngIf="post.lyst?.lyst_image_url"
           (click)="$event.stopPropagation(); openImageInFullScreen.emit(post.lyst?.lyst_image_url)"
           [src]="post.lyst?.lyst_image_url" [alt]="post.lyst?.title + '-image'">
      <img *ngIf="post.lyst?.lyst_item_image_url"
           (click)="$event.stopPropagation(); openImageInFullScreen.emit(post.lyst?.lyst_item_image_url)"
           [src]="post.lyst_item?.lyst_item_image_url" [alt]="post.lyst_item?.name  + '-image'">
    </div>
    <div *ngIf="post.lyst !== null || post.lyst_item !== null" class="wrapper-tagged-list-item">
      <div class="wrapper-tags">
        <div *ngIf="post.lyst !== null" class="wrapper-tag list">
          <img src="../../../../../assets/icons/outline/icon-tag.svg" alt="">
          <span>{{post.lyst?.title}}</span>
        </div>
        <div *ngIf="post.lyst_item !== null" class="wrapper-tag item">
          <img src="../../../../../assets/icons/outline/icon-tag.svg" alt="">
          <span>{{post.lyst_item?.name}}</span>
        </div>
      </div>
    </div>
    <div class="wrapper-toolbar-post">
      <div class="wrapper-toolbar-left">
        <span [routerLink]="[PAGES_AND_PATHS.postLikes.pagePath, post.id]">{{post.likes_count}} {{post.likes_count === 1 ? 'like' : 'likes'}}</span>
        <span [routerLink]="[PAGES_AND_PATHS.singlePost.pagePath, post.id]"> {{post.comments_count}} {{post.comments_count === 1 ? 'comment' : 'comments'}}</span>
      </div>
      <div class="wrapper-toolbar-right">
        <div class="wrapper-like" [ngClass]="{'liked': post.am_i_liked_this}" (click)="togglePostLike($event)">
          <img class="no-like" src="../../../../../assets/icons/outline/icon-like.svg" alt="">
          <img class="like" src="../../../../../assets/icons/fill/icon-like.svg" alt="">
          <span>Like</span>
        </div>
        <div class="wrapper-share" (click)="isSharePostModalOpen = true">
          <img src="../../../../../assets/icons/outline/icon-share.svg" alt="">
          <span>Share</span>
        </div>
      </div>
    </div>
    <form [formGroup]="commentForm" (ngSubmit)="onSubmitCommentForm()" (click)="$event.stopPropagation()" class="wrapper-write-comment">
      <div class="avatar-comment">
        <img
          *ngIf="!authUserData?.profile_photo_url"
          (click)="$event.stopPropagation()"
          [routerLink]="[PAGES_AND_PATHS.profile.pagePath, authUserData.id]"
          [src]="authUserData?.roles[0].name === UserRoles.expert ? '../../../assets/images/avatar-user-expert.png' : '../../../assets/images/avatar-user.png'" alt="">
        <img
          *ngIf="authUserData?.profile_photo_url"
          (click)="$event.stopPropagation()"
          [routerLink]="[PAGES_AND_PATHS.profile.pagePath, authUserData.id]"
          [src]="authUserData?.profile_photo_url" alt="">
      </div>
      <div class="wrapper-input">
        <div class="custom-input">
          <input formControlName="comment" type="text" placeholder="Write a comment...">
        </div>
      </div>
      <button type="submit" [disabled]="commentForm.invalid" class="custom-btn custom-btn--circle-primary">
        <img src="../../../../../assets/icons/outline/icon-send.svg" alt="">
      </button>
    </form>
  </div>
</div>

<tl-confirm-modal
  *ngIf="isDeletePostModalOpen"
  [modalTitle]="'Delete post?'"
  [modalDescription]="'Are you sure you want to delete this post?'"
  (cancelModal)="isDeletePostModalOpen = false"
  (confirmModal)="onConfirmPostDelete()"
></tl-confirm-modal>

<!--<tl-post-report-modal-->
<!--  [isModalOpen]="isReportPostModalOpen"-->
<!--  [postId]="post?.id"-->
<!--  (closeModal)="isReportPostModalOpen = false"-->
<!--  (reportSent)="onPostReportSent()"-->
<!--&gt;</tl-post-report-modal>-->

<tl-sharing-options-modal
  *ngIf="isSharePostModalOpen"
  [entityType]="ShareEntitiesEnums.post"
  [entityId]="post.id"
  [urlForShare]="postUrl"
  (closeModal)="isSharePostModalOpen = false"
></tl-sharing-options-modal>
