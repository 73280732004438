import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {EmailRegex} from '../../../constants/general-variables';
import {PAGES_AND_PATHS} from '../../../constants/pages-and-paths';
import {AuthenticationHttpService} from '../../../services/http-services/authentication/authentication-http.service';
import {Router} from '@angular/router';

@Component({
  selector: 'tl-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit {
  formTriedToSubmit = false;
  forgotPasswordForm!: FormGroup;
  isSpinnerActive = false;
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;

  constructor(private authenticationHttpService: AuthenticationHttpService,
              private router: Router,
              // private toastService: ToastService,
              private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(EmailRegex)]],
    });
  }

  onSubmitForgotPasswordForm(): void {
    this.formTriedToSubmit = true;
    if (this.forgotPasswordForm.valid) {
      this.isSpinnerActive = true;
      this.authenticationHttpService.forgotPassword(this.forgotPasswordForm.value).then((res: any) => {
        this.isSpinnerActive = false;
        this.router.navigate([PAGES_AND_PATHS.checkYourEmail.pagePath, this.forgotPasswordForm.controls.email.value]);
      }).catch(({error}: {error: { errors: { [key: string]: string } }}) => {
        this.isSpinnerActive = false;
        Object.values(error.errors).forEach(error => {
          // this.toastService.error(error);
        });
      });
    }
  }
}
