<form [formGroup]="editProfileImageForm" (ngSubmit)="onSubmitEditProfileImageForm()" class="content-edit-profile">
  <div class="wrapper-change-image" [ngClass]="{'error': isMaxSizePhotoError}">
    <div class="preview-image-profile">
      <img *ngIf="profileImage" [src]="profileImage" alt="">
      <img *ngIf="!profileImage" [src]="profileData?.roles[0].name === UserRoles.expert ? '../../../assets/images/avatar-user-expert.png' : '../../../assets/images/avatar-user.png'" alt="">
    </div>
    <div class="btn-edit-profile-image">
      <label (click)="triggerFileInput($event)" class="custom-btn custom-btn--white">
        <span>Change profile photo</span>
      </label>
      <input #fileInput (change)="onFileChange($event)" type="file" accept=".jpg,.jpeg,.png,.svg"/>
    </div>
  </div>
  <div *ngIf="isMaxSizePhotoError" class="wrapper-error-message">
    <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
    <span>Your image is too big. The maximum image size is 10mb.</span>
  </div>
  <div class="wrapper-list-info-profile">
    <div class="item-list-info-profile" (click)="changeActiveComponent.emit(EditProfileEnums.name)">
      <span class="list-profile-label">Name</span>
      <div class="wrapper-value-info-profile">
        <span>{{profileData?.name}}</span>
        <img src="../../../../assets/icons/icon-arrow-left.svg" alt="">
      </div>
    </div>
    <div class="item-list-info-profile" (click)="changeActiveComponent.emit(EditProfileEnums.username)">
      <span class="list-profile-label">Username</span>
      <div class="wrapper-value-info-profile">
        <span>{{profileData?.username}}</span>
        <img src="../../../../assets/icons/icon-arrow-left.svg" alt="">
      </div>
    </div>
    <div class="item-list-info-profile" (click)="changeActiveComponent.emit(EditProfileEnums.bio)">
      <span class="list-profile-label">Bio</span>
      <div class="wrapper-value-info-profile">
        <span>{{profileData?.bio}}</span>
        <img src="../../../../assets/icons/icon-arrow-left.svg" alt="">
      </div>
    </div>
    <div class="item-list-info-profile" (click)="changeActiveComponent.emit(EditProfileEnums.gender)">
      <span class="list-profile-label">Gender</span>
      <div class="wrapper-value-info-profile gender">
        <span>{{profileData?.gender}}</span>
        <img src="../../../../assets/icons/icon-arrow-left.svg" alt="">
      </div>
    </div>
    <div class="item-list-info-profile" (click)="changeActiveComponent.emit(EditProfileEnums.phone)">
      <span class="list-profile-label">Phone</span>
      <div class="wrapper-value-info-profile">
        <span>{{profileData?.phone_number}}</span>
        <img src="../../../../assets/icons/icon-arrow-left.svg" alt="">
      </div>
    </div>
    <div class="item-list-info-profile" (click)="changeActiveComponent.emit(EditProfileEnums.birthday)">
      <span class="list-profile-label">Birthday</span>
      <div class="wrapper-value-info-profile">
        <span>{{profileData?.date_of_birth | date: 'd MMMM yyyy'}}</span>
        <img src="../../../../assets/icons/icon-arrow-left.svg" alt="">
      </div>
    </div>
    <div class="item-list-info-profile" (click)="changeActiveComponent.emit(EditProfileEnums.country)">
      <span class="list-profile-label">Country</span>
      <div class="wrapper-value-info-profile">
        <span>{{profileData?.country}}</span>
        <img src="../../../../assets/icons/icon-arrow-left.svg" alt="">
      </div>
    </div>
    <div class="item-list-info-profile" (click)="changeActiveComponent.emit(EditProfileEnums.city)">
      <span class="list-profile-label">City</span>
      <div class="wrapper-value-info-profile">
        <span>{{profileData?.city}}</span>
        <img src="../../../../assets/icons/icon-arrow-left.svg" alt="">
      </div>
    </div>
    <div class="wrapper-save-btn">
      <button type="submit" [disabled]="!editProfileImageForm.controls['profile_photo'].value" class="custom-btn custom-btn--primary">
        <span>Save changes</span>
      </button>
    </div>
  </div>
</form>
<tl-confirm-modal
  *ngIf="isRemoveImageModalOpen"
  [modalIcon]="'../../../../../assets/icons/outline/icon-delete.svg'"
  [modalTitle]="'Remove profile photo?'"
  [confirmBtnText]="'Remove'"
  [modalDescription]="'Are you sure you want to remove profile photo?'"
  (cancelModal)="isRemoveImageModalOpen = false"
  (confirmModal)="onConfirmRemoveFile()">
</tl-confirm-modal>
