<div class="wrapper-page wrapper-my-list">
  <tl-header></tl-header>
  <div class="wrapper-header-my-lists">
    <div class="header-my-lists">
      <span>Favorite items</span>
    </div>
  </div>
  <div class="wrapper-content-my-lists favorite-lists">
    <div class="content-my-lists">
      <div class="filter-my-lists">
        <div class="wrapper-custom-search">
          <img class="icon-search" src="../../../assets/icons/outline/icon-search.svg" alt="">
          <input type="text" placeholder="Search..." [(ngModel)]="searchTerm" (input)="onSearchChange($event)">
          <div (click)="resetSearch()" class="wrapper-delete-search">
            <img src="../../../assets/icons/outline/icon-close.svg" alt="">
          </div>
        </div>
      </div>
      <div class="wrapper-favorite-items"  #favoriteItemsContainter  (scroll)="onScroll()"
      style="overflow-y: auto; height: 100%;">
        <div *ngFor="let item of favoriteItems" [routerLink]="[PAGES_AND_PATHS.singleItem.pagePath, item.id]" class="card-favorite-item">
          <div class="info-favorite-item">
            <img [src]="item.lyst_item_image_url" alt="">
            <div class="item-info">
              <span>{{item.name}}</span>
              <div class="tag-list">
                <img src="./assets/icons/fill/icon-tag-list.svg" alt="">
                <span>{{item.by}}</span>
              </div>
            </div>
          </div>
          <div class="wrapper-send-btn">
            <button (click)="addItemToFavorites(item)" class="custom-btn" [ngClass]="item.am_i_favorite_this_lyst_item ? 'custom-btn--circle-primary' : 'custom-btn--circle-outline'">
              <img src="../../../../assets/icons/fill/icon-heart.svg" alt="">
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
