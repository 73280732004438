import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ListsHttpService } from "../../../services/http-services/lists/lists-http.service";
import { PAGES_AND_PATHS } from "../../../constants/pages-and-paths";
import { Location } from '@angular/common';
import { ListInterface } from 'src/app/services/http-services/lists/lists-http-intterfaces';

@Component({
  selector: 'tl-create-list',
  templateUrl: './create-list.component.html',
})
export class CreateListComponent {
  MAX_FILE_SIZE = 10 * 1024 * 1024;
  formTriedToSubmit = false;
  isSpinnerActive = false;
  createListForm!: FormGroup;
  coverImage: any = null;
  isMaxSizePhotoError = false;
  isRemoveImageModalOpen = false;
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  listId = 0;
  list: ListInterface | null = null;
  historyState: any;
  isOpenToastOpen = false;
  selectedListAndItem: any;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private toastService: ToastrService,
    private listsHttpService: ListsHttpService,
    private location: Location,
    private actRoute: ActivatedRoute) { }

  ngOnInit() {
    this.historyState = history.state;
    this.buildForm();
    this.actRoute.paramMap.subscribe(params => {
      this.listId = Number(params.get('id') || '');
      if (this.listId) {
        this.getSingleList(this.listId);
      }
    });
  }

  buildForm(): void {
    this.createListForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.maxLength(50)]],
      cover_image: [null, Validators.required],
      visibility: [1],
    });
  }

  triggerFileInput(event: Event): void {
    event.preventDefault();
    this.fileInput.nativeElement.click();
  }

  onFileChange(event: any): void {
    const file = event.target.files[0];
    console.log(file)
    if (file?.size > this.MAX_FILE_SIZE) {
      this.isMaxSizePhotoError = true;
      this.coverImage = null;
      this.createListForm.patchValue({ cover_image: null });
    } else {
      this.isMaxSizePhotoError = false;
      this.createListForm.patchValue({ cover_image: file });
      this.coverImage = URL.createObjectURL(file);
    }
    this.fileInput.nativeElement.value = '';
  }

  onConfirmRemoveFile(): void {
    this.coverImage = null;
    this.createListForm.patchValue({ cover_image: null });
    this.isRemoveImageModalOpen = false;
  }

  onSubmit() {
    console.log(this.list)
    this.list === null ? this.createList() : this.updateList();
  }

  createList(): void {
    this.formTriedToSubmit = true;
    if (this.createListForm.valid) {
      const formData = new FormData();
      const coverImage = this.createListForm.get('cover_image')?.value;
      this.isSpinnerActive = true;
      this.createListForm.patchValue({ cover_image: this.coverImage });
      formData.append('title', this.createListForm.controls['title'].value);
      formData.append('visibility', this.createListForm.controls['visibility'].value ? '1' : '0');
      formData.append('cover_image', coverImage, coverImage.name);

      this.listsHttpService.postList(formData).then(res => {
        const successPageDetails = {
          iconPath: '../../../../assets/icons/outline/icon-successfully-screen1.svg',
          title: '',
          description: 'Your list has been successfully created!',
          btnText: 'Add items to your list',
          redirectToUrl: `/single-list/${res.data.id}/0/cards`
        }
        this.isSpinnerActive = false;
        this.formTriedToSubmit = false;
        this.coverImage = null;
        this.createListForm.reset();

        if (this.historyState.selectedItem || this.historyState.url) {
          this.isOpenToastOpen = true;
          this.selectedListAndItem = { list: res.data, item: this.historyState.selectedItem};
          this.addItemToList(this.historyState.selectedItem?.id, res.data.id, this.historyState.url)
        } else {
          this.router.navigate([PAGES_AND_PATHS.successAction.pagePath], { state: successPageDetails });
        }
      }).catch(({ error }: { error: { errors: { [key: string]: string } } }) => {
        this.isSpinnerActive = false;
        Object.values(error.errors).forEach(error => this.toastService.error(error));
      });
    }
  }

  addItemToList(itemId: number, listId: number, url: string): void {
    if (listId !== null && itemId !== null) {
      this.listsHttpService.copyListItem(listId, itemId).subscribe((response: any) => {
        // { state: this.selectedListAndItem }
        this.router.navigateByUrl(url);
      })
    }
  }

  updateList() {
    this.formTriedToSubmit = true;
    const formData = new FormData();
    const coverImage = this.createListForm.get('cover_image')?.value;
    console.log(coverImage)
    this.isSpinnerActive = true;
    this.createListForm.patchValue({ cover_image: this.coverImage });
    formData.append('title', this.createListForm.controls['title'].value);
    formData.append('visibility', this.createListForm.controls['visibility'].value ? '1' : '0');
    if (coverImage) {
      formData.append('cover_image', coverImage, coverImage.name);
    }

    this.listsHttpService.updateList(formData, this.listId).then(res => {
      this.isSpinnerActive = false;
      this.formTriedToSubmit = false;
      this.coverImage = null;
      this.createListForm.reset();
      this.router.navigate([`/single-list/${res.data.id}/0/cards`]);
    }).catch(({ error }: { error: { errors: { [key: string]: string } } }) => {
      this.isSpinnerActive = false;
      Object.values(error.errors).forEach(error => this.toastService.error(error));
    });
  }

  getSingleList(listId: number) {
    this.listsHttpService.getSingleList(listId).then((res: ListInterface) => {
      if (res)
        this.list = res;
      console.log(this.list)
      this.coverImage = this.list?.lyst_image_url;
      this.createListForm.patchValue({
        cover_image: null,
        title: this.list?.title,
        visibility: this.list?.visibility
      });
    })
  }

  goBack(): void {
    this.location.back();
  }
}
