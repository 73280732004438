<div class="wrapper-lists-search-results">
    <!--     TODO JS - Prosledjujes ove boje ovde u style od teme
               -->
    <!-- <div class="wrapper-title-theme">
        <img style="border: 6px solid #974C8B;"
            src="https://dynamic-media-cdn.tripadvisor.com/media/photo-o/14/2e/3a/88/fresh-lunch.jpg?w=600&h=400&s=1"
            alt="">
        <span style="color: #974C8B;">Cooks</span>
    </div> -->
    <div #listsContainer (scroll)="onScroll()">
        <div *ngFor="let list of lists" class="wrapper-card-list" [routerLink]="[PAGES_AND_PATHS.singleList.pagePath, list.id, 0, 'cards']">
            <img class="image-list"
                [src]="list.lyst_image_url ? list.lyst_image_url : 'https://cdn.vox-cdn.com/thumbor/5d_RtADj8ncnVqh-afV3mU-XQv0=/0x0:1600x1067/1200x900/filters:focal(672x406:928x662)/cdn.vox-cdn.com/uploads/chorus_image/image/57698831/51951042270_78ea1e8590_h.7.jpg'"
                alt="">
            <div class="wrapper-card-list-info">
                <div class="wrapper-badges">
                    <div class="badge gray">
                        <span>{{list.lyst_items_count}} {{list.lyst_items_count === '1' ? 'item' : 'items'}}</span>
                    </div>
                </div>
                <span class="name-list">{{list.title}}</span>
                <span class="time-list">{{list.user.name}}</span>
            </div>
        </div>
    </div>
</div>