import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShouldDisplayDatePipe } from './should-display-date.pipe';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ShouldDisplayDatePipe
  ],
  exports: [
    ShouldDisplayDatePipe
  ]
})
export class ShouldDisplayDateModule { }
