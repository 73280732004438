<div class="wrapper-tab-lists">
  <div *ngFor="let list of lists" [routerLink]="[PAGES_AND_PATHS.singleList.pagePath, list.id, 0, 'cards']"
    class="wrapper-list-preview">
    <div class="wrapper-image-list">
      <img [src]="list.lyst_image_url" alt="">
    </div>
    <div class="wrapper-info-list">
      <div class="title-list">
        <span>{{list.title}}</span>
        <span class="label-item">{{list.lyst_items_count}} {{list.lyst_items_count === 1 ? 'item' : 'items'}}</span>
      </div>
      <span class="time-list">{{list.created_at | timeAgo}}</span>
    </div>
  </div>
</div>
<button [routerLink]="PAGES_AND_PATHS.myLists.pagePath" class="custom-btn custom-btn--gray">
  <span>View all lists</span>
</button>