import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Countries} from "../../../../../constants/general-variables";
import {FormControlErrorMessagesModule} from "../../../../../pipes/form-control-error-messages/form-control-error-messages.module";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {ProfileHttpService} from "../../../../../services/http-services/profile/profile-http.service";
import {SpinnerComponent} from "../../../../../shared-components/spinner/spinner.component";

@Component({
  selector: 'tl-edit-profile-country',
  standalone: true,
  templateUrl: './edit-profile-country.component.html',
  imports: [
    FormControlErrorMessagesModule,
    NgForOf,
    NgIf,
    ReactiveFormsModule,
    NgClass,
    FormsModule,
    SpinnerComponent
  ]
})
export class EditProfileCountryComponent implements OnInit, OnChanges{
  @Input() profileData: any;
  @Output() changeProfile: EventEmitter<any> = new EventEmitter<any>();
  showCountryDropdown = false;
  searchQuery = '';
  editProfileCountryForm!: FormGroup;
  isSpinnerActive = false;
  selectedCountry: any;
  filteredCountries = Countries;
  formTriedToSubmit = false;
  @ViewChild('countrySearch') countrySearch: ElementRef | undefined;
  protected readonly Countries = Countries;

  constructor(private formBuilder: FormBuilder,
              private toastService: ToastrService,
              private profileHttpService: ProfileHttpService) {
  }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm(): void {
    this.editProfileCountryForm = this.formBuilder.group({
      country: ['', [Validators.required]],
    });
    if (this.profileData.country) {
      this.editProfileCountryForm.controls['country'].patchValue(this.profileData.country);
      this.selectedCountry = this.Countries.find(country => {
        return country.name === this.profileData.country;
      })
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.profileDate && changes.profileData.currentValue.country) {
      this.editProfileCountryForm.controls['country'].patchValue(changes.profileData.currentValue.country);
      this.selectedCountry = this.Countries.find(country => {
        return country.name === changes.profileData.currentValue.country;
      })
    }
  }

  onSearchCountries(): void {
    this.filteredCountries = Countries.filter(country => {
      return country.name.toLowerCase().includes(this.searchQuery.toLowerCase());
    });
  }

  onBlurSearchbar(): void {
    setTimeout(() => {
      this.showCountryDropdown = false;
    }, 200);
    this.editProfileCountryForm.controls.country.markAsTouched();
  }

  onSelectCountry(country: any): void {
    this.editProfileCountryForm.controls.country.patchValue(country.name);
    this.selectedCountry = country;
    this.searchQuery = country.name;
  }

  removeSelectedCountry(): void {
    this.selectedCountry = null;
    this.editProfileCountryForm.controls.country.patchValue('');
    this.onSearchCountries();
    setTimeout(() => {
      this.countrySearch?.nativeElement.focus();
    }, 200);
  }

  onCancelChanges(): void {
    this.editProfileCountryForm.reset();
    this.searchQuery = '';
    this.editProfileCountryForm.controls['country'].patchValue(this.profileData.country);
    this.selectedCountry = this.Countries.find(country => {
      return country.name === this.profileData.country;
    })
  }

  onSubmitEditProfileCountryForm(): void {
    if (this.editProfileCountryForm.valid) {
      this.isSpinnerActive = true;
      this.profileHttpService.editAuthUserProfile(this.editProfileCountryForm.value).then(res => {
        this.isSpinnerActive = false;
        this.changeProfile.emit(res.data);
        this.toastService.success(res.message);
      }).catch(({error}: {error:{ errors: { [key: string]: string }}}) => {
        this.isSpinnerActive = false;
        Object.values(error.errors).forEach(error => this.toastService.error(error));
      });
    }
  }
}
