import { Component, Input } from '@angular/core';
import { Theme } from 'src/app/services/http-services/home/home-interfaces';

@Component({
  selector: 'app-theme',
  standalone: true,
  imports: [],
  templateUrl: './theme.component.html',
})
export class ThemeComponent {
  @Input() theme!: Theme;

}
