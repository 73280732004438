import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeAgoPipe } from './time-ago.pipe';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TimeAgoPipe
  ],
  exports: [
    TimeAgoPipe
  ]
})
export class TimeAgoModule { }
