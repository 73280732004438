import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PAGES_AND_PATHS} from '../../../constants/pages-and-paths';
import {OuterPagesGuardService} from '../../../guards/outer-pages-guard.service';
import {CheckYourEmailComponent} from './check-your-email.component';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.checkYourEmail.pageInRouting,
    data: {data: PAGES_AND_PATHS.checkYourEmail},
    component: CheckYourEmailComponent,
    canActivate: [OuterPagesGuardService],
    loadChildren: () => import('./check-your-email.module').then(m => m.CheckYourEmailModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CheckYourEmailRoutingModule { }
