import { NgForOf } from '@angular/common';
import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { TimeAgoModule } from 'src/app/pipes/time-ago/time-ago.module';
import { SearchHttpService } from 'src/app/services/http-services/search/search-http.service';

@Component({
  selector: 'app-lists',
  standalone: true,
  imports: [TimeAgoModule, NgForOf, RouterModule,],
  templateUrl: './lists.component.html',
})
export class ListsComponent implements OnInit {
  @Input() searchTerm = '';
  @Input() isLiveSearch = false;
  lists:any[]=[];
  pagination!:TlPagination;
  @ViewChild('listsContainer', { static: false }) private listsContainer!: ElementRef;
  pageNum = 1;
  loading = false;
  PAGES_AND_PATHS = PAGES_AND_PATHS;

  constructor(
    private searchHttpService: SearchHttpService
  ) { }

  ngOnInit(): void {
    // this.getLists();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.getLists();
    }
  }

  getLists(): void {
    this.loading = true;
    this.searchHttpService.formattedGetListsResults(this.pageNum, this.isLiveSearch,this.searchTerm).then((response: {data: any[], pagination: TlPagination}) => {
      console.log('accounts', response)
      this.lists = [...this.lists, ...response.data];
      this.pagination = response.pagination;
      this.loading = false;
    })
  }

  onScroll(): void {
    const container = this.listsContainer.nativeElement;
    const scrollTop = container.scrollTop;
    const offset = 100;

    if (!this.loading && scrollTop < offset && !this.loading && ((this.pagination?.currentPage ?? 0) < (this.pagination?.lastPage ?? 0))) {
      this.pageNum++;
      this.getLists();
    }
  }

}
