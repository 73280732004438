import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChatHelperService } from 'src/app/services/helpers/chat-helper.service';
import { ChatHttpService } from 'src/app/services/http-services/chat/chat-http.service';

@Component({
  selector: 'app-delete-chat-modal',
  standalone: true,
  imports: [],
  templateUrl: './delete-chat-modal.component.html',
})
export class DeleteChatModalComponent {
  @Input() conversationId!: number;
  @Input() deleteChatText: any;
  @Output() closeDeleteChatModal = new EventEmitter<boolean>();
  @Output() deleteChat = new EventEmitter<boolean>(false);

  constructor(
    private chatsHttpService: ChatHttpService,
    private chatHelperService: ChatHelperService
  ) { }

  takeAction() {
    this.deleteChatText.buttonText === 'Delete' ? this.delete() : this.leaveGroup();
  }

  delete() {
    this.chatsHttpService.deleteConversation(this.conversationId).subscribe(res => {
      this.closeDeleteChatModal.emit(true);
      this.deleteChat.emit(true);
      this.chatHelperService.refreshListOfConversations(true);
    });
  }

  leaveGroup() {
    this.chatsHttpService.leaveConversation(this.conversationId).subscribe(res => {
      this.closeDeleteChatModal.emit(true);
      this.deleteChat.emit(true);
      this.chatHelperService.refreshListOfConversations(true);
    });
  }

}
