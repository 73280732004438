import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { from, Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { AuthenticationHelperService } from 'src/app/services/helpers/authentication-helper.service';
import { FollowersHttpService } from 'src/app/services/http-services/followers/followers-http.service';
import { Following } from 'src/app/services/http-services/followers/followers-interface';
import { ProfileHttpService } from 'src/app/services/http-services/profile/profile-http.service';

@Component({
  selector: 'app-following-followers',
  templateUrl: './following-followers.component.html',
})
export class FollowingFollowersComponent implements OnInit {
  tabSelected = '';
  loggedUser: any;
  pagination!: TlPagination;
  users: Array<Following> = [];
  PAGES_AND_PATHS = PAGES_AND_PATHS;
  private searchSubject = new Subject<string>();
  searchTerm: string = '';
  friends: Array<Following> = [];
  experts: Array<Following> = [];
  pageNum = 1;
  loading = false;
  isRemoveUserModalOpen = false;
  selectedUser: Following | null = null;
  removeModalText = {
    title: '',
    description: '',
    button: ''
  };
  profileData: any;
  userId: number = 0;

  constructor(
    private authenticationHelperService: AuthenticationHelperService,
    private actRoute: ActivatedRoute,
    private router: Router,
    private followersHttpService: FollowersHttpService,
    private profileHttpService: ProfileHttpService
  ) { }

  ngOnInit() {
    this.getParams();
    this.loggedUser = this.authenticationHelperService.getUserData;
    this.searchSubject.pipe(
      debounceTime(300),
      switchMap((searchTerm: string) => {
        if (this.tabSelected === 'followers') {
          return this.getFollowers(searchTerm);
        } else {
          return this.getFollowing(searchTerm);
        }
      })
    ).subscribe((response: any) => {
      this.users = response.data;
      this.pagination = response.pagination;
    });
  }

  getParams() {
    this.actRoute.paramMap.subscribe(params => {
      this.tabSelected = params.get('type') || '';
      this.userId = Number(params.get('userId'));
      this.getUserProfile();
      this.tabSelected === 'followers' ? this.getFollowers() : this.getFollowing();
    });
  }

  selectTab(tabSelected: string) {
    this.tabSelected = tabSelected;
    this.users = [];
    this.searchTerm = '';
    this.router.navigate([PAGES_AND_PATHS.followingFollowers.pagePath, this.userId, this.tabSelected])
  }

  getFollowers(searchTerm?: string) {
    this.loading = true;
    if (this.userId !== 0) {
      return from(
        this.followersHttpService.formattedGetAllFollowersUser(this.userId, this.pageNum, searchTerm).then((response: { data: Array<Following>, pagination: TlPagination, friends: Array<Following>, experts: Array<Following> }) => {
          console.log('FOLLOWERS', response)
          this.users = [...this.users, ...response.data];
          this.pagination = response.pagination;
          this.friends = response.friends;
          this.experts = response.experts;
          this.loading = false;
          return response
        })
      );
    } else {
      return from(
        this.followersHttpService.formattedGetAllFollowers(this.pageNum, searchTerm).then((response: { data: Array<Following>, pagination: TlPagination, friends: Array<Following>, experts: Array<Following> }) => {
          console.log('FOLLOWERS', response)
          this.users = [...this.users, ...response.data];
          this.pagination = response.pagination;
          this.friends = response.friends;
          this.experts = response.experts;
          this.loading = false;
          return response
        })
      );
    }
  }

  getFollowing(searchTerm?: string) {
    this.loading = true;
    if (this.userId !== 0) {
      return from(
        this.followersHttpService.formattedGetAllFollowingUser(this.userId, this.pageNum, searchTerm).then((response: { data: Array<Following>, pagination: TlPagination, friends: Array<Following>, experts: Array<Following> }) => {
          console.log('FOLLOWING', response)
          this.users = [...this.users, ...response.data];
          this.pagination = response.pagination;
          this.friends = response.friends;
          this.experts = response.experts;
          this.loading = false;
          return response
        })
      )
    } else {
      return from(
        this.followersHttpService.formattedGetAllFollowing(this.pageNum, searchTerm).then((response: { data: Array<Following>, pagination: TlPagination, friends: Array<Following>, experts: Array<Following> }) => {
          console.log('FOLLOWING', response)
          this.users = [...this.users, ...response.data];
          this.pagination = response.pagination;
          this.friends = response.friends;
          this.experts = response.experts;
          this.loading = false;
          return response
        })
      )
    }
  }

  getUserProfile(): void {
    if (this.userId !== 0) {
      this.profileHttpService.getUserProfile({ userId: this.userId }).then(res => {
        this.profileData = res.data;
      })
    } else {
      this.profileHttpService.getAuthUserProfile().then(res => {
        this.profileData = res.data;
      })
    }
  }

  followUnfollow(user: Following) {
    this.followersHttpService.followUnfollowUser(user.id).subscribe((response: any) => {
      const userSelected = this.users.findIndex(user => user.id === response.data.id);
      this.users[userSelected].am_i_following_this_user = response.data.am_i_following_this_user;
      this.removeFromFriendsExperts(user);
    })
  }

  removeFromFriendsExperts(user: Following) {
    const maxFriends = 3;
    const maxExperts = 3;

    const isExpert = this.hasExpertRole(user.roles);
    const targetArray = isExpert ? this.experts : this.friends;
    const userIndex = targetArray.findIndex(targetUser => targetUser.id === user.id);

    if (userIndex !== -1) {
      // User is already in the target array, remove them
      targetArray.splice(userIndex, 1);
      console.log(`Removed user with ID ${user.id} from ${isExpert ? 'experts' : 'friends'}.`);
    } else {
      // User is not in the target array
      if (targetArray.length < (isExpert ? maxExperts : maxFriends)) {
        targetArray.push(user);
        console.log(`Added user with ID ${user.id} to ${isExpert ? 'experts' : 'friends'}.`);
      } else {
        console.log(`Cannot add user with ID ${user.id} to ${isExpert ? 'experts' : 'friends'}, maximum limit reached.`);
      }
    }
  }

  hasExpertRole(roles: any): boolean {
    return roles.some((role: any) => role.name === 'expert');
  }

  onScroll(event: any) {
    console.log(event);
    const threshold = 100;
    const position = event.target.scrollTop + event.target.offsetHeight;
    const height = event.target.scrollHeight;

    if (position > height - threshold && !this.loading && (this.pagination?.currentPage ?? 0) < (this.pagination?.lastPage ?? 0)) {
      this.pageNum++
      this.tabSelected === 'followers' ? this.getFollowers() : this.getFollowing();
    }
  }

  openRemoveModal(user: Following) {
    this.isRemoveUserModalOpen = true;
    this.selectedUser = user;
    this.removeModalText.title = `Remove ${this.selectedUser.name}?`;
    this.removeModalText.description = `The Lysts won’t notify ${this.selectedUser.name} that they have been removed from your followers.`
    this.removeModalText.button = 'Remove';
  }

  takeAction() {
    this.isRemoveUserModalOpen = false;
    if (this.selectedUser !== null) {
      this.removeFollower(this.selectedUser.id)
    }
  }

  removeFollower(userId: number): void {
    this.followersHttpService.removeFollower(userId).subscribe((response: any) => {
      console.log('removeFollower', response)
      const index = this.users?.findIndex(user => user.id === userId);
      if (index > -1 && index < this.users?.length) {
        this.users?.splice(index, 1);
      }
    })
  }

  onSearchChange(event: any): void {
    this.searchTerm = event.target.value;
    this.pageNum = 1;
    this.searchSubject.next(this.searchTerm);
  }

  resetSearch() {
    this.searchTerm = '';
    this.searchSubject.next('');
  }

}
