import { Component } from '@angular/core';
import {InsightsIndexComponent} from "../insights-index/insights-index.component";
import {InsightsPagesEnums} from "../../../../enums/profile-enums";
import {InsightsContentYouCreateComponent} from "../insights-content-you-create/insights-content-you-create.component";
import {InsightsDetailsComponent} from "../insights-details/insights-details.component";
import {NgIf} from "@angular/common";

@Component({
  selector: 'tl-insights',
  standalone: true,
  imports: [
    InsightsIndexComponent,
    InsightsContentYouCreateComponent,
    InsightsDetailsComponent,
    NgIf
  ],
  templateUrl: './insights.component.html',
})
export class InsightsComponent {
  activeInsightsPage = InsightsPagesEnums.index;
  selectedListId: any;
  selectedDetailsType = ''
  protected readonly InsightsPagesEnums = InsightsPagesEnums;
}
