<div class="wrapper-custom-search">
  <img class="icon-search" src="../../../assets/icons/outline/icon-search.svg" alt="">
  <input type="text" placeholder="Search..." [value]="urlParams.search" (input)="onSearchChange($event)">
  <div *ngIf="urlParams.search?.length" (click)="clearSearch()" class="wrapper-delete-search">
    <img src="../../../assets/icons/outline/icon-close.svg" alt="">
  </div>
</div>
<div class="wrapper-tab-lists" (scroll)="onScroll($event)">
  <div class="wrapper-custom-radiobutton-list">
    <label *ngFor="let tagItem of tagListItems">
      <input type="radio" [value]="tagItem.id" [ngModel]="selectedListItemId" (ngModelChange)="onChangeTagListItem($event)">
      <div class="custom-radiobutton">
        <div class="circle-radio">
          <div class="circle-center"></div>
        </div>
        <div class="wrapper-language-info">
          <div class="wrapper-list-preview">
            <div class="wrapper-image-list">
              <img [src]="tagItem.lyst_item_image_url" alt="">
            </div>
            <div class="wrapper-info-list">
              <div class="info-list-tag">
                <div class="title-list">
                  <span>{{tagItem.name}}</span>
                </div>
                <div class="author-list">
                  <span>{{tagItem.lyst.title}}</span>
                </div>
              </div>
              <div class="wrapper-review-item">
                <img src="../../../assets/icons/rating/icon-star-fill.svg" alt="">
                <span>{{tagItem.average_rating}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </label>
  </div>
</div>
