<div class="wrapper-custom-datepicker">
  <div class="custom-datepicker" (click)="datepicker.open()">
    <input
      matInput
      [matDatepicker]="datepicker"
      [max]="currentDate"
      [value]="defaultDate"
      [placeholder]="'Select a date'"
      (dateChange)="onDateChange($event)"
      readonly>
    <img src="../../../assets/icons/outline/icon-calendar.svg" alt="">
    <mat-datepicker #datepicker></mat-datepicker>
  </div>
</div>


