<div class="wrapper-page">
  <app-my-content></app-my-content>
  <div class="wrapper-home-page">
    <tl-header></tl-header>
    <div class="wrapper-following-followers">
      <div class="wrapper-custom-tabs">
        <div class="custom-tab" [ngClass]="{'active': tabSelected === 'followers'}" (click)="selectTab('followers')">
          <span>{{profileData?.followers_count}} {{profileData?.followers_count > 1 ? 'Followers' : 'Follower'}}</span>
        </div>
        <div class="custom-tab" [ngClass]="{'active': tabSelected === 'following'}" (click)="selectTab('following')">
          <span>{{profileData?.followings_count}} Following</span>
        </div>
      </div>
      <!--   TODO JS - Kad search sklanjas stvari koje nisu potrbne , pogledati dizajn.     -->
      <div class="wrapper-custom-search">
        <img class="icon-search" src="../../../assets/icons/outline/icon-search.svg" alt="">
        <input type="text" placeholder="Search..." [(ngModel)]="searchTerm" (input)="onSearchChange($event)">
        <div (click)="resetSearch()" class="wrapper-delete-search">
          <img src="../../../assets/icons/outline/icon-close.svg" alt="">
        </div>
      </div>
      <span class="title-following-followers">Categories</span>
      <div class="wrapper-boxes-following-followers">
        <div class="wrapper-box-friends"
          [routerLink]="[PAGES_AND_PATHS.friendsExperts.pagePath, userId, tabSelected, 'friends']">
          <div class="box-images">
            <img *ngFor="let friend of friends"
              [src]="friend.avatar ? friend.avatar : '../../../../assets/images/avatar-user.png'" alt="">
          </div>
          <div class="box-info">
            <span class="text1">Friends</span>
            <span *ngIf="loggedUser.id === profileData?.id" class="text2">{{tabSelected === 'followers' ? 'Friends who
              follows you' : 'Friends you
              follow'}}</span>
            <span *ngIf="loggedUser.id !== profileData?.id" class="text2">{{tabSelected === 'followers' ? 'Friends who
              follows' : 'Friends who
              follow'}}</span>
          </div>
        </div>
        <div class="wrapper-box-friends purple"
          [routerLink]="[PAGES_AND_PATHS.friendsExperts.pagePath, userId, tabSelected, 'experts']">
          <div class="box-images">
            <img *ngFor="let expert of experts"
              [src]="expert.avatar ? expert.avatar : '../../../../assets/images/avatar-user.png'" alt="">
          </div>
          <div class="box-info">
            <span class="text1">Experts</span>
            <span *ngIf="loggedUser.id === profileData?.id" class="text2">{{tabSelected === 'followers' ? 'Experts who
              follows you' : 'Experts you
              follow'}}</span>
            <span *ngIf="loggedUser.id !== profileData?.id" class="text2">{{tabSelected === 'followers' ? 'Experts who
              follows' : 'Experts who
              follow'}}</span>
          </div>
        </div>
      </div>
      <span class="title-following-followers">{{this.tabSelected === 'followers' ? 'All followers' : 'All
        following'}}</span>
      <div class="wrapper-list-users-ff" (scroll)="onScroll($event)">
        <!-- ispod ide klasa expert -->
        <div *ngFor="let user of users" class="wrapper-user-item" [ngClass]="{'expert': user.is_expert}">
          <div [routerLink]="[PAGES_AND_PATHS.profile.pagePath, user.id]" class="wrapper-info-user-ff">
            <div class="avatar-user">
              <img [src]="user.avatar ? user.avatar : '../../../../assets/images/avatar-user.png'" alt="">
            </div>
            <span>{{user.name}}</span>
            <img *ngIf="user.is_expert" src="../../../../assets/icons/fill/icon-badge.svg" alt="">
          </div>
          <button *ngIf="tabSelected === 'followers' && profileData?.id === loggedUser.id"
            (click)="openRemoveModal(user)" class="custom-btn custom-btn--outline">
            <span>Remove</span>
          </button>
          <button *ngIf="tabSelected === 'followers' && profileData?.id !== loggedUser.id"
            (click)="followUnfollow(user)" class="custom-btn"
            [ngClass]="user.am_i_following_this_user ? 'custom-btn--outline' : 'custom-btn--primary'">
            <span>{{user.am_i_following_this_user ? 'Following' : 'Follow'}}</span>
          </button>
          <button *ngIf="tabSelected === 'following'" (click)="followUnfollow(user)" class="custom-btn"
            [ngClass]="user.am_i_following_this_user ? 'custom-btn--outline' : 'custom-btn--primary'">
            <span>{{user.am_i_following_this_user ? 'Following' : 'Follow'}}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<tl-confirm-modal *ngIf="isRemoveUserModalOpen" [modalIcon]="'../../../../../assets/icons/outline/icon-delete.svg'"
  [modalTitle]="removeModalText.title" [confirmBtnText]="removeModalText.button"
  [modalDescription]="removeModalText.description" (cancelModal)="isRemoveUserModalOpen = false"
  (confirmModal)="takeAction()">
</tl-confirm-modal>