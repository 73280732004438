import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { ShareEntitiesEnums } from 'src/app/enums/chat-enums';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { AuthenticationHelperService } from 'src/app/services/helpers/authentication-helper.service';
import { ListsHelperService } from 'src/app/services/helpers/lists-helper.service';
import { ItemsHttpService } from 'src/app/services/http-services/items/items-http.service';
import { DeleteModalText } from 'src/app/services/http-services/lists/lists-http-intterfaces';

@Component({
  selector: 'app-single-review',
  templateUrl: './single-review.component.html',
})
export class SingleReviewComponent implements OnInit {
  type = '';
  reviewId = 0;
  typeId = 0;
  review: any;
  comments: any[] = [];
  pagination!: TlPagination;
  isDropdownOpen = false;
  isDeleteModalOpen = false;
  modalText: DeleteModalText = { title: '', description: '', button: '' };
  loggedUser: any;
  isSharePostModalOpen = false;
  ShareEntitiesEnums = ShareEntitiesEnums;
  reviewUrl = '';
  commentForm!: FormGroup;
  PAGES_AND_PATHS = PAGES_AND_PATHS;

  constructor(
    private actRoute: ActivatedRoute,
    private itemsHttpService: ItemsHttpService,
    private router: Router,
    private toastService: ToastrService,
    private formBuilder: FormBuilder,
    private authHelper: AuthenticationHelperService,
    public listsHelperService: ListsHelperService
  ) { }

  ngOnInit(): void {
    this.loggedUser = this.authHelper.getUserData;
    this.buildForm();
    this.getParams();
  }

  buildForm(): void {
    this.commentForm = this.formBuilder.group({
      comment: ['', [Validators.required]],
    });
  }

  getParams(): void {
    this.actRoute.paramMap.subscribe(params => {
      this.reviewId = Number(params.get('id'));
      this.typeId = Number(params.get('typeId'));
      this.type = params.get('type') as string;
      this.type === 'list' ? this.getSingleListReview(this.reviewId, this.typeId) : this.getSingleItemReview(this.reviewId, this.typeId);
    })
  }

  getSingleListReview(reviewId: number, typeId: number): void {
    this.itemsHttpService.getSingleListReview(reviewId, typeId).then((response: any) => {
      console.log('getSingleListReview', response.data.review);
      console.log('comments', response.data.comments.data)
      this.review = response.data.review;
      this.comments = response.data.comments.data;
      this.pagination = response.data.comments;
    })
  }

  getSingleItemReview(reviewId: number, typeId: number): void {
    this.itemsHttpService.getSingleItemReview(reviewId, typeId).then((response: any) => {
      console.log('getSingleitemReview', response.data.review)
      this.review = response.data.review;
      this.comments = response.data.comments.data;
      this.pagination = response.data.comments;
    })
  }

  openViewMore(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  openEditReview() {
    const reviewDetails = {
      obj: this.review
    }
    this.router.navigate([PAGES_AND_PATHS.updateReview.pagePath, this.type === 'list' ? 'list' : 'item'], { state: reviewDetails })
  }

  openDeleteModal() {
    this.isDeleteModalOpen = true;
    this.modalText.title = 'Delete review?';
    this.modalText.description = 'Are you sure you wish to delete this review? This action cannot be undone.'
    this.modalText.button = 'Delete';
  }

  takeAction(): void {
    this.type === 'list' ? this.deleteListReview() : this.deleteItemReview();
  }

  deleteItemReview(): void {
    this.isDeleteModalOpen = false;
    this.itemsHttpService.deleteItemReview(this.review.id).then((response: any) => {
      this.toastService.success(response.message);
      this.router.navigate([PAGES_AND_PATHS.singleItem.pagePath, this.typeId]);
    })
  }

  deleteListReview(): void {
    this.isDeleteModalOpen = false;
    this.itemsHttpService.deleteListReview(this.review.id).then((response: any) => {
      this.toastService.success(response.message);
      this.router.navigate([PAGES_AND_PATHS.singleList.pagePath, this.typeId, 0, 'reviews']);
    })
  }

  shareReview(): void {
    this.isSharePostModalOpen = true;
  }

  toggleLikeReview(review: any): void {
    this.itemsHttpService.addReviewToFavorites(review.id).then((response: any) => {
      this.review = response.data;
    })
  }

  toggleLikeComment(comment: any): void {
    this.itemsHttpService.addCommentToFavorites(comment.id).then((response: any) => {
      const index = this.comments.findIndex(obj => obj.id === comment.id);
      this.comments[index].am_i_liked_this = response.data.am_i_liked_this;
      this.comments[index].likes_count = response.data.likes_count;
    })
  }

  storeComment(): void {
    this.itemsHttpService.storeReviewComment(this.review.id, this.commentForm.value).then((response: any) => {
      this.toastService.success(response.message);
      this.commentForm.reset();
      // this.comments.push(response.data);
      this.type === 'list' ? this.getSingleListReview(this.reviewId, this.typeId) : this.getSingleItemReview(this.reviewId, this.typeId);
    })
  }

}
