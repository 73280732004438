<div class="wrapper-my-content">
  <div class="wrapper-header-my-content">
    <span>My content</span>
    <button (click)="openDropdown = true" class="custom-btn custom-btn--circle-primary">
      <img src="../../../assets/icons/outline/icon-plus.svg" alt="">
    </button>
    <div *ngIf="openDropdown">
      <button [routerLink]="[PAGES_AND_PATHS.createList.pagePath]">create list</button>
      <button [routerLink]="[PAGES_AND_PATHS.createPost.pagePath]">create post</button>
    </div>
  </div>
  <div class="wrapper-content-my-content">
    <div class="tabs-my-content">
      <div (click)="selectTab('lists')" class="tab-my-content" [ngClass]="{'active': tabSelected === 'lists'}">
        <span>My lists</span>
      </div>
      <div (click)="selectTab('posts')" class="tab-my-content" [ngClass]="{'active': tabSelected === 'posts'}">
        <span>My posts</span>
      </div>
      <div (click)="selectTab('listers')" class="tab-my-content" [ngClass]="{'active': tabSelected === 'listers'}">
        <span>My listers</span>
      </div>
    </div>
    <div>
      <app-lists *ngIf="tabSelected === 'lists'"></app-lists>
      <app-posts *ngIf="tabSelected === 'posts'" [myPosts]="true"></app-posts>
      <app-listers *ngIf="tabSelected === 'listers'"></app-listers>
    </div>
  </div>
</div>
