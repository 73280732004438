import {ElementRef, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UiHelperService {

  constructor() {
  }

  checkIfClickedOutsideOfTheElement(toggleButton: ElementRef | undefined, menu: ElementRef | undefined, e: any): boolean {
    return e.target !== toggleButton?.nativeElement && !toggleButton?.nativeElement.contains(e.target)
      && e.target !== menu?.nativeElement && !menu?.nativeElement.contains(e.target);
  }
}
