import { Component, OnInit } from '@angular/core';
import { Subject, from } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { HomeService } from 'src/app/services/http-services/home/home.service';

@Component({
  selector: 'app-all-themes',
  templateUrl: './all-themes.component.html',
})
export class AllThemesComponent implements OnInit {
  pageNum = 1;
  searchTerm: string = '';
  pagination!: TlPagination;
  themes: any[] = [];
  colors: string[] = [];
  private searchSubject = new Subject<string>();

  constructor(
    private homeHttpService: HomeService
  ) { }

  ngOnInit() {
    this.getAllThemes();
    this.searchSubject.pipe(
      debounceTime(300),
      switchMap((searchTerm: string) => from(this.getAllThemes(searchTerm)))
    ).subscribe(response => {
      this.themes = response.data;
      this.pagination = response.pagination;
    });
  }

  getAllThemes(searchTerm?: string) {
    return from(
      this.homeHttpService.formattedGetAllThemes(this.pageNum, searchTerm).then((response: {
        data: Array<any>,
        pagination: TlPagination,
      }) => {
        console.log('get all themes', response)
        this.themes = [...this.themes, ...response.data];
        this.pagination = response.pagination;
        return response;
      })
    );
  }

  onSearchChange(event: any): void {
    const keyword = event.target.value;
    this.pageNum = 1;
    this.searchSubject.next(keyword);
  }
}
