<div class="wrapper-page">
  <app-my-content></app-my-content>
  <div class="wrapper-home-page">
    <tl-header></tl-header>
    <div class="home-page">
      <div class="wrapper-header-home-page">
        <div class="header-home-page">
          <div class="info-header">
            <span class="title">Latest Posts</span>
            <span class="subtitle">Latest posts of the people you follow</span>
          </div>
          <button [routerLink]="[PAGES_AND_PATHS.latestPost.pagePath]" type="submit"
            class="custom-btn custom-btn--gray">
            <span>View all</span>
          </button>
        </div>
        <swiper-container class="mySwiper wrapper-latest-post" slides-per-view="1.5">
          <swiper-slide *ngFor="let post of homepageData?.followedUsersPosts">
            <app-latest-post [post]="post"
              [routerLink]="[PAGES_AND_PATHS.singlePost.pagePath, post.id]"></app-latest-post>
          </swiper-slide>
        </swiper-container>
        <div class="header-home-page">
          <div class="info-header">
            <span class="title">Listers</span>
            <span class="subtitle">Updated hourly most popular listers ranking in the region</span>
          </div>
          <!-- <button type="submit" class="custom-btn custom-btn--gray">
            <span>View all</span>
          </button> -->
        </div>
        <swiper-container class="mySwiper wrapper-listers-home-page" slides-per-view="5">
          <swiper-slide *ngFor="let lister of homepageData?.recommendedListers">
            <app-lister [lister]="lister" [routerLink]="[PAGES_AND_PATHS.profile.pagePath, lister?.id]"></app-lister>
          </swiper-slide>
        </swiper-container>
        <div class="header-home-page">
          <div class="info-header">
            <span class="title">Continue browsing</span>
          </div>
        </div>
        <div class="wrapper-themes-home">
          <app-theme [routerLink]="[PAGES_AND_PATHS.singleTheme.pagePath, theme.id]"
            *ngFor="let theme of homepageData?.recommendedSubcategories" [theme]="theme"
            [style.background-color]="theme?.color" class="wrapper-card-theme"></app-theme>
        </div>
        <div class="header-home-page">
          <div class="info-header">
            <span class="title">Recommended lists</span>
          </div>
          <button [routerLink]="PAGES_AND_PATHS.recommendedLists.pagePath" type="submit" class="custom-btn custom-btn--gray">
            <span>View all</span>
          </button>
        </div>
        <div class="grid">
          <app-list class="grid__s-4" *ngFor="let list of homepageData?.recommendedLysts[0].data" [list]="list"></app-list>
        </div>
      </div>
    </div>
  </div>
</div>
