import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayEditAndDeleteMessage',
  standalone: true
})
export class DisplayEditAndDeleteMessagePipe implements PipeTransform {

  transform(message: any, selectedMessage: any, loggedUser: any): boolean {
    return (
      loggedUser.id === selectedMessage?.user_id &&
      selectedMessage.type !== 'video' &&
      selectedMessage.type !== 'image' &&
      selectedMessage.type !== 'share' &&
      selectedMessage.type !== 'attachment'
    );
  }

}
