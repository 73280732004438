import {Component, OnInit} from '@angular/core';
import {UserRoles} from "../../enums/general-enums";
import {PAGES_AND_PATHS} from "../../constants/pages-and-paths";
import { FollowersHttpService } from 'src/app/services/http-services/followers/followers-http.service';
import {ProfileTabsEnums} from "../../enums/profile-enums";
import {ShareEntitiesEnums} from "../../enums/chat-enums";
import {ProfileHttpService} from "../../services/http-services/profile/profile-http.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ChatHttpService} from "../../services/http-services/chat/chat-http.service";
import {AuthenticationHelperService} from "../../services/helpers/authentication-helper.service";


@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
})
export class MyProfileComponent implements OnInit {
  profileData: any;
  activeTab = ProfileTabsEnums.lysts;
  userId!: number;
  isAuthUserProfile = true;
  isConfirmUnfollowModalOpen = false;
  isShareProfileModalOpen = false;
  profileUrl = '';
  baseUrl = '';
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;
  protected readonly ProfileTabsEnums = ProfileTabsEnums;
  protected readonly UserRoles = UserRoles;
  protected readonly ShareEntitiesEnums = ShareEntitiesEnums;

  constructor(private profileHttpService: ProfileHttpService,
              private router: Router,
              private followersHttpService: FollowersHttpService,
              private activatedRoute: ActivatedRoute,
              private chatHttpService: ChatHttpService,
              public authHelperService: AuthenticationHelperService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(res => {
      console.log(res);
      this.userId = res['userId'];
      this.isAuthUserProfile = Number(this.userId) === this.authHelperService.getUserData.id;
      this.profileUrl = window.location.href;
      this.baseUrl = window.location.origin;
      if (this.isAuthUserProfile) {
        this.getAuthUserProfile();
      } else {
        this.getUserProfile();
      }
    });
  }
  getAuthUserProfile(): void {
    this.profileHttpService.getAuthUserProfile().then(res => {
      this.profileData = res.data;
      this.authHelperService.setUserData(this.profileData);
    })
  }

  getUserProfile(): void {
    this.profileHttpService.getUserProfile({userId: this.userId}).then(res => {
      this.profileData = res.data;
    })
  }

  goToChatWithUser(): void  {
    if (this.profileData.existing_conversation_id_between_me_and_this_user) {
      this.router.navigate(['/single-conversation', this.profileData.existing_conversation_id_between_me_and_this_user]);
    } else {
      this.chatHttpService.createChat({participants: [this.authHelperService.getUserData.id, this.userId] , is_group: false}).then(res => {
        this.router.navigate(['/single-conversation', res.data.id]);
      });
    }
  }

  followUnfollowUser() {
    if (!this.isAuthUserProfile) {
      this.followersHttpService.followUnfollowUser(this.userId).subscribe((response: any) => {
        this.isConfirmUnfollowModalOpen = false;
        this.profileData.am_i_following_this_user = !this.profileData.am_i_following_this_user;
        this.profileData.am_i_following_this_user ? this.profileData.followers_count++ : this.profileData.followers_count--;
      });
    }
  }

  toggleFavoriteProfile(): void {
    this.profileHttpService.toggleProfileFavorite({userId: this.userId}).then(res => {
      this.profileData.am_i_favorite_this_user = !this.profileData.am_i_favorite_this_user;
    });
  }
}
