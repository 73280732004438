import {Component} from '@angular/core';
import {SettingsTabs} from "../../enums/settings-enums";
import {PAGES_AND_PATHS} from "../../constants/pages-and-paths";

@Component({
  selector: 'tl-settings',
  templateUrl: './settings.component.html',
})
export class SettingsComponent {
  activeTab: SettingsTabs = SettingsTabs.insights;
  isAccountDropdownOpen = false;
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;
  protected readonly SettingsTabs = SettingsTabs;
}
