import { NgForOf, NgIf, NgStyle, SlicePipe } from '@angular/common';
import { Component, ElementRef, HostListener, Input, OnChanges, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { ClickedOutsideModule } from 'src/app/directives/clicked-outside/clicked-outside.module';
import { AuthenticationHelperService } from 'src/app/services/helpers/authentication-helper.service';
import { ListsHelperService } from 'src/app/services/helpers/lists-helper.service';
import { ItemsHttpService } from 'src/app/services/http-services/items/items-http.service';
import { Column, DeleteModalText, ListItem } from 'src/app/services/http-services/lists/lists-http-intterfaces';
import { ListsHttpService } from 'src/app/services/http-services/lists/lists-http.service';
import { ConfirmModalComponent } from 'src/app/shared-components/confirm-modal/confirm-modal.component';
import { CopyItemModalComponent } from '../copy-item-modal/copy-item-modal.component';
import { CopyToastNotificationComponent } from 'src/app/shared-components/copy-toast-notification/copy-toast-notification.component';

@Component({
  selector: 'app-item-row',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    ConfirmModalComponent,
    FormsModule,
    NgStyle,
    RouterModule,
    ClickedOutsideModule,
    SlicePipe,
    CopyItemModalComponent,
    CopyToastNotificationComponent
  ],
  templateUrl: './item-row.component.html',
})
export class ItemRowComponent implements OnChanges, OnInit {
  @Input() item!: ListItem;
  @Input() addNewRow = false;
  @Input() isLast: boolean = false;
  @Input() columns: Column[] = [];
  isDeleteButtonVisible = false;
  isDeleteModalOpen = false;
  deleteModalText: DeleteModalText = { title: '', description: '', button: '' };
  selectedItem: ListItem | null = null;
  isEditing: { [key: string]: boolean } = {};
  editingValues: { [key: string]: string } = {};
  PAGES_AND_PATHS = PAGES_AND_PATHS;
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  @Input() user_id: any;
  loggedUser: any;
  isNewItemCreated = false;
  @ViewChild('input') inputElement!: ElementRef;
  isMasterItem = 0;
  currentEditingField: string | null = null;
  isCopyModalOpen = false;
  isCopyDropdownOpen = false;
  url = '';
  isOpenToastOpen = false;
  selectedListAndItem: any;
  historyState: any;

  @HostListener('document:click', ['$event'])
  handleOutsideClick(event: MouseEvent) {
    if (this.currentEditingField && !this.isClickInsideInput(event)) {
      this.saveEdit(this.currentEditingField);
    }
  }

  constructor(
    private listsHttpService: ListsHttpService,
    private toastService: ToastrService,
    private listsHelperService: ListsHelperService,
    private itemsHttpService: ItemsHttpService,
    private renderer: Renderer2,
    private actRoute: ActivatedRoute,
    private authenticationHelperService: AuthenticationHelperService,
    private router: Router
  ) { }

  ngOnInit() {
    // this.historyState = history.state;
    // console.log('HISTORY STAT U rowwwww',  this.historyState)
    // if (this.historyState.list || this.historyState.item) {
    //   this.selectedListAndItem = { list: this.historyState.list, item: this.historyState.item };
    //   this.isOpenToastOpen = true;
    //   const navigation = this.router.getCurrentNavigation();
    //   window.history.replaceState({}, '', this.router.url);
    // }
    this.loggedUser = this.authenticationHelperService.getUserData;
    this.actRoute.paramMap.subscribe(params => {
      this.isMasterItem = Number(params.get('isMasterItem'));
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.addNewRow !== undefined) {
      this.isNewItemCreated = changes.addNewRow.currentValue;
    }
  }

  openDeleteItemModal(item: ListItem) {
    this.isDeleteButtonVisible = false;
    this.isDeleteModalOpen = true;
    this.selectedItem = item;
    this.deleteModalText.title = 'Delete item?';
    this.deleteModalText.description = 'Are you sure you wish to delete this item? This action cannot be undone.'
    this.deleteModalText.button = 'Delete';
  }

  takeAction(): void {
    if (this.selectedItem) {
      this.deleteIetem(this.item?.id);
    }
  }

  deleteIetem(id: number): void {
    this.isDeleteModalOpen = false;
    this.listsHttpService.deleteListItem(id).then((response: any) => {
      this.toastService.success(response.message);
      this.listsHelperService.refreshPage(true);
    });
  }

  getAttributeValue(attributes: any[], lyst_attribute_id: number): string {
    const attribute = attributes.find(attr => attr.lyst_attribute_id === lyst_attribute_id);
    return attribute && attribute.value ? attribute.value : 'Type here...';
  }

  saveActiveEdits() {
    if (this.currentEditingField) {
      this.saveEdit(this.currentEditingField);
    }
  }

  isClickInsideInput(event: MouseEvent): any {
    const target = event.target as HTMLElement;
    return target.tagName === 'INPUT' || target.closest('.column-table-row-item');
  }

  editValue(columnId: string | number, arr: any) {
    console.log('is edit value working')
    if (this.loggedUser.id !== this.user_id) {
      return;
    }
    if (columnId === 'name') {
      this.isEditing['name'] = true;
      this.editingValues[columnId] = this.item.name;
    } else if (columnId === 'by') {
      this.isEditing['by'] = true;
      this.editingValues[columnId] = this.item.by;
    } else {
      const attribute = arr.find((attr: any) => attr.lyst_attribute_id === columnId);
      this.isEditing[columnId] = true;
      this.editingValues[columnId] = attribute ? attribute.value : null;
    }
    setTimeout(() => {
      if (this.inputElement) {
        this.renderer.selectRootElement(this.inputElement.nativeElement).focus();
      }
    },);
  }

  saveEdit(columnId: number | string) {
    if (this.loggedUser.id !== this.user_id) {
      return;
    }
    this.isEditing[columnId] = false;
    let reqObj = {};
    if (columnId === 'name') {
      this.item.name = this.editingValues[columnId];
      reqObj = {
        name: this.editingValues[columnId] ? this.editingValues[columnId] : null
      }
    } else if (columnId === 'by') {
      this.item.by = this.editingValues[columnId];
      reqObj = {
        by: this.editingValues[columnId] ? this.editingValues[columnId] : null
      }
    } else {
      reqObj = {
        [`lyst_attribute_${columnId}`]: this.editingValues[columnId] ? this.editingValues[columnId] : null
      }
    }
    console.log('reqObj', reqObj)
    this.updateItem(reqObj);
  }

  updateItem(reqObj: any) {
    this.itemsHttpService.updateItem(this.item.id, reqObj).then((response: any) => {
      console.log(response)
      this.item = response.data;
      // this.listsHelperService.updateSingleList(true);
    })
  }

  triggerFileInput(event: Event): void {
    if (this.loggedUser.id !== this.user_id) {
      return;
    }
    event.preventDefault();
    this.fileInput.nativeElement.click();
  }

  onFileChange(event: any): void {
    const file = event.target.files[0];
    const formData = new FormData();
    if (file) {
      formData.append('image', file);
    }
    this.itemsHttpService.addImageToItem(this.item.id, formData).then((response: any) => {
      this.fileInput.nativeElement.value = '';
      this.item = response.data;
      // this.listsHelperService.updateSingleList(true);
    })
  }

  openMasterItem(): void {
    if (this.loggedUser.id !== this.user_id) {
      return;
    }
    this.router.navigate([PAGES_AND_PATHS.newItem.pagePath, this.item.id, this.item.lyst_id])
  }

  openCopyModal(selectedItem: ListItem | null, event: any): void {
    this.isCopyDropdownOpen = true;
    event.stopPropagation();
    this.url = `/single-list/${selectedItem?.lyst_id}/0/table`
    this.selectedItem = selectedItem;
  }

  closeCopyModal(): void {
    this.isOpenToastOpen = false;
  }

}
