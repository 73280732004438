import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DonutChartComponent} from './donut-chart.component';
import {NgApexchartsModule} from 'ng-apexcharts';

@NgModule({
  declarations: [DonutChartComponent],
  imports: [
    CommonModule,
    NgApexchartsModule
  ],
  exports: [DonutChartComponent]
})
export class DonutChartModule { }
