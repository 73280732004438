<div class="wrapper-page">
  <app-my-content></app-my-content>
  <div class="wrapper-edit-profile">
    <tl-header
      [isEditProfile]="activeComponent !== EditProfileEnums.index"
      (clickOnBackArrow)="activeComponent = EditProfileEnums.index">
    </tl-header>
    <tl-edit-profile-index
      *ngIf="activeComponent === EditProfileEnums.index"
      [profileData]="profileData"
      (changeProfile)="onChangeProfileData($event)"
      (changeActiveComponent)="activeComponent = $event">
    </tl-edit-profile-index>
    <tl-edit-profile-name
      *ngIf="activeComponent === EditProfileEnums.name" [profileData]="profileData"
      (changeProfile)="onChangeProfileData($event)">
    </tl-edit-profile-name>
    <tl-edit-profile-username
      *ngIf="activeComponent === EditProfileEnums.username" [profileData]="profileData"
      (changeProfile)="onChangeProfileData($event)">
    </tl-edit-profile-username>
    <tl-edit-profile-bio
      *ngIf="activeComponent === EditProfileEnums.bio" [profileData]="profileData"
      (changeProfile)="onChangeProfileData($event)">
    </tl-edit-profile-bio>
    <tl-edit-profile-gender
      *ngIf="activeComponent === EditProfileEnums.gender" [profileData]="profileData"
      (changeProfile)="onChangeProfileData($event)">
    </tl-edit-profile-gender>
    <tl-edit-profile-phone
      *ngIf="activeComponent === EditProfileEnums.phone" [profileData]="profileData"
      (changeProfile)="onChangeProfileData($event)">
    </tl-edit-profile-phone>
    <tl-edit-profile-birthday
      *ngIf="activeComponent === EditProfileEnums.birthday" [profileData]="profileData"
      (changeProfile)="onChangeProfileData($event)">
    </tl-edit-profile-birthday>
    <tl-edit-profile-country
      *ngIf="activeComponent === EditProfileEnums.country" [profileData]="profileData"
      (changeProfile)="onChangeProfileData($event)">
    </tl-edit-profile-country>
    <tl-edit-profile-city
      *ngIf="activeComponent === EditProfileEnums.city" [profileData]="profileData"
      (changeProfile)="onChangeProfileData($event)">
    </tl-edit-profile-city>
  </div>
</div>

