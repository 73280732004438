import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SingleConversationMessage } from '../http-services/chat/chat-http-interfaces';

@Injectable({
  providedIn: 'root'
})
export class ChatHelperService {
  private selectedConversationId = new BehaviorSubject<number>(0);
  selectedConversationId$ = this.selectedConversationId.asObservable();
  private refreshConversations = new BehaviorSubject<boolean>(false);
  refreshConversationsList = this.refreshConversations.asObservable();
  private refreshUsers = new BehaviorSubject<boolean>(false);
  refreshUsersList = this.refreshUsers.asObservable();
  private closeGroupAccountsComponent = new BehaviorSubject<boolean>(false);
  closeGroupInfo = this.closeGroupAccountsComponent.asObservable();

  selectConversation(conversationId: number): void {
    this.selectedConversationId.next(conversationId);
  }

  constructor() { }

  refreshListOfConversations(refresh: boolean): void {
    this.refreshConversations.next(refresh);
  }

  closeGroupAccounts(close: boolean): void {
    this.closeGroupAccountsComponent.next(close);
  }

  refreshUsersGroupAccounts(refresh: boolean): void {
    this.refreshUsers.next(refresh);
  }

  downloadFile(blob: Blob, filename: string): void {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  handleNewEmojiReaction(message: SingleConversationMessage, messageReaction: any, isGroup: number): SingleConversationMessage {
    if (isGroup) {
      const existingReactionIndex = message.message_reactions.findIndex(
        (reaction: any) => reaction.user_id === messageReaction.message.user_id
      );
  
      if (existingReactionIndex === -1) {
        // If the user has not reacted yet, use your original push logic
        message.message_reactions.push(messageReaction.messageReaction);
        message.message_reactions_count++;
      } else {
        // If the user is changing their reaction, update the emoji
        message.message_reactions[existingReactionIndex] = messageReaction.messageReaction;
      }
    } else {
      // For single chats, only one reaction allowed
      if (message.message_reactions.length) {
        // If there's already a reaction, update it to the new one
        message.message_reactions[0] = messageReaction.messageReaction;
      } else {
        // If no reaction exists, use your original push logic
        message.message_reactions.push(messageReaction.messageReaction);
        message.message_reactions_count = 1; // Set count to 1 since we're adding the first reaction
      }
    }
    return message;
  }
  
  
  handleDeletedEmojiReaction(message: SingleConversationMessage, messageReaction: any, isGroup: number): SingleConversationMessage {
    console.log(message, messageReaction)
    if (isGroup) {
      // Remove only the specific user's reaction
      const existingReactionIndex = message.message_reactions.findIndex(
        (reaction: any) => reaction.user_id === messageReaction.message.user_id
      );
  
      if (existingReactionIndex !== -1) {
        message.message_reactions.splice(existingReactionIndex, 1);
        message.message_reactions_count = message.message_reactions.length;
      }
    } else {
      // For single chats
      const userId = messageReaction.message.user_id; // Assuming user_id is available in messageReaction
  
      if (message.message_reactions.length > 0) {
        const existingReactionIndex = message.message_reactions.findIndex(
          (reaction) => reaction.user_id === userId
        );
  
        if (existingReactionIndex !== -1) {
          // Remove the user's reaction
          message.message_reactions.splice(existingReactionIndex, 1);
          message.message_reactions_count = message.message_reactions.length;
        }
      }
    }
  
    return message;
  }
  

  handleMessageUpdate(messageIndex: number, messages: Array<SingleConversationMessage>, message: SingleConversationMessage): Array<SingleConversationMessage> {
    if (messageIndex !== -1) {
      messages[messageIndex] = message;
      console.log('Updated message', messages[messageIndex]);
    } else {
      console.log('Message not found');
    }
    return messages;
  }

  handleMediaFormData(formData: FormData, fileType: string, file: File): FormData {
    if (fileType.startsWith('image/')) {
      formData.append('type', 'image');
    } else if (fileType === 'application/pdf') {
      formData.append('type', 'attachment');
    } else if (fileType.startsWith('video/')) {
      formData.append('type', 'video');
    }
    formData.append('attachment', file, file.name);
    formData.append('body', file.name);
    return formData;
  }

  handleSetEmojiReaction(
    message: SingleConversationMessage,
    isGroup: number,
    response: any
  ): SingleConversationMessage {
    if (isGroup) {
      if (message) {
        const reactions = response.data.message_reactions;
        if (response.message === 'Reaction successfully saved.' && reactions.length > 0) {
          // Add the new reaction to the existing array
          message.message_reactions.push(...reactions);
          message.message_reactions_count += reactions.length;
        } else if (response.message === 'Reaction successfully updated.' && reactions.length > 0) {
          // Update the specific reaction in the array
          reactions.forEach((updatedReaction: any) => {
            const index = message.message_reactions.findIndex(
              (r: any) => r.user_id === updatedReaction.user_id
            );
            if (index !== -1) {
              message.message_reactions[index] = updatedReaction;
            } else {
              message.message_reactions.push(updatedReaction);
              message.message_reactions_count++;
            }
          });
        } else {
          // Handle reaction removal or other cases
          message.message_reactions = reactions;
          message.message_reactions_count = reactions.length;
        }
      }
    } else {
      if (message) {
        message.message_reactions = response.data.message_reactions;
        message.message_reactions_count = message.message_reactions.length;
      } else {
        console.warn(`Message with ID ${response.data.id} not found.`);
      }
    }
    return message;
  }  

  handleDownloadMediaName(fileName: string, blob: Blob, timestamp: any): string {
    if (blob.type === 'image/jpeg') {
      fileName = `image_${timestamp}.jpg`;
    } else if (blob.type === 'video/mp4') {
      fileName = `video_${timestamp}.mp4`;
    } else if (blob.type === 'application/pdf') {
      fileName = `document_${timestamp}.pdf`;
    }
    return fileName;
  }

  handleMediaId(selectedMedia: any, mediaId: any):any {
    if (selectedMedia.type === 'image') {
      mediaId = selectedMedia.image.id;
    } else if (selectedMedia.type === 'video') {
      mediaId = selectedMedia.video.id;
    } else {
      mediaId = selectedMedia.attachment.id;
    }
    return mediaId;
  }

  removeParticipant(participantsArray: any[], userId: number): void {
    const index = participantsArray?.findIndex(user => user.id === userId);
    if (index > -1 && index < participantsArray?.length) {
      participantsArray?.splice(index, 1);
    }
  }

}

