import { Component } from '@angular/core';
import {AvailableLanguages} from "../../../../constants/translate-variables";
import {TranslateHelperService} from "../../../../services/helpers/translate-helper.service";
import {ToastrService} from "ngx-toastr";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {NgForOf, NgIf} from "@angular/common";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'tl-languages',
  standalone: true,
  imports: [
    TranslateModule,
    NgForOf,
    NgIf,
    FormsModule
  ],
  templateUrl: './languages.component.html',
})
export class LanguagesComponent {
  searchQuery = '';
  initialLanguage: any;
  selectedLanguage: any;
  filteredLanguages = AvailableLanguages;
  protected readonly AvailableLanguages = AvailableLanguages;
  constructor(private translate: TranslateService,
              private toastService: ToastrService,
              public translateHelperService: TranslateHelperService,) { }
  ngOnInit() {
    if (localStorage.getItem('user_lang') !== 'undefined') {
      this.initialLanguage = AvailableLanguages.find(lang => {
        return lang.name === JSON.parse(localStorage.getItem('user_lang') as string);
      });
      this.filteredLanguages = AvailableLanguages.filter(lang => {
        return lang.name !== this.initialLanguage.name;
      });
    }
  }

  onSearchLanguage(): void {
    this.searchQuery
      ? this.filteredLanguages = AvailableLanguages.filter(lang => {
        return this.translate.instant('LANGUAGES.' + lang.name).toLowerCase().includes(this.searchQuery.toLowerCase() || lang.name !== this.initialLanguage.name);
      })
      : this.filteredLanguages = AvailableLanguages.filter(lang => {
        return lang.name !== this.initialLanguage.name;
      });
  }

  onClearSearch(): void {
    this.searchQuery = '';
    this.onSearchLanguage();
  }

  onChangeLanguage(event: Event): void {
    const target = event.target as HTMLInputElement
    this.selectedLanguage = target.value
  }

  onSubmitNewLanguage(): void {
    if (this.selectedLanguage) {
      this.initialLanguage = AvailableLanguages.find(lang => {
        return lang.name === this.selectedLanguage;
      });
      this.filteredLanguages = AvailableLanguages.filter(lang => {
        return lang.name !== this.initialLanguage.name;
      });
      this.selectedLanguage = undefined;
      this.translateHelperService.changeLang((this.selectedLanguage));
      this.toastService.success('The language has been changed successfully.')
    } else {
      this.toastService.success('Please, choose language.')
    }
  }
}
