import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Column } from 'src/app/services/http-services/lists/lists-http-intterfaces';

@Component({
  selector: 'app-add-new-column',
  standalone: true,
  imports: [
    FormsModule
  ],
  templateUrl: './add-new-column.component.html',
})
export class AddNewColumnComponent implements OnInit {
  @Output() closeModal = new EventEmitter<boolean>();
  @Output() reqObj = new EventEmitter<{ name: string }>();
  @Input() selectedColumn: Column | null  =null;
  columnName: string = '';

  ngOnInit(): void {
    this.columnName = this.selectedColumn !== null ? this.selectedColumn.name : '';
  }

  addColumn() {
    if (this.columnName.length > 0 && this.columnName.length <= 20) {
      this.reqObj.emit({ name: this.columnName });
      this.columnName = '';
    } 
  }

}
