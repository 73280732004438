<div class="wrapper-modal">
  <div class="wrapper-modal-content cancel-subscription">
    <div class="modal-title">Cancel Expert subscription plan?</div>
    <div class="modal-description">Are you sure you want to cancel your Expert subscription plan? Upon cancellation, you will be switched to the free plan. You will have all Expert features until your subscription ends on August 7, 2024.</div>
    <div class="modal-buttons">
      <button class="custom-btn custom-btn--gray">
        <span>Cancel</span>
      </button>
      <button class="custom-btn custom-btn--primary">
        <span>Cancel my Expert plan</span>
      </button>
    </div>
  </div>
</div>
