<div class="wrapper-page-group-details">
  <div class="header-group-details">
    <button (click)="openConversation()" class="custom-btn custom-btn--circle-outline">
      <img src="../../../assets/icons/icon-arrow-left.svg" alt="">
    </button>
    <span>Chat details</span>
  </div>
  <form class="wrapper-content-group-details">
    <div class="wrapper-add-image-group">
      <div class="wrapper-user-avatar-group-chat chat-details">
        <div *ngIf="conversationInfo.is_group && conversationInfo.profile_photo === null && !imagePreview" class="images-grid-chat"
          [ngClass]="conversationInfo.other_participants?.length | checkChatGroupLength">
          <div class="image">
            <img [src]="'../../../../../assets/images/avatar-user.png'" alt="">
          </div>
          <div *ngFor="let person of conversationInfo.other_participants | slice:0:3" class="image">
            <img [src]="'../../../../../assets/images/avatar-user.png'" alt="">
          </div>
          <div class="number-more-people">
            <span>{{conversationInfo.other_participants?.length}}+</span>
          </div>
        </div>
        <div *ngIf="conversationInfo.is_group && conversationInfo.profile_photo !== null || imagePreview" class="images-grid-chat"
          [ngClass]="conversationInfo.other_participants?.length | checkChatGroupLength">
          <div class="image">
            <img [src]="imagePreview ? imagePreview : conversationInfo.profile_photo.url" alt="">
          </div>
        </div>
        <div *ngIf="!conversationInfo.is_group" class="images-grid-chat">
          <div class="image">
            <img src="../../../../../assets/images/avatar-user.png" alt="">
          </div>
        </div>
      </div>
      <div class="upload-avatar-group">
        <button (click)="triggerFileInput($event)" type="submit" class="custom-btn custom-btn--gray">
          <span>Change profile photo</span>
        </button>
        <input #fileInput type="file" (change)="onFileSelected($event)" accept="image/*" />
      </div>
    </div>
    <div class="line-horizontal"></div>
    <div class="wrapper-change-name-group">
      <div class="wrapper-input">
        <span class="label-input">Group name</span>
        <div class="custom-input">
          <input type="text" name="groupName" [(ngModel)]="groupName" maxlength="30">
        </div>
      </div>
      <button type="submit" (click)="saveChanges()" [disabled]="selectedImage === null && groupName === null"
        class="custom-btn custom-btn--primary">
        <span>Save changes</span>
      </button>
    </div>
  </form>
</div>
