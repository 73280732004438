import { Injectable } from '@angular/core';
import { AuthenticationHelperService } from './authentication-helper.service';
import Pusher from 'pusher-js';
import { environment } from 'src/environments/environment';
import Echo from 'laravel-echo';

@Injectable({
  providedIn: 'root'
})
export class EchoHelperService {

  constructor(private authenticationHelperService: AuthenticationHelperService) { }

  initEcho(): void {
    (window as any).Pusher = Pusher;
    (window as any).Echo = new Echo({
      broadcaster: 'pusher',
      key: environment.PUSHER_APP_KEY,
      cluster: environment.PUSHER_APP_CLUSTER,
      authEndpoint: environment.PUSHER_API_ENDPOINT,
      secret: environment.PUSHER_SECRET_KEY,
      auth: {
        headers: {
          Authorization: 'Bearer ' + this.authenticationHelperService.getToken,
          'X-App-ID': environment.PUSHER_APP_ID,
        },
      },
    });

    (window as any).Echo.connector.pusher.connection.bind('connected', () => {
      console.log('Pusher connected successfully.');
    });

    (window as any).Echo.connector.pusher.connection.bind('disconnected', () => {
      console.log('Pusher disconnected.');
    });

    (window as any).Echo.connector.pusher.connection.bind('error', (error: any) => {
      console.error('Pusher connection error:', error);
    });
  }

  disconnectFromSockets(): void {
    (window as any).Echo.leave();
  }

}
