import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GeneralHttpHelperService } from '../../helpers/general-http-helper.service';

@Injectable({
  providedIn: 'root'
})
export class SearchHttpService {
  constructor(
    private httpClient: HttpClient,
    private generalHttpHelperService: GeneralHttpHelperService
  ) { }

  private getNearYouResults(page: number, latitude: any, longitude: any, isLiveSearch: boolean, searchTerm?: string | undefined): Observable<any[]> {
    let url = 'search/near_you'
    let params = [];
    if (searchTerm) {
      params.push(`search_keyword=${encodeURIComponent(searchTerm)}`);
    }
    if (isLiveSearch) {
      params.push(`isLiveSearch=true`);
    }
    if (page) {
      params.push(`page=${page}`);
    }
    if (latitude) {
      params.push(`latitude=${latitude}`);
    }
    if (longitude) {
      params.push(`longitude=${longitude}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    console.log('URLLLLLLLL', url)
    return this.httpClient.get<any[]>(url);
  }
  formattedGetNearYouResults(page: number, latitude: any, longitude: any, isLiveSearch: boolean, searchTerm?: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getNearYouResults(page, latitude, longitude, isLiveSearch, searchTerm).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.data,
            pagination: this.generalHttpHelperService.returnPagination(res.data),
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private getAccountsResults(isLiveSearch: boolean, searchTerm?: string | undefined): Observable<any[]> {
    let url = 'search/accounts';
    let params = [];
    if (searchTerm) {
      params.push(`search_keyword=${encodeURIComponent(searchTerm)}`);
    }
    if (isLiveSearch) {
      params.push(`isLiveSearch=true`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get<any[]>(url);
  }
  formattedGetAccountsResults(isLiveSearch: boolean, searchTerm?: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getAccountsResults(isLiveSearch, searchTerm).subscribe({
        next: (res: any) => {
          resolve(res);
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private getCompaniesResults(pageNum: number, isLiveSearch: boolean, searchTerm?: string | undefined): Observable<any[]> {
    let url = 'search/companies';
    let params = [];
    if (searchTerm) {
      params.push(`search_keyword=${encodeURIComponent(searchTerm)}`);
    }
    if (isLiveSearch) {
      params.push(`isLiveSearch=true`);
    }
    if (pageNum) {
      params.push(`page=${pageNum}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get<any[]>(url);
  }
  formattedGetCompaniesResults(pageNum: number, isLiveSearch: boolean, searchTerm?: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getCompaniesResults(pageNum, isLiveSearch, searchTerm).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.data,
            pagination: this.generalHttpHelperService.returnPagination(res.data),
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private getListersResults(pageNum: number, isLiveSearch: boolean, searchTerm?: string | undefined): Observable<any[]> {
    let url = 'search/listers';
    let params = [];
    if (searchTerm) {
      params.push(`search_keyword=${encodeURIComponent(searchTerm)}`);
    }
    if (isLiveSearch) {
      params.push(`isLiveSearch=true`);
    }
    if (pageNum) {
      params.push(`page=${pageNum}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get<any[]>(url);
  }
  formattedGetListersResults(pageNum: number, isLiveSearch: boolean, searchTerm?: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getListersResults(pageNum, isLiveSearch, searchTerm).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.data,
            pagination: this.generalHttpHelperService.returnPagination(res.data),
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private getListsResults(pageNum: number, isLiveSearch: boolean, searchTerm?: string | undefined): Observable<any[]> {
    let url = 'search/lysts';
    let params = [];
    if (searchTerm) {
      params.push(`search_keyword=${encodeURIComponent(searchTerm)}`);
    }
    if (isLiveSearch) {
      params.push(`isLiveSearch=true`);
    }
    if (pageNum) {
      params.push(`page=${pageNum}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get<any[]>(url);
  }
  formattedGetListsResults(pageNum: number, isLiveSearch: boolean, searchTerm?: string | undefined): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getListsResults(pageNum, isLiveSearch, searchTerm).subscribe({
        next: (res: any) => {
          resolve({
            data: res.data.data,
            pagination: this.generalHttpHelperService.returnPagination(res.data),
          });
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private getRecentSearches(searchTerm?: string): Observable<any[]> {
    let url = 'recent_searches';
    let params = [];
    if (searchTerm) {
      params.push(`search_keyword=${encodeURIComponent(searchTerm)}`);
    }
    if (params.length > 0) {
      url += `?${params.join('&')}`;
    }
    return this.httpClient.get<any[]>(url);
  }
  formattedGetRecentSearches(searchTerm?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getRecentSearches(searchTerm).subscribe({
        next: (res: any) => {
          resolve(res);
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

  private deleteRecentSearch(recent_search_id: number): Observable<any[]> {
    let url = `recent_searches/${recent_search_id}`;
    return this.httpClient.delete<any[]>(url);
  }
  formatteddeleteRecentSearch(recent_search_id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.deleteRecentSearch(recent_search_id).subscribe({
        next: (res: any) => {
          resolve(res);
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  }

}
