import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighlightSearchTermPipe } from './highlight-search-term.pipe';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HighlightSearchTermPipe
  ],
  exports: [
    HighlightSearchTermPipe
  ]
})
export class HighlightSearchTermModule { }
