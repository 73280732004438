<div class="wrapper-list-column-single-item">
  <div class="grid">
    <div *ngFor="let attribute of item?.lyst?.attributes | slice:3; let i = index;" class="grid__m-6">
      <div *ngIf="i === 0" class="column-item-single-view">
        <div class="name-column">{{attribute?.name}}</div>
        <span class="value-column">{{item.by}}</span>
      </div>
      <div *ngIf="i !== 0" class="column-item-single-view">
        <div class="name-column">{{attribute?.name}}</div>
        <span class="value-column" [innerHtml]="getAttributeValue(item.lyst_item_attributes, attribute?.id)"></span>
      </div>
    </div>
  </div>
</div>
