import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {PostsHttpService} from "../../../../services/http-services/posts/posts-http.service";
import {TlPagination} from "../../../../interfaces/general-interfaces";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {Subject} from "rxjs";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";

@Component({
  selector: 'tl-tag-list-item',
  standalone: true,
  imports: [
    NgIf,
    NgForOf,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './tag-list-item.component.html',
})
export class TagListItemComponent implements OnInit, OnChanges{
  @Input() selectedList: any;
  @Input() selectedListItem: any;
  @Output() changeListItem: EventEmitter<any> = new EventEmitter<any>();
  urlParams = {page: 1, search: '', lystId: ''};
  tagListItemPagination: TlPagination | undefined;
  tagListItems: any[] = [];
  selectedListItemId: any;
  searchTerm$ = new Subject<string>();
  constructor(private postsHttpService: PostsHttpService) { }

  ngOnInit(): void {
    this.searchTerm$.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe((searchTerm: any) => {
      this.urlParams.search = searchTerm;
      this.tagListItemPagination = undefined;
      this.getTagsListItems(true);
    });

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedList) {
      this.urlParams.lystId = changes.selectedList.currentValue?.id;
      this.tagListItemPagination = undefined;
      this.tagListItems = [];
      this.getTagsListItems();
    }

    if (changes.selectedListItem) {
      this.selectedListItemId = this.selectedListItem?.id;
    }
  }

  getTagsListItems(isSearchChange = false): void {
    this.urlParams.page = this.tagListItemPagination?.currentPage ? this.tagListItemPagination.currentPage + 1 : 1;
    this.postsHttpService.getTagListItems(this.urlParams).then(res => {
      this.tagListItems = isSearchChange ? res.data : [...this.tagListItems, ...res.data];
      this.tagListItemPagination = res.pagination;
    })
  }

  onChangeTagListItem(event: any): void {
    this.selectedList = this.tagListItems.find(tagElement => tagElement.id === event);
    this.changeListItem.emit(this.selectedList);
  }

  onSearchChange(event: any): void {
    this.searchTerm$.next(event.target.value);
  }

  clearSearch(): void {
    this.searchTerm$.next('');
  }

  onScroll(event: any): void {
    const threshold = 1;
    const position = event.target.scrollTop + event.target.offsetHeight;
    const height = event.target.scrollHeight;
    if (position > height - threshold && (this.tagListItemPagination?.currentPage ?? 0) < (this.tagListItemPagination?.lastPage ?? 0)) {
      this.getTagsListItems();
    }
  }
}
