<div class="wrapper-page">
  <app-my-content></app-my-content>
  <div class="wrapper-all-themes">
    <tl-header></tl-header>
    <div class="wrapper-content-all-themes">
      <span class="title-all-themes">All list themes</span>
      <div class="wrapper-custom-search">
        <img class="icon-search" src="../../../assets/icons/outline/icon-search.svg" alt="">
        <input type="text" placeholder="Search...">
        <div class="wrapper-delete-search">
          <img src="../../../assets/icons/outline/icon-close.svg" alt="">
        </div>
      </div>
      <div class="wrapper-list-all-themes">
        <div class="grid">
          <div *ngFor="let theme of themes" class="grid__s-4">
            <div class="wrapper-card-theme" style="background-color: #8A95F9;">
              <div class="wrapper-theme-info">
                <span>{{theme.name}}</span>
              </div>
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXbKMuxHD5Ho6T-lIVzT6tszCiT0qwtRlgUw&s"
                   alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

