<div class="wrapper-accounts-search-results">
    <div class="wrapper-custom-tabs-purple">
        <div class="custom-tab-purple" (click)="tabSelected = 'all'" [ngClass]="{'active': tabSelected === 'all'}">
            <span>All</span>
        </div>
        <div class="custom-tab-purple" (click)="tabSelected = 'listers'"
            [ngClass]="{'active': tabSelected === 'listers'}">
            <img class="no-active" src="./assets/icons/outline/icon-user.svg" alt="">
            <img class="active" src="./assets/icons/fill/icon-user.svg" alt="">
            <span class="visibility-text">Listers</span>
        </div>
        <div class="custom-tab-purple" (click)="tabSelected = 'companies'"
            [ngClass]="{'active': tabSelected === 'companies'}">
            <img src="" alt="">
            <img class="no-active" src="./assets/icons/outline/icon-company.svg" alt="">
            <img class="active" src="./assets/icons/fill/icon-fill-company.svg" alt="">
            <span class="visibility-text">Companies</span>
        </div>
    </div>
    <div *ngIf="tabSelected === 'all'" class="wrapper-list-search-accounts">
        <span class="title-search-results">Users</span>
        <div *ngFor="let user of listers" class="wrapper-card-lister" [routerLink]="[PAGES_AND_PATHS.profile.pagePath, user.id]">
            <div class="wrapper-favorite-lister">
                <div class="wrapper-avatar">
                    <img [src]="user?.profile_photo_url ? user?.profile_photo_url : '../../../../assets/images/avatar-user.png'" alt="">
                </div>
                <div class="wrapper-user-info">
                    <div class="wrapper-username">
                        <span>{{user?.name}}</span>
                        <img *ngIf="user?.is_expert" src="../../../assets/icons/fill/icon-badge.svg" alt="">
                    </div>
                    <span>{{user?.followers_count}} {{user.followers_count === 1 ? 'followe' : 'followers'}}</span>
                </div>
            </div>
        </div>
        <span class="title-search-results">Companies</span>
        <div *ngFor="let company of companies" class="wrapper-card-lister">
            <div class="wrapper-favorite-lister">
                <div class="wrapper-avatar">
                    <img [src]="company?.profile_photo_url ? company?.profile_photo_url : './assets/images/avatar-user-expert.png'" alt="">
                </div>
                <div class="wrapper-user-info">
                    <div class="wrapper-username">
                        <span>{{company.name}}</span>
                        <img src="./assets/icons/fill/icon-fill-company.svg" alt="">
                    </div>
                    <span>{{company?.followers_count}} {{company.followers_count === 1 ? 'followe' : 'followers'}}</span>
                </div>
            </div>
        </div>
    </div>
    <app-listers *ngIf="tabSelected === 'listers'"></app-listers>
    <app-companies *ngIf="tabSelected === 'companies'"></app-companies>

</div>