import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PAGES_AND_PATHS} from '../../../constants/pages-and-paths';
import {SelectLanguageComponent} from './select-language.component';
import {OuterPagesGuardService} from '../../../guards/outer-pages-guard.service';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.selectLanguage.pageInRouting,
    data: {data: PAGES_AND_PATHS.selectLanguage},
    component: SelectLanguageComponent,
    canActivate: [OuterPagesGuardService],
    loadChildren: () => import('./select-language.module').then(m => m.SelectLanguageModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SelectLanguageRoutingModule { }
