<form [formGroup]="editProfileCityForm" (ngSubmit)="onSubmitEditProfileCityForm()" class="wrapper-edit-info-page">
  <span class="title-edit-page">City</span>
  <span class="subtitle-edit-page">Change your account city.</span>
  <div class="wrapper-input" [ngClass]="{'error': editProfileCityForm.controls['city'] | formControlErrorMessages: null : formTriedToSubmit}">
    <span class="label-input">City</span>
    <div class="custom-input">
      <input type="text" formControlName="city">
    </div>
    <div *ngIf="editProfileCityForm.controls['city'] | formControlErrorMessages: null: formTriedToSubmit" class="wrapper-error-message">
      <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
      <span>{{editProfileCityForm.controls['city'] | formControlErrorMessages: null: formTriedToSubmit}}</span>
    </div>
  </div>
  <div class="buttons-edit-page">
    <button type="button" (click)="onCancelChanges()" class="custom-btn custom-btn--outline">
      <span>Cancel</span>
    </button>
    <button type="submit" class="custom-btn custom-btn--primary">
      <span *ngIf="!isSpinnerActive">Save changes</span>
      <tl-spinner *ngIf="isSpinnerActive"></tl-spinner>
    </button>
  </div>
</form>
