<div class="wrapper-page">
  <app-my-content></app-my-content>
  <div class="wrapper-single-post-page">
    <tl-header></tl-header>
    <div class="single-post-page">
      <div class="wrapper-card-post">
        <div class="header-card-post">
          <div class="user-card-post">
            <img class="user-img" src="../../../../assets/images/avatar-user.png" alt="">
            <div class="wrapper-user-card-info-post">
              <div class="user-name">
                <span>{{review?.author?.name}}</span>
                <img *ngIf="review?.author?.is_expert" src="./assets/icons/fill/icon-badge.svg" alt="">
              </div>
              <span>{{review?.created_at | timeAgo}}</span>
            </div>
          </div>
          <div class="wrapper-dropdown-btn" appClickedOutside (clickedOutside)="isDropdownOpen = false;">
            <div (click)="openViewMore()" class="view-more-post">
              <img src="../../../../assets/icons/outline/icon-view-more.svg" alt="">
            </div>
            <div *ngIf="isDropdownOpen" class="content-dropdown-btn">
              <div *ngIf="loggedUser?.id === review?.user_id && listsHelperService.checkIfWithin48Hours(review.created_at)"
                class="item-dropdown" (click)="openEditReview()">
                <img src="../../../../assets/icons/outline/icon-edit.svg" alt="">
                <span>Edit review</span>
              </div>
              <div (click)="shareReview()" class="item-dropdown">
                <img src="../../../../assets/icons/outline/icon-share.svg" alt="">
                <span>Share</span>
              </div>
              <div class="item-dropdown delete">
                <img src="../../../../assets/icons/outline/icon-outline-attention.svg" alt="">
                <span>Report</span>
              </div>
              <div *ngIf="review?.user_id === loggedUser?.id" (click)="openDeleteModal()"  class="item-dropdown delete">
                <img src="../../../../assets/icons/outline/icon-delete.svg" alt="">
                <span>Delete review</span>
              </div>
            </div>
          </div>
        </div>
        <div class="content-card-post">
          <p class="description">{{review?.review}}</p>
          <div class="wrapper-stars-preview">
            <app-stars [average_rating]="review?.rating"></app-stars>
          </div>
          <div class="wrapper-toolbar-post">
            <div class="wrapper-toolbar-left">
              <span [routerLink]="[PAGES_AND_PATHS.listLikes.pagePath, review?.id, type === 'list' ? 'listReview' : 'itemReview']">{{review?.likes_count}} {{review?.likes_count === 1 ? 'like' : 'likes'}} |</span>
              <span> {{review?.comments_count}} {{review?.comments_count === 1 ? 'comment' : 'comments'}}</span>
            </div>
            <div class="wrapper-toolbar-right">
              <div class="wrapper-like" (click)="toggleLikeReview(review)" class="wrapper-like"
                [ngClass]="{'liked': review?.am_i_liked_this}">
                <img class="no-like" src="./assets/icons/outline/icon-like.svg" alt="">
                <img class="like" src="./assets/icons/fill/icon-like.svg" alt="">
                <span>Like</span>
              </div>
              <div (click)="shareReview()" class="wrapper-share">
                <img src="./assets/icons/outline/icon-share.svg" alt="">
                <span>Share</span>
              </div>
            </div>
          </div>
          <form [formGroup]="commentForm" (ngSubmit)="storeComment()" class="wrapper-write-comment">
            <div class="avatar-comment">
              <img
                [src]="loggedUser?.profile_photo_url !== null ? loggedUser.profile_photo_url : '../../../../assets/images/avatar-user.png'"
                alt="">
            </div>
            <div class="wrapper-input">
              <div class="custom-input">
                <input type="text" formControlName="comment" placeholder="Write a comment...">
              </div>
            </div>
            <button type="submit" [disabled]="!commentForm.valid" class="custom-btn custom-btn--circle-primary">
              <img src="../../../../../assets/icons/outline/icon-send.svg" alt="">
            </button>
          </form>
        </div>
      </div>
      <div class="wrapper-comments">
        <div *ngFor="let comment of comments" class="wrapper-comment">
          <div class="wrapper-comment-left">
            <div class="avatar-comment">
              <img [routerLink]="[PAGES_AND_PATHS.profile.pagePath, comment?.author?.id]"
                [src]="comment?.author?.profile_photo_url !== null ? comment?.author?.profile_photo_url !== null : '../../../../assets/images/avatar-user.png'"
                alt="">
            </div>
            <div class="info-comment">
              <div class="user-name-comment">
                <span class="name">{{comment?.author?.name}}</span>
                <img src="../../../../assets/icons/fill/icon-badge.svg" alt="">
                <span class="time">{{comment?.created_at | timeAgo}}</span>
              </div>
              <span class="comment">{{comment?.comment}}</span>
            </div>
          </div>
          <div class="wrapper-comment-right">
            <div class="wrapper-like" [ngClass]="{'liked': comment?.am_i_liked_this}"
              (click)="toggleLikeComment(comment)">
              <img class="no-like" src="../../../assets/icons/outline/icon-heart.svg" alt="">
              <img class="like" src="../../../assets/icons/fill/icon-heart.svg" alt="">
              <span>{{comment?.likes_count}}</span>
            </div>
            <!--            <img src="../../../assets/icons/outline/icon-edit.svg" alt="">-->
            <!--            <img src="../../../assets/icons/outline/icon-delete.svg" alt="">-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<tl-confirm-modal *ngIf="isDeleteModalOpen" [modalIcon]="'../../../../../assets/icons/outline/icon-delete.svg'"
  [modalTitle]="modalText.title" [confirmBtnText]="modalText.button" [modalDescription]="modalText.description"
  (cancelModal)="isDeleteModalOpen = false" (confirmModal)="takeAction()">
</tl-confirm-modal>
<tl-sharing-options-modal *ngIf="isSharePostModalOpen" [entityType]="ShareEntitiesEnums.review"
  [entityId]="review.id" [urlForShare]="reviewUrl"
  (closeModal)="isSharePostModalOpen = false"></tl-sharing-options-modal>