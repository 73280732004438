<div class="wrapper-page">
  <app-my-content></app-my-content>
  <div class="wrapper-my-profile-page">
    <tl-header></tl-header>
    <div class="content-profile-page">
      <div class="wrapper-info-my-profile">
        <div class="wrapper-info-my-profile-left">
          <img *ngIf="!profileData?.profile_photo_url" [src]="profileData?.roles[0].name === UserRoles.expert ? '../../../assets/images/avatar-user-expert.png' : '../../../assets/images/avatar-user.png'" alt="">
          <img *ngIf="profileData?.profile_photo_url" [src]="profileData?.profile_photo_url" alt="">
          <div class="wrapper-username">
            <span>{{profileData?.username}}</span>
            <div class="badge">
              <img *ngIf="profileData?.roles[0].name === UserRoles.expert" src="../../../assets/icons/fill/icon-badge.svg" alt="">
              <img *ngIf="profileData?.roles[0].name === UserRoles.company" src="../../../assets/icons/fill/icon-fill-company.svg" alt="">
              <span>{{profileData?.roles[0].name}}</span>
            </div>
          </div>
        </div>
        <div class="wrapper-info-my-profile-right">
          <div class="wrapper-counter-box">
            <div class="item-box">
              <span class="number">{{profileData?.lysts_count}}</span>
              <span>{{profileData?.lysts_count === 1 ? 'lyst' : 'lysts'}}</span>
            </div>
            <div [routerLink]="[PAGES_AND_PATHS.followingFollowers.pagePath, isAuthUserProfile ? 0 : userId, 'followers']" class="item-box">
              <span class="number">{{profileData?.followers_count}}</span>
              <span>{{profileData?.followers_count === 1 ? 'follower' : 'followers'}}</span>
            </div>
            <div [routerLink]="[PAGES_AND_PATHS.followingFollowers.pagePath, isAuthUserProfile ? 0 : userId, 'following']" class="item-box">
              <span class="number">{{profileData?.followings_count}}</span>
              <span>following</span>
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper-name-desc">
        <span class="name">{{profileData?.name}}</span>
        <span class="country">{{profileData?.country}}</span>
        <span class="desc">{{profileData?.bio}}</span>
      </div>
<!--      <tl-adsense-banner adSlot="6575311004" adFormat="auto" [fullWidthResponsive]="true">-->
<!--      </tl-adsense-banner>-->
      <div class="wrapper-btns-my-profile">
        <button *ngIf="isAuthUserProfile" class="custom-btn custom-btn--gray" [routerLink]="[PAGES_AND_PATHS.editProfile.pagePath]">
          <span>Edit profile</span>
          <img src="../../../assets/icons/outline/icon-edit.svg" alt="">
        </button>
        <button class="custom-btn custom-btn--gray" (click)="isShareProfileModalOpen = true">
          <span>Share profile</span>
          <img src="../../../assets/icons/outline/icon-share.svg" alt="">
        </button>
        <!--   Message     -->
        <button *ngIf="!isAuthUserProfile && profileData?.am_i_following_this_user && profileData?.does_this_user_follow_me" (click)="goToChatWithUser()" class="custom-btn custom-btn--gray tl-justify-center">
          <span class="xs-ml-0">Message</span>
        </button>
        <!--   Follow     -->
        <button *ngIf="!isAuthUserProfile && !profileData?.am_i_following_this_user" (click)="followUnfollowUser()" class="custom-btn custom-btn--primary tl-justify-center">
          <span>Follow</span>
        </button>
        <!--   Unfollow     -->
        <button *ngIf="!isAuthUserProfile && profileData?.am_i_following_this_user" (click)="followUnfollowUser()" class="custom-btn custom-btn--gray">
          <span>Unfollow</span>
          <img src="../../../assets/icons/outline/icon-minus-cirlce.svg" alt="">
        </button>
        <button *ngIf="isAuthUserProfile && profileData?.roles[0].name === UserRoles.expert" class="custom-btn custom-btn--gray" [routerLink]="[PAGES_AND_PATHS.settings.pagePath]">
          <span>View Insight</span>
          <img src="../../../assets/icons/outline/icon-statistics.svg" alt="">
        </button>
      </div>
      <div class="wrapper-custom-tabs">
        <div class="custom-tab" [ngClass]="{'active': activeTab === ProfileTabsEnums.lysts}"
          (click)="activeTab = ProfileTabsEnums.lysts">
          <img src="../../../../../assets/icons/outline/icon-posts.svg" alt="">
          <span>{{ProfileTabsEnums.lysts}}</span>
        </div>
        <div class="custom-tab" [ngClass]="{'active': activeTab === ProfileTabsEnums.posts}"
          (click)="activeTab = ProfileTabsEnums.posts">
          <img src="../../../../../assets/icons/outline/icon-my-lists.svg" alt="">
          <span>{{ProfileTabsEnums.posts}}</span>
        </div>
      </div>
      <tl-lists-tab
        *ngIf="activeTab === ProfileTabsEnums.lysts"
        [isAuthUserProfile]="isAuthUserProfile"
        [userId]="userId">
      </tl-lists-tab>
      <tl-posts-tab
        *ngIf="activeTab === ProfileTabsEnums.posts"
        [profileData]="profileData"
        [isAuthUser]="isAuthUserProfile"
        [baseUrl]="baseUrl"
        [userId]="userId">
      </tl-posts-tab>
    </div>
  </div>
</div>
<tl-sharing-options-modal
  *ngIf="isShareProfileModalOpen"
  [entityType]="ShareEntitiesEnums.profile"
  [entityId]="userId"
  [urlForShare]="profileUrl"
  (closeModal)="isShareProfileModalOpen = false">
</tl-sharing-options-modal>
