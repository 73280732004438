<form [formGroup]="deleteAccountForm" (ngSubmit)="onSubmitDeleteAccountForm()" class="wrapper-account-page">
  <span class="title-settings-page">Delete account</span>
  <span class="subtitle-settings-page" style="color: #ffffff;"><b>Are you sure you want to delete your account?</b></span>
  <span class="subtitle-settings-page">When you delete it, you will not be able to restore it.</span>
  <div class="wrapper-input" [ngClass]="{'error': deleteAccountForm.controls['password'] | formControlErrorMessages: 'Wrong password, try again.': formTriedToSubmit}">
    <span class="label-input">Password</span>
    <div class="custom-input">
      <input
        [type]="isPasswordVisible ? 'text' : 'password'"
        placeholder="Enter password"
        formControlName="password">
      <img *ngIf="!isPasswordVisible" (click)="isPasswordVisible = true" class="icon-input" src="../assets/icons/outline/icon-outline-eye.svg" alt="">
      <img *ngIf="isPasswordVisible" (click)="isPasswordVisible = false" class="icon-input" src="../assets/icons/outline/icon-outline-eye-with-slash.svg" alt="">
    </div>
    <div *ngIf="deleteAccountForm.controls['password'] | formControlErrorMessages: null: formTriedToSubmit" class="wrapper-error-message">
      <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
      <span>{{deleteAccountForm.controls['password'] | formControlErrorMessages: null: formTriedToSubmit}}</span>
    </div>
  </div>
  <!--       Button submit       -->
  <div class="wrapper-submit-settings">
    <button type="submit" class="custom-btn custom-btn--primary">
      <span>Delete account</span>
    </button>
  </div>
</form>
