import { Component } from '@angular/core';
import {AvailableLanguages, DefaultLang} from '../../../constants/translate-variables';
import {PAGES_AND_PATHS} from '../../../constants/pages-and-paths';
import {TranslateService} from '@ngx-translate/core';
import {TranslateHelperService} from '../../../services/helpers/translate-helper.service';

@Component({
  selector: 'tl-select-language',
  templateUrl: './select-language.component.html',
})
export class SelectLanguageComponent {
  searchQuery = '';
  filteredLanguages = AvailableLanguages;
  languages: any[] = [];
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;
  constructor(private translate: TranslateService,
              public translateHelperService: TranslateHelperService, ) { }

  onSearchLanguage(): void {
    this.searchQuery
      ? this.filteredLanguages = AvailableLanguages.filter(lang => {
        return this.translate.instant('LANGUAGES.' + lang.name).toLowerCase().includes(this.searchQuery.toLowerCase());
      })
      : this.filteredLanguages = AvailableLanguages;
  }

  onClearSearch(): void {
    this.searchQuery = '';
    this.onSearchLanguage();
  }

  onChangeLanguage(event: Event): void {
    const target = event.target as HTMLInputElement
    this.translateHelperService.changeLang((target.value));
  }
}
