import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PAGES_AND_PATHS} from '../../../constants/pages-and-paths';
import {AddAccountDetailsComponent} from './add-account-details.component';
import {AuthGuardService} from '../../../guards/auth-guard.service';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.completeRegistration.pageInRouting,
    data: {data: PAGES_AND_PATHS.completeRegistration},
    component: AddAccountDetailsComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./add-account-details.module').then(m => m.AddAccountDetailsModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AddAccountDetailsRoutingModule { }
