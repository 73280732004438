import {Component, ElementRef, OnInit, QueryList, ViewChildren} from '@angular/core';
import {PAGES_AND_PATHS} from '../../../constants/pages-and-paths';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationHttpService} from '../../../services/http-services/authentication/authentication-http.service';

@Component({
  selector: 'tl-verify-account',
  templateUrl: './verify-account.component.html',
})
export class VerifyAccountComponent implements OnInit {
  codeForm!: FormGroup;
  codeArray = Array.from({ length: 5 }, (_, i) => i);
  isCodeError = false;
  isSpinnerActive = false;
  numberOfSecondsToEnableResendCodeBtn = 0;
  counterInterval!: ReturnType<typeof setInterval>;
  isVerificationCompanyAccount = false;
  @ViewChildren('codeInput') inputElements!: QueryList<ElementRef>;
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;
  constructor(private fb: FormBuilder,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              // private toastService: ToastService,
              private authHttpService: AuthenticationHttpService) {
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.url.map(segment => segment.path).join('/') === PAGES_AND_PATHS.verifyAccountCompany.pageInRouting) {
      this.isVerificationCompanyAccount = true;
    }
    this.buildForm();
  }

  buildForm(): void {
    this.codeForm = this.fb.group({});
    this.codeArray.forEach((_, i) => {
      this.codeForm.addControl(`code${i}`, this.fb.control('', [Validators.required, Validators.pattern('[0-9]')]));
    });
  }

  onInput(event: Event, index: number): void {
    const target = event.target as HTMLInputElement
    this.isCodeError = false;
    console.log(target.value, index)
    if (target.value && index < this.codeArray.length - 1) {
      const nextInput = this.inputElements.toArray()[index + 1].nativeElement;
      if (nextInput) {
        nextInput.focus();
      }
    }
  }

  onKeyDown(event: KeyboardEvent, index: number): void {
    this.isCodeError = false;
    if (event.key === 'Backspace' && index > 0 && !this.codeForm.controls[`code${index}`].value) {
      const previousInput = this.inputElements.toArray()[index - 1].nativeElement;
      if (previousInput) {
        previousInput.focus();
      }
    }
  }

  onPaste(event: ClipboardEvent): void {
    const pasteData = event.clipboardData?.getData('text');
    if (pasteData && pasteData.length === this.codeArray.length) {
      pasteData.split('').forEach((char, i) => {
        this.codeForm.controls[`code${i}`].setValue(char);
      });
      this.isCodeError = false;
    }
    event.preventDefault();
  }

  onSubmit(): void {
    const successPageDetails = {
      iconPath: '../assets/icons/outline/icon-check.svg',
      title: 'Account has been successfully created and subscribed to company plan',
      description: 'Account has been successfully created and subscribed to company plan.',
      btnText: 'Continue',
      redirectToUrl: PAGES_AND_PATHS.selectTopics.pagePath
    }
    this.isCodeError = false;
    if (this.codeForm.valid) {
      this.isSpinnerActive = true;
      const code = Object.values(this.codeForm.value).join('');
      this.authHttpService.postVerifyAccount({code}).then(res => {
        this.isSpinnerActive = false;
        if (res.verified_successfully) {
          this.isVerificationCompanyAccount ? this.router.navigate([PAGES_AND_PATHS.successAction.pagePath], {state: successPageDetails}) : this.router.navigate([PAGES_AND_PATHS.completeRegistration.pagePath]);
        } else {
          this.isCodeError = true;
        }
      }).catch(error => {
        this.isCodeError = true;
        this.isSpinnerActive = false;
      });
    } else {
      this.isCodeError = true;
    }
  }

  resendVerificationCode(): void {
    if (!this.numberOfSecondsToEnableResendCodeBtn) {
      this.authHttpService.postResendVerificationCode().then(res => {
        this.numberOfSecondsToEnableResendCodeBtn = 60;
        this.counterInterval = setInterval(() => {
          (this.numberOfSecondsToEnableResendCodeBtn)
            ? this.numberOfSecondsToEnableResendCodeBtn--
            : clearInterval(this.counterInterval);
        }, 1000);
      });
    }
  }
}
