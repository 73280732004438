import {Injectable} from '@angular/core';
import {TlPageInterface} from '../../interfaces/general-interfaces';

@Injectable({
  providedIn: 'root'
})
export class ActivePageHelperService {

  private _activePage: TlPageInterface | undefined;

  constructor() {
  }
  get activePage(): TlPageInterface | undefined {
    return this._activePage;
  }

  set activePage(value: TlPageInterface | undefined) {
    this._activePage = value;
  }
}
