<div class="wrapper-card-post">
  <div class="header-card-post">
    <div class="user-card-post">
      <img class="user-img" src="../../../../../assets/images/avatar-user.png" alt="">
      <div class="wrapper-user-card-info-post">
        <div class="user-name">
          <span>{{post.user.name}}</span>
          <img src="../../../../../assets/icons/fill/icon-badge.svg" alt="">
        </div>
        <span>{{post.updated_at | timeAgo}}</span>
      </div>
    </div>
    <div class="wrapper-dropdown-btn">
      <div class="view-more-post">
        <img src="../../../../assets/icons/outline/icon-view-more.svg" alt="">
      </div>
      <!--    TODO JS - Ovo pojavljujes kad ide na view more    -->
      <div *ngIf="false" class="content-dropdown-btn">
        <div class="item-dropdown">
          <img src="../../../../assets/icons/outline/icon-edit.svg" alt="">
          <span>Edit post</span>
        </div>
        <div class="item-dropdown">
          <img src="../../../../assets/icons/outline/icon-share.svg" alt="">
          <span>Share</span>
        </div>
        <div class="item-dropdown delete">
          <img src="../../../../assets/icons/outline/icon-outline-attention.svg" alt="">
          <span>Report</span>
        </div>
        <div class="item-dropdown delete">
          <img src="../../../../assets/icons/outline/icon-delete.svg" alt="">
          <span>Delete post</span>
        </div>
      </div>
    </div>
  </div>
  <div class="content-card-post">
    <p class="description">
      {{post.content}}... <span>more</span>
    </p>
    <!--  TODO JS - Ukoliko nema slike sklanjas ovaj div, ukoliko ima jedna ili dbe ucitavas ih unutar ovog diva    -->
    <div class="wrapper-image-tagged">
      <img src="https://gratisography.com/wp-content/uploads/2024/03/gratisography-funflower-800x525.jpg" alt="">
      <img src="https://gratisography.com/wp-content/uploads/2024/03/gratisography-funflower-800x525.jpg" alt="">
    </div>
    <div class="wrapper-tagged-list-item">
      <div class="wrapper-tags">
        <div class="wrapper-tag list">
          <img src="../../../../../assets/icons/outline/icon-tag.svg" alt="">
          <span>Beach hotels in Spain</span>
        </div>
        <div class="wrapper-tag item">
          <img src="../../../../../assets/icons/outline/icon-tag.svg" alt="">
          <span>Gabriel Kreuthin</span>
        </div>
      </div>
    </div>
    <div class="wrapper-toolbar-post">
      <div class="wrapper-toolbar-left">
        <span>57 likes |</span>
        <span> 12 comments</span>
      </div>
      <div class="wrapper-toolbar-right">
        <!--     TODO JS - Dodajes klasu "liked"     -->
        <div class="wrapper-like liked">
          <img class="no-like" src="../../../../../assets/icons/outline/icon-like.svg" alt="">
          <img class="like" src="../../../../../assets/icons/fill/icon-like.svg" alt="">
          <span>Like</span>
        </div>
        <div class="wrapper-share">
          <img src="../../../../../assets/icons/outline/icon-share.svg" alt="">
          <span>Share</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div></div>
