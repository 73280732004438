import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ChatInfoMediaComponent } from '../chat-info-media/chat-info-media.component';
import { ChatInfoPdfsComponent } from '../chat-info-pdfs/chat-info-pdfs.component';
import { NgClass, NgForOf, NgIf, SlicePipe } from '@angular/common';
import { Conversation } from 'src/app/services/http-services/chat/chat-http-interfaces';
import { RouterModule } from '@angular/router';
import { CheckChatGroupLengthModule } from 'src/app/pipes/check-chat-group-length/check-chat-group-length.module';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { WebsocketsHelperService } from 'src/app/services/helpers/websockets-helper.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-chat-info',
  standalone: true,
  imports: [
    NgIf,
    ChatInfoMediaComponent,
    ChatInfoPdfsComponent,
    NgClass,
    CheckChatGroupLengthModule,
    SlicePipe,
    NgForOf,
    RouterModule
  ],
  templateUrl: './chat-info.component.html',
})
export class ChatInfoComponent implements OnInit, OnDestroy {
  @Input() conversation!: Conversation;
  @Output() isSearchOpen = new EventEmitter<boolean>();
  @Output() isGroupAccountsOpen = new EventEmitter<boolean>(false);
  tabSelected = 'media';
  PAGES_AND_PATHS = PAGES_AND_PATHS;
  groupAccountDetailsChanged: Subscription | undefined;

  constructor(
    private websocketsHelperService: WebsocketsHelperService
  ) {
  }

  ngOnInit(): void {
    this.listenForGroupDetailsChanges();
  }

  selectTab(tab: string) {
    this.tabSelected = tab;
  }

  listenForGroupDetailsChanges() {
    this.websocketsHelperService.listenForGroupDetailsChanges(this.conversation.id);
    this.groupAccountDetailsChanged = this.websocketsHelperService.groupDetailsChanges.subscribe((res: any) => {
      console.log('details changed!', res)
      this.conversation.name = res.conversation.name;
      this.conversation.profile_photo = res.conversation.profile_photo;
    });
  }

  ngOnDestroy(): void {
    this.groupAccountDetailsChanged?.unsubscribe();
  }

}
