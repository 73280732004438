<div class="wrapper-chat-conversation-header" [ngClass]="{'open-search': isSearchOpen}">
  <div class="header-chat-conversation" *ngIf="!isNewChatActive && conversationId !== 0">
    <div class="wrapper-custom-search">
      <img class="icon-search" src="../../../assets/icons/outline/icon-search.svg" alt="">
      <input type="text" placeholder="Search..." [(ngModel)]="searchTerm" (input)="onSearchChange($event)">
      <div (click)="isSearchOpen = false; searchTerm = ''" class="wrapper-delete-search">
        <img src="../../../assets/icons/outline/icon-close.svg" alt="">
      </div>
    </div>
    <div *ngIf="conversation">
      <div *ngIf="!isChatInfoOpen" class="wrapper-chat-conversation-left">
        <div *ngIf="conversation.is_group" class="wrapper-user-avatar-group-chat">
          <div *ngIf="conversation.profile_photo === null" class="images-grid-chat"
            [ngClass]="conversation.other_participants?.length | checkChatGroupLength">
            <div class="image">
              <img [src]="'../../../../../assets/images/avatar-user.png'" alt="">
            </div>
            <div *ngFor="let person of conversation.other_participants | slice:0:3" class="image">
              <img [src]="'../../../../../assets/images/avatar-user.png'" alt="">
            </div>
            <div class="number-more-people">
              <span>{{conversation.other_participants?.length}}+</span>
            </div>
          </div>
          <div *ngIf="conversation.profile_photo !== null" class="images-grid-chat"
            [ngClass]="conversation.other_participants?.length | checkChatGroupLength">
            <div class="image">
              <img [src]="conversation?.profile_photo?.url" alt="">
            </div>
          </div>
        </div>
        <div *ngIf="!conversation.is_group" class="avatar-image">
          <img src="../../../../../assets/images/avatar-user.png" alt="">
        </div>
        <span>{{conversation.is_group && conversation.name ? conversation.name : conversation |
          setChatUsernames}}</span>
        <img src="../../../../../assets/icons/fill/icon-badge.svg" alt="">
      </div>
    </div>
    <div class="wrapper-chat-conversation-right">
      <button
        *ngIf="conversation?.is_group && (conversation.admin_participants !== undefined && conversation.admin_participants[0]?.id !== loggedUser?.id)"
        (click)="openLeaveChatModal()" class="custom-btn custom-btn--circle-outline">
        <img src="../../../../../assets/icons/outline/icon-logout.svg" alt="">
      </button>
      <!--  TODO JS - Ovo dugme stavlajs kad je admin u grupnom chat-u, e sad ne znam kad je single chat i korisnik ima ovaj delete, uglavnom logiku sredi imas oba dugmeta :)    -->
      <button
        *ngIf="!conversation?.is_group || (conversation.admin_participants !== undefined && conversation.admin_participants[0]?.id === loggedUser?.id)"
        class="custom-btn custom-btn--circle-outline" (click)="openDeleteModal()">
        <img src="../../../../../assets/icons/outline/icon-delete.svg" alt="">
      </button>
      <button class="custom-btn custom-btn--outline"
        (click)="openCloseChatInfo()"><span>{{chatInfoButtonText}}</span></button>
    </div>
  </div>
</div>
<div *ngIf="!isChatInfoOpen && !isNewChatActive && conversationId !== 0" class="wrapper-chat-conversation-content">
  <div class="chat-conversation-content" [ngClass]="{'height-content': isSearchOpen}" #chatContainer
    (scroll)="onScroll()">
    <div *ngFor="let message of messages; let i = index">
      <div
        *ngIf="(message.type === 'text' && message?.body !== null) || (message.type === 'image' && message?.image !== null) || (message.type === 'image' && message?.image !== null) || (message.type === 'video' && message.video !== null) || (message.type === 'attachment' && message.attachment !== null) || (message.type === 'share' && message.share !== null)"
        [ngClass]="{ 'my-message': message.is_mine }" class="wrapper-message" appClickedOutside
        (clickedOutside)="selectedMessage = null">
        <div *ngIf="messages | shouldDisplayDate: i" class="time-message old">
          <span>{{message.updated_at | formatMessageTimestamp}}</span>
        </div>
        <div class="message-group-chat">
          <div *ngIf="conversation?.is_group" class="avatar-user-group-chat">
            <img src="../../../../../assets/images/avatar-user.png" alt="">
          </div>
          <div class="message" (click)="$event.stopPropagation()"
            [ngClass]="{'media-preview': message.type ===  'video' || message.type === 'image' || message.type === 'share', 'xs-mb-20': message.message_reactions.length > 0}">
            <span *ngIf="conversation.is_group" class="username-user-group-chat">{{ message.user_id |
              getUserData:conversation.other_participants:'username' }}</span>
            <div class="image-message" (click)="openMedia(message)"
              *ngIf="message.type === 'image' && message?.image !== null">
              <img [src]="message?.image?.url" alt="">
            </div>
            <div class="video-message" (click)="openMedia(message)"
              *ngIf="message.type === 'video' && message.video !== null">
              <app-video-box [video]="message"></app-video-box>
            </div>
            <div class="attachment-message"
              *ngIf="message.type === 'attachment' && message.attachment !== null && message.attachment !== undefined"
              (click)="download(message)">
              <img src="../../../../../assets/icons/outline/icon-my-lists.svg" alt="">
              <span>{{message.attachment.name + '.' + message.attachment.extension}}</span>
            </div>
            <div class="text-message" *ngIf="message.type === 'text' && message?.body !== null">
              <span *ngIf="!searchTerm">{{message?.body}}</span>
              <span *ngIf="searchTerm" [innerHTML]="message.body | highlightFoundSearchTerm: searchTerm"></span>
            </div>
            <div (click)="openEmojies(message)" class="view-more-message">
              <img src="../../../../../assets/icons/icon-arrow-left.svg" alt="">
            </div>
            <div *ngIf="selectedMessage?.id === message.id" class="wrapper-emoticons">
              <img (click)="emojiReaction('heart', message.id)"
                src="../../../../../assets/icons/emoticons/emoticon-love.png" alt="">
              <img (click)="emojiReaction('unlike', message.id)"
                src="../../../../../assets/icons/emoticons/emoticon-dislike.png" alt="">
              <img (click)="emojiReaction('like', message.id)"
                src="../../../../../assets/icons/emoticons/emoticon-like.png" alt="">
              <img (click)="emojiReaction('sad', message.id)"
                src="../../../../../assets/icons/emoticons/emoticon-sad-smiley.png" alt="">
              <img (click)="emojiReaction('happy', message.id)"
                src="../../../../../assets/icons/emoticons/emoticon-smile.png" alt="">
            </div>
            <div class="wrapper-edit-delete-message"
              *ngIf="selectedMessage?.id === message.id && (loggedUser.id === selectedMessage?.user_id || loggedUser.id === conversation.admin_participants[0].id)">
              <div *ngIf="message | displayEditAndDeleteMessage: selectedMessage : loggedUser"
                (click)="onEditMessage(message)" class="edit-delete-message">
                <img src="../../../../../assets/icons/outline/icon-pen.svg" alt="">
              </div>
              <div
                *ngIf="loggedUser.id === selectedMessage?.user_id || loggedUser.id === conversation.admin_participants[0].id"
                (click)="deleteMessage(message.id)" class="edit-delete-message">
                <img src="../../../../../assets/icons/outline/icon-delete.svg" alt="">
              </div>
            </div>
            <div *ngIf="message.message_reactions.length && !conversation.is_group" class="wrapper-selected-emoticon">
              <img *ngIf="message.message_reactions[0].type === 'like'"
                src="../../../../../assets/icons/emoticons/emoticon-like.png" alt="">
              <img *ngIf="message.message_reactions[0].type === 'unlike'"
                src="../../../../../assets/icons/emoticons/emoticon-dislike.png" alt="">
              <img *ngIf="message.message_reactions[0].type === 'heart'"
                src="../../../../../assets/icons/emoticons/emoticon-love.png" alt="">
              <img *ngIf="message.message_reactions[0].type === 'happy'"
                src="../../../../../assets/icons/emoticons/emoticon-smile.png" alt="">
              <img *ngIf="message.message_reactions[0].type === 'sad'"
                src="../../../../../assets/icons/emoticons/emoticon-sad-smiley.png" alt="">
            </div>
            <div *ngIf="message.message_reactions.length && conversation.is_group" class="wrapper-selected-emoticon">
              <img *ngFor="let reaction of message.message_reactions" [src]="reaction | returnMessageEmoji" alt="">
            </div>
            <!--     TODO JS - ako ja nesto srujem u listu dodajes klasu my-share ako to stigne od nekog korisnika ne dodajes     -->
            <!--     TODO JS - Kad jegore dodata klasa my-message i ovde dodajes my-share, ako gore nema my-message i ovde sklanjas my-share         -->
            <!--     TODO JS - ukoliko se ne seruje nista ceo ovaj div sklanjas         -->
            <!--     Share wrapper     -->
            <div *ngIf="message.type === 'share'" class="wrapper-share-message"
              [ngClass]="{ 'my-share': message.is_mine }">
              <div class="share-message">
                <!--      Share list        -->
                <div *ngIf="message.share.type === 'lyst'"
                  [routerLink]="[PAGES_AND_PATHS.singleList.pagePath, message.share.shareable.id, 0, 'cards']"
                  class="wrapper-share-list">
                  <div class="tl-d-flex tl-align-center">
                    <img [src]="message.share.shareable?.lyst_image_url" alt="">
                    <div class="wrapper-info-share-list">
                      <span class="name-list">{{message.share.shareable.title}}</span>
                      <span class="author-list">{{message.share.shareable.user.name}}</span>
                    </div>
                  </div>
                  <div class="wrapper-badges">
                    <div class="badge yellow">
                      <span>{{message.share.shareable.lyst_items_count}} {{message.share.shareable.lyst_items_count === 1 ? 'item' : 'items'}}</span>
                    </div>
                  </div>
                </div>
                <!--      Share item        -->
                <div *ngIf="message.share.type === 'lyst_item'"
                  [routerLink]="[PAGES_AND_PATHS.singleItem.pagePath, message.share.shareable.id]"
                  class="wrapper-share-item">
                  <span class="number-item">{{message.share.shareable.position}}</span>
                  <img [src]="message.share.shareable?.lyst_item_image_url" alt="">
                  <div class="wrapper-info-share-item">
                    <span class="name-item">{{message.share.shareable?.name}}</span>
                    <span class="desc-item">{{message.share.shareable?.by}}</span>
                  </div>
                </div>
                <!--      Share post        -->
                <div *ngIf="message.share.type === 'post'"
                  [routerLink]="[PAGES_AND_PATHS.singlePost.pagePath, message.share.shareable?.id]"
                  class="wrapper-share-post">
                  <div class="wrapper-author">
                    <img [src]="message.share.shareable?.user?.profile_photo_url !== null ? message.share.shareable?.user?.profile_photo_url : '../../../../../assets/images/avatar-user.png'" alt="">
                    <div class="wrapper-author-info">
                      <span class="name">{{message.share.shareable?.user?.name}}</span>
                      <span class="time">{{message.share.shareable?.created_at | timeAgo}}</span>
                    </div>
                  </div>
                  <span class="description-post">{{message.share.shareable?.content}}</span>
                  <div class="wrapper-images">
                    <img *ngIf="message.share.shareable?.lyst?.lyst_image_url"
                      [src]="message.share.shareable?.lyst?.lyst_image_url"
                      [alt]="message.share.shareable?.lyst?.title + '-image'">
                    <img *ngIf="message.share.shareable?.lyst?.lyst_item_image_url"
                      [src]="message.share.shareable?.lyst_item?.lyst_item_image_url"
                      [alt]="message.share.shareable?.lyst_item?.name  + '-image'">
                  </div>
                  <div class="wrapper-tags">
                    <div *ngIf="message.share.shareable?.lyst !== null" class="wrapper-tag list">
                      <img src="../../../../../assets/icons/outline/icon-tag.svg" alt="">
                      <span>{{message.share.shareable?.lyst?.title}}</span>
                    </div>
                    <div *ngIf="message.share.shareable?.lyst_item !== null" class="wrapper-tag item">
                      <img src="../../../../../assets/icons/outline/icon-tag.svg" alt="">
                      <span>{{message.share.shareable?.lyst_item?.name}}</span>
                    </div>
                  </div>
                </div>
                <!--      Share review list        -->
                <div *ngIf="message.share?.type === 'review' && message?.share?.shareable?.review_model_type === 'lyst'"
                  [routerLink]="[PAGES_AND_PATHS.singleReview.pagePath, 'list', message?.share?.shareable?.reviewable_id, message?.share?.shareable_id]"
                  class="wrapper-share-review">
                  <div class="wrapper-author">
                    <img [src]="message.share.shareable?.user?.profile_photo_url !== null ? message.share.shareable?.user?.profile_photo_url : '../../../../../assets/images/avatar-user.png'" alt="">   
                    <div class="wrapper-author-info">
                      <span class="name">{{message?.share?.shareable?.user?.name}}</span>
                      <span class="time">{{message?.share?.shareable?.created_at | timeAgo}}</span>
                    </div>
                  </div>
                  <div class="wrapper-review">
                    <div class="wrapper-rating">
                      <app-stars [average_rating]="message?.share?.shareable?.reviewable?.average_rating"></app-stars>
                    </div>
                    <span>{{message?.share?.shareable?.review}}</span>
                  </div>
                  <div class="wrapper-share-list">
                    <div class="tl-d-flex tl-align-center">
                      <img [src]="message?.share?.shareable?.reviewable?.lyst_image_url" alt="">
                      <div class="wrapper-info-share-list">
                        <span class="name-list">{{message?.share?.shareable?.reviewable?.title}}</span>
                        <span class="author-list">author-list</span>
                      </div>
                    </div>
                    <div class="wrapper-badges">
                      <div class="badge yellow">
                        <span>24 items</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!--      Share review item        -->
                <div
                  *ngIf="message?.share?.type === 'review' && message?.share?.shareable?.review_model_type === 'lyst_item'"  [routerLink]="[PAGES_AND_PATHS.singleReview.pagePath, 'item', message?.share?.shareable?.reviewable_id, message?.share?.shareable_id]"
                  class="wrapper-share-review">
                  <div class="wrapper-author">
                    <img src="../../../../../assets/images/avatar-user.png" alt="">
                    <div class="wrapper-author-info">
                      <span class="name">{{message?.share?.shareable?.user.name}}</span>
                      <span class="time">{{message?.share?.shareable?.created_at | timeAgo}}</span>
                    </div>
                  </div>
                  <div class="wrapper-review">
                    <div class="wrapper-rating">
                      <app-stars [average_rating]="message?.share?.shareable?.reviewable?.average_rating"></app-stars>
                    </div>
                    <span>{{message?.share?.shareable?.review}}</span>
                  </div>
                  <div class="wrapper-share-item">
                    <img src="https://t3.ftcdn.net/jpg/03/24/73/92/360_F_324739203_keeq8udvv0P2h1MLYJ0GLSlTBagoXS48.jpg"
                      alt="">
                    <div class="wrapper-info-share-item">
                      <span class="name-item">Best cheeseburgers in New York</span>
                      <span class="desc-item">Best burgers in New York City</span>
                    </div>
                  </div>
                </div>
                <!--      Share profile        -->
                <div *ngIf="message.share.type === 'profile'"
                  [routerLink]="['/profile' + '/' + message.share.shareable?.id]" class="wrapper-share-profile">
                  <div class="wrapper-author">
                    <img
                      [src]="message.share.shareable?.avatar !== null ? message.share.shareable?.avatar : '../../../../../assets/images/avatar-user.png'"
                      alt="">
                    <div class="wrapper-author-info">
                      <span class="name">{{message.share.shareable?.name}}</span>
                      <span class="time">{{message.share.shareable?.username}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="message.updated_at &&
        (i === messages.length - 1 ||
        !(messages[i + 1].updated_at | isSameMinutePipe: message.updated_at) ||
        message.is_mine !== messages[i + 1].is_mine)" class="time-message">
          <span>{{ message.updated_at | date:'HH:mm' }}</span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isMediaUploading" class="wrapper-loading-media">
    <div class="zc-spinner-wrapper-buttons">
      <span>Uploading</span>
      <div class="zc-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
  <!-- TODO JS - Ovo sklanjas kad je dodata ona klasa gore "open-search" -->
  <div class="chat-conversation-footer">
    <div class="wrapper-upload-icons">
      <div class="wrapper-upload-file">
        <label>
          <img src="../../../../../assets/icons/outline/icon-camera.svg" alt="">
          <input type="file" accept="image/*,video/*" class="upload-custom" (change)="sendFile($event)" />
        </label>
      </div>
      <div class="wrapper-upload-file">
        <label>
          <img src="../../../../../assets/icons/outline/icon-my-lists.svg" alt="">
          <input id="file-upload" type="file" accept=".pdf" class="upload-custom" (change)="sendFile($event)" />
        </label>
      </div>
    </div>
    <div class="wrapper-input-chat">
      <input #messageInput placeholder="Message..." [(ngModel)]="message" (onFocus)="onInputFocus(true)"
        (onBlur)="onInputFocus(false)" (keydown.enter)="sendMessage()">
    </div>
    <div class="wrapper-send-btn">
      <button class="custom-btn custom-btn--circle-primary" (click)="sendMessage()">
        <img src="../../../../../assets/icons/outline/icon-send.svg" alt="">
      </button>
    </div>
  </div>
</div>
<app-no-chats *ngIf="loadedConversations === 0"></app-no-chats>
<app-delete-chat-modal *ngIf="isDeleteModalOpen" [conversationId]="conversationId" [deleteChatText]="deleteChatText"
  (closeDeleteChatModal)="isDeleteModalOpen = false" (deleteChat)="deleteConversation()"></app-delete-chat-modal>
<app-chat-info *ngIf="isChatInfoOpen" [conversation]="conversation" (closeChatInfoModal)="isChatInfoOpen = false"
  (isSearchOpen)="isSearchOpen = $event; openCloseChatInfo()"
  (isGroupAccountsOpen)="isGroupAccountsOpen.emit($event); isChatInfoOpen = false;"></app-chat-info>
<app-preview-media *ngIf="selectedMedia !== null" [selectedMedia]="selectedMedia" (closeModal)="selectedMedia = null"
  (download)="download(selectedMedia)"></app-preview-media>