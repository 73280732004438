import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { ShareEntitiesEnums } from 'src/app/enums/chat-enums';
import { AuthenticationHelperService } from 'src/app/services/helpers/authentication-helper.service';
import { ItemsHttpService } from 'src/app/services/http-services/items/items-http.service';
import { SingleItem } from 'src/app/services/http-services/items/items-interfaces';
import { DeleteModalText } from 'src/app/services/http-services/lists/lists-http-intterfaces';
import { ListsHttpService } from 'src/app/services/http-services/lists/lists-http.service';

@Component({
  selector: 'app-single-item',
  templateUrl: './single-item.component.html',
})
export class SingleItemComponent implements OnInit {
  tabSelected = 'info';
  itemId = 0;
  item!: SingleItem;
  PAGES_AND_PATHS = PAGES_AND_PATHS;
  isShareProfileModalOpen = false;
  ShareEntitiesEnums = ShareEntitiesEnums;
  itemUrl = '';
  isViewMoreModalOpen = false;
  isDeleteModalOpen = false;
  modalText: DeleteModalText = { title: '', description: '', button: '' };
  loggedUser: any;
  isReportModalOpen = false;
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  constructor(
    private listsHttpService: ListsHttpService,
    private actRoute: ActivatedRoute,
    private itemsHttpService: ItemsHttpService,
    private router: Router,
    private toastService: ToastrService,
    private authenticationHelperService: AuthenticationHelperService,
  ) { }

  ngOnInit(): void {
    this.loggedUser = this.authenticationHelperService.getUserData;
    this.itemUrl = window.location.href;
    this.getParams();
  }

  getParams() {
    this.actRoute.paramMap.subscribe(params => {
      this.itemId = Number(params.get('id'));
      this.getSingleItem();
    });
  }

  getSingleItem(): void {
    this.listsHttpService.getSingleItem(this.itemId).then((res: any) => {
      this.item = res;
      console.log('this single item', res)
    });
  }

  selectTab(tabSelected: string): void {
    this.tabSelected = tabSelected;
  }

  additemToFavorites() {
    this.itemsHttpService.addItemToFavorites(this.itemId).then((res: any) => {
      console.log('additemToFavorites', res)
      this.getSingleItem();
    })
  }

  openDeleteModal() {
    this.isDeleteModalOpen = true;
    this.modalText.title = 'Delete item?';
    this.modalText.description = 'Are you sure you wish to delete this item? This action cannot be undone.'
    this.modalText.button = 'Delete';
  }

  openDropdown(event: any): void {
    this.isViewMoreModalOpen = !this.isViewMoreModalOpen;
  }

  deleteItem() {
    this.isDeleteModalOpen = false;
    this.itemsHttpService.deleteItem(this.itemId).subscribe((response: any) => {
      this.toastService.success(response.message);
      this.router.navigateByUrl('lists/my_lists');
    })
  }

  reportItem() {
    this.isReportModalOpen = true;
    // const reqBody = {
    //   body: this.item.id.toString()
    // }
    // this.itemsHttpService.reportItem(this.item.id, reqBody).subscribe((res: any) => {
    //   console.log('report item res', res)
    // })
  }

  triggerFileInput(event: Event): void {
    event.preventDefault();
    this.fileInput.nativeElement.click();
  }

  onFileChange(event: any): void {
    const file = event.target.files[0];
    const formData = new FormData();
    if (file) {
      formData.append('image', file);
    }
    this.itemsHttpService.addImageToItem(this.item.id, formData).then((response: any) => {
      this.fileInput.nativeElement.value = '';
      this.getSingleItem();
    })
  }

  deleteImage(image: any) {
    console.log(image)
    this.itemsHttpService.deleteImageFromItem(this.item.id, image.id).then((response: any) => {
      this.toastService.success(response.data);
      this.getSingleItem();
    })
  }

}
