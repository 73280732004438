import { NgClass, NgForOf, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { ClickedOutsideModule } from 'src/app/directives/clicked-outside/clicked-outside.module';
import { ShareEntitiesEnums } from 'src/app/enums/chat-enums';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { SharingOptionsModalComponent } from 'src/app/pages/my-profile/components/sharing-options-modal/sharing-options-modal.component';
import { PostPreviewComponent } from 'src/app/pages/posts/components/post-preview/post-preview.component';
import { HandleShowMoreModule } from 'src/app/pipes/handle-show-more/handle-show-more.module';
import { TimeAgoModule } from 'src/app/pipes/time-ago/time-ago.module';
import { DeleteModalText } from 'src/app/services/http-services/lists/lists-http-intterfaces';
import { ListsHttpService } from 'src/app/services/http-services/lists/lists-http.service';
import { PostsHttpService } from 'src/app/services/http-services/posts/posts-http.service';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { AuthenticationHelperService } from 'src/app/services/helpers/authentication-helper.service';
import { ProfileHttpService } from 'src/app/services/http-services/profile/profile-http.service';

@Component({
  selector: 'app-posts',
  standalone: true,
  imports: [
    RouterModule,
    NgForOf,
    NgClass,
    PostPreviewComponent,
    TimeAgoModule,
    NgIf,
    HandleShowMoreModule,
    SharingOptionsModalComponent,
    ClickedOutsideModule,
    ConfirmModalComponent
  ],
  templateUrl: './posts.component.html',
})
export class PostsComponent implements OnInit {
  @Input() isList = false;
  @Input() id: number = 0;
  @Input() list: any;
  @Input() item: any;
  @Input() myPosts = false;
  pageNum = 1;
  pagination!: TlPagination;
  PAGES_AND_PATHS = PAGES_AND_PATHS;
  isShowMore = false;
  isSharePostModalOpen = false;
  protected readonly ShareEntitiesEnums = ShareEntitiesEnums;
  selectedPost: any | null = null;
  postUrl = '';
  isViewMoreModalOpen = false;
  isDeleteModalOpen = false;
  modalText: DeleteModalText = { title: '', description: '', button: '' };
  loggedUser: any;
  urlParams = {page: 1};
  posts: any[] = [];
  postsPagination: TlPagination | undefined;

  constructor(
    private listsHttpService: ListsHttpService,
    private postsHttpService: PostsHttpService,
    private authenticationHelperService: AuthenticationHelperService,
    private router: Router,
    private profileHttpService: ProfileHttpService
  ) { }

  ngOnInit(): void {
    this.loggedUser = this.authenticationHelperService.getUserData;
    console.log(this.loggedUser)
    if (this.myPosts) {
      this.getMyPosts();
    } else {
      this.isList ? this.getPostsForList() : this.getPostsForItem();
    }
  }

  getMyPosts(): void {
    this.urlParams.page = this.postsPagination?.currentPage ? this.postsPagination.currentPage + 1 : 1;
    this.profileHttpService.getAuthUserPosts(this.urlParams).then(res => {
      this.posts = [...this.posts, ...res.data];
      this.postsPagination = res.pagination;
    })
  }
  getPostsForList() {
    this.listsHttpService.getListPosts(this.id, this.pageNum).then((res: { data: any[], pagination: TlPagination }) => {
      console.log('posts', res)
      this.posts = res.data;
      this.pagination = res.pagination;
    });
  }

  getPostsForItem() {
    this.listsHttpService.getItemPosts(this.id, this.pageNum).then((res: { data: any[], pagination: TlPagination }) => {
      console.log('posts', res)
      this.posts = res.data;
      this.pagination = res.pagination;
    });
  }

  togglePostLike(event: any, post: any): void {
    event.stopPropagation();
    this.postsHttpService.togglePostLike({ postId: post.id }).then(res => {
      if (res.code === 200) {
        const index = this.posts.findIndex((postObj) => postObj.id === post.id);
        console.log(res)
        this.posts[index] = res.data;
      }
    })
  }

  sharePost(post: any) {
    this.selectedPost = post;
    this.isSharePostModalOpen = true;
    this.postUrl = PAGES_AND_PATHS.singlePost.pagePath + '/' + post.id;
  }

  openDropdown(event: any, post: any): void {
    this.isViewMoreModalOpen = !this.isViewMoreModalOpen;
    this.selectedPost = post;
  }

  openDeleteModal(post: any) {
    this.selectedPost = post;
    this.isDeleteModalOpen = true;
    this.modalText.title = 'Delete post?';
    this.modalText.description = 'Are you sure you wish to delete this post? This action cannot be undone.'
    this.modalText.button = 'Delete';
  }

  onConfirmPostDelete(): void {
    this.postsHttpService.deletePost({ postId: this.selectedPost.id }).then(res => {
      if (res.code === 200) {
        this.isDeleteModalOpen = false;
        const index = this.posts.findIndex((postObj) => postObj.id === this.selectedPost.id);
        this.posts.splice(index, 1);
        this.isViewMoreModalOpen = false;
        this.isDeleteModalOpen = false;
      }
    });
  }

  routeToCreatePost(): void {
    const pageDetails = {
      selectedLyst: this.list,
      selectedItem: this.item
    }
    this.router.navigate([PAGES_AND_PATHS.createPost.pagePath], { state: pageDetails });
  }
}
