import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-report-modal',
  standalone: true,
  imports: [],
  templateUrl: './report-modal.component.html',
})
export class ReportModalComponent {
  @Input() entityType = '';
  @Input() entityId!: number;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

  report(){

  }

}
