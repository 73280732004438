import {Component, Input} from '@angular/core';

@Component({
  selector: 'tl-insights-content-you-create',
  standalone: true,
  imports: [],
  templateUrl: './insights-content-you-create.component.html',
})
export class InsightsContentYouCreateComponent {
  @Input() listId: any;
}
