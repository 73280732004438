import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {
  ApexLegend,
  ApexNonAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexStates,
  ApexStroke, ApexResponsive
} from 'ng-apexcharts';

export type ChartOptions = {
  series?: ApexNonAxisChartSeries;
  chart?: ApexChart | any;
  dataLabels?: ApexDataLabels | any;
  stroke?: ApexStroke | any;
  legend?: ApexLegend | any;
  colors?: string[] | any;
  states?: ApexStates | any;
  labels?: string[] | any;
};
@Component({
  selector: 'tl-donut-chart',
  templateUrl: './donut-chart.component.html',
  styles: ``
})
export class DonutChartComponent implements OnChanges {
  chartOptions: ChartOptions | undefined;
  @Input() data: any;
  @Input() colors: string[] = ['#974C8B', '#FFD51D'];
  color = '#c9c3c3';
  chartData: any = {series: [], labels: []};
  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && changes['data'].currentValue) {
      this.chartData.labels = ['Man', 'Women'];
      this.chartData.series = [changes['data'].currentValue.men, changes['data'].currentValue.women];
      setTimeout(() => {
        this.initChart();
      }, 500)
    }
  }

  initChart(): void {
    this.chartOptions = {
      series: this.chartData?.series,
      colors: this.colors,
      chart: {
        width: '100%',
        type: "donut"
      },
      labels: this.chartData?.labels,
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 0
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          }
        },
        active: {
          filter: {
            type: 'none'
          }
        }
      }
    };
    // this.chartOptions = {
    //   series: this.data?.series,
    //   colors: this.colors,
    //   chart: {
    //     width: '100%',
    //     type: 'donut',
    //     zoom: {
    //       enabled: false
    //     },
    //   },
    //   tooltip: {
    //     y: {
    //       formatter: function (val: number) {
    //         return val.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%';
    //       }
    //     }
    //   },
    //   dataLabels: {
    //     enabled: false,
    //   },
    //   plotOptions: {
    //     pie: {
    //       offsetY: 60,
    //     }
    //   },
    //   labels: this.data?.labels,
    //   legend: {
    //     // position: 'left',
    //     // floating: true,
    //     // itemMargin: {
    //     //   vertical: 2,
    //     // },
    //   },
    //   states: {
    //     hover: {
    //       filter: {
    //         type: 'lighten',
    //         value: 0.05,
    //       }
    //     },
    //     active: {
    //       filter: {
    //         type: 'none'
    //       }
    //     }
    //   }
    // };
  }
}
