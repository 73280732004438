<p>near-you works!</p>
<div>
    <button (click)="selectTab('near-me')">near me</button>
    <button (click)="selectTab('themes')">themes</button>
    <button (click)="selectTab('listers')">listers</button>
</div>
<div>
    <app-near-me *ngIf="tabSelected === 'near-me'"></app-near-me>
    <app-themes *ngIf="tabSelected === 'themes'"></app-themes>
    <app-listers *ngIf="tabSelected === 'listers'"></app-listers>
</div>
