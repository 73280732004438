import { Pipe, PipeTransform } from '@angular/core';
import { ChatParticipant } from 'src/app/services/http-services/chat/chat-http-interfaces';

@Pipe({
  name: 'getUserData',
  standalone: true
})
export class GetUserDataPipe implements PipeTransform {

  transform(userId: number, participants: ChatParticipant[] | undefined, dataType: 'username' | 'image'): string {
    const participant = participants?.find(p => p.id === userId);

    if (participant) {
      if (dataType === 'username') {
        return participant.username;
      } else if (dataType === 'image') {
        return participant.image ? participant.image : '../../../../assets/images/avatar-user.png';
      }
    }

    return dataType === 'username' ? 'Unknown User' : '../../../../assets/images/avatar-user.png';
  }

}
