import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormatMessageTimestampPipe } from './format-message-timestamp.pipe';



@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    FormatMessageTimestampPipe
  ],
  exports: [
    FormatMessageTimestampPipe
  ]
})
export class FormatMessageTimestampModule { }
