<div class="wrapper-page-create-list">
  <div class="header-create-list">
    <button (click)="goBack()" class="custom-btn custom-btn--circle-outline">
      <img src="../../../assets/icons/icon-arrow-left.svg" alt="">
    </button>
  </div>
  <div class="wrapper-create-review">
    <form [formGroup]="reviewForm" (ngSubmit)="submitReview()">
      <div class="author-create-review">
        <img class="profile-image" [src]="loggedUser.profile_photo_url !== null ? loggedUser.profile_photo_url : '../../../../assets/images/avatar-user.png'" alt="">
        <span>{{loggedUser.name}}</span>
        <img *ngIf="loggedUser.is_expert" class="badge" src="../../../assets/icons/fill/icon-badge.svg" alt="">
        <!--        <img class="badge"src="../../../assets/icons/fill/icon-fill-company.svg" alt="">-->
      </div>
      <span class="label-create-review">You are posting review for the following {{reviewType === 'list' ? 'list' :
        'item'}}:</span>
      <div class="wrapper-tags">
        <div class="wrapper-tag list">
          <img src="../../../../../assets/icons/outline/icon-tag.svg" alt="">
          <span>{{reviewType === 'item' ? historyState?.name : historyState?.title}}</span>
        </div>
        <!--      <div class="wrapper-tag item">-->
        <!--        <img src="../../../../../assets/icons/outline/icon-tag.svg" alt="">-->
        <!--        <span>tag lorem ipsum</span>-->
        <!--      </div>-->
      </div>
      <div class="wrapper-add-rating">
        <img *ngFor="let star of stars; let i = index"
          src="{{ i < reviewForm.controls['rating'].value ? filledStar : outlinedStar }}" (click)="rate(i + 1)"
          alt="star">
      </div>
      <div class="wrapper-input textarea">
        <span class="label-input">Description</span>
        <div class="custom-input">
          <textarea formControlName="review" maxlength="200" placeholder="Share details of your review..."></textarea>
        </div>
        <div class="wrapper-message-description">
          <span class="label-info-input">The maximum length of a review description is {{reviewForm.controls['review'].value?.length || 0 }}/200  characters.</span>
        </div>
      </div>
      <div class="wrapper-message-info">
        <img src="./assets/icons/outline/icon-outline-attention.svg" alt="">
        <span>Next 48 hours you will be able to modify this review.</span>
      </div>
      <button type="submit" [disabled]="!reviewForm.valid" class="custom-btn custom-btn--primary">
        <span>Post</span>
      </button>
    </form>
  </div>
</div>