<div class="content-insights">
  <div class="wrapper-boxes">
    <div class="wrapper-box-insight" (click)="clickOnCard.emit(InsightsDetailsTypeEnums.followers)">
      <div class="wrapper-icon purple">
        <img src="./assets/icons/insights/icon-insights-followers.svg" alt="">
      </div>
      <span class="value">68</span>
      <span class="label">Total followers</span>
    </div>
    <div class="wrapper-box-insight" (click)="clickOnCard.emit(InsightsDetailsTypeEnums.likes)">
      <div class="wrapper-icon blue">
        <img src="./assets/icons/insights/icon-insights-likes.svg" alt="">
      </div>
      <span class="value">743</span>
      <span class="label">Total list likes</span>
    </div>
  </div>
  <div class="wrapper-boxes">
    <div class="wrapper-box-insight" (click)="clickOnCard.emit(InsightsDetailsTypeEnums.views)">
      <div class="wrapper-icon orange">
        <img src="./assets/icons/insights/icon-insights-list-views.svg" alt="">
      </div>
      <span class="value">1,456</span>
      <span class="label">Total list views</span>
    </div>
    <div class="wrapper-box-insight" (click)="clickOnCard.emit(InsightsDetailsTypeEnums.shares)">
      <div class="wrapper-icon blue-light">
        <img src="./assets/icons/insights/icon-insights-list-shares.svg" alt="">
      </div>
      <span class="value">95</span>
      <span class="label">Total list shares</span>
    </div>
  </div>
  <div class="header-lists-insights">
    <span>Content you created</span>
    <button type="submit" class="custom-btn custom-btn--gray"><span>View all</span></button>
  </div>
  <div class="content-slide-list-insights">
    <div class="grid">
      <div class="grid__s-4">
        <div class="wrapper-list-insights">
          <div class="image-insights">
            <img src="https://media.architecturaldigest.com/photos/63079fc7b4858efb76814bd2/16:9/w_4000,h_2250,c_limit/9.%20DeLorean-Alpha-5%20%5BDeLorean%5D.jpg" alt="">
          </div>
          <span class="name-list-insights">Wine and Dine</span>
          <p>743 <span>Likes</span></p>
          <p>66 <span>Views</span></p>
          <p>85 <span>Shares</span></p>
        </div>
      </div>
      <div class="grid__s-4">
        <div class="wrapper-list-insights">
          <div class="image-insights">
            <img src="https://media.architecturaldigest.com/photos/63079fc7b4858efb76814bd2/16:9/w_4000,h_2250,c_limit/9.%20DeLorean-Alpha-5%20%5BDeLorean%5D.jpg" alt="">
          </div>
          <span class="name-list-insights">Wine and Dine</span>
          <p>743 <span>Likes</span></p>
          <p>66 <span>Views</span></p>
          <p>85 <span>Shares</span></p>
        </div>
      </div>
      <div class="grid__s-4">
        <div class="wrapper-list-insights">
          <div class="image-insights">
            <img src="https://media.architecturaldigest.com/photos/63079fc7b4858efb76814bd2/16:9/w_4000,h_2250,c_limit/9.%20DeLorean-Alpha-5%20%5BDeLorean%5D.jpg" alt="">
          </div>
          <span class="name-list-insights">Wine and Dine</span>
          <p>743 <span>Likes</span></p>
          <p>66 <span>Views</span></p>
          <p>85 <span>Shares</span></p>
        </div>
      </div>
    </div>
  </div>
</div>
