import { Component } from '@angular/core';
import {TlPagination} from "../../../interfaces/general-interfaces";
import {PAGES_AND_PATHS} from "../../../constants/pages-and-paths";
import {PostsHttpService} from "../../../services/http-services/posts/posts-http.service";

@Component({
  selector: 'tl-latest-post',
  templateUrl: './latest-post.component.html',
})
export class LatestPostComponent {
  posts: any[] = [];
  postsPagination: TlPagination | undefined;
  urlParams = {page: 1};
  baseUrl = '';
  showImageInFullScreen: any;
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;

  constructor(private postsHttpService: PostsHttpService) { }

  ngOnInit() {
    this.baseUrl = window.location.origin;
    this.posts = [];
    this.postsPagination = undefined;
    this.getLatestPosts();
  }

  getLatestPosts(): void {
    this.urlParams.page = this.postsPagination?.currentPage ? this.postsPagination.currentPage + 1 : 1;
    this.postsHttpService.getLatestPosts(this.urlParams).then(res => {
      this.posts = [...this.posts, ...res.data];
      this.postsPagination = res.pagination;
    })
  }

  onTogglePostLike(post: any): void {
    const postIndex = this.posts.findIndex(postElement => postElement.id === post.id);
    if (postIndex !== -1) {
      this.posts[postIndex] = post;
    }
  }

  onConfirmPostDeleted(postId: string): void {
    this.posts = this.posts.filter(post => post.id !== postId);
  }

  onScroll(event: any): void {
    const threshold = 1;
    const position = event.target.scrollTop + event.target.offsetHeight;
    const height = event.target.scrollHeight;
    if (position > height - threshold && (this.postsPagination?.currentPage ?? 0) < (this.postsPagination?.lastPage ?? 0)) {
      this.getLatestPosts();
    }
  }
}
