import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';
import { GroupDetailsComponent } from './group-details.component';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.groupChatDetails.pageInRouting,
    data: { data: PAGES_AND_PATHS.groupChatDetails },
    component: GroupDetailsComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./group-details.module').then(m => m.GroupDetailsModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GroupDetailsRoutingModule { }
