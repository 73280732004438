import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { AuthenticationHelperService } from 'src/app/services/helpers/authentication-helper.service';
import { ItemsHttpService } from 'src/app/services/http-services/items/items-http.service';

@Component({
  selector: 'app-create-review',
  templateUrl: './create-review.component.html',
})
export class CreateReviewComponent implements OnInit {
  reviewType = '';
  historyState: any;
  reviewForm!: FormGroup;
  filledStar: string = "../../../../assets/icons/rating/icon-star-fill.svg";
  outlinedStar: string = "../../../../assets/icons/outline/icon-star-outline1.svg";
  stars: number[] = [1, 2, 3, 4, 5];
  loggedUser: any;
  PAGES_AND_PATHS = PAGES_AND_PATHS;

  constructor(
    private location: Location,
    private actRoute: ActivatedRoute,
    private itemsHttpService: ItemsHttpService,
    private formBuilder: FormBuilder,
    private authService: AuthenticationHelperService,
    private toastService: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loggedUser = this.authService.getUserData;
    this.buildForm();
    this.getReviewType();
    this.historyState = history.state.obj;
  }

  buildForm(): void {
    this.reviewForm = this.formBuilder.group({
      rating: ['', [Validators.required]],
      review: ['', [Validators.required, Validators.maxLength(200)]]
    });
  }

  rate(star: number) {
    this.reviewForm.controls['rating'].setValue(star);
  }

  goBack(): void {
    this.location.back();
  }

  getReviewType(): void {
    this.actRoute.paramMap.subscribe((params: any) => {
      this.reviewType = params.get('type') || '';
      console.log(this.reviewType)
    })
  }

  submitReview(): void {
    console.log(this.reviewForm.value)
    this.reviewType === 'list' ? this.createListReview() : this.createItemReview();
  }

  createListReview(): void {
    this.itemsHttpService.createListReview(this.historyState.id, this.reviewForm.value).then((response: any) => {
      this.toastService.success(response.message);
      this.router.navigate([PAGES_AND_PATHS.singleList.pagePath, this.historyState.id, 0, 'reviews']);
    })
  }

  createItemReview(): void {
    this.itemsHttpService.createItemReview(this.historyState.id, this.reviewForm.value).then((response: any) => {
      this.toastService.success(response.message);
      this.router.navigate([PAGES_AND_PATHS.singleItem.pagePath, this.historyState.id]);
    })
  }
}
