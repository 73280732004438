import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetUserDataPipe } from './get-user-data.pipe';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    GetUserDataPipe
  ],
  exports: [
    GetUserDataPipe
  ]
})
export class GetUserDataModule { }
