<form [formGroup]="changeUsernameForm" (ngSubmit)="onSubmitChangeUsernameForm()" class="wrapper-account-page">
  <span class="title-settings-page">Change username</span>
  <span class="subtitle-settings-page">Changing username can be performed once every -- days.</span>
  <span class="subtitle-settings-page">The update will take effect immediately.</span>
  <div class="wrapper-input" [ngClass]="{'error': changeUsernameForm.controls['username'] | formControlErrorMessages: null : formTriedToSubmit}">
    <span class="label-input">Username</span>
    <div class="custom-input">
      <input type="text" formControlName="username" placeholder="Enter your username">
    </div>
    <div *ngIf="changeUsernameForm.controls['username'] | formControlErrorMessages: null : formTriedToSubmit" class="wrapper-error-message">
      <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
      <span>{{changeUsernameForm.controls['username'] | formControlErrorMessages: null: formTriedToSubmit}}</span>
    </div>
  </div>
  <div class="wrapper-submit-settings">
    <button type="submit" class="custom-btn custom-btn--primary">
      <span>Save changes</span>
      <tl-spinner *ngIf="isSpinnerActive"></tl-spinner>
    </button>
  </div>
</form>
