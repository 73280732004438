import { NgForOf } from '@angular/common';
import { Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { SearchHttpService } from 'src/app/services/http-services/search/search-http.service';

@Component({
  selector: 'app-listers',
  standalone: true,
  imports: [NgForOf, RouterModule],
  templateUrl: './listers.component.html',
})
export class ListersComponent {
  @Input() searchTerm = '';
  @Input() isLiveSearch = false;
  listers: any[] = [];
  pagination!: TlPagination;
  PAGES_AND_PATHS = PAGES_AND_PATHS;
  @ViewChild('listersContainer', { static: false }) private listersContainer!: ElementRef;
  pageNum = 1;
  loading = false;

  constructor(
    private searchHttpService: SearchHttpService
  ) { }

  ngOnInit(): void {
    this.getListers();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      console.log(changes)
      this.pageNum = 1;
      this.getListers();
    }
  }

  getListers(): void {
    console.log(this.searchTerm)
    this.loading = true;
    this.searchHttpService.formattedGetListersResults(this.pageNum, this.isLiveSearch, this.searchTerm).then((response: {data: any[], pagination: TlPagination}) => {
      console.log('listers', response)
      this.listers = [...this.listers, ...response.data];
      this.pagination = response.pagination;
      this.loading = false;
    })
  }

  onScroll(): void {
    const container = this.listersContainer.nativeElement;
    const scrollTop = container.scrollTop;
    const offset = 100;

    if (!this.loading && scrollTop < offset && !this.loading && ((this.pagination?.currentPage ?? 0) < (this.pagination?.lastPage ?? 0))) {
      this.pageNum++;
      this.getListers();
    }
  }
}
