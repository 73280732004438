import {Component, ElementRef, Renderer2, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Countries} from "../../../../constants/general-variables";
import {ToastrService} from "ngx-toastr";
import {AuthenticationHelperService} from "../../../../services/helpers/authentication-helper.service";
import {AuthenticationHttpService} from "../../../../services/http-services/authentication/authentication-http.service";
import {FormControlErrorMessagesModule} from "../../../../pipes/form-control-error-messages/form-control-error-messages.module";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {UiHelperService} from "../../../../services/helpers/ui-helper.service";
import {SpinnerComponent} from "../../../../shared-components/spinner/spinner.component";
import {ProfileHttpService} from "../../../../services/http-services/profile/profile-http.service";

@Component({
  selector: 'tl-change-phone',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormControlErrorMessagesModule,
    NgClass,
    NgIf,
    NgForOf,
    SpinnerComponent
  ],
  templateUrl: './change-phone.component.html',
})
export class ChangePhoneComponent {
  changePhoneNumberForm!: FormGroup;
  isSpinnerActive = false;
  formTriedToSubmit = false;
  isAreaCodeOpen =  false;
  selectedArea: any;
  @ViewChild('areaCodeBtn') areaCodeBtn: ElementRef | undefined;
  protected readonly Countries = Countries;
  constructor(private formBuilder: FormBuilder,
              private toastService: ToastrService,
              private renderer: Renderer2,
              private uiHelperService: UiHelperService,
              private authHelperService: AuthenticationHelperService,
              private profileHttpService: ProfileHttpService) { }

  ngOnInit(): void {
    this.buildForm();
    this.listenToClickAreaCode();
  }

  listenToClickAreaCode(): void {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.uiHelperService.checkIfClickedOutsideOfTheElement(this.areaCodeBtn, this.areaCodeBtn, e)) {
        this.isAreaCodeOpen = false;
      }
    });
  }
  buildForm(): void {
    this.changePhoneNumberForm = this.formBuilder.group({
      phone_number: ['', [Validators.required]],
    });
    const [areaCode, phone] = this.authHelperService.getUserData.phone_number?.split(' ');
    this.changePhoneNumberForm.controls.phone_number.patchValue(phone);
    this.selectedArea = this.Countries.find(country => {
      return country.phone_code === areaCode;
    })
  }

  onSelectCodeArea(country: any): void {
    this.selectedArea = country;
  }

  onSubmitChangePhoneNumberForm(): void {
    this.formTriedToSubmit = true;
    if (this.changePhoneNumberForm.valid) {
      this.isSpinnerActive = true;
      this.profileHttpService.editAuthUserProfile({phone_number: this.selectedArea.phone_code + ' ' + this.changePhoneNumberForm.controls['phone_number'].value}).then(res => {
        this.authHelperService.setUserData({...this.authHelperService.getUserData, phone_number: res.phone_number})
        this.isSpinnerActive = false;
        this.toastService.success(res.message);
      }).catch(({error}: {error:{ errors: { [key: string]: string }}}) => {
        this.isSpinnerActive = false;
        Object.values(error.errors).forEach(error => this.toastService.error(error));
      });
    }
  }
}
