export enum LocalStorageProperties {
  token = 'tl-token',
  user = 'tl-user',
  userProfile = 'tl-user-profile',
}

export enum UserRoles {
  user = 'user',
  expert = 'expert',
  company = 'company'
}

export enum AccountTypes {
  individual = 'individual',
  company = 'company'
}
