export enum ProfileTabsEnums {
  lysts = 'Lysts',
  posts = 'Posts'
}

export enum EditProfileEnums {
  index = 'index',
  name = 'Name',
  username = 'Username',
  bio = 'Bio',
  gender = 'Gender',
  phone = 'Phone',
  birthday = 'Birthday',
  country = 'Country',
  city = 'City'
}

export enum CreateEditPostTabsEnums {
  tagList = 'tah-list',
  tagListItem = 'tag-list-item'
}

export enum InsightsPagesEnums {
  index = 'index',
  details = 'details',
  yourContent = 'yourContent',
}

export enum InsightsDetailsTypeEnums {
  followers = 'followers',
  likes = 'likes',
  views = 'views',
  shares = 'shares'
}

export enum InsightsTopLocationsTabsEnums {
  city = 'city',
  country = 'country',
}

export enum InsightsAgeRangeTabsEnums {
  all = 'all_genders',
  man = 'man',
  women = 'woman',
  other = 'other'
}

