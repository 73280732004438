<div class="gallery-chat-settings">
  <div class="grid">
    <div *ngFor="let media of mediaFiles" class="grid__s-4">
      <div (click)="openMedia(media)" class="wrapper-media-chat-settings">
        <img [src]="(media.type === 'image' ? media.image.url : media?.thumbnail?.url) | safeUrl" alt="">
      </div>
    </div>
  </div>
</div>
<app-preview-media *ngIf="selectedMedia !== null" [selectedMedia]="selectedMedia" (closeModal)="selectedMedia = null" (download)="download(selectedMedia)"></app-preview-media>
