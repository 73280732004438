import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';
import { FollowingFollowersComponent } from './following-followers.component';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.followingFollowers.pageInRouting,
    data: { data: PAGES_AND_PATHS.followingFollowers },
    component: FollowingFollowersComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./following-followers.module').then(m => m.FollowingFollowersModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FollowingFollowersRoutingModule { }
