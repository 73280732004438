import { Component, OnInit } from '@angular/core';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { ListsHttpService } from 'src/app/services/http-services/lists/lists-http.service';

@Component({
  selector: 'app-lists',
  templateUrl: './lists.component.html',
})
export class ListsComponent implements OnInit {
  PAGES_AND_PATHS = PAGES_AND_PATHS;

  constructor(
    private listsHttpService: ListsHttpService
  ) { }

  ngOnInit(): void {
    this.getListsParams();
  }

  getListsParams(): void {
    this.listsHttpService.getLists().subscribe((res: any) => {
      console.log('getLists', res.data)
    })
  }
}
