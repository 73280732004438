<div class="wrapper-page">
  <app-my-content></app-my-content>
  <div class="wrapper-single-theme">
    <tl-header></tl-header>
    <div class="wrapper-content-single-theme">
      <div class="overlay1-background-header"></div>
      <div class="overlay2-background-header" [ngStyle]="{
        'background': 'linear-gradient(0deg, rgba(31, 31, 31, 0.3) 20%, ' + (singleTheme?.color || '#000') + ' 100%)'
    }">
        <span class="title-single-theme">{{singleTheme?.name}}</span>
        <span class="label-lists">{{pagination?.totalItems}} {{pagination?.totalItems === 1 ? 'list' :
          'lists'}}</span>
      </div>
      <div class="content-single-theme">
        <div class="wrapper-custom-search">
          <img class="icon-search" src="../../../assets/icons/outline/icon-search.svg" alt="">
          <input type="text" placeholder="Search..." [(ngModel)]="searchTerm" (input)="onSearchChange($event)">
          <div (click)="resetSearch()" class="wrapper-delete-search">
            <img src="../../../assets/icons/outline/icon-close.svg" alt="">
          </div>
        </div>
        <div class="grid lists">
          <div *ngFor="let list of lists" [routerLink]="[PAGES_AND_PATHS.singleList.pagePath, list.id, 0, 'cards']"  class="grid__m-4">
            <div class="wrapper-card-list">
              <img class="image-list"
                [src]="list.lyst_image_url"
                alt="">
              <div class="wrapper-card-list-info">
                <div class="wrapper-badges">
                  <div class="badge gray">
                    <span>{{list.lyst_items_count}} {{list.lyst_items_count === 1 ? 'item' : 'items'}}</span>
                  </div>
                </div>
                <span class="name-list">{{list.title}}</span>
                <div class="author-recommended-list">
                  <span>{{list.user.name}}</span>
                  <img *ngIf="list.user.is_expert" src="./assets/icons/fill/icon-badge.svg" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div (click)="loadMoreLists()" class="custom-btn custom-btn--outline">
          <span>Load more lists</span>
        </div>
        <span class="title-list-theme">List themes</span>
        <div class="grid">
          <div *ngFor="let theme of themes" [routerLink]="[PAGES_AND_PATHS.singleTheme.pagePath, theme.id]"
            class="grid__m-4">
            <div class="wrapper-card-theme" [ngStyle]="{'background-color': theme?.color}">
              <div class="wrapper-theme-info">
                <span>{{theme.name}}</span>
              </div>
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXbKMuxHD5Ho6T-lIVzT6tszCiT0qwtRlgUw&s"
                alt="">
            </div>
          </div>
        </div>
        <div [routerLink]="['/all-themes']" class="custom-btn custom-btn--outline">
          <span>Explore all list themes</span>
        </div>
      </div>
    </div>
  </div>
</div>