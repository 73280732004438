<div class="wrapper-page">
    <app-my-content></app-my-content>
    <div class="wrapper-home-page">
        <tl-header></tl-header>
        <div class="wrapper-search-page">
            <div class="wrapper-custom-search">
                <img class="icon-search" src="../../../assets/icons/outline/icon-search.svg" alt="">
                <input type="text" placeholder="Search..." (input)="onSearchChange($event)" [(ngModel)]="searchTerm">
                <div (click)="clearSearchTerm()" class="wrapper-delete-search">
                    <img src="../../../assets/icons/outline/icon-close.svg" alt="">
                </div>
            </div>
            <div class="wrapper-search-results">
                <div class="wrapper-custom-tabs">
                    <div class="custom-tab" (click)="tabSelected = 'lists'"
                        [ngClass]="{'active': tabSelected === 'lists'}">
                        <span>Lists</span>
                    </div>
                    <div class="custom-tab" (click)="tabSelected = 'accounts'"
                        [ngClass]="{'active': tabSelected === 'accounts'}">
                        <span>Accounts</span>
                    </div>
                    <div class="custom-tab" (click)="tabSelected = 'nearYou'"
                        [ngClass]="{'active': tabSelected === 'nearYou'}">
                        <span>Near you</span>
                    </div>
                </div>
                <app-lists *ngIf="tabSelected === 'lists'" [searchTerm]="debouncedSearchTerm" [isLiveSearch]="isLiveSearch"></app-lists>
                <app-accounts *ngIf="tabSelected === 'accounts'" [searchTerm]="debouncedSearchTerm" [isLiveSearch]="isLiveSearch"></app-accounts>
                <app-near-you *ngIf="tabSelected === 'nearYou'" [searchTerm]="debouncedSearchTerm" [isLiveSearch]="isLiveSearch"></app-near-you>
            </div>
        </div>
    </div>
</div>