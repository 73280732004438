<div class="wrapper-page wrapper-page-create-post">
  <div class="wrapper-my-content">
    <div class="wrapper-header-my-content">
      <div class="back-btn-chat tl-d-flex tl-align-center">
        <button class="custom-btn custom-btn--circle-outline xs-mr-12">
          <img src="../../../assets/icons/icon-arrow-left.svg" alt="">
        </button>
        <span>{{postId ? 'Edit post' : 'Create post'}}</span>
      </div>
    </div>
    <div class="wrapper-content-my-content">
      <div class="tabs-my-content">
        <div class="tab-my-content" (click)="activeTab = CreateEditPostTabsEnums.tagList" [ngClass]="{'active': activeTab === CreateEditPostTabsEnums.tagList}">
          <span>Tag list</span>
        </div>
        <div class="tab-my-content" (click)="activeTab = CreateEditPostTabsEnums.tagListItem" [ngClass]="{'active': activeTab === CreateEditPostTabsEnums.tagListItem}">
          <span>Tag list item</span>
        </div>
      </div>
      <tl-tag-list
        [hidden]="activeTab !== CreateEditPostTabsEnums.tagList"
        [selectedList]="selectedList"
        [selectedListItem]="selectedListItem"
        (changeList)="onChangeList($event)">
      </tl-tag-list>
      <tl-tag-list-item
        [hidden]="activeTab !== CreateEditPostTabsEnums.tagListItem"
        [selectedList]="selectedList"
        [selectedListItem]="selectedListItem"
        (changeListItem)="onChangeListItem($event)">
      </tl-tag-list-item>
    </div>
  </div>
  <form [formGroup]="createEditPostForm" (ngSubmit)="onSubmitCreateEditPostForm()" class="wrapper-create-post-page">
    <div class="create-post-page">
      <div class="author-create-post">
        <img class="profile-image" *ngIf="!profileData?.profile_photo_url" [src]="profileData?.roles[0].name === UserRoles.expert ? '../../../assets/images/avatar-user-expert.png' : '../../../assets/images/avatar-user.png'" alt="">
        <img class="profile-image" *ngIf="profileData?.profile_photo_url" [src]="profileData?.profile_photo_url" alt="">
        <span>{{profileData.name}}</span>
        <img class="badge" *ngIf="profileData?.roles[0].name === UserRoles.expert" src="../../../assets/icons/fill/icon-badge.svg" alt="">
        <img class="badge" *ngIf="profileData?.roles[0].name === UserRoles.company" src="../../../assets/icons/fill/icon-fill-company.svg" alt="">
      </div>
      <div class="wrapper-tagged-list-item">
        <div *ngIf="selectedList" class="tag-post list">
          <img src="../../../assets/icons/outline/icon-tag.svg" alt="">
          <span>{{selectedList?.title}}</span>
          <div class="delete-tag-btn" (click)="removeSelectedTagList()">
            <img src="../../../assets/icons/outline/icon-delete.svg" alt="">
          </div>
        </div>
        <div *ngIf="selectedListItem" class="tag-post item">
          <img src="../../../assets/icons/outline/icon-tag.svg" alt="">
          <span>{{selectedListItem?.name}}</span>
          <div class="delete-tag-btn" (click)="removeSelectedTagListItem()">
            <img src="../../../assets/icons/outline/icon-delete.svg" alt="">
          </div>
        </div>
      </div>
      <div class="wrapper-input textarea">
        <span class="label-input">Description</span>
        <div class="custom-input">
          <textarea
            formControlName="content"
            [maxlength]="200"
            placeholder="What's on your mind...">
          </textarea>
        </div>
        <div class="wrapper-message-description">
          <div *ngIf="createEditPostForm.controls['content'] | formControlErrorMessages: null: formTriedToSubmit" class="wrapper-error-message">
            <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
            <span>{{createEditPostForm.controls['content'] | formControlErrorMessages: null: formTriedToSubmit}}</span>
          </div>
          <span class="label-info-input" *ngIf="!(createEditPostForm.controls['content'] | formControlErrorMessages: null: formTriedToSubmit)">{{createEditPostForm.controls['content'].value ? (200 - createEditPostForm.controls['content'].value.length) + ' characters left.' : 'The maximum length of a post description is 200 characters.'}}</span>
        </div>
      </div>
      <button *ngIf="!postId" type="submit" class="custom-btn custom-btn--primary">
        <span>Post</span>
      </button>
      <div *ngIf="postId" class="wrapper-buttons-create-edit-post">
        <button (click)="onCancelEditPost()" type="button" class="custom-btn custom-btn--outline">-->
          <span>Cancel</span>
        </button>
        <button type="submit" class="custom-btn custom-btn--primary">
          <span>Save</span>
        </button>
      </div>
    </div>
  </form>
</div>
