<div class="wrapper-insights-page">
  <span class="title-settings-page">Insights</span>
  <div class="header-insights">
    <div class="wrapper-datepicker">
      <div class="preview-days">
        <span>Last 7 days</span>
        <img src="../../../../assets/icons/icon-arrow-dropdown.svg" alt="">
      </div>
    </div>
    <div class="wrapper-week-label">
      <span>June 9 - June 16</span>
    </div>
  </div>
  <div class="line-gray"></div>
  <tl-insights-index
    *ngIf="!selectedDetailsType && !selectedListId"
    (clickOnCard)="selectedDetailsType = $event"
    (clickOnList)="selectedListId = $event"
  ></tl-insights-index>
  <tl-insights-content-you-create
    *ngIf="selectedListId"
    [listId]="selectedListId">
  </tl-insights-content-you-create>
  <tl-insights-details
    *ngIf="selectedDetailsType"
    [detailsType]="selectedDetailsType"
  >
  </tl-insights-details>
</div>
