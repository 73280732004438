import { Component } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PAGES_AND_PATHS} from "../../../constants/pages-and-paths";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationHelperService} from "../../../services/helpers/authentication-helper.service";
import {PostsHttpService} from "../../../services/http-services/posts/posts-http.service";
import {ToastrService} from "ngx-toastr";
import {UserRoles} from "../../../enums/general-enums";
import {CreateEditPostTabsEnums} from "../../../enums/profile-enums";

@Component({
  selector: 'tl-create-edit-post',
  templateUrl: './create-edit-post.component.html',
})
export class CreateEditPostComponent {
  initialPostData: any;
  formTriedToSubmit = false;
  postId!: number;
  profileData: any;
  createEditPostForm!: FormGroup;
  isSpinnerActive = false;
  selectedList: any;
  selectedListItem: any;
  activeTab = CreateEditPostTabsEnums.tagList
  historyState: any;
  protected readonly history = history;
  protected readonly UserRoles = UserRoles;
  protected readonly PAGES_AND_PATHS = PAGES_AND_PATHS;
  protected readonly CreateEditPostTabsEnums = CreateEditPostTabsEnums;
  constructor(private formBuilder: FormBuilder,
              private toastService: ToastrService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private authHelperService: AuthenticationHelperService,
              private postsHttpService: PostsHttpService) { }

  ngOnInit(): void {
    this.buildForm();
    this.historyState = history.state;
    if (this.historyState.selectedLyst || this.historyState.selectedItem) {
      this.selectedList = this.historyState.selectedLyst;
      this.selectedListItem = this.historyState.selectedItem;
      this.createEditPostForm.controls['lyst_item_id'].patchValue(this.selectedListItem?.id);
      this.createEditPostForm.controls['lyst_id'].patchValue(this.selectedList?.id);
    }
    this.activatedRoute.params.subscribe(res => {
      this.postId = res['postId'];
      if (this.postId) {
        this.getSinglePostData();
      }
    });
    this.profileData = this.authHelperService.getUserData;
  }

  buildForm(): void {
    this.createEditPostForm = this.formBuilder.group({
      content: ['', [Validators.required]],
      lyst_id: [undefined],
      lyst_item_id: [undefined],
    });
  }

  getSinglePostData(): void {
    this.postsHttpService.getSinglePost({postId: this.postId}).then(res => {
      this.selectedList = res.data.lyst;
      this.createEditPostForm.controls['lyst_id'].patchValue(this.selectedList?.id);
      this.selectedListItem = res.data.lyst_item;
      this.createEditPostForm.controls['lyst_item_id'].patchValue(this.selectedListItem?.id);
      this.createEditPostForm.controls['content'].patchValue(res.data.content);
      this.initialPostData = res.data;
    });
  }

  removeSelectedTagList(): void {
    this.selectedList = null;
    this.createEditPostForm.controls['lyst_id'].patchValue(undefined);
  }

  removeSelectedTagListItem(): void {
    this.selectedListItem = null;
    this.createEditPostForm.controls['lyst_item_id'].patchValue(undefined);
  }

  onChangeList(selectedList: any): void {
    this.selectedList = selectedList;
    this.createEditPostForm.controls['lyst_id'].patchValue(selectedList?.id);
  }

  onChangeListItem(selectedListItem: any): void {
    this.selectedListItem = selectedListItem;
    this.createEditPostForm.controls['lyst_item_id'].patchValue(selectedListItem?.id);
  }

  onCancelEditPost(): void {
    this.formTriedToSubmit = false;
    this.createEditPostForm.reset();
    this.selectedList = this.initialPostData.lyst;
    this.createEditPostForm.controls['lyst_id'].patchValue(this.selectedList?.id);
    this.selectedListItem = this.initialPostData.lyst_item;
    this.createEditPostForm.controls['lyst_item_id'].patchValue(this.selectedListItem?.id);
    this.createEditPostForm.controls['content'].patchValue(this.initialPostData.content);
  }

  onSubmitCreateEditPostForm(): void {
    this.formTriedToSubmit = true;
    this.postId
      ? this.onSubmitEditPostForm()
      : this.onSubmitCreatePostForm();

  }

  onSubmitCreatePostForm(): void {
    this.isSpinnerActive = true;
    this.createEditPostForm.controls['content'].markAsTouched();
    if (this.createEditPostForm.valid) {
      this.postsHttpService.storePost(this.createEditPostForm.value).then(res => {
        this.isSpinnerActive = false;
        this.createEditPostForm.reset();
        this.selectedList = null;
        this.selectedListItem = null;
        this.toastService.success(res.message);
        this.router.navigate([PAGES_AND_PATHS.profile.pagePath, this.authHelperService.getUserData.id]);
      }).catch(error => {
        this.isSpinnerActive = false;
        if (error.error.code === 403) {
          this.toastService.error(error.error.message);
        } else {
          Object.values(error.error.data).forEach((value: any) => this.toastService.error(value[0]));
        }
      });
    }
  }

  onSubmitEditPostForm(): void {
    this.isSpinnerActive = true;
    this.createEditPostForm.controls['content'].markAsTouched();
    if (this.createEditPostForm.valid) {
      this.postsHttpService.updatePost({postId: this.postId}, this.createEditPostForm.value).then(res => {
        this.isSpinnerActive = false;
        this.createEditPostForm.reset();
        this.selectedList = null;
        this.selectedListItem = null;
        this.toastService.success(res.message);
        this.router.navigate([PAGES_AND_PATHS.profile.pagePath, this.authHelperService.getUserData.id]);
      }).catch(error => {
        this.isSpinnerActive = false;
        if (error.error.code === 403) {
          this.toastService.error(error.error.message);
        } else {
          Object.values(error.error.data).forEach((value: any) => this.toastService.error(value[0]));
        }
      });
    }
  }
}
