<div class="wrapper-custom-search">
  <img class="icon-search" src="../../../assets/icons/outline/icon-search.svg" alt="">
  <input type="text" placeholder="Search..." [value]="urlParams.search" (input)="onSearchChange($event)">
  <div *ngIf="urlParams.search?.length" (click)="clearSearch()" class="wrapper-delete-search">
    <img src="../../../assets/icons/outline/icon-close.svg" alt="">
  </div>
</div>
<div class="wrapper-tab-lists" (scroll)="onScroll($event)">
  <div class="wrapper-custom-radiobutton-list">
    <!--    TODO JS - kad su disabled dodaje klasu "tl-not-allowed"      -->
    <label *ngFor="let tagList of tagLists">
      <input type="radio" name="tag-list" [value]="tagList.id" [ngModel]="selectedListId" (ngModelChange)="onChangeTagList($event)">
      <div class="custom-radiobutton">
        <div class="circle-radio">
          <div class="circle-center"></div>
        </div>
        <div class="wrapper-language-info">
          <div class="wrapper-list-preview">
            <div class="wrapper-image-list">
              <img [src]="tagList.lyst_image_url" alt="">
            </div>
            <div class="wrapper-info-list">
              <div class="info-list-tag">
                <div class="title-list">
                  <span>{{tagList.title}}</span>
                </div>
                <div class="author-list">
                  <span>{{tagList.user.name}}</span>
                  <img *ngIf="tagList.user.is_expert" src="../../../assets/icons/fill/icon-badge.svg" alt="">
                </div>
              </div>
              <span class="label-item">{{tagList.lyst_items_count}} {{tagList.lyst_items_count === 1 ? 'item' : 'items'}}</span>
            </div>
          </div>
        </div>
      </div>
    </label>
  </div>
</div>
