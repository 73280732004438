<div class="wrapper-posts-tab">
  <div *ngIf="authUserData.id === profileData.id" class="wrapper-card-create-post" [routerLink]="[PAGES_AND_PATHS.createPost.pagePath]">
    <div class="wrapper-left-card-create-post">
      <img
        *ngIf="!authUserData?.profile_photo_url"
        [routerLink]="[PAGES_AND_PATHS.profile.pagePath, authUserData.id]"
        [src]="authUserData?.roles[0].name === UserRoles.expert ? '../../../assets/images/avatar-user-expert.png' : '../../../assets/images/avatar-user.png'" alt="">
      <img
        *ngIf="authUserData?.profile_photo_url"
        [routerLink]="[PAGES_AND_PATHS.profile.pagePath, authUserData.id]"
        [src]="authUserData?.profile_photo_url" alt="">
      <span>What’s on your mind...</span>
    </div>
    <img src="../../../../../assets/icons/outline/icon-tag.svg" alt="">
  </div>
  <tl-post-preview
    *ngFor="let post of posts"
    [post]="post"
    [baseUrl]="baseUrl"
    (togglePostLikeUnlike)="onTogglePostLike($event)"
    (confirmPostDeleted)="onConfirmPostDeleted($event)"
    (openImageInFullScreen)="showImageInFullScreen = $event"
  ></tl-post-preview>
</div>
