<form [formGroup]="editProfileDateOfBirthdayForm" (ngSubmit)="onSubmitEditProfileDateOfBirthdayForm()" class="wrapper-edit-info-page">
  <span class="title-edit-page">Birthday</span>
  <span class="subtitle-edit-page">Change your account date of birth.</span>
  <div class="wrapper-input" [ngClass]="{'error': editProfileDateOfBirthdayForm.controls['date_of_birth'] | formControlErrorMessages: null : formTriedToSubmit}">
    <span class="label-input">Date of birth</span>
    <tl-date-picker
      [defaultDate]="selectedDate"
      (dateSelected)="onSelectDateOfBirth($event)"
    ></tl-date-picker>
    <div *ngIf="editProfileDateOfBirthdayForm.controls['date_of_birth'] | formControlErrorMessages: null: formTriedToSubmit" class="wrapper-error-message">
      <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
      <span>{{editProfileDateOfBirthdayForm.controls['date_of_birth'] | formControlErrorMessages: null: formTriedToSubmit}}</span>
    </div>
  </div>
  <div class="buttons-edit-page">
    <button type="button" (click)="onCancelChanges()" class="custom-btn custom-btn--outline">
      <span>Cancel</span>
    </button>
    <button type="submit" class="custom-btn custom-btn--primary">
      <span *ngIf="!isSpinnerActive">Save changes</span>
      <tl-spinner *ngIf="isSpinnerActive"></tl-spinner>
    </button>
  </div>
</form>
