import { NgForOf, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { ListType } from 'src/app/enums/list-enums';
import { TlPagination } from 'src/app/interfaces/general-interfaces';
import { List, ListItem } from 'src/app/services/http-services/lists/lists-http-intterfaces';
import { ListsHttpService } from 'src/app/services/http-services/lists/lists-http.service';

@Component({
  selector: 'app-copy-item-modal',
  standalone: true,
  imports: [NgForOf],
  templateUrl: './copy-item-modal.component.html',
})
export class CopyItemModalComponent implements OnInit {
  @Input() selectedItem: ListItem | null = null;
  @Input() url = '';
  @Output() closeModal = new EventEmitter<boolean>();
  lists: List[] = [];
  pageNum = 1;
  pagination!: TlPagination;
  activeListType: ListType = ListType.all;
  selectedList: List | null = null;
  PAGES_AND_PATHS = PAGES_AND_PATHS;
  @Output() isOpenToastOpen = new EventEmitter<boolean>();
  @Output() selectedListAndItem = new EventEmitter<{ item: any, list: any }>();

  constructor(
    private listsHttpService: ListsHttpService,
    private toastService: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getMyLists(this.pageNum, this.activeListType);
  }

  getMyLists(pagNumber: number, listType: ListType): Promise<any> {
    return new Promise((resolve, reject) => {
      this.listsHttpService.getMyLists(pagNumber, listType).then((res: any) => {
        try {
          this.lists = [...this.lists, ...res.data];
          this.pagination = res.pagination;
        } catch (e) {
        }
        this.pagination = res.pagination;
        resolve(res);
      }, error => {
        reject(false);
      })
    });
  }

  select(list: List): void {
    this.selectedList = list;
  }

  addItemToList(): void {
    console.log(this.selectedItem, this.selectedList)
    if (this.selectedList !== null && this.selectedItem !== null) {
      this.listsHttpService.copyListItem(this.selectedList?.id, this.selectedItem?.id).subscribe((response: any) => {
        this.isOpenToastOpen.emit(true);
        this.selectedListAndItem.emit({ list: this.selectedList, item: this.selectedItem });
        this.closeModal.emit(true);
      })
    }
  }

  createNewList(): void {
    const pageDetails = {
      selectedItem: this.selectedItem,
      url: this.url
    }
    this.router.navigate([PAGES_AND_PATHS.createList.pagePath], { state: pageDetails });
  }

}
