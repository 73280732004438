import { NgForOf, NgIf } from '@angular/common';
import { Component, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { Homepage } from 'src/app/services/http-services/home/home-interfaces';
import { HomeService } from 'src/app/services/http-services/home/home.service';
import { HeaderModule } from 'src/app/shared-components/header/header.module';
import { MyContentModule } from 'src/app/shared-components/my-content/my-content.module';
import { LatestPostComponent } from './components/latest-post/latest-post.component';
import { ListComponent } from './components/list/list.component';
import { ListerComponent } from './components/lister/lister.component';
import { ThemeComponent } from './components/theme/theme.component';

@Component({
  selector: 'tl-home',
  templateUrl: './home.component.html',
  standalone: true,
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
  imports: [
    RouterModule,
    NgForOf,
    NgIf,
    MyContentModule,
    HeaderModule,
    LatestPostComponent,
    ListComponent,
    ListerComponent,
    ThemeComponent
  ]
})
export class HomeComponent implements OnInit {
  PAGES_AND_PATHS = PAGES_AND_PATHS;
  homepageData: any;

  constructor(
    private homeHttpService: HomeService
  ){}

  ngOnInit(): void {
    this.getHomepageData();
  }
  
  getHomepageData(): void {
    this.homeHttpService.formattedGetHomepageData().then((response: {data: Homepage}) => {
      this.homepageData = response.data;
      console.log('get homepageData', response.data)
    });
  }

  
}
