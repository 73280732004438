<div class="wrapper-chat-page">
  <div class="wrapper-chat-all-conversation">
    <div class="wrapper-chat-all-conversation-header">
      <div class="back-btn-chat">
        <button (click)="back();" class="custom-btn custom-btn--circle-outline">
          <img src="../../../assets/icons/icon-arrow-left.svg" alt="">
        </button>
        <span>Group accounts</span>
      </div>
    </div>
    <div class="wrapper-add-new-conversation">
      <span class="text-add-new-conversation-group">Add user to this group chat</span>
      <div class="wrapper-custom-search">
        <img class="icon-search" src="../../../assets/icons/outline/icon-search.svg" alt="">
        <input type="text" [(ngModel)]="searchTerm" (input)="onSearchChange($event)" placeholder="Search..." />
        <div (click)="resetSearch()" class="wrapper-delete-search">
          <img src="../../../assets/icons/outline/icon-close.svg" alt="">
        </div>
      </div>
      <div class="wrapper-all-people group">
        <div class="wrapper-custom-checkbox" #userListContainer (scroll)="onScroll()" style="overflow-y: auto;">
          <label *ngFor="let user of users">
            <input type="checkbox" [checked]="isSelected(user.id)" (change)="toggleSelection(user.id)" />
            <div class="user-item-list">
              <div class="user-item-list-left">
                <div class="wrapper-avatar-item">
                  <img src="../../../../../assets/images/avatar-user.png" alt="">
                </div>
                <div class="wrapper-name-message">
                  <div class="wrapper-name">
                    <span class="length-character">{{ user.username }}</span>
                    <!--                    <img src="../../../assets/icons/fill/icon-badge.svg" alt="">-->
                  </div>
                  <span class="message length-character">{{ user.name }}</span>
                </div>
              </div>
              <div class="user-item-list-right">
                <div class="checkbox-rectangle">
                  <div class="checkbox-center">
                    <img src="../../../../../assets/icons/outline/icon-check.svg" alt="">
                  </div>
                </div>
              </div>
            </div>
          </label>
        </div>
      </div>
      <div class="wrapper-button-add-chat">
        <button class="custom-btn custom-btn--primary" [disabled]="selectedUsersCount() === 0" (click)="addToGroup()">
          <span>+ Add users</span>
        </button>
      </div>
    </div>
  </div>
  <div class="wrapper-chat-conversation group">
    <div class="wrapper-chat-conversation-header" style="background-color: transparent;     border-bottom: 1px solid #333333;"></div>
    <app-group-accounts [conversation]="conversation" (userRemoved)="userRemovedEvent($event)"></app-group-accounts>
  </div>
</div>
