import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TimeAgoModule } from 'src/app/pipes/time-ago/time-ago.module';
import { FollowedUsersPost } from 'src/app/services/http-services/home/home-interfaces';

@Component({
  selector: 'app-latest-post',
  standalone: true,
  imports: [TimeAgoModule,NgIf],
  templateUrl: './latest-post.component.html',
})
export class LatestPostComponent {
  @Input() post!: FollowedUsersPost;

}
