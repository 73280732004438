<div class="wrapper-page">
  <app-my-content></app-my-content>
  <div class="wrapper-my-profile-page">
    <tl-header></tl-header>
    <div class="content-profile-page">
<!--      TODO Zoca prisredi ovu stranicu ako treba -->
      <h2>Latest posts</h2>
      <tl-post-preview
        *ngFor="let post of posts"
        [post]="post"
        [baseUrl]="baseUrl"
        (togglePostLikeUnlike)="onTogglePostLike($event)"
        (confirmPostDeleted)="onConfirmPostDeleted($event)"
        (openImageInFullScreen)="showImageInFullScreen = $event"
      ></tl-post-preview>
    </div>
  </div>
</div>
