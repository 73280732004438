import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgIf} from "@angular/common";

@Component({
  selector: 'tl-cancel-subscription-modal',
  standalone: true,
  imports: [
  ],
  templateUrl: './cancel-subscription-modal.component.html',
})
export class CancelSubscriptionModalComponent {
  @Input() modalTitle = '';
  @Input() modalDescription = '';
  @Input() confirmBtnText = '';
  @Output() cancelModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmModal: EventEmitter<void> = new EventEmitter<void>();
}
