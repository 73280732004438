import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {PAGES_AND_PATHS} from '../../../constants/pages-and-paths';
import {AuthGuardService} from '../../../guards/auth-guard.service';
import {VerifyAccountComponent} from './verify-account.component';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.verifyAccount.pageInRouting,
    data: {data: PAGES_AND_PATHS.verifyAccount},
    component: VerifyAccountComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./verify-account.module').then(m => m.VerifyAccountModule)
  },
  {
    path: PAGES_AND_PATHS.verifyAccountCompany.pageInRouting,
    data: {data: PAGES_AND_PATHS.verifyAccountCompany},
    component: VerifyAccountComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./verify-account.module').then(m => m.VerifyAccountModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VerifyAccountRoutingModule { }
