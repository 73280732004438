<form [formGroup]="changePhoneNumberForm" (ngSubmit)="onSubmitChangePhoneNumberForm()" class="wrapper-account-page">
  <span class="title-settings-page">Change phone number</span>
  <div class="wrapper-input" [ngClass]="{'error': changePhoneNumberForm.controls['phone_number'] | formControlErrorMessages: null: formTriedToSubmit}">
    <span class="label-input">Phone number</span>
    <div class="wrapper-phone-number open" [ngClass]="{'open': isAreaCodeOpen}">
      <div #areaCodeBtn class="wrapper-flags" (click)="isAreaCodeOpen = !isAreaCodeOpen">
        <img class="icon-flag" [src]="'../../../../assets/icons/flags/' + selectedArea?.code.toLowerCase() + '.png'" alt="">
        <img class="icon-arrow-flags" src="../../../assets/icons/icon-arrow-left.svg" alt="">
      </div>
      <div class="wrapper-number-country">
        <span>{{selectedArea.phone_code}}</span>
      </div>
      <input type="text" formControlName="phone_number">
      <div class="content-dropdown-phone-number" *ngIf="isAreaCodeOpen">
        <div class="item-dropdown-phone-number" *ngFor="let country of Countries" (click)="onSelectCodeArea(country)">
          <img [src]="'../../../../assets/icons/flags/' + country.code.toLowerCase() + '.png'" alt="">
          <span>{{country.name}} ({{country.phone_code}})</span>
        </div>
      </div>
    </div>
    <div *ngIf="changePhoneNumberForm.controls['phone_number'] | formControlErrorMessages: null : formTriedToSubmit" class="wrapper-error-message">
      <img src="../assets/icons/outline/icon-outline-attention.svg" alt="">
      <span>{{changePhoneNumberForm.controls['phone_number'] | formControlErrorMessages: null: formTriedToSubmit}}</span>
    </div>
  </div>
  <div class="wrapper-submit-settings">
    <button type="submit" class="custom-btn custom-btn--primary">
      <span *ngIf="!isSpinnerActive">Save changes</span>
      <tl-spinner *ngIf="isSpinnerActive"></tl-spinner>
    </button>
  </div>
</form>
