<div class="wrapper-chat-all-conversation-header">
    <div class="back-btn-chat">
        <button (click)="back();" class="custom-btn custom-btn--circle-outline">
            <img src="../../../assets/icons/icon-arrow-left.svg" alt="">
        </button>
        <span>{{isGroupAccountsOpen ? 'Group accounts' : title}}</span>
    </div>
    <button *ngIf="!isUserListOpen" (click)="openUserList(); isGroupAccountsOpen = false" class="custom-btn custom-btn--circle-primary">
        <img src="../../../assets/icons/outline/icon-plus.svg" alt="">
    </button>
</div>
<div *ngIf="!isUserListOpen" class="wrapper-chat-all-conversation-content">
    <div class="wrapper-custom-search">
        <img class="icon-search" src="../../../assets/icons/outline/icon-search.svg" alt="">
        <input type="text" placeholder="Search..." [(ngModel)]="searchTerm" (input)="onSearchChange($event)">
        <div (click)="resetSearch()" *ngIf="searchTerm.length > 0" class="wrapper-delete-search">
            <img src="../../../assets/icons/outline/icon-close.svg" alt="">
        </div>
    </div>
    <div *ngIf="conversations?.length" class="wrapper-all-conversation-list" #conversationsContainer
        (scroll)="onScroll()" style="overflow-y: auto;">
        <div *ngFor="let chat of conversations" (click)="openConversation(chat.id)" class="user-item-list"
            [ngClass]="chat.my_unread_count > 0 ? 'unread' : ''" [ngClass]="{
                'unread': chat.my_unread_count > 0,
                'active': selectedConversationId === chat.id
              }">
            <div class="user-item-list-left">
                <div class="wrapper-user-avatar-group-chat">
                    <div *ngIf="chat.is_group && chat.profile_photo === null" class="images-grid-chat"
                        [ngClass]="chat.other_participants?.length | checkChatGroupLength">
                        <div class="image">
                            <img [src]="'../../../../../assets/images/avatar-user.png'"
                                alt="">
                        </div>
                        <div *ngFor="let person of chat.other_participants | slice:0:3" class="image">
                            <img [src]="'../../../../../assets/images/avatar-user.png'"
                                alt="">
                        </div>
                        <div class="number-more-people">
                            <span>{{chat.other_participants?.length}}+</span>
                        </div>
                    </div>
                    <div *ngIf="chat.is_group && chat.profile_photo !== null" class="images-grid-chat"
                        [ngClass]="chat.other_participants?.length | checkChatGroupLength">
                        <div class="image">
                            <img [src]="chat.profile_photo.url" alt="">
                        </div>
                    </div>
                    <div *ngIf="!chat.is_group" class="images-grid-chat">
                        <div class="image">
                            <img src="../../../../../assets/images/avatar-user.png"
                                alt="">
                        </div>
                    </div>
                </div>
                <div class="wrapper-name-message">
                    <div class="wrapper-name">
                        <span class="length-character">{{chat.is_group && chat.name !== null ? chat.name : chat |
                            setChatUsernames}}</span>
                        <!--                    <img src="../../../assets/icons/fill/icon-badge.svg" alt="">-->
                    </div>
                    <span class="message length-character">
                        {{chat.latest_message.body}}
                    </span>
                </div>
            </div>
            <div class="user-item-list-right">
                <div class="item-user-time">
                    <span>{{chat.updated_at | date:'HH:mm'}}</span>
                </div>
                <div class="unread-message">
                    <span>{{chat.my_unread_count}}</span>
                </div>
            </div>
        </div>
    </div>
</div>
<app-new-conversation *ngIf="isUserListOpen || isGroupAccountsOpen" [isGroupAccountsOpen]="isGroupAccountsOpen" [conversationId]="selectedConversationId"
    (openConversation)="openConversation($event); isUserListOpen = false; isNewChatActive.emit(false)"></app-new-conversation>