import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationHelperService} from './authentication-helper.service';
import {AvailableLanguages, DefaultLang, DeLang, EnLang, EsLang, FrLang, ItLang} from '../../constants/translate-variables';

@Injectable({
  providedIn: 'root'
})
export class TranslateHelperService {
  availableLanguages: string[] = [];
  defaultLang: string = DefaultLang.name;
  langFromStorage = JSON.parse(localStorage.getItem('user_lang') as string);

  constructor(public translate: TranslateService,
              private authHelperService: AuthenticationHelperService) {}

  initTranslate(): void {
    this.translate.addLangs(this.availableLanguages);
    this.translate.setDefaultLang(this.defaultLang);
    if (this.langFromStorage) {
      this.translate.use(this.langFromStorage);
      this.changeLang(this.langFromStorage);
    } else if (this.authHelperService.getUserData && this.availableLanguages.includes(this.authHelperService.getUserData.language)) {
      this.translate.use(this.authHelperService.getUserData.language);
      this.changeLang(this.authHelperService.getUserData.language);
    } else {
      localStorage.setItem('user_lang', JSON.stringify(this.defaultLang));
      this.translate.use(this.defaultLang);
    }
  }

  setLang(lang: string): void {
    this.translate.use(lang);
    localStorage.setItem('user_lang', JSON.stringify(lang));
    this.langFromStorage = lang;
    this.defaultLang = lang;
  }

  getLang(): any {
    return this.translate.currentLang;
  }

  getFullLangName(langSlug: string): string {
    switch (langSlug.toLowerCase()) {
      case EnLang.name:
        return EnLang.value;
      case DeLang.name:
        return DeLang.value;
      case FrLang.name:
        return FrLang.value;
      case EsLang.name:
        return EsLang.value;
      case ItLang.name:
        return ItLang.value;
      default:
        return EnLang.value;
    }
  }

  getShortenedLangName(lang: string): string {
    switch (lang.toLowerCase()) {
      case EnLang.value:
        return EnLang.name;
      case DeLang.value:
        return DeLang.name;
      case FrLang.value:
        return FrLang.name;
      case EsLang.value:
        return EsLang.name;
      case ItLang.value:
        return ItLang.name;
      default:
        return EnLang.name;
    }
  }

  getAllLanguages(): any {
    return this.translate.getLangs();
  }

  changeLang(lang: any): void {
    AvailableLanguages.find((langObj: any) => {
      if (langObj.name === lang) {
        this.setLang(langObj.name);
        this.changeLangHttp(lang);
      }
    });
  }

  changeLangHttp(lang: string): void {
    // this.httpUserService.changeLang(this.authHelperService.getUserData.id, {lang: this.getShortenedLangName(lang)}).subscribe((response: any) => {
    // });
  }
}
