import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SingleListComponent } from './single-list.component';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.singleList.pageInRouting,
    data: { data: PAGES_AND_PATHS.singleList },
    component: SingleListComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./single-list.module').then(m => m.SingleListModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SingleListRoutingModule { }
