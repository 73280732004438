import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {GeneralHttpHelperService} from "../../helpers/general-http-helper.service";

const PROFILE_API_ROUTES = {
  GET_AUTH_USER_PROFILE: 'user/profile',
  PATCH_AUTH_USER_PROFILE: 'user/profile',
  GET_AUTH_USER_LYSTS: 'user/lysts',
  GET_AUTH_USER_POSTS: 'user/posts',
  GET_USER_PROFILE: 'user/:userId/profile',
  GET_USER_LYSTS: 'user/:userId/lysts',
  GET_USER_POSTS: 'user/:userId/posts',
  CHANGE_PROFILE_IMAGE: 'user/avatar',
  DELETE_PROFILE_IMAGE: 'user/avatar/remove',
  EDIT_PROFILE_USERNAME: 'user/username',
  EDIT_PROFILE_EMAIL: 'user/email',
  CHANGE_PROFILE_PASSWORD: 'user/password',
  GET_USERS_FOR_SHARE: 'user/get_users_for_chat',
  TOGGLE_PROFILE_FAVORITE: 'favorites/users/:userId/toggle'
}
@Injectable({
  providedIn: 'root'
})
export class ProfileHttpService {

  constructor(private httpClient: HttpClient,
              private generalHttpHelperService: GeneralHttpHelperService) {
  }

  private getAuthUserProfileReq(): Observable<any> {
    return this.httpClient.get(PROFILE_API_ROUTES.GET_AUTH_USER_PROFILE) as any;
  }

  getAuthUserProfile(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getAuthUserProfileReq().subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private getUserProfileReq(urlParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(PROFILE_API_ROUTES.GET_USER_PROFILE, urlParams)
    return this.httpClient.get(apiUrl) as any;
  }


  getUserProfile(urlParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getUserProfileReq(urlParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }


  private getAuthUserLystsReq(urlParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.addUrlParams(PROFILE_API_ROUTES.GET_AUTH_USER_LYSTS, urlParams);
    return this.httpClient.get(apiUrl) as any;
  }

  getAuthUserLysts(urlParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getAuthUserLystsReq(urlParams).subscribe((res: any) => {
        resolve({
          data: res.data.data,
          pagination: this.generalHttpHelperService.returnPagination(res.data)
        });
      }, error => {
        reject(error);
      });
    });
  }

  private getUserLystsReq(urlParams: any): Observable<any> {
    const{userId, ...resUrlParams} = urlParams;
    let apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(PROFILE_API_ROUTES.GET_USER_LYSTS, {userId});
    apiUrl = this.generalHttpHelperService.addUrlParams(apiUrl, resUrlParams);
    return this.httpClient.get(apiUrl) as any;
  }

  getUserLysts(urlParam: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getUserLystsReq(urlParam).subscribe((res: any) => {
        resolve({
          data: res.data.data,
          pagination: this.generalHttpHelperService.returnPagination(res.data)
        });
      }, error => {
        reject(error);
      });
    });
  }

  private getAuthUserPostsReq(urlParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.addUrlParams(PROFILE_API_ROUTES.GET_AUTH_USER_POSTS, urlParams);
    return this.httpClient.get(apiUrl) as any;
  }

  getAuthUserPosts(urlParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getAuthUserPostsReq(urlParams).subscribe((res: any) => {
        resolve({
          data: res.data.data,
          pagination: this.generalHttpHelperService.returnPagination(res.data)
        });
      }, error => {
        reject(error);
      });
    });
  }

  private getUserPostsReq(urlParams: any): Observable<any> {
    const{userId, ...resUrlParams} = urlParams;
    let apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(PROFILE_API_ROUTES.GET_USER_POSTS, {userId});
    apiUrl = this.generalHttpHelperService.addUrlParams(apiUrl, resUrlParams);
    return this.httpClient.get(apiUrl) as any;
  }

  getUserPosts(urlParam: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getUserPostsReq(urlParam).subscribe((res: any) => {
        resolve({
          data: res.data.data,
          pagination: this.generalHttpHelperService.returnPagination(res.data)
        });
      }, error => {
        reject(error);
      });
    });
  }

  private changeProfileImageReq(bodyParams: any): Observable<any> {
    return this.httpClient.post(PROFILE_API_ROUTES.CHANGE_PROFILE_IMAGE, bodyParams) as any;
  }

  changeProfileImage(bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.changeProfileImageReq(bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private deleteProfileImageReq(): Observable<any> {
    return this.httpClient.post(PROFILE_API_ROUTES.DELETE_PROFILE_IMAGE, {}) as any;
  }

  deleteProfileImage(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.deleteProfileImageReq().subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private editProfileUsernameReq(bodyParams: any): Observable<any> {
    return this.httpClient.post(PROFILE_API_ROUTES.EDIT_PROFILE_USERNAME, bodyParams) as any;
  }

  editProfileUsername(bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.editProfileUsernameReq(bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private editProfileEmailReq(bodyParams: any): Observable<any> {
    return this.httpClient.post(PROFILE_API_ROUTES.EDIT_PROFILE_EMAIL, bodyParams) as any;
  }

  editProfileEmail(bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.editProfileEmailReq(bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private changeProfilePasswordReq(bodyParams: any): Observable<any> {
    return this.httpClient.post(PROFILE_API_ROUTES.CHANGE_PROFILE_PASSWORD, bodyParams) as any;
  }

  changeProfilePassword(bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.changeProfilePasswordReq(bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private editAuthUserProfileReq(bodyParams: any): Observable<any> {
    return this.httpClient.patch(PROFILE_API_ROUTES.PATCH_AUTH_USER_PROFILE, bodyParams) as any;
  }

  editAuthUserProfile(bodyParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.editAuthUserProfileReq(bodyParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private getUserForShareReq(urlParams: any): Observable<any> {
    const apiUrl = urlParams.search_keyword.length ? this.generalHttpHelperService.addUrlParams(PROFILE_API_ROUTES.GET_USERS_FOR_SHARE, urlParams) : PROFILE_API_ROUTES.GET_USERS_FOR_SHARE;
    return this.httpClient.get(apiUrl) as any;
  }

  getUserForShare(urlParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getUserForShareReq(urlParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }

  private toggleProfileFavoriteReq(urlParams: any): Observable<any> {
    const apiUrl = this.generalHttpHelperService.replaceUrlParamsWithValues(PROFILE_API_ROUTES.TOGGLE_PROFILE_FAVORITE, urlParams)
    return this.httpClient.post(apiUrl, {}) as any;
  }

  toggleProfileFavorite(urlParams: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.toggleProfileFavoriteReq(urlParams).subscribe((res: any) => {
        resolve(res);
      }, error => {
        reject(error);
      });
    });
  }
}
