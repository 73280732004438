import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'handleShowMore',
  standalone: true
})
export class HandleShowMorePipe implements PipeTransform {

  transform(value: string, initialLength: number, isShowMore: boolean = false): string {
    return isShowMore
      ? value
      : value.slice(0, initialLength) + (value.length > initialLength ? '...' : '');
  }

}
