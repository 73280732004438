import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {DatePickerModule} from "../../../../../shared-components/date-picker/date-picker.module";
import {
  FormControlErrorMessagesModule
} from "../../../../../pipes/form-control-error-messages/form-control-error-messages.module";
import {DatePipe, NgClass, NgIf} from "@angular/common";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {AuthenticationHelperService} from "../../../../../services/helpers/authentication-helper.service";
import {ProfileHttpService} from "../../../../../services/http-services/profile/profile-http.service";
import {SpinnerComponent} from "../../../../../shared-components/spinner/spinner.component";

@Component({
  selector: 'tl-edit-profile-birthday',
  standalone: true,
  templateUrl: './edit-profile-birthday.component.html',
  providers: [DatePipe],
  imports: [
    DatePickerModule,
    FormControlErrorMessagesModule,
    NgIf,
    NgClass,
    ReactiveFormsModule,
    SpinnerComponent
  ]
})
export class EditProfileBirthdayComponent implements OnInit, OnChanges {
  editProfileDateOfBirthdayForm!: FormGroup;
  isSpinnerActive = false ;
  formTriedToSubmit = false;
  selectedDate: any;
  @Input() profileData: any;
  @Output() changeProfile: EventEmitter<any> = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder,
              private toastService: ToastrService,
              private datePipe: DatePipe,
              private authHelperService: AuthenticationHelperService,
              private profileHttpService: ProfileHttpService) {
  }
  ngOnInit(): void {
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.profileDate && changes.profileData.currentValue.date_of_birth) {
      this.selectedDate = changes.profileData.currentValue.date_of_birth
      const initialDate = this.datePipe.transform(changes.profileData.currentValue.date_of_birth, 'dd.MM.yyyy.');
      this.editProfileDateOfBirthdayForm.controls['date_of_birth'].patchValue(initialDate);
    }
  }

  buildForm(): void {
    this.editProfileDateOfBirthdayForm = this.formBuilder.group({
      date_of_birth: ['', [Validators.required]],
    });
    this.profileData = this.authHelperService.getUserData;
    if (this.profileData?.date_of_birth) {
      this.selectedDate = this.profileData.date_of_birth;
      const initialDate = this.datePipe.transform(this.profileData.date_of_birth, 'dd.MM.yyyy.');
      this.editProfileDateOfBirthdayForm.controls['date_of_birth'].patchValue(initialDate);
    }
  }

  onSelectDateOfBirth(selectedDate: any): void {
    this.selectedDate = selectedDate;
    this.editProfileDateOfBirthdayForm.controls.date_of_birth.patchValue(this.datePipe.transform(selectedDate._d, 'dd.MM.yyyy.'));
  }

  onCancelChanges(): void {
    this.editProfileDateOfBirthdayForm.reset();
    if (this.profileData?.date_of_birth) {
      this.selectedDate = this.profileData.date_of_birth;
      const initialDate = this.datePipe.transform(this.profileData.date_of_birth, 'dd.MM.yyyy.');
      this.editProfileDateOfBirthdayForm.controls['date_of_birth'].patchValue(initialDate);
    }
  }

  onSubmitEditProfileDateOfBirthdayForm(): void {
    this.isSpinnerActive = true;
    this.editProfileDateOfBirthdayForm.controls['date_of_birth'].markAsTouched();
    if (this.editProfileDateOfBirthdayForm.valid) {
      const [day, month, year] = this.editProfileDateOfBirthdayForm.controls['date_of_birth'].value.split('.');
      const bodyParams = {date_of_birth: `${year}-${month}-${day}`}
      this.profileHttpService.editAuthUserProfile(bodyParams).then(res => {
        this.isSpinnerActive = false;
        this.changeProfile.emit(res.data);
        this.toastService.success(res.message);
      }).catch(({error}: {error:{ errors: { [key: string]: string }}}) => {
        this.isSpinnerActive = false;
        Object.values(error.errors).forEach(error => this.toastService.error(error));
      });
    }
  }

}
