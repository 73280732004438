import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FavoriteListersComponent } from './favorite-listers.component';
import { PAGES_AND_PATHS } from 'src/app/constants/pages-and-paths';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';

const routes: Routes = [
  {
    path: PAGES_AND_PATHS.favoriteListers.pageInRouting,
    data: { data: PAGES_AND_PATHS.favoriteListers },
    component: FavoriteListersComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./favorite-listers.module').then(m => m.FavoriteListersModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FavoriteListersRoutingModule { }
