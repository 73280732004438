<div class="wrapper-forgot-password-page">
  <div class="content-forgot-password">
    <div class="back-btn" [routerLink]="PAGES_AND_PATHS.forgotPassword.pagePath">
      <img src="../assets/icons/icon-arrow-left.svg" alt="">
    </div>
    <div class="wrapper-circle-gray-icon">
      <img src="../assets/icons/outline/icon-email-sent.svg" alt="">
    </div>
    <div class="wrapper-header-forgot-password">
      <h1>Check your email!</h1>
      <h6>Your temporary password was successfully sent to your email. Please login with your temporary password.</h6>
    </div>
    <button class="custom-btn custom-btn--primary xs-mt-12" [routerLink]="PAGES_AND_PATHS.login.pagePath">
      <span>Login</span>
    </button>
    <div class="wrapper-label-link">
      <span>Didn’t receive an email?</span>
      <a href="javascript:void(0)" (click)="resendResetPasswordForm()">Resend email</a>
    </div>
  </div>
</div>
