import { Injectable } from '@angular/core';
import { LocalStorageProperties } from '../../enums/general-enums';
import { BehaviorSubject } from 'rxjs';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationHelperService {

  private isUserAuthenticated = false;
  private userData: any = null;
  private authUserRole = '';
  private token = '';
  private authStatusSubject = new BehaviorSubject<boolean>(false);
  public authStatus$ = this.authStatusSubject.asObservable();

  constructor(private router: Router) {
    this.checkIfUserIsLogged();
  }

  get isAuthenticated(): boolean {
    return this.isUserAuthenticated;
  }

  get getAuthUserRole(): string {
    return this.authUserRole;
  }

  get getToken(): string {
    return this.token;
  }

  private setToken(newData: any): void {
    this.token = newData;
    localStorage.setItem(LocalStorageProperties.token, JSON.stringify(this.token));
  }

  get getUserData(): any {
    return this.userData;
  }

  setUserData(newData: any): void {
    this.userData = newData;
    // this.authUserRole = newData.role.name;
    localStorage.setItem(LocalStorageProperties.user, JSON.stringify(this.userData));
  }

  clientLogin(user: any, token: string, redirectionUrl: string): void {
    this.setUserData(user);
    this.setToken(token);
    this.isUserAuthenticated = true;
    if (redirectionUrl) {
      setTimeout(() => {
        this.router.navigate([redirectionUrl]);
      });
    }
    this.authStatusSubject.next(true);
  }

  clientLogout(redirectionUrl: string): void {
    this.deleteClientInfoFromStorage();
    this.authStatusSubject.next(false);
    this.router.navigate([redirectionUrl]);
  }

  private deleteClientInfoFromStorage(): void {
    localStorage.removeItem(LocalStorageProperties.user);
    localStorage.removeItem(LocalStorageProperties.userProfile);
    localStorage.removeItem(LocalStorageProperties.token);
    this.userData = null;
    this.token = '';
    this.isUserAuthenticated = false;
    this.authUserRole = '';
  }

  private checkIfUserIsLogged(): void {
    let user: any;
    let token: any;
    if (localStorage.getItem(LocalStorageProperties.user) !== 'undefined') {
      user = JSON.parse(localStorage.getItem(LocalStorageProperties.user) as string);
    }
    if (localStorage.getItem(LocalStorageProperties.token) !== 'undefined') {
      token = JSON.parse(localStorage.getItem(LocalStorageProperties.token) as string);
    }
    if (user && !this.isUserAuthenticated) {
      this.clientLogin(user, token, '');
    }
    if (this.isUserAuthenticated && user) {
      this.authStatusSubject.next(true);
    }
  }
}
