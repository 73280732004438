<!--<div class="wrapper-insights-page">-->
<!--  <span class="title-settings-page">Content you created</span>-->
<!--  <div class="line-gray"></div>-->
  <div class="content-insights">
    <div class="content-slide-list-insights">
      <div class="grid">
        <div class="grid__s-4">
          <div class="wrapper-list-insights">
            <div class="image-insights">
              <img src="https://media.architecturaldigest.com/photos/63079fc7b4858efb76814bd2/16:9/w_4000,h_2250,c_limit/9.%20DeLorean-Alpha-5%20%5BDeLorean%5D.jpg" alt="">
            </div>
            <span class="name-list-insights">Wine and Dine</span>
            <p>743 <span>Likes</span></p>
            <p>66 <span>Views</span></p>
            <p>85 <span>Shares</span></p>
          </div>
        </div>
        <div class="grid__s-4">
          <div class="wrapper-list-insights">
            <div class="image-insights">
              <img src="https://media.architecturaldigest.com/photos/63079fc7b4858efb76814bd2/16:9/w_4000,h_2250,c_limit/9.%20DeLorean-Alpha-5%20%5BDeLorean%5D.jpg" alt="">
            </div>
            <span class="name-list-insights">Wine and Dine</span>
            <p>743 <span>Likes</span></p>
            <p>66 <span>Views</span></p>
            <p>85 <span>Shares</span></p>
          </div>
        </div>
        <div class="grid__s-4">
          <div class="wrapper-list-insights">
            <div class="image-insights">
              <img src="https://media.architecturaldigest.com/photos/63079fc7b4858efb76814bd2/16:9/w_4000,h_2250,c_limit/9.%20DeLorean-Alpha-5%20%5BDeLorean%5D.jpg" alt="">
            </div>
            <span class="name-list-insights">Wine and Dine</span>
            <p>743 <span>Likes</span></p>
            <p>66 <span>Views</span></p>
            <p>85 <span>Shares</span></p>
          </div>
        </div>
        <div class="grid__s-4">
          <div class="wrapper-list-insights">
            <div class="image-insights">
              <img src="https://media.architecturaldigest.com/photos/63079fc7b4858efb76814bd2/16:9/w_4000,h_2250,c_limit/9.%20DeLorean-Alpha-5%20%5BDeLorean%5D.jpg" alt="">
            </div>
            <span class="name-list-insights">Wine and Dine</span>
            <p>743 <span>Likes</span></p>
            <p>66 <span>Views</span></p>
            <p>85 <span>Shares</span></p>
          </div>
        </div>
        <div class="grid__s-4">
          <div class="wrapper-list-insights">
            <div class="image-insights">
              <img src="https://media.architecturaldigest.com/photos/63079fc7b4858efb76814bd2/16:9/w_4000,h_2250,c_limit/9.%20DeLorean-Alpha-5%20%5BDeLorean%5D.jpg" alt="">
            </div>
            <span class="name-list-insights">Wine and Dine</span>
            <p>743 <span>Likes</span></p>
            <p>66 <span>Views</span></p>
            <p>85 <span>Shares</span></p>
          </div>
        </div>
        <div class="grid__s-4">
          <div class="wrapper-list-insights">
            <div class="image-insights">
              <img src="https://media.architecturaldigest.com/photos/63079fc7b4858efb76814bd2/16:9/w_4000,h_2250,c_limit/9.%20DeLorean-Alpha-5%20%5BDeLorean%5D.jpg" alt="">
            </div>
            <span class="name-list-insights">Wine and Dine</span>
            <p>743 <span>Likes</span></p>
            <p>66 <span>Views</span></p>
            <p>85 <span>Shares</span></p>
          </div>
        </div>
        <div class="grid__s-4">
          <div class="wrapper-list-insights">
            <div class="image-insights">
              <img src="https://media.architecturaldigest.com/photos/63079fc7b4858efb76814bd2/16:9/w_4000,h_2250,c_limit/9.%20DeLorean-Alpha-5%20%5BDeLorean%5D.jpg" alt="">
            </div>
            <span class="name-list-insights">Wine and Dine</span>
            <p>743 <span>Likes</span></p>
            <p>66 <span>Views</span></p>
            <p>85 <span>Shares</span></p>
          </div>
        </div>
      </div>
    </div>
    <!--  TODO JS - No data list view  -->
    <div *ngIf="false" class="wrapper-no-list-view-insights">
      <img src="./assets/icons/icon-no-list-insights.svg" alt="">
      <span class="label1">No lists available</span>
      <span class="label2">You didn’t post any lists during this time.</span>
    </div>
  </div>
<!--</div>-->
